import React, { useState, useRef, useEffect } from "react";
import img_1 from "../../../assets/intergrity_is_my_personality.png";
import img_2 from "../../../assets/cFDA_IDL.png";
import img_3 from "../../../assets/L2LWorkshop.png";
import img_4 from "../../../assets/Aspire2inspire_Workshop.png";
import img_5 from "../../../assets/peer2post2.png";
import icratgy from "../../../assets/icretegy_logo_1.png";
import styles from "./course.module.css";
import { Button, Card, Modal, ConfigProvider } from "antd";
import Aspire2InspireVIdeo from "../../../assets/A2I.mp4";
import L2L_video from "../../../assets/L2L.mp4";
import idl_video from "../../../assets/Cipla.mp4";
import FDCA_video from "../../../assets/FDCA.mp4";
import BDRVideo from "../../../assets/BDR.mp4";
import samplePDF from "../../../assets/A2I_Flyer.pdf";
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import fdca from "../../../assets/FDCA.pdf";
import ll from "../../../assets/L2L.pdf";
import idl from "../../../assets/IDL.pdf";
import P2P_IQA from '../../../assets/P2P_IQA.pdf'

import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

import A2I_C_I1 from "../../../assets/A2I_Carosul_Photos/1.JPG";
import A2I_C_I2 from "../../../assets/A2I_Carosul_Photos/2.JPG";
import A2I_C_I3 from "../../../assets/A2I_Carosul_Photos/3.JPG";
import A2I_C_I4 from "../../../assets/A2I_Carosul_Photos/4.JPG";
import A2I_C_I5 from "../../../assets/A2I_Carosul_Photos/5.JPG";
import A2I_C_I6 from "../../../assets/A2I_Carosul_Photos/6.JPG";
import A2I_C_I7 from "../../../assets/A2I_Carosul_Photos/7.JPG";
import A2I_C_I8 from "../../../assets/A2I_Carosul_Photos/8.JPG";
import A2I_C_I9 from "../../../assets/A2I_Carosul_Photos/9.JPG";
import A2I_C_I10 from "../../../assets/A2I_Carosul_Photos/10.JPG";
import A2I_C_I11 from "../../../assets/A2I_Carosul_Photos/11.JPG";
import A2I_C_I12 from "../../../assets/A2I_Carosul_Photos/12.JPG";
import A2I_C_I13 from "../../../assets/A2I_Carosul_Photos/13.JPG";
import A2I_C_I14 from "../../../assets/A2I_Carosul_Photos/14.JPG";
import A2I_C_I15 from "../../../assets/A2I_Carosul_Photos/15.JPG";
import A2I_C_I16 from "../../../assets/A2I_Carosul_Photos/16.JPG";
import A2I_C_I17 from "../../../assets/A2I_Carosul_Photos/17.JPG";
import A2I_C_I18 from "../../../assets/A2I_Carosul_Photos/18.JPG";

import IQA_img1 from "../../../assets/BDR IQA Workshop/1.JPG";
import IQA_img2 from "../../../assets/BDR IQA Workshop/2.JPG";
import IQA_img3 from "../../../assets/BDR IQA Workshop/3.JPG";
import IQA_img4 from "../../../assets/BDR IQA Workshop/4.JPG";
import IQA_img6 from "../../../assets/BDR IQA Workshop/6.JPG";
import IQA_img9 from "../../../assets/BDR IQA Workshop/9.JPG";
import IQA_img11 from "../../../assets/BDR IQA Workshop/11.jpg";
import IQA_img12 from "../../../assets/BDR IQA Workshop/12.jpg";
import IQA_img13 from "../../../assets/BDR IQA Workshop/13.jpg";
import IQA_img14 from "../../../assets/BDR IQA Workshop/14.JPG";
import IQA_img15 from "../../../assets/BDR IQA Workshop/15.JPG";
import IQA_img16 from "../../../assets/BDR IQA Workshop/16.JPG";

import cFDA_Img1 from "../../../assets/cFDA Cipla Workshop/1.JPG";
import cFDA_Img2 from "../../../assets/cFDA Cipla Workshop/2.JPG";
import cFDA_Img3 from "../../../assets/cFDA Cipla Workshop/3.JPG";
import cFDA_Img4 from "../../../assets/cFDA Cipla Workshop/4.JPG";
import cFDA_Img5 from "../../../assets/cFDA Cipla Workshop/5.JPG";
import cFDA_Img6 from "../../../assets/cFDA Cipla Workshop/6.JPG";
import cFDA_Img7 from "../../../assets/cFDA Cipla Workshop/7.JPG";
import cFDA_Img8 from "../../../assets/cFDA Cipla Workshop/8.JPG";
import cFDA_Img9 from "../../../assets/cFDA Cipla Workshop/9.JPG";
import cFDA_Img10 from "../../../assets/cFDA Cipla Workshop/10.JPG";
import cFDA_Img11 from "../../../assets/cFDA Cipla Workshop/11.JPG";
import cFDA_Img12 from "../../../assets/cFDA Cipla Workshop/12.JPG";
import cFDA_Img13 from "../../../assets/cFDA Cipla Workshop/13.JPG";
import cFDA_Img14 from "../../../assets/cFDA Cipla Workshop/14.JPG";
import cFDA_Img15 from "../../../assets/cFDA Cipla Workshop/15.JPG";
import cFDA_Img16 from "../../../assets/cFDA Cipla Workshop/16.JPG";

import FCDA_img1 from "../../../assets/FDCA Gujarat Workshop/1.jpeg";
import FCDA_img3 from "../../../assets/FDCA Gujarat Workshop/3.jpeg";
import FCDA_img4 from "../../../assets/FDCA Gujarat Workshop/4.jpeg";
import FCDA_img5 from "../../../assets/FDCA Gujarat Workshop/5.jpeg";
import FCDA_img6 from "../../../assets/FDCA Gujarat Workshop/6.jpeg";
import FCDA_img7 from "../../../assets/FDCA Gujarat Workshop/7.jpeg";
import FCDA_img8 from "../../../assets/FDCA Gujarat Workshop/8.jpeg";
import FCDA_img9 from "../../../assets/FDCA Gujarat Workshop/9.jpeg";
import FCDA_img11 from "../../../assets/FDCA Gujarat Workshop/11.jpeg";
import FCDA_img12 from "../../../assets/FDCA Gujarat Workshop/12.jpeg";

import L2L_img1 from "../../../assets/L2L Harman Workshop/1.JPG";
import L2L_img2 from "../../../assets/L2L Harman Workshop/2.JPG";
import L2L_img3 from "../../../assets/L2L Harman Workshop/3.JPG";
import L2L_img4 from "../../../assets/L2L Harman Workshop/4.JPG";
import L2L_img5 from "../../../assets/L2L Harman Workshop/5.JPG";
import L2L_img6 from "../../../assets/L2L Harman Workshop/6.JPG";
import L2L_img7 from "../../../assets/L2L Harman Workshop/7.JPG";
import L2L_img8 from "../../../assets/L2L Harman Workshop/8.JPG";
import L2L_img9 from "../../../assets/L2L Harman Workshop/9.JPG";
import L2L_img10 from "../../../assets/L2L Harman Workshop/10.JPG";
import L2L_img11 from "../../../assets/L2L Harman Workshop/11.JPG";
import L2L_img12 from "../../../assets/L2L Harman Workshop/12.JPG";
import L2L_img13 from "../../../assets/L2L Harman Workshop/13.JPG";
import { devUseWarning } from "antd/es/_util/warning";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const Courses = () => {

    const width = 700;
  const height = 1000;
  console.log("DocViewerRenderers",DocViewerRenderers);

  const Page = React.forwardRef(({ pageNumber }, ref) => {
    return (
      <div ref={ref}>
        <ReactPdfPage pageNumber={pageNumber} width={width} />
      </div>
    );
  });

  const images = [
    {
      id: 1,
      img: img_1,
      course: "Lead With In",
      pdf: fdca,
      video: [{ uri:FDCA_video }],
      imageData: [
        { url: FCDA_img1, alt: "Image 1" },
        { url: FCDA_img3, alt: "Image 1" },
        { url: FCDA_img4, alt: "Image 1" },
        { url: FCDA_img5, alt: "Image 1" },
        { url: FCDA_img6, alt: "Image 1" },
        { url: FCDA_img7, alt: "Image 1" },
        { url: FCDA_img8, alt: "Image 1" },
        { url: FCDA_img9, alt: "Image 1" },
        { url: FCDA_img11, alt: "Image 1" },
        { url: FCDA_img12, alt: "Image 1" },
      ],
    },
    {
      id: 2,
      img: img_2,
      course: "cFDA IDL",
      pdf: idl,
      video: [{ uri:idl_video }],

      imageData: [
        { url: cFDA_Img1, alt: "Image 1" },
        { url: cFDA_Img2, alt: "Image 1" },
        { url: cFDA_Img3, alt: "Image 1" },
        { url: cFDA_Img4, alt: "Image 1" },
        { url: cFDA_Img5, alt: "Image 1" },
        { url: cFDA_Img6, alt: "Image 1" },
        { url: cFDA_Img7, alt: "Image 1" },
        { url: cFDA_Img8, alt: "Image 1" },
        { url: cFDA_Img9, alt: "Image 1" },
        { url: cFDA_Img10, alt: "Image 1" },
        { url: cFDA_Img11, alt: "Image 1" },
        { url: cFDA_Img12, alt: "Image 1" },
        { url: cFDA_Img13, alt: "Image 1" },
        { url: cFDA_Img14, alt: "Image 1" },
        { url: cFDA_Img15, alt: "Image 1" },
        { url: cFDA_Img16, alt: "Image 1" },
      ],
    },
    {
      id: 3,
      img: img_3,
      course: "Learn 2 Lead",
      pdf: ll,
      video: [{ uri:L2L_video  }],
      imageData: [
        { url: L2L_img1, alt: "Image 1" },
        { url: L2L_img2, alt: "Image 1" },
        { url: L2L_img3, alt: "Image 1" },
        { url: L2L_img4, alt: "Image 1" },
        { url: L2L_img5, alt: "Image 1" },
        { url: L2L_img6, alt: "Image 1" },
        { url: L2L_img7, alt: "Image 1" },
        { url: L2L_img8, alt: "Image 1" },
        { url: L2L_img9, alt: "Image 1" },
        { url: L2L_img10, alt: "Image 1" },
        { url: L2L_img11, alt: "Image 1" },
        { url: L2L_img12, alt: "Image 1" },
        { url: L2L_img13, alt: "Image 1" },
      ],
    },
    {
      id: 4,
      img: img_4,
      course: "Apsire 2 Inspire",
      pdf: samplePDF,
      video: [{ uri:Aspire2InspireVIdeo  }],

      imageData: [
        { url: A2I_C_I1, alt: "Image 1" },
        { url: A2I_C_I2, alt: "Image 1" },
        { url: A2I_C_I3, alt: "Image 1" },
        { url: A2I_C_I4, alt: "Image 1" },
        { url: A2I_C_I5, alt: "Image 1" },
        { url: A2I_C_I6, alt: "Image 1" },
        { url: A2I_C_I7, alt: "Image 1" },
        { url: A2I_C_I8, alt: "Image 1" },
        { url: A2I_C_I9, alt: "Image 1" },
        { url: A2I_C_I10, alt: "Image 1" },
        { url: A2I_C_I11, alt: "Image 1" },
        { url: A2I_C_I12, alt: "Image 1" },
        { url: A2I_C_I12, alt: "Image 1" },
        { url: A2I_C_I13, alt: "Image 1" },
        { url: A2I_C_I14, alt: "Image 1" },
        { url: A2I_C_I15, alt: "Image 1" },
        { url: A2I_C_I16, alt: "Image 1" },
        { url: A2I_C_I17, alt: "Image 1" },
        { url: A2I_C_I18, alt: "Image 1" },
      ],
    },
    {
      id: 5,
      img: img_5,
      course: "Prior 2 Post",
      pdf: P2P_IQA,
      video: [{ uri:BDRVideo  }],

      imageData: [
        { url: IQA_img1, alt: "Image 1" },
        { url: IQA_img2, alt: "Image 1" },
        { url: IQA_img3, alt: "Image 1" },
        { url: IQA_img4, alt: "Image 1" },
        { url: IQA_img6, alt: "Image 1" },
        { url: IQA_img9, alt: "Image 1" },
        { url: IQA_img11, alt: "Image 1" },
        { url: IQA_img12, alt: "Image 1" },
        { url: IQA_img13, alt: "Image 1" },
        { url: IQA_img14, alt: "Image 1" },
        { url: IQA_img15, alt: "Image 1" },
        { url: IQA_img16, alt: "Image 1" },
      ],
    },
  ];

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const firstThreeCards = images.slice(0, 3);
  const remainingCards = images.slice(3);
  const vidRef = useRef(); 
  const videoUrl = useRef();
  const openModal = (data) => {
    setOpen(true);
    videoUrl.current = null;
    setTimeout(() => {
        videoUrl.current = data.video;
      }, 100);
    setModalData(data);
  };

  const closeModal = () => {
    setModalData({});
    vidRef?.current?.pause();
    setOpen(false);
    setCurrentPage(1);
  };

  const customMenuItems = [
    {
      label: "Toggle Full Screen",
      icon: "fullscreen",
      onClick: () => console.log("Toggle Full Screen"),
    },
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex = prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex = prevIndex + 1));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  return (
    <div className={styles.workshopwrp}>
      <div className={styles.courseHeader}>
        <h1 className="heading">Several Workshops Conducted by</h1>
        <div className={styles.heading_logo}>
          <img src={icratgy} />
        </div>
      </div>
      <div className={styles.CourseList_info}>
        <div className={styles.firstLineCards}>
          {firstThreeCards?.map((data, index) => {
            return (
              <Card hoverable className={styles.workshopCard}>
                <div className={styles.worksopCardImg}>
                  <img alt="example" src={data.img} />
                </div>
                <div>
                  <Button
                    onClick={() => openModal(data)}
                    style={{
                      marginTop: "1rem",
                      backgroundColor: "#171151",
                      color: "white",
                    }}
                  >
                    View Flyer
                  </Button>
                </div>
              </Card>
            );
          })}
        </div>
        <div className={styles.secondLineCards}>
          {remainingCards?.map((data, index) => {
            return (
              <Card hoverable className={styles.workshopCard2}>
                <div className={styles.worksopCardImg}>
                  <img alt="example" src={data.img} />
                </div>
                <div>
                  <Button
                    style={{
                      marginTop: "1rem",
                      backgroundColor: "#171151",
                      color: "white",
                    }}
                    onClick={() => openModal(data)}
                  >
                    View Flyer
                  </Button>
                </div>
              </Card>
            );
          })}
        </div>

        <Modal
          centered
          open={open}
          onOk={() => setOpen(false)}
          onCancel={() => closeModal(false)}
          width={1200}
          footer={[]}
        >
          {modalData !== null && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <h2>Workshop Flyer</h2>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "1rem",
                  }}
                >
                  <Document file={modalData?.pdf}>
                    <HTMLFlipBook width={width} height={height}>
                      <div>
                        <Page pageNumber={currentPage} />
                      </div>
                    </HTMLFlipBook>
                  </Document>
                  <div>
                    <Button
                      className={styles.prevPageBtn}
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                    >
                      <IoIosArrowBack />
                    </Button>
                    <Button
                      className={styles.nextPageBtn}
                      onClick={handleNextPage}
                      disabled={currentPage === 4}
                    >
                      <IoIosArrowForward />
                    </Button>
                  </div>
                </div>
              </div>
              <div style={{ height: "35rem", padding: "4rem 0" }}>
                <h2>Workshop Glimpse (Video)</h2>
                <DocViewer
                    documents={modalData?.video ?? ""}
                    config={{
                      header: {
                        disableFullScreen: true,
                        disableFileName: true,
                      },
                      disableDownload: true,
                      customMenu: customMenuItems,
                    }}
                    pluginRenderers={DocViewerRenderers}
                  />
                {/* {
                    videoUrl?.current &&
                <div className={styles.videoWrp}> */}

                    {/* {Clip(videoUrl.current)} */}
                  {/* <video
                    controlsList="nodownload"
                    width="600"
                    height="400"
                    controls
                    ref={vidRef}
                    >
                    <source src={videoUrl.current} type="video/mp4" />
                  </video> */}
                {/* </div>
                } */}
              </div>

              {modalData.imageData && (
                <>
                  <h2>Workshop Glimpse (Photos)</h2>
                  <div className={styles.carouselContainer}>
                    <div>
                      <div className={styles.carouselImage}>
                        <img
                          src={modalData.imageData[currentImageIndex].url}
                          alt={modalData.imageData[currentImageIndex].alt}
                        />
                      </div>
                      <div className={styles.carouselButtons}>
                        <Button
                          disabled={currentImageIndex === 0}
                          onClick={() => handlePrevious(modalData.imageData)}
                        >
                          Previous
                        </Button>
                        <Button
                          disabled={
                            currentImageIndex === modalData.imageData.length - 1
                          }
                          onClick={() => handleNext(modalData.imageData)}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Courses;
