import React, { useEffect, useRef, useState } from "react";
import styles from "./chat.module.scss";
// import headerImg from "../../assets/";
import TTInput from "./../shared/TTInput/TTInput";
import { IoSend } from "react-icons/io5";
import iCretegy from "../../assets/Vani's Patel.JPG";
import axios from "axios";
import { baseURL } from "../../Constant";
import { getToken } from "../../common/helpers/localStorage.helper";
import { useParams } from "react-router-dom";
import { getUserInfo } from "./../../common/helpers/localStorage.helper";
import { BsCheck2, BsCheck2All } from "react-icons/bs";
import ScrollToBottom from "react-scroll-to-bottom";
import { css } from "@emotion/css";


const ChatDetail = () => {
  const [chatData, setChatData] = useState([]);
  const [chatObj, setChatObj] = useState({
    message: "",
    });
    const token = getToken("token");
    const { id } = useParams();
    const user = getUserInfo();
    const ROOT_CSS = css({
      height: 400,
    });


    
  //functions
  const handleChatInput = (e) => {
    const { data, name } = e;
    let _chatObj = { ...chatObj };
    _chatObj[name] = data;
    setChatObj(_chatObj);
  };

  const fetchChetData = async () => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/fetch_chat/${user.id}`,
        data: { role: user.role },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the access token in the headers
        },
      });
      const data = await response?.data;
      setChatData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleKeyUp = (e) => {
    if (e.code === "Enter") {
      sendChetData();
    }
  };

  const sendChetData = async () => {
    let _chatobj = { ...chatObj };
    _chatobj["employee_id"] = user?.id;
    _chatobj["role"] = "employee";
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/send_chat`,
        data: _chatobj,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the access token in the headers
        },
      });

      let data = response?.data;

      if (data.message === "Chat sent successfully") {
        setChatObj({
          message: "",
        });
        fetchChetData();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //Callbacks
  useEffect(() => {
    fetchChetData();
  }, [token]);

  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.wrapper} ref={messageBoxRef} > */}
      <div className={styles.chatDetailHeader}>
        <div className={styles.headerWrp}>
          <div className={styles.headerImage}>
            <img src={iCretegy} />
          </div>
          <div className={styles.headerContent}>
            <h3>Ms. Vani Patel</h3>
            <p>iCAMS Advisory</p>
          </div>
        </div>
      </div>
        <div className={styles.chatHistory}>
          <ScrollToBottom className={ROOT_CSS}>
          {chatData?.map((chat, index) => {
            return (
              <div
                className={
                  chat.role === "employee" ? styles.rowRight : styles.rowLeft
                }
              >
                <div
                  key={index}
                  className={`${styles.messageContent} ${
                    chat.role === "admin" ? styles.adminMsg : styles.employeeMsg
                  }`}
                >
                  <p style={{ color: "#000" }}>{chat.message}</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: ".3rem",
                    }}
                  >
                    <p
                      style={{
                        color: "#000",
                        textAlign: "right",
                        fontSize: ".6rem",
                      }}
                    >
                      {chat.time}
                    </p>

                    {chat.role === "employee" && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {chat.is_read === true ? (
                          <BsCheck2All
                            style={{
                              color: "green",
                              fontWeight: "800",
                              fontSize: "1.1rem",
                            }}
                          />
                        ) : (
                          <BsCheck2 style={{ color: "gray" }} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          </ScrollToBottom>
        </div>
      <div className={styles.chatInput}>
        <TTInput
          name="message"
          value={chatObj.message}
          className={styles.input}
          placeholder={"Type Masage"}
          onChange={handleChatInput}
          onKeyUp={handleKeyUp}
        />
        <div className={styles.sendBtn}>
          <IoSend onClick={sendChetData} />
        </div>
      </div>
    </div>
  );
};

export default ChatDetail;
