import React, { useEffect, useState } from "react";
import { Layout, theme, Empty, Tooltip } from "antd";
import { FaUserCircle } from "react-icons/fa";
import styles from "../EmpDetail/empDetail.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../../Constant";
import {
  getToken,
  getUserInfo,
} from "../../../../common/helpers/localStorage.helper";
import SmHeader from "../../../SmHeader/SmHeader";
import Navbar from "../Navbar/Navbar";
import Chatbox from "../../Chatbox/Chatbox";

const CurrentAssignRole = () => {
  const [suborList, setSuborList] = useState([]);
  const [inlList, setInlList] = useState([]);
  const token = getToken("token");
  const user = getUserInfo();
  const [collapsed, setCollapsed] = useState(false);
  const { Content } = Layout;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  //fcutions
  const fetchSuborData = async (id) => {
    const url = `${baseURL}/show_ordinate/${id}`;
    try {
      const response = await axios({
        method: "get",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      const emplist = data?.employees;

      setSuborList(emplist);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchInlineData = async (id) => {
    const url = `${baseURL}/show_inlines/${id}`;
    try {
      const response = await axios({
        method: "get",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      const emplist = data?.employees;

      setInlList(emplist);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const renderData = (desc) => {
    return (
      <Empty style={{ fontSize: 18, fontWeight: "600" ,marginBottom:"2rem" }} description={desc} />
    );
  };

  //callbacks
  useEffect(() => {
    if (user?.id) {
      fetchSuborData(user?.id);
      fetchInlineData(user?.id);
    }
  }, [user?.id]);

  //Others
  const DataRender = (employee) => {
    return (
      <div className={styles.EmpList_card}>
        <div className={styles.EmpList_card_img_wrp}>
          {employee.profile_photo ? (
            <img src={`${baseURL}${employee.profile_photo}`} />
          ) : (
            <FaUserCircle />
          )}
        </div>
        <Tooltip
          title={`${employee?.user?.first_name} ${employee?.user?.last_name}`}
        >
          <h2 className={styles.ellips}>
            {employee?.user?.first_name} {employee?.user?.last_name}
          </h2>
        </Tooltip>
        {/* <h2 className={styles.subHead}>
          {employee?.user?.first_name} {employee?.user?.last_name}
        </h2> */}
        <p className={styles.empDes}>{employee?.designation}</p>
        <p>{employee?.department}</p>

        <button>
          {" "}
          <Link
            to={`/subor-courses/${employee?.user?.id}`}
            className={styles.Link}
          >
            View Course
          </Link>{" "}
        </button>
      </div>
    );
  };
  const DataInlRender = (employee) => {
    return (
      <div className={styles.EmpList_card}>
        <div className={styles.EmpList_card_img_wrp}>
          {employee.profile_photo ? (
            <img src={`${baseURL}${employee.profile_photo}`} />
          ) : (
            <FaUserCircle />
          )}
        </div>

        <Tooltip
          title={`${employee?.user?.first_name} ${employee?.user?.last_name}`}
        >
          <h2 className={styles.ellips}>
            {employee?.user?.first_name} {employee?.user?.last_name}
          </h2>
        </Tooltip>
        <p className={styles.empDes}>{employee?.designation}</p>
        <p>{employee?.department}</p>

        <button>
          {" "}
          <Link
            to={`/inlineCourse/${employee?.user?.id}`}
            className={styles.Link}
          >
            View Course
          </Link>{" "}
        </button>
      </div>
    );
  };

  return (
    <Layout>
      <Navbar collapsed={collapsed} />
      <Layout>
        <Content className={styles.empDetailPage}>
          <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />
          <div className={styles.empDetailwrp}>
            <div className={styles.EmpList}>
              <h1>Peer Assignments</h1>
              <div className={styles.EmpList_cards}>
                {inlList?.map((elist, index) => {
                  return <div key={index}>{DataInlRender(elist)}</div>;
                })}
              </div>
            </div>
            {inlList?.length === 0 &&
              renderData(
                "You are not assigned as a peer to any profiles currently!"
              )}
            <div className={styles.EmpList}>
              <h1>Subordinate Assignments</h1>
              <div className={styles.EmpList_cards}>
                {suborList?.map((elist, index) => {
                  return <div key={index}>{DataRender(elist)}</div>;
                })}
              </div>
            </div>
            {suborList?.length === 0 &&
              renderData(
                "You are not assigned as a subordinate to any profiles currently!"
              )}
          </div>
        </Content>
        <Chatbox />
      </Layout>
    </Layout>
  );
};
export default CurrentAssignRole;
