import React, { useEffect, useRef, useState } from "react";
import styles from "./adminChat.module.scss";
// import headerImg from "../../assets/";
import TTInput from "./../shared/TTInput/TTInput";
import { IoSend } from "react-icons/io5";
import { Button, Empty, Tooltip } from "antd";
import axios from "axios";
import { baseURL } from "../../Constant";
import { getToken } from "../../common/helpers/localStorage.helper";
import { getUserInfo } from "./../../common/helpers/localStorage.helper";
import { BsCheck2, BsCheck2All } from "react-icons/bs";
import { IoIosArrowBack } from "react-icons/io";
import ScrollToBottom from "react-scroll-to-bottom";
import { css } from "@emotion/css";

const AdminChatDetail = () => {
  const [chatData, setChatData] = useState([]);
  const [chatObj, setChatObj] = useState({
    message: "",
  });
  const [selectedUser, setSelectedUser] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [userList, setUserList] = useState([]);
  const token = getToken("token");
  const user = getUserInfo();
  const ROOT_CSS = css({
    height: 400,
  });

  //Callbacks
  useEffect(() => {
    if (token) {
      fetchAllUser(true);
    }
  }, [token]);

  //functions

  const handleChatInput = (e) => {
    const { data, name } = e;
    let _chatObj = { ...chatObj };
    _chatObj[name] = data;
    setChatObj(_chatObj);
  };

  const fetchChetData = async (id) => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/fetch_chat/${id}`,
        data: { role: user.role },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the access token in the headers
        },
      });
      const data = (await response?.data) ?? [];
      setChatData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const parseDateTime = (date, time) => {
    const dateTimeString = `${date} ${time}`;
    return new Date(dateTimeString);
  };

  const handleKeyUp = (e) => {
    if (e.code === "Enter") {
      sendChetData();
    }
  };

  const sendChetData = async () => {
    let _chatobj = { ...chatObj };
    _chatobj["employee_id"] = selectedUser?.id;
    _chatobj["role"] = "admin";
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/send_chat`,
        data: _chatobj,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the access token in the headers
        },
      });

      let data = response?.data;

      if (data.message === "Chat sent successfully") {
        fetchChetData(selectedUser?.id);
        fetchAllUser();
        setChatObj({
          message: "",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAllUser = async (isFirst) => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/all_employee_chat`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the access token in the headers
        },
      });
      let data = response?.data ?? [];
      const sortedData = data.sort((a, b) => {
        const aMessage = a.message;
        const bMessage = b.message;

        // Check if both messages have date and time properties
        if (
          aMessage &&
          aMessage.date &&
          aMessage.time &&
          bMessage &&
          bMessage.date &&
          bMessage.time
        ) {
          return (
            parseDateTime(bMessage.date, bMessage.time) -
            parseDateTime(aMessage.date, aMessage.time)
          );
        }
        // If one of the messages does not have date/time, prioritize the one that has
        if (aMessage && aMessage.date && aMessage.time) return -1;
        if (bMessage && bMessage.date && bMessage.time) return 1;
        // If neither have date/time, consider them equal
        return 0;
      });
      if (isFirst) {
        {
          window.innerWidth > 700 && setSelectedUser(sortedData[0]);
        }
        fetchChetData(sortedData[0]?.id);
      }
      setUserList({
        allData: data,
        filteredData: data,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = ({ data }) => {
    setSearchTerm(data);
    let _searchTerm = data?.toLowerCase();
    let _filtedred = userList.allData.filter((user) =>
      user.name.toLowerCase().includes(_searchTerm)
    );
    setUserList({
      ...userList,
      filteredData: _filtedred,
    });
  };

  const handleChangeChat = () => {
    setSelectedUser({});
  };

  const formatMessageTime = (message) => {
    const dateTimeString = `${message?.date} ${message?.time}`;
    const messageDate = new Date(dateTimeString);
    const today = new Date();
    
    const isToday = messageDate.toDateString() === today.toDateString();
    const isYesterday =
      new Date(today.setDate(today.getDate() - 1)).toDateString() ===
      messageDate.toDateString();

    if (isToday) {
      return messageDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else if (isYesterday) {
      return "Yesterday";
    } else {
      return messageDate.toLocaleDateString();
    }
  };


  //templates
  const renderData = () => {
    return (
      <div className={styles.wrapper}>
        <div className={styles.chatDetailHeader}>
          <div className={styles.headerWrp}>
            {window.innerWidth < 700 && (
              <div className={styles.backBtn} onClick={handleChangeChat}>
                <IoIosArrowBack />
              </div>
            )}
            <div className={styles.headerImage}>
              <img src={`${baseURL}${selectedUser.image}`} />
            </div>
            <div className={styles.headerContent}>
              <h3>{selectedUser.name}</h3>
              <p className={styles.designation}>
                {selectedUser?.designation} {selectedUser?.department}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.chatHistory} id="box">
          <ScrollToBottom className={ROOT_CSS}>
            {chatData?.map((chat, index) => {
              // const showDate = index === 0 || (chat.date !== chatData[index - 1].date);
              // console.log(chatData[index - 1].date,'data');

              return (
                <div
                  className={
                    chat.role === "admin" ? styles.rowRight : styles.rowLeft
                  }
                >
                  <div
                    key={index}
                    className={`${styles.messageContent} ${
                      chat.role === "employee"
                      ? styles.adminMsg
                      : styles.employeeMsg
                      }`}
                      >
                    {/* {showDate && (
                      <div className={styles.dateSeparator}>
                        {formatMessageTime(chat)}
                      </div>
                    )} */}
                    <p style={{ color: "#000" }}>{chat.message}</p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: ".3rem",
                      }}
                    >
                      <p
                        style={{
                          color: "#000",
                          textAlign: "right",
                          fontSize: ".6rem",
                        }}
                      >
                        {chat.time}
                      </p>
                      {chat.role === "admin" && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {chat.is_read === true ? (
                            <BsCheck2All
                              style={{
                                color: "green",
                                fontWeight: "800",
                                fontSize: "1.1rem",
                              }}
                            />
                          ) : (
                            <BsCheck2 style={{ color: "gray" }} />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </ScrollToBottom>
        </div>
        <div className={styles.chatInput}>
          <TTInput
            name="message"
            value={chatObj.message}
            className={styles.input}
            placeholder={"Type Masage"}
            onChange={handleChatInput}
            onKeyUp={handleKeyUp}
          />
          <div className={styles.sendBtn}>
            <IoSend onClick={sendChetData} />
          </div>
        </div>
      </div>
    );
  };

  const renderNoData = () => {
    return (
      <div className={styles.noData}>
        <Empty description="No Data Available!" />{" "}
      </div>
    );
  };

  return (
    <div className={styles.mainContainer}>
      {window.innerWidth < 700 && Object.keys(selectedUser)?.length === 0 && (
        <div className={styles.mainListWrp}>
          <div className={styles.subHeader}>
            <TTInput
              className={styles.inp}
              name="searchTerm"
              value={searchTerm}
              onChange={handleChange}
              placeholder="Search Here..."
            />
          </div>
          <div className={styles.userListDetail}>
            {userList?.filteredData?.length === 0 && renderNoData()}
            {userList?.filteredData?.map((user) => {
              return (
                <div
                  className={styles.menuWrp}
                  onClick={() => {
                    setSelectedUser(user);
                    fetchChetData(user.id);
                  }}
                >
                  <div
                    className={`${styles.smWrp} ${
                      selectedUser.id === user.id ? styles.active : ""
                    }`}
                  >
                    <div className={styles.smImg}>
                      <img
                        src={`${baseURL}${user?.image}`}
                        alt="User profile"
                      />
                    </div>

                    <div className={styles.rightInfo}>
                      <div className={styles.nameInfo}>
                        <span className={styles.name}>{user?.name}</span>
                        <span className={styles.time}>
                          {user?.message && formatMessageTime(user?.message)}
                        </span>
                      </div>
                      <div className={styles.lastMessageInfo}>
                        <Tooltip>
                          <span className={styles.message}>
                            {user?.message?.message}
                          </span>
                        </Tooltip>
                        {user.count > 0 && (
                          <span className={styles.count}>
                            {user.count > 9 ? "9+" : user.count}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {window.innerWidth > 700 && Object.keys(selectedUser)?.length > 0 && (
        <div className={styles.mainListWrp}>
          <div className={styles.subHeader}>
            <TTInput
              className={styles.inp}
              name="searchTerm"
              value={searchTerm}
              onChange={handleChange}
              placeholder="Search Here..."
            />
          </div>
          <div className={styles.userListDetail}>
            {userList?.filteredData?.length === 0 && renderNoData()}
            {userList?.filteredData?.map((user) => {
              return (
                <div
                  className={styles.menuWrp}
                  onClick={() => {
                    setSelectedUser(user);
                    fetchChetData(user.id);
                  }}
                >
                  <div
                    className={`${styles.smWrp} ${
                      selectedUser.id === user.id ? styles.active : ""
                    }`}
                  >
                    <div className={styles.smImg}>
                      <img src={`${baseURL}${user.image}`} />
                    </div>
                    <div className={styles.rightInfo}>
                      <div className={styles.nameInfo}>
                        <span className={styles.name}>{user?.name}</span>
                        <span className={styles.time}>
                          {/* {user?.message?.time} */}
                          {user?.message && formatMessageTime(user?.message)}
                        </span>
                      </div>
                      <div className={styles.lastMessageInfo}>
                        <span className={styles.message}>
                          {user?.message?.message}
                        </span>
                        {user.count > 0 && (
                          <span className={styles.count}>
                            {user.count > 9 ? "9+" : user.count}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {window.innerWidth > 700 && renderData()}
      {window.innerWidth < 700 &&
        Object.keys(selectedUser)?.length > 0 &&
        renderData()}
    </div>
  );
};

export default AdminChatDetail;
