import React, { useEffect, useRef, useState } from "react";
import styles from "./usermanual.module.css";
import { Layout, theme } from "antd";
import Navbar from "../Navbar/Navbar";
import { Content } from "antd/es/layout/layout";
import SmHeader from "../../../SmHeader/SmHeader";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import pdf from "../../../../assets/iCAMS_USER_MANUAL_May.pdf";
import Chatbox from "../../Chatbox/Chatbox";
// import Pdf from "@mikecousins/react-pdf";
import { usePdf } from "@mikecousins/react-pdf";
import TTInput from "../../../shared/TTInput/TTInput";

import { GrLinkPrevious, GrLinkNext } from "react-icons/gr";
import { toastNotify } from "./../../../shared/Toast/Toast";
import axios from "axios";
import { baseURL } from "../../../../Constant";
import { getToken } from "../../../../common/helpers/localStorage.helper";

function UserManual() {
  const [collapsed, setCollapsed] = useState(false);
  const { Content } = Layout;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const token = getToken("token");

  const [manual, setManual] = useState("");

  const handleChange = (e) => {
    let _value = Number(e.target.value);
    if (isNaN(_value))
      return toastNotify("Plaese enter a Number.", "error");

    if (_value > pdfDocument?.numPages) {
      toastNotify(
        `Page number should not be greater than ${pdfDocument?.numPages}`,
        "error"
      );
      return;
    } else if(_value > 0) {
      setPage(_value);
    }
  };

  const [page, setPage] = useState(1);
  const canvasRef = useRef(null);

  const { pdfDocument, pdfPage } = usePdf({
    file: `${baseURL}${manual}`,
    page,
    canvasRef,
  });

  //Functions
  const fetchManual = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/user_manual`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the access token in the headers
        },
      });
      const data = await response?.data;
      setManual(data.manual);
      console.log(data.manual);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchManual();
  }, []);

  return (
    <Layout>
      <Navbar collapsed={collapsed} />
      <Layout>
        <Content className={styles.userManualPage}>
          <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />

          <div className={styles.maualWrp}>
            <h1>User Manual</h1>
            <div className={styles.controllsAndInput}>
              <div className={styles.pdfInput}>
                <label>Search Page Number</label>
                <input
                  className={styles.input}
                  label="Search Page Number"
                  value={page}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.displayControlls}>
                <div>
                  <button
                    className={styles.contrBtn}
                    disabled={page === 1}
                    onClick={() => setPage(page - 1)}
                  >
                    <GrLinkPrevious />
                  </button>
                </div>

                <div className={styles.displayPageNo}>
                  {page} / {pdfDocument?.numPages}
                </div>
                <div>
                  <button
                    className={styles.contrBtn}
                    disabled={page === pdfDocument?.numPages}
                    onClick={() => setPage(page + 1)}
                  >
                    <GrLinkNext />
                  </button>
                </div>
              </div>
            </div>

            {!pdfDocument && <span>Loading...</span>}
            <canvas ref={canvasRef} className={styles.pdfCanvas} />
          </div>
        </Content>
      </Layout>
      <Chatbox />
    </Layout>
  );
}

export default UserManual;
