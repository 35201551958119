import React from "react";
import styles from "./testimonials.module.scss";
import { IoIosStar } from "react-icons/io";
import { CiStar } from "react-icons/ci";
import { Col, Row } from "antd";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import img from '../../../logo.svg'
import gurpreet from "../../../assets/gurpreet_ceo.png";
import dharmesh from "../../../assets/Testimonial_Photo/Dharmesh.jpg";
import hemant from "../../../assets/Testimonial_Photo/Hemant.jpg";
import Arvind from "../../../assets/Testimonial_Photo/Arvind.jpg";
import Pradeep from "../../../assets/Testimonial_Photo/Pradeep.jpg";
import Rajnikant from "../../../assets/Testimonial_Photo/Rajnikant.jpg";
import Rupam from "../../../assets/Testimonial_Photo/Rupam.jpg";
import Ashish from "../../../assets/Testimonial_Photo/Ashish.jpg";
import Puran from "../../../assets/Testimonial_Photo/Purandare.jpg";
import { FaQuoteRight } from "react-icons/fa";
import { IoIosStarHalf } from "react-icons/io";
import { IoIosStarOutline } from "react-icons/io";

// import { TESTIMONIALS } from '../../common/constants/general.constant';

const TESTIMONIALS = [
  {
    name: "Dr. Hemant Koshia",
    designation:"Food & Drug Control Assoc. (FDCA) of Gujarat",
    description:
      "I had the pleasure of hosting a Leadership Workshop in association with iCretegy for the Food & Drug Control Administration (FDCA), Gujarat. The theme, 'Integrity is My Personality,' resonated deeply with the values of our organization. The sessions were not just informative but also engaging and full of enthusiasm. Mr. Alpesh Patel is an excellent facilitator and you can hear the passion he has not just for his work, but to truly change the world for the better. Moreover, the professionalism and dedication demonstrated by the iCretegy team were beyond commendable. From the meticulous planning to the seamless execution, every element of the workshop was handled with utmost precision and attention to detail. This workshop has equipped us with valuable tools and insights to enhance our leadership skills and promote integrity-driven practices within our organization.",
    numArr: [1, 1, 1, 1, 1],
    img: hemant,
  },
  {
    name: "Dr. Gurpreet Singh Minhas",
    designation: "Director, Harman Finochem Ltd.",
    description:
      "The workshop organized by iCretegy was a refreshing experience. What truly impressed me was iCretegy's flexibility in tailoring the workshop topics to address our team's challenges. The workshop's emphasis on effective leadership and teamwork was complemented by insightful discussions on adapting to industry shifts. Throughout the session, I admired how the iCretegy team seamlessly orchestrated various activities, fostering an inclusive environment where diverse perspectives were valued. This collaborative approach not only enriched our leadership acumen but also inspired a culture of innovation within our organization. I extend my sincere gratitude to the iCretegy team for their professionalism and commitment to delivering a truly customized workshop experience.",
    numArr: [1, 1, 1, 1, 1],
    img: gurpreet,
  },
  {
    name: "Mr. Dharmesh Shah",
    designation: "Chairman & MD, BDR Pharmaceuticals",
    description:
      "It’s our pleasure to collaborate with iCretegy on Technical Areas of Regulatory Affairs for easy and smooth filings. Their expertise in regulatory affairs is evident in the invaluable services they provide. Their commitment to excellence and innovation in regulatory affairs is truly commendable. Through our collaboration with iCretegy, we are able to leverage their expertise to optimize our regulatory submissions and enhance our regulatory strategy. Their guidance, tools and support are instrumental in achieving successful regulatory outcomes. Mr. Alpesh Patel's guidance and energy was unparalleled during our Technical Workshop 'Post2Prior IQA workshop,'.I highly recommend iCretegy to any pharmaceutical company or regulatory professional seeking expert assistance in navigating the complexities of regulatory affairs. Their services are unmatched in the industry, and I look forward to continuing our collaborations with them.",
    numArr: [1, 1, 1, 1, 1],
    img: dharmesh,
  },
  {
    name: "Mr. Pradeep Bhadauria",
    designation: "President and Chief Scientific Officer (CSO), Cipla",
    description:
      "The technical workshop organized by Cipla in association with iCretegy was commendable. iCretegy's experts provided invaluable insights to better file cFDA IDL Applications, their strategies to streamline our submissions and enhance regulatory compliance were really remarkable. Through interactive discussions and practical case studies, they provided us with the tools and knowledge needed to navigate the regulatory process with confidence. Their commitment to excellence and their comprehensive range of regulatory services makes them a valuable partner in achieving regulatory success. I extend my gratitude to Mr. Alpesh and iCretegy for organizing such a valuable workshop.",
    numArr: [1, 1, 1, 1, 0.5],
    img: Pradeep,
  },
  {
    name: "Mr. Rajnikant Mehta",
    designation: "Chairman, BCM Corp. & OASIS Labs",
    description:
      "The 'Aspire2Inspire' workshop provided us a great platform for self-reflection and collective visioning. The inclusive nature of the workshop fostered a sense of unity and purpose among all participants. We left the workshop with a renewed sense of commitment to living our shared vision and principles, both personally and professionally. My heartfelt appreciation to Mr. Alpesh Patel and the entire iCretegy team for their invaluable support and partnership throughout this transformative experience. Their dedication, expertise, and commitment to our success were instrumental in making this workshop a resounding success. I am excited to continue this journey of transformation and excellence, guided by the principles we have collectively defined. With iCretegy as our trusted partner, I am confident that we will continue to inspire and empower those around us.",
    numArr: [1, 1, 1, 1, 1],
    img: Rajnikant,
  },
  {
    name: "Dr. Arvind Badiger",
    designation: "Technical Director, BDR Pharmaceuticals",
    description:
      "We are really glad to associate with iCretegy for our Regulatory Filings & Compliance in USFDA. They have created very robust tools for easy and better Regulatory Submissions. I would like to appreciate Mr. Alpesh & iCretegy Team for your dedicated and prompt support. We also found our Technical Workshop insightful!. The workshop was not just informative; it was incredibly energetic, fostering an environment filled with learning, joy, and motivation. The 'Prior2Post - IQA workshop,' was crafted to provide crucial strategies for achieving better filing of ANDA applications. Mr. Alpesh Patel effortlessly imparted complex technical knowledge while keeping the atmosphere lively and engaging. Each session was a perfect blend of theory and practical insights, ensuring that every attendee gained actionable takeaways. I feel empowered with the tools and strategies necessary to enhance our filing processes and drive continuous improvement within our organization. Kudos to iCretegy for organizing such a phenomenal conference. I look forward to future opportunities for learning and growth.",
    numArr: [1, 1, 1, 1, 1],
    img: Arvind,
  },
  {
    name: "Mr. Rupam Patel",
    designation: "Deputy Commissioner, Food & Drug Control Assoc. (FDCA) of Gujarat",
    description:"iCretegy organized an impactful leadership workshop under the theme of 'Integrity is My Personality'. The workshop gave us useful guidance and principles to be better leaders and promote honesty in our work, especially considering integrity is the top-most value required when working in a government sector. The legendary lessons led by Mr. Alpesh Patel were not only enlightening but also deeply engrossing. The iCretegy team tirelessly worked 3 months to create and collaborate all the features necessary for a successful workshop, from presentation preparation to logistics. Everything was absolutely fantastic. Great planning, great teamwork. I know this because I worked closely with the iCretegy team for the time leading up to the workshop and witnessed myself how they carefully and comprehensively executed every aspect of the workshop.",        
    numArr: [1, 1, 1, 1, 1],
    img: Rupam,
  },
  
  {
    name: "Dr. Shrinivas Purandare",
    designation: "Global Regulatory Affairs Head, Cipla",
    description:" I extend my heartfelt appreciation to iCretegy team for organizing such an impactful technical workshop for Cipla. Thanks to the collaborative efforts of iCretegy and the influential energy brought by Alpesh Patel, the cFDA IDL workshop exceeded our expectations. Their dedication to understanding our specific needs was instrumental in the workshop's effectiveness. It provided us with invaluable insights and strategies to optimize our regulatory submissions in China and foster regulatory excellence within our organization. Their commitment to excellence, collaborative approach, and influential energy makes them invaluable partners in achieving regulatory success.",        
    numArr: [1, 1, 1, 1, 1],
    img: Puran,
  },


  
  {
    name: "Ashish Mehta",
    designation: "CEO, OASIS Labs",
    description:" Through collaborative exercises and meaningful discussions, we collectively defined our Mission, Vision, and Guiding principles, laying the foundation for a purpose-driven journey ahead in our organization. The 'Aspire2Inspire' workshop designed by iCretegy provided us unique opportunity to reflect on our personal, spiritual, and professional aspirations, and to chart a course towards inspiring not only ourselves but also our community. From understanding the culture of the organization to studying the requirements, Mr. Alpesh Patel & iCretegy team has been instrumental in suggesting and designing the Workshop. Meticulous planning and discussion over the last couple of months resulted in an exuberant atmosphere which we have all pledged to carry on. I look forward to continuing this transformative journey, inspired by the principles we have defined together, and I am confident that our partnership with iCretegy will continue to drive excellence and innovation in our organization.",        
    numArr: [1, 1, 1, 1, 0.5],
    img:Ashish ,
  },
];
const Testimonials = () => {
  // Variables
  // Templates
  const renderReview = (data) => {
    return data?.numArr?.map((val) => {
      if (val === 1){
        return <IoIosStar style={{ fill: "#faaf00", fontSize: 24 }} />;
      }
      else if(val === 0.5){
        return <IoIosStarHalf style={{ fill: "#faaf00", fontSize: 24 }} />;
      }
      else{
        return <IoIosStarOutline style={{ fill: "#faaf00", fontSize: 24 }} />;
      }
    });
  };

  return (
    <>
      <div className={styles.corosul}>
        <h1 style={{ marginBottom: "3rem" }}>Testimonials</h1>
        <Carousel
          axis="horizontal"
          showStatus={false}
          autoPlay
          infiniteLoop
          interval={3000}
          className="relative"
          renderArrowPrev={(clickHandler, hasPrev) => {
            return (
              <div
                className={`${
                  hasPrev ? "absolute" : "hidden"
                } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
                onClick={clickHandler}
              >
                <FaChevronLeft
                  style={{ marginRight: 10, cursor: "pointer", fontSize: 20 }}
                />
              </div>
            );
          }}
          renderArrowNext={(clickHandler, hasNext) => {
            return (
              <div
                className={`${
                  hasNext ? "absolute" : "hidden"
                } top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
                onClick={clickHandler}
              >
                <FaChevronRight
                  style={{ marginLeft: 10, cursor: "pointer", fontSize: 20 }}
                />
              </div>
            );
          }}
        >
          {TESTIMONIALS?.map((val) => {
            return (
              <div className={styles.review}>
                <div className={styles.details}>
                  <div className={styles.testiHeaderWrp}>
                    <div className={styles.header_photo}>
                      <img src={val?.img} />
                    </div>
                    <div className={styles.headerContent}>
                      <h4>{val.name}</h4>
                      <p>{val.designation}</p>
                    </div>
                  </div>
                  <div className={styles.trademark}>
                    <FaQuoteRight style={{ color: "blue" }} />
                  </div>
                </div>
                <div className={styles.star}>
                  {renderReview(val)}
                  <p className={styles.Testimonials_desc}>{val.description}</p>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default Testimonials;
