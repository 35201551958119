import React, { useEffect, useState } from "react";
import { Button, Layout, theme, Popconfirm, Modal, Empty } from "antd";
import Navbar from "../Navbar/Navbar";
import styles from "./course.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getToken,
  getUserInfo,
} from "../../../../common/helpers/localStorage.helper";
import { baseURL } from "../../../../Constant";
import axios from "axios";
import SmHeader from "../../../SmHeader/SmHeader";
import Chatbox from "../../Chatbox/Chatbox";
import { toastNotify } from "../../../shared/Toast/Toast";
import dayjs from "dayjs";
import DatePicker from "react-date-picker";
import { logicalPropertiesLinter } from "@ant-design/cssinjs";

const Course = () => {
  //variabes
  const [data, setData] = useState([]);
  const token = getToken("token");
  const user = getUserInfo();
  const { id } = useParams();
  const location = useLocation();
  const [courseData, setCourseData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const { Content } = Layout;
  const navigate = useNavigate();
  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  //Callbacks
  useEffect(() => {
    fetchData();
  }, [token]);

  //Functions
  const fetchData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/showUserCourses/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the access token in the headers
        },
      });
      const data = await response?.data;
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRemoveCourse = async (id) => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/delete_assigned_course/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the access token in the headers
        },
      });
      if (response?.status === 204) {
        let _data = [...data];
        let _index = _data.findIndex((val) => val.id === id);
        _data.splice(_index, 1);
        setData(_data);
        toastNotify("Course Deleted Successfully", "success");
      }
    } catch (error) {}
  };

  const fecthCoursedata = async (id) => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/update_assign_course/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the access token in the headers
        },
      });

      if (response?.status === 200) {
        setCourseData(response?.data);
      }
    } catch (error) {}
  };

  const handleNavigate = (val) => {
    let _ceoCourse = location.pathname.startsWith("/ceoCourses");

    let _url =
      user?.role === "employee"
        ? `/courses-details/${val?.id}`
        : user?.role === "admin"
        ? `/adminCourseDetail/${val?.id}`
        : user?.role === "ceo"
        ? _ceoCourse
          ? `/ceo-course-detail/${val?.id}`
          : `/courses-details/${val?.id}`
        : "";
    navigate(_url);
  };

  const hadleUpdate = async () => {
    try {
      let payload = { ...courseData };
      const response = await axios({
        method: "post",
        data: payload,
        url: `${baseURL}/update_assign_course/${courseData?.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the access token in the headers
        },
      });
      console.log(response);
      if(response?.status === 200){
        fecthCoursedata(courseData?.id)
        toastNotify("Date Updated Successfully!","success");
        setIsOpen(false)
      }
    } catch (error) {}
  };

  const opemAddModal = (id) => {
    setIsOpen(true);
    fecthCoursedata(id);
    // setIsEmpModalOpen(true);
    // setFormdata({
    //   username: "",
    //   first_name: "",
    //   last_name: "",
    //   email: "",
    //   password: "",
    //   confirmPass: "",
    //   role: "",
    // });
  };

  const handleCancel = () => {
    setIsOpen(false);
    // setEmpEnrollCourse({
    //   course: null,
    //   courseId: null,
    //   enrolled_user: null,
    //   starting_date: new Date(),
    //   empDeadDate: new Date(),
    //   perrSubDeadDate: new Date(),
    //   adminDeadaDate: new Date(),
    // });
  };

  const onEmpChange = (date) => {
    let _courseData = { ...courseData };
    let _date = dayjs(date).format("YYYY-MM-DD");
    _courseData["starting_date"] = _date;
    setCourseData(_courseData);
  };

  const onEmpDeadChange = (date) => {
    let _courseData = { ...courseData };
    let _date = dayjs(date).format("YYYY-MM-DD");
    _courseData["deadline_date"] = _date;
    setCourseData(_courseData);
  };

  const onPeerSubChange = (date) => {
    let _courseData = { ...courseData };
    let _date = dayjs(date).format("YYYY-MM-DD");
    _courseData["peersub_deadline_date"] = _date;
    setCourseData(_courseData);
  };

  const onAdminChange = (date) => {
    let _courseData = { ...courseData };
    let _date = dayjs(date).format("YYYY-MM-DD");
    _courseData["admin_deadline_date"] = _date;
    setCourseData(_courseData);
  };


  const renderNoData = (desc) => {
    return (
      <Empty style={{ fontSize: 18, fontWeight: "600" ,marginBottom:"2rem" }} description={desc} />
    );
  };

  return (
    <Layout>
      <Navbar />
      <Layout>
        <Content className={styles.coursesWrp}>
          <SmHeader />

          <div
            style={{
              padding: 24,
              borderRadius: borderRadiusLG,
            }}
            className={styles.courses}
          >
            <h1 className={styles.heading}>Your Presentations</h1>
            {data?.length === 0 &&
              renderNoData(
                "Not Enroll Courses Available!"
              )}
            <div className={styles.grid}>
              {data?.map((val, index) => {
                return (
                  <div key={index}>
                    <div className={styles.cardWrapper}>
                      <div
                        className={styles.cardHeading}
                        onClick={() => handleNavigate(val)}
                      >
                        <div className={styles.imageWrapper}>
                          <img src={`${baseURL}${val?.course?.images}`} />
                        </div>
                      </div>
                      {user?.role === "admin" && (
                        <div className={styles.ControlBtn}>
                          <div>
                            <Popconfirm
                              title="Delete Course"
                              description="Are you sure to delete this Course?"
                              onConfirm={(e) => handleRemoveCourse(val.id)}
                              onCancel={() => {}}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button className={styles.removeBtn}>
                                Remove
                              </Button>
                            </Popconfirm>
                          </div>
                          <Button
                            className={styles.EditCourseData}
                            onClick={() => opemAddModal(val.id)}
                          >
                            Edit
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Modal
            title="Courses List"
            open={isOpen}
            onCancel={handleCancel}
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
          >
            <div className={styles.coursemodalDate}>
              <label>Starting Date:</label>
              <DatePicker
                clearIcon={false}
                format="dd-MM-yyyy"
                value={dayjs(courseData?.starting_date)}
                onChange={onEmpChange}
              />
            </div>
            <div className={styles.coursemodalDate}>
              <label>Presentor Deadline Date:</label>
              <DatePicker
                clearIcon={false}
                format="dd-MM-yyyy"
                value={dayjs(courseData?.deadline_date)}
                onChange={onEmpDeadChange}
              />
            </div>
            <div className={styles.coursemodalDate}>
              <label>Peer & Subordinate Deadline Date:</label>
              <DatePicker
                clearIcon={false}
                format="dd-MM-yyyy"
                value={dayjs(courseData?.peersub_deadline_date)}
                onChange={onPeerSubChange}
              />
            </div>
            <div className={styles.coursemodalDate}>
              <label>Advisor Deadline Date:</label>
              <DatePicker
                clearIcon={false}
                format="dd-MM-yyyy"
                value={dayjs(courseData?.admin_deadline_date)}
                onChange={onAdminChange}
              />
            </div>
            <Button type="primary" onClick={hadleUpdate}>
              Update
            </Button>
          </Modal>
        </Content>
      </Layout>
      <Chatbox />
    </Layout>
  );
};
export default Course;
