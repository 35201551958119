import React, { useEffect, useState } from "react";
import { Button, Layout, theme, Col, Row, Card, Flex } from "antd";
import { FaUserCircle } from "react-icons/fa";
import Navbar from "../Navbar/Navbar";
import styles from "../EmpDetail/empDetail.module.css";
import { Link} from "react-router-dom";
import {
  getToken,
  getUserInfo,
} from "../../../../common/helpers/localStorage.helper";
import { baseURL, show_courses_url } from "../../../../Constant";
import axios from "axios";
import SmHeader from "../../../SmHeader/SmHeader";
import Chatbox from "../../Chatbox/Chatbox";

const InlineOfEmp = () => {
  const [data, setData] = useState([]);
  const [empList, setEmpList] = useState([]);

  const token = getToken("token");
  const user = getUserInfo();

  const [collapsed, setCollapsed] = useState(false);
  const { Content } = Layout;

  //callbacks
  useEffect(() => {
    if (user?.id) {
      fetchInlineData(user?.id);
    }
  }, [user?.id]);

  //functions
  const fetchInlineData = async (id) => {
    const url = `${baseURL}/show_inlines/${id}`;
    try {
      const response = await axios({
        method: "get",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      const emplist = data?.employees;

      setEmpList(emplist);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //others
  const DataRender = (employee) => {
    return (
      <div className={styles.EmpList_card}>
        <div className={styles.EmpList_card_img_wrp}>
          {employee.profile_photo ? (
            <img src={`${baseURL}${employee.profile_photo}`} />
          ) : (
            <FaUserCircle />
          )}
        </div>

        <h2>
          {employee?.user?.first_name} {employee?.user?.last_name}
        </h2>
        <p>{employee?.designation}</p>
        <p>{employee?.department}</p>

        <button>
          {" "}
          <Link
            to={`/inlineCourse/${employee?.user?.id}`}
            className={styles.Link}
          >
            View Course
          </Link>{" "}
        </button>
      </div>
    );
  };
  return (
    <Layout>
      <Navbar collapsed={collapsed} />
      <Layout>
        <Content className={styles.empDetailPage}>
          <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />
          <div className={styles.empDetailwrp}>
            <div className={styles.EmpList}>
              <h1>Workshop Presenters</h1>
              <div className={styles.EmpList_cards}>
                {empList?.map((elist, index) => {
                  return <div key={index}>{DataRender(elist)}</div>;
                })}
              </div>
            </div>
          </div>
        </Content>
        <Chatbox/>
      </Layout>
    </Layout>
  );
};
export default InlineOfEmp;
