import React, { useEffect, useState } from "react";
import { FloatButton } from "antd";
import ChatDetail from "../../Chat/ChatDetail";
import { FaRegMessage } from "react-icons/fa6";
import {  useRecoilValue } from "recoil";
import { AtomIsNotification } from "../../../store/genral.store";

const Chatbox = () => {
  const notificationCount = useRecoilValue(AtomIsNotification);

  return (
    <div>
      <FloatButton.Group
        trigger="click"
        type="primary"
        style={{
          right: 24,
        }}
        badge={(notificationCount !== 0) && {count: notificationCount, color: 'blue'} }
        icon={<FaRegMessage />}
      >
        <ChatDetail />
      </FloatButton.Group>
    </div>
  );
};

export default Chatbox;
