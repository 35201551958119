import { atom } from "recoil";

export const AtomUserInfo = atom({
    key:"AtomUserInfo",
    default: {}
})

export const AtomIsLogin =  atom({
    key: "AtomIsLogin",
    default: false
})

export const AtomIsNotification = atom({
    key: "AtomIsNotification",
    default: 0
})
export const AtomNotificationCount = atom({
    key: "AtomNotificationCount",
    default: 0
})
export const AtomMenuBar = atom({
    key: "AtomMenuBar",
    default: false
})