import React, { useEffect, useRef, useState } from "react";
import styles from "./CeoDashboard.module.css";
import {
  getToken,
  getUserInfo,
} from "../../../common/helpers/localStorage.helper";
import { baseURL } from "../../../Constant";
import { Button, ConfigProvider, Layout, Modal, Table, theme } from "antd";
import Navbar from "../../../components/AfterLogin/Employee/Navbar/Navbar";
import SmHeader from "../../../components/SmHeader/SmHeader";
import { useNavigate, Link } from "react-router-dom";

//chart
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { FaUserCircle } from "react-icons/fa";
import axios from "axios";
import { LiaUniversitySolid } from "react-icons/lia";
import { PiFolderUserDuotone } from "react-icons/pi";
import { RiUser3Line } from "react-icons/ri";
import { FaCircleUser } from "react-icons/fa6";
import { BiSolidUserDetail } from "react-icons/bi";

const CeoDashboard = () => {
  // Variables
  const user = getUserInfo();
  const token = getToken("token");
  const [data, setData] = useState({});
  const { Content } = Layout;
  const navigate = useNavigate();
  const chartRef = useRef(null);
  const [showEmployeeDetails, setShowEmployeeDetails] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [dataSeris, setDataSeris] = useState([]);

  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  const [employeeTaskOpt, setEmployeeTaskOpt] = useState({
    chart: {
      type: "area",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      title: "Total Task",
    },
    series: [
      {
        name: "",
        data: [],
      },
    ],
  });

  const [employeeLevelOpt, setEmployeeLevelOpt] = useState({
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      title: "Total Task",
    },
    series: [
      {
        name: "",
        data: [],
      },
    ],
  });

  // const [courseEmpOpt, setCourseEmpOpt] = useState({
  //   labels: [],
  //   series: [],
  // });

  //functions
  const handleRouter = (id) => {
    navigate(`/dashboard/${id}`);
  };

  const fetchGraphData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/dash_ceo/${user.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = response?.data;
      let _newList = [];

      for (const key in data) {
        let ele = data[key];
        let obj = {};
        obj["category"] = key;
        let index = 0;
        let _list = [];
        for (const newKey in ele) {
          let _obj = {};
          index++;
          let _newEle = ele[newKey];
          _obj["id"] = index;
          _obj["question"] = newKey;
          _obj["level"] = _newEle;
          _obj["color"] = getColor(index);
          _list.push(_obj);
          obj[`value${index}`] = _newEle;
        }
        obj["Questions"] = _list;
        _newList.push(obj);
      }
      setDataSeris("_newList", _newList);

      am4core.useTheme(am4themes_animated);
      let chart = am4core.create("chartdiv", am4charts.RadarChart);
      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
      chart.data = _newList;

      chart.padding(20, 20, 20, 20);
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.labels.template.location = 0.5;
      categoryAxis.renderer.tooltipLocation = 0.5;
      categoryAxis.renderer.cellStartLocation = 0.2;
      categoryAxis.renderer.cellEndLocation = 0.8;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.labels.template.horizontalCenter = "left";
      valueAxis.min = 0;
      valueAxis.max = 10;

      _newList.forEach((item, index) => {
        let series = chart.series.push(new am4charts.RadarColumnSeries());
        series.columns.template.width = am4core.percent(100);
        series.dataFields.categoryX = "category";
        series.dataFields.valueY = `value${index + 1}`;
        series.fill = am4core.color(getColor(index));
        attachHitEventHandler(series);
      });

      chart.seriesContainer.zIndex = -1;
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.exportable = false;
      chart.cursor = new am4charts.RadarCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.fullWidthXLine = true;
      chart.cursor.lineX.strokeOpacity = 0;
      chart.cursor.lineX.fillOpacity = 0.1;
      chart.cursor.lineX.fill = am4core.color("#000000");

      function attachHitEventHandler(series) {
        series.columns.template.events.on("hit", function (ev) {
          if (ev.target.dataItem) {
            const employee = ev.target.dataItem.dataContext;
            if (chartRef.current) {
              chartRef.current.data = [
                {
                  category: employee.category,
                  Questions: employee.Questions,
                  value1: employee.value1,
                  value2: employee.value2,
                  value3: employee.value3,
                  value4: employee.value4,
                  value5: employee.value5,
                },
              ];
            }
            setSelectedEmployee(employee);
            setShowEmployeeDetails(true);
          }
        });
      }
    } catch {}
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${baseURL}/show_ceo_dashboard/${user?.id}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const _res = await response.json();
      setData(_res);

      let _opt = { ...employeeTaskOpt };
      let _x = { ..._opt["xaxis"] };
      let _s = {
        name: "Employee Name",
        data: _res?.employee_vs_task?.y,
      };
      let _series = [];
      _series.push(_s);
      _opt["series"] = _series;
      _x["categories"] = _res?.employee_vs_task?.x;
      _opt["xaxis"] = _x; 
      setEmployeeTaskOpt(_opt);

      let _optLevel = { ...employeeLevelOpt };
      let _optX = { ..._optLevel["xaxis"] };
      _optX["categories"] = Object.keys(_res?.employees_vs_level);
      _optLevel["xaxis"] = _optX;
      let _opty = {
        name: "Employee Level",
        data: Object.values(_res?.employees_vs_level),
      };
      let _series1 = [];
      _series1.push(_opty);
      _optLevel["series"] = _series1;
      setEmployeeLevelOpt(_optLevel);
      // const keys = Object.keys(_res?.course_vs_employee);
      // const values = Object.values(_res?.course_vs_employee);
      // setCourseEmpOpt({
      //   labels: keys,
      //   series: values,
      // });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Others
  const employeeCols = [
    {
      title: <div className={styles.tabelSRHead}>Sr No</div>,
      dataIndex: "",
      key: "",
      render: (text, record, index) => {
        let idx = index + 1;
        return <div className={styles.tableSRBody}>{idx}</div>;
      },
    },
    {
      title: (
        <div className={styles.tablePhotoHead}>
          <FaCircleUser fontSize="30px" /> &nbsp; Profile
        </div>
      ),
      dataIndex: "profile_photo",
      key: "profile_photo",
      render: (text, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className={styles.empTable_profilePhoto}>
            {record.profile_photo ? (
              <img src={`${baseURL}${record?.profile_photo}`} />
            ) : (
              <FaUserCircle style={{ fontSize: "3rem" }} />
            )}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className={styles.tableTaskHead}>
          <RiUser3Line fontSize="30px" /> &nbsp; Name
        </div>
      ),
      dataIndex: "task_name",
      key: "task_name",
      render: (text, record) => {
        return (
          <div style={{ textAlign: "center" }}>{record?.user?.first_name}</div>
        );
      },
    },
    {
      title: (
        <div className={styles.tableDegHead}>
          <PiFolderUserDuotone fontSize="30px" /> &nbsp; Designation
        </div>
      ),
      dataIndex: "designation",
      key: "designation",
      render: (text, record) => {
        return <div style={{ textAlign: "center" }}>{record?.designation}</div>;
      },
    },
    {
      title: (
        <div
          className={styles.tableDepHead}
        >
          <LiaUniversitySolid fontSize="30px" /> &nbsp; Department
        </div>
      ),
      dataIndex: "department",
      key: "description",
      render: (text, record) => {
        return (
          <div style={{ textAlign: "center" }}>
            {record?.department ? record?.department : "--"}
          </div>
        );
      },
    },
    {
      title: (
        <div
          className={styles.tableActHead}
        >
          <BiSolidUserDetail fontSize="30px" /> &nbsp; Action
        </div>
      ),
      render: (val, record) => {
        let id = record?.user.id;

        return (
          <Link
            style={{ display: "flex", justifyContent: "center" }}
            to={`/courses/${id}`}
          >
            {" "}
            <button onClick={() => handleRouter(id)} className={styles.btn}>
              View Detail
            </button>
          </Link>
        );
      },
    },
  ];

  const EmployeeDetailsCard = ({ employee, onClose }) => {
    return (
      <div className={styles.EmpGrphData}>
        <div className={styles.disalyDataCard}>
          <button onClick={onClose}>X</button>
          <h2>{employee?.category}</h2>
        </div>
        <table>
          <thead>
            <tr>
              <th>Question</th>
              <th>Level</th>
            </tr>
          </thead>
          <tbody>
            {employee?.Questions.map((que, index) => {
              return (
                <tr key={index} className={styles.chartCardData}>
                  <td>
                    <div className={styles.tableRowDatawrp}>
                      <spna
                        style={{
                          backgroundColor: que.color,
                          minWidth: "25px",
                          height: "25px",
                          borderRadius: ".3rem",
                        }}
                      ></spna>
                      <p style={{ textAlign: "left" }}>{que.question}</p>
                    </div>
                  </td>
                  <td>
                    {" "}
                    <p>{que.level}</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  function getColor(index) {
    const colors = ["#249efa", "#24e5a4", "#fcba39", "#fd5f76", "#4CCD50"]; // Add more colors if needed
    return colors[index % colors.length]; // This ensures that even if you have more series than colors, it loops back to the beginning
  }

  //Callbacks

  useEffect(() => {
    if (user?.role !== "ceo") {
      navigate(-1);
    }
    if (user?.id) {
      fetchData();
      fetchGraphData();
    }
  }, [user?.id]);

  useEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.RadarChart);
    chartRef.current = chart;
    return () => {
      chart.dispose();
    };
  }, []);

  return (
    <div className={styles.ceoWrapper}>
      <Layout>
        <Navbar />
        <Layout>
          <Content className={styles.ceoDeshboardPage}>
            <SmHeader />
            <div className={styles.ceoDeshboard}>
              <div>
                <div className={styles.ceo_gragh_Wrapper}>
                  <h1 className={styles.heading}>Employee Analysis</h1>
                  <div className={styles.graphWrp}>
                    <div
                      className="chartdiv"
                      id={styles.ceoChart}
                      // style={{ width: showEmployeeDetails ? "75%" : "100%" }}
                    ></div>
                    {/* {showEmployeeDetails && (
                      <div id="chartDataDiv">
                        <h3 id="selectedEmployeeName"></h3>
                      </div>
                    )} */}

                    {showEmployeeDetails && (
                      <div className={styles.ceoWrapper}>
                        <EmployeeDetailsCard
                          employee={selectedEmployee}
                          onClose={() => setShowEmployeeDetails(false)}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.employeeWrapper}>
                  <h1
                    className={styles.heading}
                    style={{ paddingBottom: "1rem" }}
                  >
                    Presenters List
                  </h1>
                  <div className={styles.empTable}>
                    <ConfigProvider
                      theme={{
                        components: {
                          Table: {
                            padding: 0,
                          },
                        },
                      }}
                    >
                      <Table
                        dataSource={data?.employees ?? []}
                        columns={employeeCols}
                        pagination={false}
                      />
                    </ConfigProvider>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default CeoDashboard;
