export const getToken = (key) =>{
    return localStorage.getItem(key);
}

export const setToken = (key, value) =>{
    return localStorage.setItem(key, value)
}

export const getUserInfo = () => {
    let _userInfo = localStorage.getItem("userInfo");
    let _res = JSON.parse(_userInfo);
    return _res
}

export const removeToken = (key) => {
    return localStorage.removeItem(key)
}