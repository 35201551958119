import React, { useState, useEffect } from "react";
import { Layout, theme, Button, Modal, Popconfirm, Tooltip } from "antd";
import Navbar from "../../../../components/AfterLogin/Employee/Navbar/Navbar";
import SmHeader from "../../../../components/SmHeader/SmHeader";
import styles from "./AdminCompanyPanel.module.css";
import { useNavigate, Link } from "react-router-dom";
import { getToken } from "../../../../common/helpers/localStorage.helper";
import { baseURL } from "../../../../Constant";
import axios from "axios";
import TTInput from "../../../../components/shared/TTInput/TTInput";
import { toastNotify } from "./../../../../components/shared/Toast/Toast";

const AdminCompanyPanel = () => {
  // Variables
  const token = getToken("token");
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [preview, setPreview] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { Content } = Layout;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [companyDetail, setCompanyDetail] = useState({
    company_name: "",
    company_description: "",
    images: "",
  });

  const [compChange, setCompChange] = useState({});

  //callbacks
  useEffect(() => {
    fetchData();
    if (!token) navigate("/login");
  }, []);

  //functions
  const fetchData = async () => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/showCompanies`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      setCompanies(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    const { data, name } = e;
    setCompanyDetail({ ...companyDetail, [name]: data });

    setCompChange((prevChanges) => ({
      ...prevChanges,
      [name]: data,
    }));
  };

  const showEditModal = (data) => {
    setIsEdit(true, data);
    setIsModal(true);
    setCompanyDetail(data);
    // setIsEditModal(true);
  };

  const handleOpen = () => {
    setIsModal(true);
  };
  const handleClose = () => {
    setIsModal(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setCompanyDetail({ ...companyDetail, ["images"]: file });
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    setCompChange((prevChanges) => ({
      ...prevChanges,
      images: file,
    }));
  };

  const handleValidation = (profile) => {
    if (!profile.company_name) return "Company Name Should Not Be Empty!";
    if (!profile.company_description)
      return "Company Description Name Should Not Be Empty!";
    if (!profile.images) return "Image is Required";
    return true;
  };

  const handleSubmit = async () => {
    const isValidation = handleValidation(companyDetail);
    if (isValidation === true) {
      let _courseChange = { ...compChange };

      const formData = new FormData();
      formData.append("company_name", companyDetail.company_name);
      formData.append("company_description", companyDetail.company_description);
      formData.append("images", companyDetail.images);
      const updatedFormData = new FormData();
      Object.keys(_courseChange).forEach((key) => {
        updatedFormData.append(key, _courseChange[key]);
      });
      try {
        const response = await axios({
          method: "post",
          url: `${baseURL}/addCompany`,
          data: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response?.status === 201) {
          toastNotify("Company Added Successfully", "success");
          setCompanyDetail({
            company_name: "",
            company_description: "",
            images: "",
          });
          setIsModal(false);
        }
      } catch (error) {}
    } else {
      toastNotify(isValidation, "error");
    }
    console.log(companyDetail, "data");
  };

  const handleUpdateData = async () => {
    try {
      const updatedFormData = new FormData();
      Object.keys(compChange).forEach((key) => {
        updatedFormData.append(key, compChange[key]);
      });
      const response = await axios({
        method: "post",
        data: updatedFormData,
        url: `${baseURL}/updateCompany/${companyDetail.id}`,
        headers: {
          "Content-Type": "application/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      let _companyies = [...companies];
      let index = _companyies.findIndex((company) => company.id === data.id);
      _companyies.splice(index, 1, data);
      setCompanies(_companyies);
      if (response?.status === 200) {
        toastNotify("Company Updated Successfully!:", "success");
        setIsModal(false);
        setCompChange({});
      }
    } catch (error) {
      toastNotify("Error fetching data:", "error");
    }
  };
  const cancel = (e) => {};

  const handleDelete = async (id) => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/deleteCompany/${id}`,
        headers: {
          "Content-Type": "application/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.status === 204) {
        let _companyies = [...companies];
        let index = _companyies.findIndex((company) => company.id === id);
        _companyies.splice(index, 1);
        setCompanies(_companyies);
        toastNotify("Company Deleted Successfully!", "success");
      }
    } catch (error) {}
  };

  //others
  const DataRender = (company) => {
    return (
      <div className={styles.admin_panel_companylist_card}>
        <Link to={`/WorkShopsList/${company.id}`}>
          <div className={styles.admin_panel_com_logo}>
            <img src={`${baseURL}${company?.images}`} />
          </div>
        </Link>
        <h2>{company.company_name}</h2>
        <Tooltip title={company?.company_name}>
          <p className={styles.ellips}>{company.company_description}</p>
        </Tooltip>
        <div className={styles.buttons}>
          <Button onClick={() => showEditModal(company)}>Edit</Button>
          <Popconfirm
            title="Delete Company"
            description="Are you sure to delete this Company?"
            onConfirm={(e) => handleDelete(company.id)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button>Delete</Button>
          </Popconfirm>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.dashboardWrapper}>
      <Layout>
        <Navbar collapsed={collapsed} />
        <Layout>
          <Content>
            <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />
            <div
              style={{
                padding: 24,
                minHeight: 100,
                background: "#e8e8e8",
                borderRadius: borderRadiusLG,
              }}
              className={styles.compwrp}
            >
              <div className={styles.admin_panel}>
                <div className={styles.flex}>
                  <h1>Companies</h1>
                  <Button onClick={handleOpen} className={styles.AddCompBtn}>
                    + Add Company
                  </Button>
                </div>
                <div className={styles.admin_panel_companylist}>
                  {companies?.map((company, index) => {
                    return <div key={index}>{DataRender(company)}</div>;
                  })}
                </div>
              </div>
            </div>
            <Modal
              title={isEdit ? "Edit Company" : "Add Company"}
              open={isModal}
              onCancel={handleClose}
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
            >
              <div className={styles.modelFormWrp}>
                <TTInput
                  label="Company Name"
                  name="company_name"
                  value={companyDetail.company_name}
                  onChange={handleChange}
                />
                <TTInput
                  label="Description"
                  name="company_description"
                  value={companyDetail.company_description}
                  onChange={handleChange}
                />
                <div>
                  <label>Upload Image</label>
                  <div className={styles.uploadPhoto}>
                    <div className={styles.profilePhoto}>
                      {preview ? (
                        <img src={preview} />
                      ) : (
                        <img src={`${baseURL}${companyDetail?.images}`} />
                      )}
                      <input
                        type="file"
                        name="images"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.modelButton}>
                <Button onClick={isEdit ? handleUpdateData : handleSubmit}>
                  {isEdit ? "Update" : "Upload"}
                </Button>
              </div>
            </Modal>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default AdminCompanyPanel;
