import React, { useEffect, useRef, useState } from "react";
import style from "./profile.module.css";
import { Button, Layout, theme } from "antd";
import Navbar from "../Navbar/Navbar";
import axios from "axios";
import {
  baseURL,
  countyList,
  indianCities,
  indianStates,
} from "../../../../Constant";
import {
  getToken,
  getUserInfo,
} from "../../../../common/helpers/localStorage.helper";
import TTInput from "../../../shared/TTInput/TTInput";
import TTSelect from "../../../shared/TTSelect/TTSelect";
import { toastNotify } from "../../../shared/Toast/Toast";
import SmHeader from "../../../SmHeader/SmHeader";
import { useParams } from "react-router-dom";
import Chatbox from "../../Chatbox/Chatbox";

const Profile = () => {
  // Variables
  const [collapsed, setCollapsed] = useState(false);
  const [preview, setPreview] = useState(false);
  const { Content } = Layout;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const { id } = useParams();
  const user = getUserInfo();

  const token = getToken("token");
  const [profileInfo, setProfileInfo] = useState({});
  const [cities, setCities] = useState([]);
  const [peersOptions, setPeersOptions] = useState([]);
  const [ceoOptions, setCeoOptions] = useState([]);
  const [profileChanges, setProfileChanges] = useState({});
  const [check, setCheck] = useState(false);
  const [calPdf, setCalPdf] = useState("");
  const [companies, setCompanies] = useState([]);

  // Callbacks
  useEffect(() => {
    getProfileData();
    fetchCompListData();
    // getUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Functions

  const fetchCompListData = async () => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/showCompanies`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      let list = [];

      for (const comp of data) {
        let obj = {};
        obj["id"] = comp?.id;
        obj["label"] = comp?.company_name;
        obj["value"] = comp?.company_name;
        list.push(obj);
      }
      setCompanies(list);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getProfileData = async () => {
    const response = await axios({
      method: "get",
      url: `${baseURL}/show_user_profile/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let _data = { ...response.data };
    let _inline = { ..._data.inline };

    let _subordinate = { ..._data.subordinate };
    _data["first_name"] = _data?.user?.first_name ?? "";
    _data["last_name"] = _data?.user?.last_name ?? "";
    _data["email"] = _data?.user?.email ?? "";
    _inline["value"] = _inline.first_name
      ? _inline.first_name + " " + _inline.last_name
      : "";
    _subordinate["value"] = _subordinate.first_name
      ? _subordinate.first_name + " " + _subordinate?.last_name
      : "";
    _data["inline"] = _inline;
    _data["subordinate"] = _subordinate;
    _data["manager"] = null;
    _data["hr"] = null;
    console.log(_data);
    setCalPdf(_data?.calender_pdf)
    // editorRef.current = _data.description;
    getUserList(_data?.company_name);
    getCeoList(_data?.company_name, _data);
    setCheck(_data.is_oneonone);
  };

  const getUserList = async (company_name) => {
    if (!company_name) return;
    let payload = {};
    payload["company"] = company_name;
    const response = await axios({
      method: "post",
      data: payload,
      url: `${baseURL}/show_user_list_by_company`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const userList = response?.data;
    let list = [];

    for (const user of userList) {
      let obj = {};
      obj["key"] = user?.user?.id;
      obj["label"] = `${user.user.first_name} ${user.user.last_name}`;
      obj["value"] = `${user.user.first_name} ${user.user.last_name}`;
      list.push(obj);
    }
    setPeersOptions(list);
  };

  const getCeoList = async (company_name, data) => {
    if (!company_name) {
      setProfileInfo(data);
      return;
    }
    let _profileInfo = { ...data };
    let payload = {};
    payload["company"] = company_name;
    const response = await axios({
      method: "post",
      data: payload,
      url: `${baseURL}/show_ceo_list_by_company`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const userList = response?.data;
    let list = [];

    for (const user of userList) {
      let obj = {};
      obj["key"] = user?.user?.id;
      obj["label"] = `${user.user.first_name} ${user.user.last_name}`;
      obj["value"] = `${user.user.first_name} ${user.user.last_name}`;
      list.push(obj);
    }
    let _index = list?.findIndex((val) => val.key === data.ceo);
    _profileInfo["ceo"] = list[_index]?.label;
    setProfileInfo(_profileInfo);
    setCeoOptions(list);
  };

  const handleKeyUp = (e) => {
    if (e.code === "Enter") handleUpdateProfile();
  };

  const handleInputChange = (val) => {
    const { data, name } = val;
    setProfileInfo((prevProps) => ({
      ...prevProps,
      [name]: data,
    }));

    setProfileChanges((prevChanges) => ({
      ...prevChanges,
      [name]: data,
    }));
  };

  const handleUpdateProfile = async () => {
    let _profileChanges = { ...profileChanges };
    const updatedFormData = new FormData();
    if (_profileChanges?.email) {
      updatedFormData.append("email", _profileChanges?.email);
    }
    if (_profileChanges?.first_name) {
      updatedFormData.append("first_name", _profileChanges?.first_name);
    }
    if (_profileChanges?.last_name) {
      updatedFormData.append("last_name", _profileChanges?.last_name);
    }
    if (profileChanges?.user) {
      delete _profileChanges["user"];
    }

    updatedFormData.append("is_oneonone", check);

    let index = peersOptions.findIndex(
      (val) => val.value === _profileChanges.inline
    );
    let subIndex = peersOptions.findIndex(
      (val) => val.value === _profileChanges.subordinate
    );
    let ceoIndex = ceoOptions.findIndex(
      (val) => val.value === _profileChanges.ceo
    );

    Object.keys(_profileChanges).forEach((key) => {
      if (key === "inline") {
        updatedFormData.append(key, peersOptions[index]?.key);
      } else if (key === "subordinate") {
        updatedFormData.append(key, peersOptions[subIndex]?.key);
      } else if (key === "ceo") {
        updatedFormData.append(key, ceoOptions[ceoIndex]?.key);
      } else {
        if (
          key !== "profile_photo" &&
          key !== "email" &&
          key !== "first_name" &&
          key !== "last_name"
        ) {
          updatedFormData.append(key, _profileChanges[key]);
        }
      }
    });

    if (_profileChanges.profile_photo) {
      updatedFormData.append("profile_photo", _profileChanges.profile_photo);
    }

    const response = await axios({
      method: "POST",
      url: `${baseURL}/update_user_profile/${id}`,
      data: updatedFormData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.status === 200) {
      console.log(response);
      toastNotify("Profile Updated Successfully", "success");
    }
  };

  const handleSelectChange = (selectedVal) => {
    const { data, name } = selectedVal;
    let _editProfile = { ...profileInfo };

    if (name === "inline") {
      _editProfile[name] = data.value;
    } else {
      _editProfile[name] = data.value;
    }
    if (name === "state") {
      setCities(indianCities[data.value ?? ""]);
    }

    if (name === "company_name") {
      _editProfile["inline"] = "";
      _editProfile["subordinate"] = "";
      getUserList(data.value);
    }
    setProfileInfo(_editProfile);

    setProfileChanges((prevChanges) => ({
      ...prevChanges,
      [name]: data.value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProfileInfo((prevState) => ({
      ...prevState,
      profile_photo: file,
    }));

    setProfileChanges((prevChanges) => ({
      ...prevChanges,
      profile_photo: file,
    }));

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
  };

  const handlePDFChange = (e) => {
    const file = e.target.files[0];
    setProfileInfo((prevState) => ({
      ...prevState,
      calender_pdf: file,
    }));
    setProfileChanges((prevChanges) => ({
      ...prevChanges,
      calender_pdf: file,
    }));
  };

  // Template

  const location = [
    {
      label: "API",
      value: "api",
    },
    {
      label: "Finish Product",
      value: "Finish Product",
    },
    {
      label: "Both",
      value: "Both",
    },
  ];

  const renderSelectInput = (label, name, value, options) => {
    return (
      <div className={style.logindata}>
        <TTSelect
          name={name}
          label={label}
          value={value}
          onKeyUp={handleKeyUp}
          options={options}
          onChange={handleSelectChange}
        />
      </div>
    );
  };

  const renderInputTemp = (label, value, name, isChnaged) => {
    return (
      <div className={style.logindata_input}>
        <TTInput
          onKeyUp={handleKeyUp}
          disabled={name === "username" ? true : false}
          value={value}
          label={label}
          name={name}
          onChange={isChnaged ? handleInputChange : handleInputChange}
        />
      </div>
    );
  };

  return (
    <Layout>
      <Navbar collapsed={collapsed} />
      <Layout>
        <Content className={style.profilepageWrp}>
          <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />
          <div className={style.profileWrp}>
            <div className={style.profileWrapper}>
              <div className={style.profileHeader}>
                <h1>Profile Detail</h1>
              </div>
              <div className={style.basic_info}>
                {renderInputTemp(
                  "Username",
                  profileInfo?.user?.username,
                  "username"
                )}
                {renderInputTemp(
                  "First Name",
                  profileInfo?.first_name,
                  "first_name"
                )}
                {renderInputTemp(
                  "Last Name",
                  profileInfo?.last_name,
                  "last_name"
                )}

                {renderSelectInput(
                  "Country",
                  "country",
                  profileInfo?.country,
                  countyList,
                  true
                )}
                {renderSelectInput(
                  "State",
                  "state",
                  profileInfo?.state,
                  indianStates,
                  true
                )}
                {renderSelectInput(
                  "City",
                  "city",
                  profileInfo?.city,
                  cities,
                  true
                )}
                {renderInputTemp(
                  "Pin Code",
                  profileInfo?.pincode,
                  "pincode",
                  true
                )}
                {renderInputTemp("Email", profileInfo?.email, "email")}
                {renderInputTemp(
                  "Contact Number",
                  profileInfo?.contact_number,
                  "contact_number",
                  true
                )}
                {renderInputTemp(
                  "Department",
                  profileInfo?.department,
                  "department",
                  true
                )}
                {renderInputTemp(
                  "Designation",
                  profileInfo?.designation,
                  "designation",
                  true
                )}
                {renderSelectInput(
                  "Company Name",
                  "company_name",
                  profileInfo?.company_name,
                  companies,
                  true
                )}
                {renderSelectInput(
                  "Peers (CFT)",
                  "inline",
                  profileInfo?.inline,
                  peersOptions,
                  true
                )}
                {renderSelectInput(
                  "Subordinate",
                  "subordinate",
                  profileInfo?.subordinate,
                  peersOptions,
                  true
                )}
                {renderSelectInput(
                  "Reporting To",
                  "reporting_to",
                  profileInfo?.reporting_to,
                  peersOptions,
                  true
                )}
              </div>
              <div className={style.address}>
                {renderInputTemp(
                  "Site",
                  profileInfo?.address_line_1,
                  "address_line_1",
                  true
                )}
              </div>
              <div className={style.profile}>
                {renderSelectInput(
                  "Formulation",
                  "location",
                  profileInfo?.location,
                  location,
                  true
                )}
                {renderSelectInput(
                  "CEO",
                  "ceo",
                  profileInfo?.ceo,
                  ceoOptions,
                  true
                )}

                {user.role === "admin" && (
                  <>
                    <div className={style.checkBoxWrp}>
                      <input
                        type="checkbox"
                        onClick={() => setCheck(!check)}
                        value={check}
                        checked={check}
                        id="checkbox_id"
                      />
                      <label for="checkbox_id">One-On-One Choaching</label>
                    </div>
                    <div className={style.uploadUserPdf}>
                      <label>Upload Calendar PDF</label>
                      <div className={style.uploadPhoto}>
                        <div className={style.profilePhoto}>
                          <div className={style.showPdf}>
                            {
                              calPdf && 
                          <a
                        target="_blank"
                        href={`${baseURL}${calPdf}`}
                      >
                        Show Uploaded Pdf
                      </a>
                            }
                      </div>
                          <input
                            type="file"
                            name="calender_pdf"
                            className={style.imageInput}
                            onChange={handlePDFChange}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <label>Update Profile Image</label>
                <div className={style.uploadPhoto}>
                  <div className={style.profilePhoto}>
                    {preview ? (
                      <img src={preview} />
                    ) : (
                      <img src={`${baseURL}${profileInfo?.profile_photo}`} />
                    )}
                    <input
                      type="file"
                      name="images"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                <div></div>
              </div>
              <div className={style.footer}>
                <Button
                  className={style.footerBtn}
                  onClick={handleUpdateProfile}
                >
                  Update Profile
                </Button>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
      {user.role === "employee" && <Chatbox />}
    </Layout>
  );
};
export default Profile;
