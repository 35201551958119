import axios from "axios";
import React, { useEffect } from "react";
import { getToken, getUserInfo, removeToken } from "../common/helpers/localStorage.helper";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  AtomIsLogin,
  AtomIsNotification,
  AtomNotificationCount,
  AtomUserInfo,
} from "../store/genral.store";
import { baseURL } from "../Constant";
import { useLocation, useNavigate } from "react-router-dom";

const GlobalProvider = ({ children }) => {
  // Variables
  const token = getToken("token");
  const setUserInfo = useSetRecoilState(AtomUserInfo);
  const isLogin = useRecoilValue(AtomIsLogin);
  const userInfo = getUserInfo();
  const setNotification = useSetRecoilState(AtomIsNotification);
  const setNotificationCount = useSetRecoilState(AtomNotificationCount);
  const location = useLocation();
  const navigate = useNavigate();
  const setIsLogin = useSetRecoilState(AtomIsLogin);


  // Functions

  const getUserData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/show_user_profile/${userInfo.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response?.data;
      setUserInfo(data);
    } catch (error) {
      
      if(error?.response?.status === 401){
        removeToken("token");
        removeToken("userInfo");
        navigate("/");
        setIsLogin(false);
      }
    }
  
  };

  const getNotificationCount = async() =>{
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/notification_count`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNotificationCount(response?.data?.count);
    } catch (error) {}
  }

  const getStatus = async (id) => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/fetch_count/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNotification(response?.data?.count);
    } catch (error) {}
  };
  // Callbacks
  useEffect(() => {
    if (token) {
      getUserData();
      getNotificationCount();
    }
  }, [token, isLogin]);


  useEffect(() => {
    if (location.pathname && userInfo?.id) {
      
      getStatus(userInfo?.id);
    }
  }, [location.pathname]);
  return <>{children}</>;
};

export default GlobalProvider;
