
import React, { useState } from 'react'
import Header from '../Header/Header'
import styles from  "./p_p.module.css";
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';

const PrivacyandPolicy = () => {
  const [ menu,setMenu] = useState(false)
  return (
    <div className="beforeLoginContenet">
           <div className={styles.header}>
           <Header setHeader={setMenu}  />
         </div>
         <div className={styles.mainWrp}>
           <p>
             At iCAMS, we highly value our relationship with you and understand the
             importance of respecting and safeguarding your privacy. We strive to provide you with a comfortable
             and secure experience when using our services and entrusting us with
             your personal, company, and financial information. This privacy policy
             outlines our current practices and reinforces our dedication to
             protecting your financial privacy. Please note that we reserve the
             right to modify the content or services on our website without prior
             notice, which may result in changes to our privacy policy. Therefore,
             we encourage you to revisit this page regularly and by continuing to
             access or use our website, you agree to any updates made to this
             policy. By accessing and using our website, you consent to the
             practices described in this Privacy Policy. If you do not agree with
             any aspect of this policy, please refrain from using our website.
           </p>
           <div>
             <h1>Information We Collect:</h1>
              <div>
                <h2>Personal Information:</h2>
                <p>
                  {" "}
                  When you visit our website, we may collect personal information
                  that you voluntarily provide, such as your name, photograph, email
                  address, and contact details when you fill out our contact form or
                  subscribe to our newsletter.
                </p>
              </div>
              <div>
                <h2>Log Files:</h2>
                <p>
                  Like many other websites, we collect certain information
                  automatically and store it in log files. This information may
                  include your IP address, browser type, operating system,
                  referring/exit pages, date/time stamps, and clickstream data. This
                  information is used for analysing trends, administering the site,
                  and gathering demographic information.
                </p>
              </div>
              <div>
                <h2>Cookies and Similar Technologies: </h2>
                <p>
                  We use cookies and similar technologies to enhance your browsing
                  experience and collect certain information automatically. Cookies
                  are small files stored on your device that enable us to recognize
                  your browser and capture certain information. You can choose to
                  disable cookies through your browser settings, but please note
                  that some features of the Website may not function properly if
                  cookies are disabled.
                </p>
              </div>
                <h2>Use of Information:</h2>
              <p>
                We use the personal information collected to respond to your
                inquiries, provide requested information, and communicate with you
                regarding our services.
                <br />
                We may use the information collected to improve our website, tailor
                content to your preferences, and optimize the user experience.
                <br />
                We may use aggregated and anonymized information for statistical
                purposes, marketing analysis, and improving our services.
              </p>
            </div>
              <div>
              <h1>Data Sharing and Disclosure:</h1>
              <p style={{ marginTop: "1rem" }}>
                We do not sell, trade, or rent your personal information to third
                parties.
              </p>
              <br />
              <p>
                We may disclose personal information if required by law or in
                response to a legal request or government inquiry, or to protect our
                rights, property, or safety or that of our users or the public.
              </p>
              <h2> Data Security:</h2>
              <p>
                We use industry-accepted best methods to protect your information.
                We review our security procedures carefully and comply with
                applicable laws and security standards. Your sensitive information,
                such as financial information, is sent securely while transacting
                with Payment Gateway. We do not store your payment information in
                our system. Our staff is trained and required to safeguard your
                information.
              </p>
              <h2>Links to External Websites:</h2>
              <p>
                Our website may contain links to external websites that are not
                operated by us. We are not responsible for the privacy practices or
                content of these third-party websites. We encourage you to review
                the privacy policies of those websites.
              </p>
              <h2>Changes to this Privacy Policy:</h2>
              <p>
                We may update this Privacy Policy from time to time. Any changes we
                make will be posted on this page, and the "Last updated" date at the
                top will be revised accordingly. We encourage you to review this
                Privacy Policy periodically to stay informed about how we collect,
                use, and protect your personal information.
              </p>
              <h2>Contact Us:</h2>
              <p>
                If you have any questions or concerns about this Privacy Policy or
                our practices, please contact us at{" "}
                <a
                  style={{ textDecoration: "none", color: "blue" }}
                  href="mailto:inquiry@icretegy.com"
                >
                  inquiry@icretegy.com
                </a>
                {/* <Link to={"/mailto:"} ></Link> */}
                <br />
                By using our website, you acknowledge that you have read and
                understood this Privacy Policy and consent to the collection, use,
                and disclosure of your personal information as described herein.
              </p>{" "}
            </div>
          </div>
          <div className={styles.footer}>
            <Footer />
          </div>
          <div className={`${styles.open} ${menu ? styles.show : ""}`}>
            <Link className={styles.menuBar_link} to="/">
              Home
            </Link>
            <Link className={styles.menuBar_link} to="/signup">
              Sign up
            </Link>
            <Link className={styles.menuBar_link} to="/login">
              Log in
            </Link>
            <Link className={styles.menuBar_link} to="/t_s">
              Terms & Conditions
            </Link>
            <Link className={styles.menuBar_link} to="/privacyAndPolicy">
              Privacy Policy
            </Link>
          </div>
        </div>
  )
}

export default PrivacyandPolicy