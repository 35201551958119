import React, { useEffect, useState } from "react";
import SmHeader from "../../../../components/SmHeader/SmHeader";
import Navbar from "../../../../components/AfterLogin/Employee/Navbar/Navbar";
import styles from "../AdminCompanyPanel/AdminCompanyPanel.module.css";
import { baseURL } from "../../../../Constant";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Layout, theme, Tooltip } from "antd";
import {
  getToken,
  getUserInfo,
} from "../../../../common/helpers/localStorage.helper";

function AdminOnOComp() {
  // Variables
  const token = getToken("token");
  const user = getUserInfo();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [companies, setCompanies] = useState([]);
  const { Content } = Layout;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  //functions
  const fetchData = async () => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/showCompanies`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      setCompanies(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //callbacks
  useEffect(() => {
    fetchData();
    if (!token) navigate("/login");
  }, []);

  //others
  const DataRender = (company) => {
    return (
      <div className={styles.admin_panel_companylist_card}>
        <Link to={`/adminOnOWork/${company.id}`}>
          <div className={styles.admin_panel_com_logo}>
            <img src={`${baseURL}${company?.images}`} />
          </div>
        </Link>
          <h2>{company.company_name}</h2>
        <Tooltip title={company.company_description}>
          <p className={styles.ellips}>{company.company_description}</p>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={styles.dashboardWrapper}>
      <Layout>
        <Navbar collapsed={collapsed} />
        <Layout>
          <Content>
            <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />

            <div
              style={{
                padding: 24,
                minHeight: 100,
                background: "#e8e8e8",
                borderRadius: borderRadiusLG,
              }}
              className={styles.compwrp}
            >
              <div className={styles.admin_panel}>
                <h1>Companies</h1>
                <div className={styles.admin_panel_companylist}>
                  {companies?.map((company, index) => {
                    return <div key={index}>{DataRender(company)}</div>;
                  })}
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default AdminOnOComp;
