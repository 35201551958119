import React, { useEffect, useState } from "react";
import { Layout, theme } from "antd";
import Navbar from "../Navbar/Navbar";
import styles from "../Course/course.module.css";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  getToken,
  getUserInfo,
} from "../../../../common/helpers/localStorage.helper";
import { baseURL } from "../../../../Constant";
import axios from "axios";
import SmHeader from "../../../SmHeader/SmHeader";
import Chatbox from "../../Chatbox/Chatbox";

const SubordinateCourse = () => {
  const [data, setData] = useState([]);

  const token = getToken("token");
  const user = getUserInfo();
  const { id } = useParams();
  const location = useLocation();
   
  const [collapsed, setCollapsed] = useState(false);
  const { Content } = Layout;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const fetchData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/showUserCourses/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the access token in the headers
        },
      });
      const data = await response?.data;
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  

  useEffect(() => {
    fetchData();
  }, [token]);

  return (
    <Layout>
      <Navbar collapsed={collapsed} />
      <Layout>
        <Content className={styles.coursesWrp}>
          <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />

          <div
            style={{
              padding: 24,
              borderRadius: borderRadiusLG,
            }}
            className={styles.courses}
          >
            <h1 className={styles.heading}>Your Presentations</h1>
            <div className={styles.grid}>
              {data?.map((val, index) => {
                let _ceoCourse = location.pathname.startsWith("/ceoCourses");
                return (
                  <Link
                    key={index}
                    to={`/sub-Detials/${val.id}`}
                  >
                    
                    <div className={styles.cardWrapper}>
                      <div className={styles.cardHeading}>
                        <div className={styles.imageWrapper}>
                          <img src={`${baseURL}${val?.course?.images}`} />
                        </div>
                      </div>
                      {/* <div className={styles.cardContent}> */}
                        {/* <div className={styles.flex} >
                          <h3 className={styles.lession} >Lesson {index + 1}</h3>
                        </div> */}
                        {/* <h3 >{val?.course?.course_name}</h3> */}
                        {/* <p style={{ color: "#8A7D61" }}>{val.time_duration}</p> */}
                        {/* <div className={styles.description}>
                          {val.description}
                        </div> */}
                      {/* </div> */}
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </Content>
        <Chatbox/>
      </Layout>
    </Layout>
  );
};
export default SubordinateCourse;
