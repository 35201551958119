import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  theme,
  Button,
  Modal,
  ConfigProvider,
  Popconfirm,
  message,
  Tooltip,
} from "antd";
import Navbar from "../../../../components/AfterLogin/Employee/Navbar/Navbar";
import SmHeader from "../../../../components/SmHeader/SmHeader";
import styles from "./adminCP.module.css";
import {
  getToken,
  getUserInfo,
} from "../../../../common/helpers/localStorage.helper";
import { baseURL } from "../../../../Constant";
import axios from "axios";
import TTInput from "./../../../../components/shared/TTInput/TTInput";
import { Toast, toastNotify } from "../../../../components/shared/Toast/Toast";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import TTSelect from "../../../../components/shared/TTSelect/TTSelect";

const AdminWorkshopPanel = () => {
  // Variables
  const token = getToken("token");
  const [collapsed, setCollapsed] = useState(false);
  const [coursesList, setCoursesList] = useState([]);
  const [workshopsOptions, setWorkshopsOptions] = useState([]);
  const [courseDetail, setCourseDetail] = useState({
    workshop: "",
    course_name: "",
    time_duration: "",
    description: "",
    instructor: "",
    images: "",
    presentation: "",
    implementation_plan: "",
  });

  const [courseChange, setCourseChange] = useState({});

  const { Content } = Layout;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [isModal, setIsModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [preview, setPreview] = useState(false);

  //functions

  const showAddModal = () => {
    setIsModal(true);
    setIsEdit(false);
  };

  const handleClose = () => {
    setIsModal(false);
    setCourseDetail({
      workshop: "",
      course_name: "",
      time_duration: "",
      description: "",
      instructor: "",
      images: "",
      presentation: "",
      implementation_plan: "",
    });
  };

  const showEditModal = (data) => {
    setIsEdit(true, data);
    setIsModal(true);
    setCourseDetail(data);
  };

  const confirm = (e, course) => {
    console.log(e);
    debugger;
    message.success("Click on Yes");
    handleDeleteData(course);
  };

  const cancel = (e) => {
    console.log(e);
    message.error("Click on No");
  };

  const fetchData = async () => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/showCourses`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      setCoursesList(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchWorkshopsData = async () => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/showWorkshops`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      console.log(data);
      let workshop_list = [];
      for (const workshop of data) {
        let obj = {};
        obj["id"] = workshop?.id;
        obj["label"] = workshop?.workshop_name;
        obj["value"] = workshop?.workshop_name;
        workshop_list.push(obj);
      }
      setWorkshopsOptions(workshop_list);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
 

  const handleChange = (e) => {
    const { name, data } = e;

    setCourseDetail({
      ...courseDetail,
      // ["workshopId"]: data?.id,
      [name]: data?.value,
    });
    setCourseChange((prevChanges) => ({
      ...prevChanges,
      [name]: data,
    }));
  };

  const handleInputChange = (e) => {
    const { name, data } = e;
    setCourseDetail((prevState) => ({
      ...prevState,
      [name]: data,
    }));

    setCourseChange((prevChanges) => ({
      ...prevChanges,
      [name]: data,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setCourseDetail((prevState) => ({
      ...prevState,
      images: file,
    }));

    setCourseChange((prevChanges) => ({
      ...prevChanges,
      images: file,
    }));

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
  };

  const handleImpChange = (e) => {
    const file = e.target.files[0];
    setCourseDetail((prevState) => ({
      ...prevState,
      implementation_plan: file,
    }));
    setCourseChange((prevChanges) => ({
      ...prevChanges,
      implementation_plan: file,
    }));
  };

  const handlePPTChange = (e) => {
    debugger;
    const file = e.target.files[0];
    setCourseDetail((prevState) => ({
      ...prevState,
      presentation: file,
    }));
    setCourseChange((prevChanges) => ({
      ...prevChanges,
      presentation: file,
    }));
  };

  const handleValidation = (data) => {
    if (!data?.workshop) return "workshop Should Not Be Empty";
    if (!data?.course_name) return "course_name Should Not Be Empty";
    if (!data?.time_duration) return "time_duration Should Not Be Empty";
    if (!data?.images) return "images Should Not Be Empty";
    // if (!data?.presentation) return "presentation Should Not Be Empty";
    // if (!data?.implementation_plan) return "presentation Should Not Be Empty";
    return true;
  };

  const handleSubmit = async () => {
    let validationResult = handleValidation(courseDetail);
    if (validationResult === true) {
      const formData = new FormData();
      let obj = workshopsOptions.find(
        (val) => val.label === courseDetail.workshop
      );
      console.log(obj, "ind");
      formData.append("workshop", obj.id);
      formData.append("course_name", courseDetail.course_name);
      formData.append("time_duration", courseDetail.time_duration);
      formData.append("description", courseDetail.description);
      formData.append("instructor", courseDetail.instructor);
      formData.append("images", courseDetail.images);
      formData.append("presentation", courseDetail.presentation);
      try {
        const response = await axios({
          method: "post",
          data: formData,
          url: `${baseURL}/addCourse`,
          headers: {
            "Content-Type": "application/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response?.data;
        let _coursesList = [...coursesList];
        _coursesList.push(data);
        toast.success("Course added successfully!");
        setCoursesList(_coursesList);
        setCourseDetail({
          workshopId: "",
          workshop: "",
          course_name: "",
          time_duration: "",
          description: "",
          instructor: "",
          images: "",
          presentation: "",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsModal(false);
      setCourseDetail({});
    } else {
      toastNotify(validationResult, "error");
    }
  };

  const handleUpdateData = async () => {
    debugger;
    let _courseChange = { ...courseChange };

    if (_courseChange["workshop"]) {
      let workshopObj = _courseChange?.workshop;
      _courseChange["workshop"] = workshopObj?.id;
    }

    const updatedFormData = new FormData();
    Object.keys(_courseChange).forEach((key) => {
      updatedFormData.append(key, _courseChange[key]);
    });

    try {
      const response = await axios({
        method: "post",
        data: updatedFormData,
        url: `${baseURL}/updateCourse/${courseDetail.id}`,
        headers: {
          "Content-Type": "application/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      let _coursesList = [...coursesList];
      let index = coursesList.findIndex((course) => course.id === data.id);
      _coursesList.splice(index, 1, data);
      setCoursesList(_coursesList);
      toast.success("Workshop updated Successfully");
    } catch (error) {
      toastNotify("Error fetching data:", error);
    }
    setCourseDetail({
      workshop: "",
      course_name: "",
      time_duration: "",
      description: "",
      instructor: "",
      images: "",
      presentation: "",
      implementation_plan: "",
    });
    setIsModal(false);
  };

  const handleDeleteData = async (workshop) => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/deleteCourse/${workshop.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = response?.data;
      const _coursesList = [...coursesList];
      let index = coursesList.findIndex((course) => course.id === data.id);
      _coursesList.splice(index, 1);
      setCoursesList(_coursesList);
      toast.success("course Deleted successfully!");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClearPPt = () => {
    let _courseDetail = { ...courseDetail };
    _courseDetail.presentation = null;
    setCourseDetail(_courseDetail);
    console.log(_courseDetail, "courseDetail");
    setCourseChange((prevChanges) => ({
      ...prevChanges,
      presentation: "",
    }));
  };

  const handleClearPPtImp = () => {
    let _courseDetail = { ...courseDetail };
    _courseDetail.implementation_plan = null;
    setCourseDetail(_courseDetail);
    setCourseChange((prevChanges) => ({
      ...prevChanges,
      implementation_plan: "",
    }));
    console.log(_courseDetail, "courseDetail");
  };

  //callbacks
  useEffect(() => {
    fetchData();
    fetchWorkshopsData();
  }, []);

  //others
  const DataRender = (course) => {
    return (
      <div className={styles.admin_panel_Course_Card}>
        <Link className={styles.courseData} to={`/adminTask/${course.id}`}>
          <div className={styles.admin_panel_com_logo}>
            <img src={`${baseURL}${course?.images}`} />
          </div>
        </Link>
        <Tooltip title={course?.course_name}>
          <h2 className={styles.ellips}>{course?.course_name}</h2>
        </Tooltip>
        <div className={styles.wsCardBtn}>
          <Button onClick={() => showEditModal(course)}>Edit</Button>
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this Course?"
            onConfirm={(e) => confirm(e, course)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button>Delete</Button>
          </Popconfirm>
        </div>
      </div>
    );
  };

  const InputRender = (label, name, value, placeholder) => {
    return (
      <TTInput
        label={label}
        name={name}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
      />
    );
  };

  return (
    <div className={styles.dashboardWrapper}>
      <Layout>
        <Navbar collapsed={collapsed} />
        <Layout>
          <Content>
            <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />

            <div
              style={{
                padding: 24,
                minHeight: 100,
                background: "#e8e8e8",
                borderRadius: borderRadiusLG,
              }}
            >
              <div className={styles.admin_panel}>
                <div className={styles.workShopList_header}>
                  <h1>Courses</h1>
                  <Button onClick={showAddModal}>Add Course</Button>
                </div>
                <div className={styles.admin_panel_companylist}>
                  {coursesList?.map((course, index) => {
                    return (
                      <div
                        className={styles.admin_panel_companylist_card}
                        key={index}
                      >
                        {DataRender(course)}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <ConfigProvider
              theme={{
                components: {
                  Modal: {
                    titleFontSize: 24,
                  },
                },
              }}
            >
              <Modal
                title={isEdit ? "Edit WorkShop" : "Add New Course"}
                open={isModal}
                onCancel={handleClose}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
                width={800}
              >
                <div className={styles.modelFormWrp}>
                  <TTSelect
                    label="Workshop"
                    name="workshop"
                    value={courseDetail.workshop}
                    options={workshopsOptions}
                    onChange={handleChange}
                    className={styles.selectWorkshop}
                  />
                  <div className={styles.modelFormInputWrp}>
                    {InputRender(
                      "Course name:",
                      "course_name",
                      courseDetail.course_name,
                      "Enter Course Name"
                    )}
                    {InputRender(
                      "Time Duration:",
                      "time_duration",
                      courseDetail.time_duration,
                      "years"
                    )}
                  </div>
                  <div className={styles.workshopimage}>
                    <label>Upload Course Image:</label>
                    {preview ? (
                      <img className={styles.previewImage} src={preview} />
                    ) : (
                      <img
                        className={styles.previewImage}
                        src={`${baseURL}${courseDetail.images}`}
                      />
                    )}
                    <input
                      type="file"
                      name="images"
                      className={styles.imageInput}
                      onChange={handleFileChange}
                    />
                  </div>
                  <div className={styles.workshoppdf}>
                    <label>Upload Course Presentation:</label>
                    <div className={styles.pdfPreview}>
                      <a
                        target="_blank"
                        href={`${baseURL}${courseDetail?.presentation}`}
                      >
                        Show Uploaded Prasentation
                      </a>
                    </div>
                    <Button onClick={() => handleClearPPt()}>Clear</Button>
                    <input
                      type="file"
                      name="presentation"
                      className={styles.imageInput}
                      onChange={handlePPTChange}
                    />
                  </div>
                  <div className={styles.workshoppdf}>
                    <label>Upload Course Implementation:</label>
                    <div className={styles.pdfPreview}>
                      {/* {courseDetail?.implementation_plan &&
                        } */}
                      <a
                        target="_blank"
                        href={`${baseURL}${courseDetail?.implementation_plan}`}
                      >
                        Show Uploaded Implementation plan
                      </a>
                    </div>
                    <Button onClick={() => handleClearPPtImp()}>Clear</Button>

                    <input
                      type="file"
                      name="implementation"
                      className={styles.imageInput}
                      onChange={handleImpChange}
                    />
                  </div>
                  <div className={styles.modelButton}>
                    <Button onClick={isEdit ? handleUpdateData : handleSubmit}>
                      {isEdit ? "Update" : "Upload"}
                    </Button>
                  </div>
                </div>
              </Modal>
            </ConfigProvider>
          </Content>
          <Toast />
        </Layout>
      </Layout>
    </div>
  );
};

export default AdminWorkshopPanel;
