import { Select } from "antd";
import styles from './ttselect.module.css'

const TTSelect = (props) => {
  const onChange = (strVal, option) => {
    props.onChange &&
      props.onChange({
        data: option,
        name: props.name,
        stringValue: option[props.valueKey ?? ""],
      });
  };

  const onClear = () => {
    props.onChange &&
      props.onChange({
        data: {} ,
        name: props.name,
        stringValue: "",
      });
  };

  return (
    <div className="flex-col w100 relative">
      {props.label && (
        <label className={styles.label}>
          {props.label} &nbsp;
          {props.required && <span className="required">*</span>}
        </label>
      )}
      <Select
        style={{ minWidth: props.minWidth, ...props.style }}
        allowClear
        defaultValue={props.value ?? ""}
        showSearch={props.searchable}
        placeholder={props.placeholder}
        mode={props.mode}
        disabled={props.disabled}
        onSelect={onChange}
        onKeyUp={props.onKeyUp}
        allowClear={false}
        onClick={(e) => e.stopPropagation()}
        status={props.error ? "error" : ""}
        onSearch={props.onSearch}
        value={props.value ?? ""}
        className={`${props.className} ${styles.selectWrapper}`}
        fieldNames={{
          label: props.optionLabelKey ?? "label",
          value: props.valueKey ?? "",
        }}
        suffixIcon={props.suffixIcon}
        options={Object.values(props.options ?? {})}
      />
      {props.error && <label className="inputError">{props.error}</label>}
    </div>
  );
};

export default TTSelect;