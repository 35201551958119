import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Navbar/Navbar";
import {
  Layout,
  Collapse,
  Empty,
  ConfigProvider,
  Slider,
  Modal,
  Divider,
} from "antd";
import styles from "./coursedetails.module.css";
import { baseURL } from "../../../../Constant";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getToken } from "../../../../common/helpers/localStorage.helper";
import SmHeader from "../../../SmHeader/SmHeader";
import ApexCharts from "react-apexcharts";

import TextArea from "antd/es/input/TextArea";
import { toastNotify } from "./../../../shared/Toast/Toast";
import { useRecoilValue } from "recoil";
import { AtomUserInfo } from "../../../../store/genral.store";
import Chatbox from "../../Chatbox/Chatbox";
import { usePdf } from "@mikecousins/react-pdf";
import { GrLinkPrevious, GrLinkNext } from "react-icons/gr";
import { TbRulerOff } from "react-icons/tb";
import dayjs from "dayjs";

const CoursesDetails = () => {
  // Variables
  const { Content } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const { id } = useParams();
  const token = getToken("token");
  const [courseDetail, setCourseDetail] = useState({});
  const [taskList, setTaskList] = useState([]);
  const [tData, setTData] = useState({});
  const [docs, setDocs] = useState([]);
  const [docs1, setDocs1] = useState([]);
  const [dataSeries, setDataSeries] = useState([]);

  const userInfo = useRecoilValue(AtomUserInfo);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalData1, setModalData1] = useState({});

  const [page, setPage] = useState(1);
  const Presentation = useRef(null);
  const [pageNo, setPageNo] = useState(1);
  const implementation_plan = useRef(null);

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        height: 350,
        type: "area",
        redrawOnParentResize: true,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      yaxis: [
        {
          title: {
            text: "Assessment",
          },
          min: 0,
          max: 10,
        },
      ],
      colors: ["#1677ff", "#FF9300", "#49FF00", "#FF0000"],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.3,
          opacityTo: 0.8,
          stops: [0, 300],
        },
      },
    },
  });

  const { pdfDocument } = usePdf({
    file: docs,
    page: page,
    canvasRef: Presentation,
  });

  const { pdfDocument: pdfDocument1 } = usePdf({
    file: docs1,
    page: pageNo,
    canvasRef: implementation_plan,
  });

  // Functions
  const renderInput = (
    name,
    value,
    disable,
    taskData,
    data,
    idx,
    index,
    inl,
    sub,
    emp,
    first_name,
    inline_first_name,
    sub_first_name,
    month
  ) => {
    return (
      <div className={styles.input_wrapper}>
        <TextArea
          className={styles.input}
          name={name}
          value={value}
          disabled={disable}
          onChange={(e) =>
            handleAnswerInput(
              e,
              name,
              taskData,
              data,
              idx,
              index,
              inl,
              sub,
              emp,
              first_name,
              inline_first_name,
              sub_first_name,
              month
            )
          }
        />
      </div>
    );
  };

  const handleAnswerInput = (
    e,
    name,
    taskData,
    month,
    index,
    ansIdx,
    inl,
    sub,
    emp,
    first_name,
    inline_first_name,
    sub_first_name,
    months,
    isOneonOne
  ) => {
    let _value = "";
    if (name === "level" || name === "level_by_admin") {
      _value = e;
    } else {
      const { value } = e.target;
      _value = value;
    }
    let _taskData = { ...taskData };
    let _month = [..._taskData[month]];
    let _selMonth = { ..._month[index] };
    let _answer = [..._selMonth.answer];
    let _selectedAnsWer = { ..._answer[ansIdx] };
    _selectedAnsWer[name] = _value;
    _selectedAnsWer["isModified"] = true;
    _answer[ansIdx] = _selectedAnsWer;
    _selMonth["answer"] = _answer;
    _month[index] = _selMonth;
    _taskData[month] = _month;
    const res = makeData(
      _taskData,
      inl,
      sub,
      emp,
      first_name,
      inline_first_name,
      sub_first_name,
      months,
      isOneonOne
    );
    setTData(res);
  };

  const handleValidation = (data) => {
    if (!data) return "Answer Should Not Be Empty";
    if (!data?.answer) return "Answer Should Not Be Empty";
    if (!data?.level) return "Level Should Not Be Empty";
    return true;
  };

  const handleSubmitAnswer = async (answer, taskId) => {
    let isValid = handleValidation(answer);
    if (isValid === true) {
      let _payload = { ...answer };
      delete _payload["isDisabled"];
      delete _payload["isModified"];
      delete _payload["id2"];
      try {
        const response = await axios({
          method: "post",
          data: _payload,
          url: `${baseURL}/edit_task/${taskId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status === 200) {
          toastNotify("Answer Added Successfully", "sucess");
          // fetchData(id);
          fetchAdminData(id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      toastNotify(isValid, "error");
    }
  };

  const handleUpdateTask = async (answer) => {
    let isValid = handleValidation(answer);
    if (isValid === true) {
      let _payload = { ...answer };
      _payload["answer"] = answer.answer;
      _payload["lavel"] = answer.level;
      try {
        const response = await axios({
          method: "post",
          data: _payload,
          url: `${baseURL}/update_task/${answer.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status === 200) {
          toastNotify("Answer Added Successfully", "sucess");
          // fetchData(id);
          fetchAdminData(id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      toastNotify(isValid, "error");
    }
  };

  const handleDraftUpdateAnswer = async (answer) => {
    let isValid = handleValidation(answer);
    if (isValid === true) {
      let _payload = { ...answer };
      _payload["answer"] = answer.answer;
      _payload["level"] = 0;
      try {
        const response = await axios({
          method: "post",
          data: _payload,
          url: `${baseURL}/update_task_draft/${answer.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status === 200) {
          toastNotify("Answer Added Successfully", "sucess");
          fetchAdminData(id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      toastNotify(isValid, "error");
    }
  };

  // const handleAddField = (
  //   taskData,
  //   month,
  //   index,
  //   ansId,
  //   inl,
  //   sub,
  //   emp,
  //   first_name,
  //   inline_first_name,
  //   sub_first_name,
  //   months,
  //   isOneonOne
  // ) => {
  //   let ansObj = {};
  //   ansObj["id2"] = ansId;
  //   ansObj["answer"] = "";
  //   ansObj["level"] = "";
  //   ansObj["isDisabled"] = false;
  //   let _taskData = { ...taskData };

  //   let _month = [..._taskData[month]];
  //   let _selMonth = { ..._month[index] };
  //   let _answer = [..._selMonth.answer];
  //   _selMonth["answer"] = [..._answer, ansObj];
  //   _month[index] = _selMonth;
  //   _taskData[month] = _month;
  //   const res = makeData(
  //     _taskData,
  //     inl,
  //     sub,
  //     emp,
  //     first_name,
  //     inline_first_name,
  //     sub_first_name,
  //     months,
  //     isOneonOne
  //   );
  //   setTData(res);
  // };

  const makeData = (
    taskData,
    inl,
    sub,
    emp,
    first_name,
    inline_first_name,
    sub_first_name,
    month,
    isOneonOne
  ) => {
    let _list = [];
    let _newList = [];
    let index = 0;
    let _nObj = {};

    for (const data in taskData) {
      index++;
      let obj = {};
      debugger
      const taskMonth = dayjs(month[data]?.day);
      const currentMonth = dayjs();

      if (taskMonth.isBefore(currentMonth, "month")) {
        obj["collapsible"] = "header"; // Before June, collapsible
      } else if (taskMonth.isSame(currentMonth, "month")) {
        obj["collapsible"] = "header"; // June, collapsible
      } else {
        obj["collapsible"] = "disabled"; // After June, not collapsible
      }

      const element = taskData[data];
      obj["key"] = data;
      obj["label"] = (
        <div className={styles.CollapseHeader}>
          <p>{month[data ?? ""]?.month}</p>
          <p className={styles.deadlineDate}>{month[data ?? ""]?.day} </p>
        </div>
      );
      let _element = [...element];
      let _el = [];
      for (const ele of _element) {
        let _ans = [];
        let _obj = { ...ele };
        for (const ans of ele.answer) {
          let _obj = { ...ans };
          _ans.push(_obj);
        }
        _obj["answer"] = _ans;
        _el.push(_obj);
        _nObj[index] = _obj;
      }
      _newList.push(_el);
      obj["children"] = _el?.map((val, idx) => {
        let _obj = {};
        _obj["key"] = idx;
        _obj["label"] = val?.questions;
        _obj["children"] = val?.answer?.map((answer, index) => {
          let _idx = index + 1;
          let _disabled;
          // let currentDate = dayjs().format("YYYY-MM-DD");
          if (!answer?.answer || answer?.isModified) {
            if (
              (userInfo.role === "admin" || userInfo.role === "ceo") &&
              !answer?.answer
            ) {
              _disabled = true;
            } else {
              _disabled = false;
            }
          } else if (answer?.id && answer?.answer) {
            if (answer.is_completed) {
              _disabled = true;
            } else {
              _disabled = false;
            }
          } else {
            _disabled = answer?.isDisabled === false ? false : true;
          }

          // if (taskMonth.isBefore(currentDate, "day")) {
          //   _disabled = true;
          // }

          return (
            <>
              <div>
                <div key={index}>
                  <div className={styles.employeeinputs}>
                    <div className={styles.inputWrp}>
                      <label className={styles.questionLabel}>
                        {`Answer by ${emp} (Self Assessment)`}
                      </label>
                      {renderInput(
                        "answer",
                        answer.answer,
                        _disabled,
                        taskData,
                        data,
                        idx,
                        index,
                        inl,
                        sub,
                        emp,
                        first_name,
                        inline_first_name,
                        sub_first_name,
                        month,
                        isOneonOne
                      )}
                    </div>
                    <div className={styles.sliderWrp}>
                      <label className={styles.questionLabel}>
                        {`Assesment by ${first_name}`}
                      </label>

                      <div className={styles.sliderCounter}>
                        <Slider
                          min={1}
                          max={10}
                          disabled={_disabled}
                          onChange={(e) =>
                            handleAnswerInput(
                              e,
                              "level",
                              taskData,
                              data,
                              idx,
                              index,
                              inl,
                              sub,
                              emp,
                              first_name,
                              inline_first_name,
                              sub_first_name,
                              month,
                              isOneonOne
                            )
                          }
                          value={answer.level ? answer.level : 1}
                          marks={{
                            1: "1",
                            2: "2",
                            3: "3",
                            4: "4",
                            5: "5",
                            6: "6",
                            7: "7",
                            8: "8",
                            9: "9",
                            10: "10",
                          }}
                          step={1}
                        />
                      </div>

                      {!_disabled && (
                        <>
                          {userInfo.role === "employee" && (
                            <div className={styles.checkbtnWrp}>
                              <button
                                className={styles.checkBtn}
                                onClick={() => handleDraftUpdateAnswer(answer)}
                              >
                                Draft
                              </button>
                              <ConfigProvider
                                theme={{
                                  token: {
                                    colorSplit: "#fff",
                                  },
                                }}
                              >
                                <Divider
                                  type="vertical"
                                  className={styles.divider}
                                />
                              </ConfigProvider>
                              <button
                                className={styles.checkBtn}
                                onClick={() =>
                                  val.answer.length === 1
                                    ? handleUpdateTask(answer, val.id)
                                    : handleSubmitAnswer(answer, answer.id2)
                                }
                              >
                                Submit
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {Object.keys(answer)?.includes("answer_by_subordinate") && (
                  <>
                    {isOneonOne === false && (
                      <>
                        <div className={styles.employeeinputs}>
                          <div className={styles.inputWrp}>
                            <label className={styles.questionLabel}>
                              {`Answer by ${inl} (Peer (CFT)) `}
                            </label>
                            {renderInput(
                              "answer",
                              answer.level_by_inline !== 0
                                ? answer.answer_by_inline
                                : "",
                              // answer.level_by_inline!==0 && answer.answer_by_inline,
                              true
                            )}
                          </div>
                          <div className={styles.sliderWrp}>
                            <label className={styles.questionLabel}>
                              {`Assessment by ${inline_first_name} `}
                            </label>
                            <div className={styles.sliderCounter}>
                              <Slider
                                min={1}
                                max={10}
                                value={
                                  answer.level_by_inline
                                    ? answer.level_by_inline
                                    : 1
                                }
                                disabled={true}
                                marks={{
                                  1: "1",
                                  2: "2",
                                  3: "3",
                                  4: "4",
                                  5: "5",
                                  6: "6",
                                  7: "7",
                                  8: "8",
                                  9: "9",
                                  10: "10",
                                }}
                                step={1}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={styles.employeeinputs}>
                          <div className={styles.inputWrp}>
                            <label className={styles.questionLabel}>
                              {`Answer by ${sub} (Subordinate)`}
                            </label>
                            {renderInput(
                              "answer",
                              // answer.answer_by_subordinate,
                              answer.level_by_subordinate !== 0
                                ? answer.answer_by_subordinate
                                : "",
                              true
                            )}
                          </div>
                          <div className={styles.sliderWrp}>
                            <label className={styles.questionLabel}>
                              {`Assessment by ${sub_first_name}`}
                            </label>
                            <div className={styles.sliderCounter}>
                              <Slider
                                min={1}
                                max={10}
                                disabled={true}
                                value={
                                  answer.level_by_subordinate
                                    ? answer.level_by_subordinate
                                    : 1
                                }
                                marks={{
                                  1: "1",
                                  2: "2",
                                  3: "3",
                                  4: "4",
                                  5: "5",
                                  6: "6",
                                  7: "7",
                                  8: "8",
                                  9: "9",
                                  10: "10",
                                }}
                                step={1}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className={styles.employeeinputs}>
                      <div className={styles.inputWrp}>
                        <label className={styles.questionLabel}>
                          Answer by iCretegy Advisor
                        </label>
                        {renderInput(
                          "answer",
                          answer.level_by_admin !== 0
                            ? answer.answer_by_admin
                            : "",
                          // answer.answer_by_admin
                          true
                        )}
                      </div>
                      <div className={styles.sliderWrp}>
                        <label className={styles.questionLabel}>
                          Assessment by iCretegy
                        </label>
                        <div className={styles.sliderCounter}>
                          <Slider
                            min={1}
                            max={10}
                            value={
                              answer.level_by_admin ? answer.level_by_admin : 1
                            }
                            disabled={
                              userInfo.role === "employee" ||
                              userInfo.role === "ceo"
                                ? true
                                : false
                            }
                            marks={{
                              1: "1",
                              2: "2",
                              3: "3",
                              4: "4",
                              5: "5",
                              6: "6",
                              7: "7",
                              8: "8",
                              9: "9",
                              10: "10",
                            }}
                            step={1}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* {(userInfo.role === "employee" && !isOneonOne) ||
                  (answer.is_completed && (
                    <div>
                      {val?.answer?.length === _idx && (
                        <button
                          className={styles.addAnsBtn}
                          disabled={!_disabled}
                          onClick={() =>
                            handleAddField(
                              taskData,
                              data,
                              idx,
                              answer.id,
                              inl,
                              sub,
                              emp,
                              first_name,
                              inline_first_name,
                              sub_first_name,
                              month,
                              isOneonOne
                            )
                          }
                        >
                          +
                        </button>
                      )}
                    </div>
                  ))} */}
              </div>
            </>
          );
        });

        return (
          <div>
            <div key={index}>
              <ConfigProvider
                theme={{
                  components: {
                    Collapse: {
                      headerBg: "white",
                    },
                  },
                }}
              >
                <Collapse
                  className="custom-collapse-inner"
                  accordion={true}
                  defaultActiveKey={["0"]}
                  items={[_obj]}
                />
              </ConfigProvider>
            </div>
          </div>
        );
      });
      _list.push(obj);
    }
    setTaskList(_list);
    return _nObj;
  };

  const fetchAdminData = async (id) => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/show_course_detail_admin/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const courseData = await response?.data?.course_data;
      const _taskData = await response?.data?.task_data;
      const inlineName = await response?.data?.inline;
      const employeeName = await response?.data?.name;
      const subordinateName = await response?.data?.subordinate;
      const heading = await response?.data?.heading;
      const sortedDates = Object.values(heading).sort((a, b) => {
        // Convert date strings to Date objects for accurate comparison
        const dateA = new Date(a.day);
        const dateB = new Date(b.day);
        return dateA - dateB;
      });
      console.log(heading,'heading');
      console.log(sortedDates,'sortedDates');
      
      const _res = response.data;
      const isOneonOne = _res?.is_oneonone;
      let newObj = {};
      for (const key in _taskData) {
        let _newList = [];
        for (const data of _taskData[key]) {
          let _newObj = { ...data };
          let _sorted = data?.answer?.sort((a, b) => {
            let isInclude = Object.keys(a)?.includes("answer_by_subordinate");
            return isInclude ? 1 : -1;
          });
          _newObj["answer"] = _sorted;
          _newList.push(_newObj);
        }
        newObj[key] = _newList;
      }
      const res = makeData(
        newObj,
        inlineName,
        subordinateName,
        employeeName,
        _res.first_name,
        _res.inline_first_name,
        _res.sub_first_name,
        heading,
        isOneonOne
      );
      setTData(res);
      if (courseData) {
        const pptURl = courseData.presentation
          ? `${baseURL}${courseData.presentation}`
          : null;
        const pptURl2 = courseData.implementation_plan
          ? `${baseURL}${courseData.implementation_plan}`
          : null;

        // Only setDocs if pptURl is not null
        if (pptURl) {
          setDocs(pptURl);
        } else {
          // Handle the case where pptURl is null, if necessary
        }
        
        // If you also want to handle pptURl2, you can add similar logic
        if (pptURl2) {
          setDocs1(pptURl2);
          // handle pptURl2 as needed
        } else {
          console.error("pptURl2 is null, cannot set docs.");
        }
      } else {
        console.error("courseData is null or undefined.");
        // You might want to handle this case by setting a default state or showing an error message to the user
      }

      setCourseDetail(courseData);
      fetchGraphData(courseData, isOneonOne);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchGraphData = async (val, isOneonOne) => {
    try {
      let _payload = {};
      _payload["course_name"] = val?.course_name;
      const response = await axios({
        method: "post",
        data: _payload,
        url: `${baseURL}/monthly_data_entered/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = response.data;
      let monthList = response?.data?.month_list;
      let _chartData = { ...chartData };
      let _options = { ...chartData.options };
      let _xaxis = { ..._options.xaxis };
      _xaxis["categories"] = monthList;
      _options["xaxis"] = _xaxis;
      _chartData["options"] = _options;
      setChartData(_chartData);
      let list = [];
      let _includes = ["Peer (CFT) Assessment", "Sub-Ordinate Assessment"];
      for (const key in data) {
        if (key === "monthly_avg") continue;
        if (key === "month_list") continue;
        let element = data[key];
        for (const ele in element) {
          if (_includes.includes(ele) && isOneonOne) continue;
          let obj = {};
          obj["name"] = ele;
          obj["data"] = element[ele];
          obj["type"] = "area";
          list.push(obj);
        }
      }
      setDataSeries(list);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const renderNoData = () => {
    return <Empty description="No Task Available" />;
  };

  const openModal = (data) => {
    setPage(0);
    setOpen(true);
    setModalData(data);
    setTimeout(() => {
      setPage(1);
    }, 500);
  };

  const closeModal = () => {
    setOpen(false);
    setModalData({});
  };

  const openModal1 = (data) => {
    setPageNo(0);
    setOpen1(true);
    setModalData1(data);
    setTimeout(() => {
      setPageNo(1);
    }, 500);
  };

  const closeModal1 = () => {
    setOpen1(false);
    setModalData({});
  };

  const handleChange = (e) => {
    let _value = Number(e.target.value);
    if (isNaN(_value)) return toastNotify("Plaese enter a Number.", "error");

    if (_value > pdfDocument?.numPages) {
      toastNotify(
        `Page number should not be greater than ${pdfDocument?.numPages}`,
        "error"
      );
      return;
    } else {
      setPage(_value);
    }
  };

  const handlePdf2Change = (e) => {
    let _value = Number(e.target.value);
    if (isNaN(_value)) return toastNotify("Plaese enter a Number.", "error");

    if (_value > pdfDocument1?.numPages) {
      toastNotify(
        `Page number should not be greater than ${pdfDocument1?.numPages}`,
        "error"
      );
      return;
    } else {
      setPageNo(_value);
    }
  };

  // const getCurrentMonth = () => {
  //   const currentMnth = dayjs().format("M");
  // };
  //Callbacks
  useEffect(() => {
    if (id && userInfo.role) {
      fetchAdminData(id);
    }
  }, [id, userInfo.role]);

  return (
    <Layout>
      <Navbar collapsed={collapsed} />
      <Layout>
        <Content className={styles.courseDetailPageWrp}>
          <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />

          <div className={styles.courseDetail}>
            <div className={styles.presentation_page_wrapper}>
              <h1>Overall Assessment - {courseDetail.course_name}</h1>
              <div className={styles.chart}>
                <div className={styles.chartwrp}>
                  <ApexCharts
                    options={chartData.options}
                    series={dataSeries || []}
                    type="area"
                    height={400}
                    style={{ overflow: "hidden" }}
                  />
                </div>
              </div>

              <div className={styles.presentation_ppt_wrapper}>
                <h1>Presentations</h1>
                <div className={styles.presentationWrp}>
                  <div className={styles.pptWrp}>
                    <h2 className={styles.courseName_1}>
                      Outlook Presentation
                    </h2>
                    <div className={styles.displayControlls}>
                      <div>
                        <button
                          className={styles.contrBtn}
                          disabled={page === 1}
                          onClick={() => setPage(page - 1)}
                        >
                          <GrLinkPrevious />
                        </button>
                      </div>

                      <div>
                        {page} / {pdfDocument?.numPages}
                      </div>
                      <div>
                        <button
                          className={styles.contrBtn}
                          disabled={page === pdfDocument?.numPages}
                          onClick={() => setPage(page + 1)}
                        >
                          <GrLinkNext />
                        </button>
                      </div>
                    </div>
                    {!pdfDocument && <span>Loading...</span>}
                    <canvas ref={Presentation} className={styles.pdfCanvas} />

                    <button
                      onClick={() => openModal(docs)}
                      style={{
                        fontSize: "1rem",
                        backgroundColor: "#171151",
                        color: "white",
                        borderRadius: "1rem",
                        border: "none",
                        padding: "1rem",
                      }}
                    >
                      Full Screen
                    </button>
                  </div>
                  <div className={styles.pptWrp}>
                    <h2 className={styles.courseName_1}>Implementation Plan</h2>

                    <div className={styles.displayControlls}>
                      <div>
                        <button
                          className={styles.contrBtn}
                          disabled={pageNo === 1}
                          onClick={() => setPageNo(pageNo - 1)}
                        >
                          <GrLinkPrevious />
                        </button>
                      </div>

                      <div>
                        {pageNo} / {pdfDocument1?.numPages}
                      </div>
                      <div>
                        <button
                          className={styles.contrBtn}
                          disabled={pageNo === pdfDocument1?.numPages}
                          onClick={() => setPageNo(pageNo + 1)}
                        >
                          <GrLinkNext />
                        </button>
                      </div>
                    </div>
                    {!pdfDocument && <span>Loading...</span>}
                    <canvas
                      ref={implementation_plan}
                      className={styles.pdfCanvas}
                    />
                    <button
                      onClick={() => openModal1(docs1)}
                      style={{
                        fontSize: "1rem",
                        backgroundColor: "#171151",
                        color: "white",
                        borderRadius: "1rem",
                        border: "none",
                        padding: "1rem",
                      }}
                    >
                      Full Screen
                    </button>
                  </div>
                </div>
              </div>
              <div className={styles.taskListWrapper}>
                <h1>Task List</h1>
                <div className={styles.tasks}>
                  <ConfigProvider
                    theme={{
                      components: {
                        Collapse: {
                          headerBg: "#FAFAFA",
                        },
                      },
                    }}
                  >
                    <Collapse
                      accordion
                      className="custom-collapse"
                      // defaultActiveKey={getCurrentMonth() || ["1"]}
                      items={taskList ?? renderNoData}
                    />
                  </ConfigProvider>
                </div>
              </div>
            </div>
          </div>

          <Modal
            centered
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => closeModal(false)}
            width={1000}
          >
            {modalData && (
              <div className={styles.modalDataPdf}>
                <div className={styles.controllsAndInput}>
                  <div className={styles.pdfInput}>
                    <label>Search Page Number</label>
                    <input
                      className={styles.input}
                      label="Search Page Number"
                      value={page}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={styles.displayControlls}>
                    <div>
                      <button
                        className={styles.contrBtn}
                        disabled={page === 1}
                        onClick={() => setPage(page - 1)}
                      >
                        <GrLinkPrevious />
                      </button>
                    </div>

                    <div>
                      {page} / {pdfDocument?.numPages}
                    </div>
                    <div>
                      <button
                        className={styles.contrBtn}
                        disabled={page === pdfDocument?.numPages}
                        onClick={() => setPage(page + 1)}
                      >
                        <GrLinkNext />
                      </button>
                    </div>
                  </div>
                </div>

                {!pdfDocument && <span>Loading...</span>}
                <canvas ref={Presentation} className={styles.pdfCanvas} />
              </div>
            )}
          </Modal>
          <Modal
            centered
            open={open1}
            onOk={() => setOpen1(false)}
            onCancel={() => closeModal1(false)}
            width={1000}
          >
            {modalData1 && (
              <>
                <div className={styles.controllsAndInput}>
                  <div className={styles.pdfInput}>
                    <label>Search Page Number</label>
                    <input
                      label="Search Page Number"
                      value={pageNo}
                      onChange={handlePdf2Change}
                    />
                  </div>
                  <div className={styles.displayControlls}>
                    <div>
                      <button
                        className={styles.contrBtn}
                        disabled={pageNo === 1}
                        onClick={() => setPageNo(pageNo - 1)}
                      >
                        <GrLinkPrevious />
                      </button>
                    </div>

                    <div>
                      {pageNo} / {pdfDocument1?.numPages}
                    </div>
                    <div>
                      <button
                        className={styles.contrBtn}
                        disabled={pageNo === pdfDocument1?.numPages}
                        onClick={() => setPageNo(pageNo + 1)}
                      >
                        <GrLinkNext />
                      </button>
                    </div>
                  </div>
                </div>

                {!pdfDocument && <span>Loading...</span>}
                <canvas
                  ref={implementation_plan}
                  className={styles.pdfCanvas}
                />
              </>
            )}
          </Modal>
        </Content>
      </Layout>
      <Chatbox />
    </Layout>
  );
};

export default CoursesDetails;
