import React, { useEffect, useState } from "react";
import { Layout, theme, Badge, Calendar, Modal, Divider, Button } from "antd";
import styles from "./subCal.module.css";
import Navbar from "../Navbar/Navbar";
import { getToken } from "../../../../common/helpers/localStorage.helper";
import { Link, useNavigate } from "react-router-dom";
import SmHeader from "../../../SmHeader/SmHeader";
import { AtomUserInfo } from "../../../../store/genral.store";
import { useRecoilValue } from "recoil";
import GreenDot from "../../../../assets/GreenDot.png";
import orangeDot from "../../../../assets/orangeDot.png";
import axios from "axios";
import { baseURL } from "../../../../Constant";
import dayjs from "dayjs";
import "dayjs/locale/en"; 
import Chatbox from "../../Chatbox/Chatbox";

const SubCalendar = () => {
  //variables
  const token = getToken("token");
  const [taskList, setTaskList] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState({});
  const userInfo = useRecoilValue(AtomUserInfo);
  const { Content } = Layout;
  const {
    token: { borderRadiusLG },
  } = theme.useToken();
  const [tasks, setTasks] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [selectedTask, setSelectedTask] = useState(null);
  const navigate = useNavigate();
  console.log("tasks", tasks);

  const handleRedirect = (id) => {
    setShowModal(false);
    navigate(`/courses-details/${id}`);
    setTasks([]);
  };

  const renderStatus = (status) => {
    if (status === "success") {
      return (
        <div className={styles.Dot}>
          <img src={GreenDot} />
        </div>
      );
    }
    if (status === "error") {
      return (
        <div className={`${styles.Dot} ${styles.redDot}`}>
          {/* <img src={GreenDot} /> */}
        </div>
      );
    }
    if (status === "warning") {
      return (
        <div className={styles.Dot}>
          <img src={orangeDot} />
        </div>
      );
    }
    return <div></div>;
  };

  const handleDateClick = (value) => {
    const task = tasks.find(
      (task) => task.date === value?.format("YYYY-MM-DD")
    );
    if (task) {
      setShowModal(true);
      setSelectedTask(task);
    }
  };

  //callba
  useEffect(() => {
    if (userInfo?.user?.id) {
      let _formattedDate = dayjs().format("YYYY-MM");
      let _year = _formattedDate.slice(0, 4);
      let _month = _formattedDate.slice(5);
      fetchData(userInfo?.user?.id, Number(_year), Number(_month), dayjs());
    }
  }, [userInfo?.user]);

  //functions
  const fetchData = async (id, year, month, selectedDate) => {
    setTasks([]);
    let _payload = {
      month: month,
      year: year,
    };
    const response = await axios({
      method: "post",
      url: `${baseURL}/subord_calendar/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: _payload,
    });
    let data = response.data ?? [];
    let list = [];

    for (const val of data) {
      let obj = { ...val };
      if (val.type === "warning") {
        let endMonth = dayjs(selectedDate).endOf("month");
        // obj["starting_date"] = endMonth.format("MMMM DD, YYYY");
        obj["starting_date"] = dayjs(val.starting_date).format("YYYY-MM-DD");
        obj["date"] = endMonth.format("YYYY-MM-DD");
      } else {
        obj["date"] = dayjs(val.starting_date).format("YYYY-MM-DD");
      }

      list.push(obj);
    }
    setTasks(list);
  };


  // const getMonthData = (value) => {
  //   if (value.month() === 8) {
  //     return 1394;
  //   }
  // };

  // const monthCellRender = (value) => {
  //   const num = getMonthData(value);
  //   return num ? (
  //     <div className="notes-month">
  //       <section>{num}</section>
  //       <span>Backlog number</span>
  //     </div>
  //   ) : null;
  // };

  // const dateCellRender = (date) => {
  //   const listData = getListData1(date, taskList);
  //   console.log("listData", listData);
  //   return listData?.map((val, index) => {
  //     console.log("val", val);
  //     return (
  //       <div>
  //         <li key={index} className={styles.content}>
  //           <Badge
  //             status={val.type}
  //             text={val.course_name}
  //             onClick={() => handleCourseClick(val)}
  //           />
  //           {val?.type}
  //         </li>
  //       </div>
  //     );
  //   });
  // };

  const handleCourseClick = (item) => {
    setSelectedCourse(item);
    setIsModalOpen(true);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedCourse({});
  };

  dayjs.locale("en");

  const ShortWeekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const customLocale = {
    // Custom header text for each day of the week
    dayHeader: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
  };

  const handlePanelChange = (date) => {
    let _formattedDate = dayjs(date).format("YYYY-MM");
    let _year = _formattedDate.slice(0, 4);
    let _month = _formattedDate.slice(5);
    fetchData(userInfo?.user?.id, Number(_year), Number(_month), date);
  };

  return (
    <Layout>
      <Navbar collapsed={collapsed} />
      <Layout>
        <Content className={styles.calenderPageWrp}>
          <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />
          <div className={styles.calenderpage}>
            <div className={styles.calenderWrp}>
              <h1>Calendar</h1>
              <div className={styles.calender}>
                <Calendar
                  onSelect={handleDateClick}
                  onPanelChange={handlePanelChange}
                  cellRender={(date) => {
                    const task = tasks.find(
                      (task) => task.date === date.format("YYYY-MM-DD")
                    );
                    if (task) {
                      return (
                        <div className={styles[task.type + "1"]}>
                               <div className={styles.selDate} >Date : {task?.starting_date}</div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                          >
                            {renderStatus(task.type)}
                            <div style={{ fontSize: "1rem" }}>
                              {task.course_name}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  }}
                  locale={customLocale}
                />
                {/* <Calendar cellRender={cellRender} /> */}
              </div>
            </div>
          </div>

          <Modal
            // title="Questions"
            open={isModalOpen}
            onCancel={handleCancel}
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
          >
            {selectedCourse !== null && (
              <>
                {selectedCourse.type === "success" && (
                  <>
                    <div>
                      <h2>Course Name: {selectedCourse.courseName}</h2>
                      <div className={styles.modalDataWrp}>
                        <p>Starting Date : {selectedCourse.starting_date}</p>
                      </div>
                      <br />
                    </div>
                    <div className={styles.modalViewMoreBtn}>
                      <Link to={`/courses-details/${selectedCourse.courseId}`}>
                        <Button className={styles.Mbtn}>View More</Button>
                      </Link>
                    </div>
                  </>
                )}
                {selectedCourse.type === "warning" && (
                  <>
                    <div>
                      <h2>Upcoming Events</h2>
                      <h2>Course Name : {selectedCourse.courseName}</h2>
                      <div className={styles.modalDataWrp}>
                        <div className={styles.Dot}>
                          <img src={orangeDot} />
                        </div>
                        <p>Starting Date : {selectedCourse.starting_date}</p>
                      </div>
                      <br />
                    </div>
                    {/* <div className={styles.modalViewMoreBtn}>
                      <Button>
                        <Link to={`/courses-details/${3}`}>View More</Link>
                      </Button>
                    </div> */}
                  </>
                )}
                {selectedCourse.type === "error" && (
                  <>
                    <div>
                      <h2>{selectedCourse.courseName}</h2>
                      <div className={styles.modalDataWrp}>
                        <ul>
                          {selectedCourse.listofCourse?.map((course, index) => {
                            return (
                              <li className="modalCourseList">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <p>{course.course}</p>
                                  <Link to={`/courses-details/${course.id}`}>
                                    <Button className={styles.modalBtn}>
                                      {" "}
                                      View More
                                    </Button>
                                  </Link>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <br />
                    </div>
                    {/* <div className={styles.modalViewMoreBtn}>
                      <Button>
                        <Link to={`/courses-details/${3}`}>View More</Link>
                      </Button>
                    </div> */}
                  </>
                )}
              </>
            )}
          </Modal>
        </Content>
      </Layout>
      {showModal && (
        <Modal
          title={selectedTask.course_name}
          visible={showModal}
          onOk={() => setShowModal(false)}
          footer={[]}
          onCancel={() => setShowModal(false)}
          // onCancel={handleCloseModal}
        >
          <div>
            {(selectedTask?.type === "success" ||
              selectedTask?.type === "warning") && (
              <p>Starting Date : {selectedTask.starting_date}</p>
            )}
            {selectedTask?.listofCourse?.map((val, index) => {
              return (
                <li key={index}>
                  <div>{val.course}</div>
                  <div className={styles.modalViewMoreBtn}>
                    <Button
                      className={styles.Mbtn}
                      onClick={() => {
                        handleRedirect(val.id);
                      }}
                    >
                      View More
                    </Button>
                  </div>
                </li>
              );
            })}
            {selectedTask?.type !== "error" && (
              <div className={styles.modalViewMoreBtn}>
                <Button
                  onClick={() => {
                    handleRedirect(selectedTask.course_id);
                  }}
                  className={styles.Mbtn}
                >
                  View More
                </Button>
              </div>
            )}
          </div>
        </Modal>
      )}
      <Chatbox />
    </Layout>
  );
};
export default SubCalendar;
