import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./signup.module.css";
import { Link } from "react-router-dom";
import {
  SignupUrl,
  indianStates,
  indianCities,
  baseURL,
} from "../../../Constant";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import TTInput from "../../shared/TTInput/TTInput";
import TTSelect from "../../shared/TTSelect/TTSelect";
import header_logo from "../../../assets/icretegy_logo.png";
import { toastNotify } from "../../shared/Toast/Toast";
import Header from "../Header/Header";

const Signup = () => {
  //Variables
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [confirm_password, setConfirmPassword] = useState("");
  const [formdata, setFormdata] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });

  //Funcitons
  const handleInputChange = (e) => {
    const { data, name } = e;
    let _formData = { ...formdata };
    _formData[name] = data;
    setFormdata(_formData);
  };

  const handleUserChange = (e) => {
    const { data, name } = e;
    let _formData = { ...formdata };
    _formData[name] = data;
    setFormdata(_formData);
    if (name === "confirm_password") {
      setConfirmPassword(data);
    }
  };

  const handleKeyUp = (e) => {
    if (e.code === "Enter") handleSubmit();
  };

  const handleValidation = (data) => {
    if (!data.username) return "Username should not be empty";
    if (!data.first_name) return "First Name should not be empty";
    if (!data.last_name) return "Last Name should not be empty";
    if (!data.password) return "Password should not be empty";

    return true;
  };

  const validatePassword = (password) => {
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    return pattern.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let _formData = { ...formdata };
    _formData["city"] = _formData?.city?.value;
    _formData["state"] = _formData?.state?.value;
    _formData["country"] = _formData?.country?.value;
    _formData["role"] = _formData?.role?.value;
    let validationResult = handleValidation(_formData);
    let regex = validatePassword(formdata?.password);

    if (validationResult === true && regex === true) {
      try {
        const res = await axios({
          method: "post",
          url: SignupUrl,
          data: _formData,
          headers: {
            "Content-Type": "application/json", // Include the access token in the headers
          },
        });
        if (res.status === 200) {
          navigate("/login");
          toastNotify("Registration Successfully.", "success");
        }
      } catch (error) {
        toastNotify("Data Not Provided Correctly!", "error");
      }
    } else {
      if (validationResult !== true) {
        toastNotify(validationResult, "error");
      } else {
        toastNotify(
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character, and be at least 8 characters long.",
          "error"
        );
      }
    }
  };


  //Templates
  const renderInputTemp = (label, value, name, isChnaged) => {
    return (
      <TTInput
        type={
          name === "password" || name === "confirm_password"
            ? "password"
            : "text"
        }

        className={styles.TTinput}
        onKeyUp={handleKeyUp}
        value={value}
        label={label}
        name={name}
        onChange={isChnaged ? handleInputChange : handleUserChange}
      />
    );
  };


  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Header setHeader={setMenu} />
      </div>
      <div className={styles.signupDiv}>
        <div className={styles.signupWrapper}>
          <div className={styles.signup}>
            <h2>Welcome to iCAMS</h2>
            <p>Sign up to continue to iCAMS</p>
            <div className={styles.form}>
              <div className={styles.basic_info}>
                {renderInputTemp("Username", formdata?.username, "username")}
                {renderInputTemp(
                  "First Name",
                  formdata?.first_name,
                  "first_name"
                )}
                {renderInputTemp("Last Name", formdata?.last_name, "last_name")}
                {renderInputTemp("Password", formdata?.password, "password")}
                {renderInputTemp(
                  "Confirm Password",
                  confirm_password,
                  "confirm_password"
                )}
              </div>

              <div className={styles.submit}>
                <input type="checkbox" name="t&c" /> I Agree{" "}
                <Link to={"/t_s"}>Terms & Condition</Link>
                <button onClick={handleSubmit} className={styles.signup_btn}>
                  Sign Up
                </button>
              </div>
              <button className={styles.login_btn}>
                Already have an Account? <Link to="/login">Log in</Link>{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
      <div className={`${styles.open} ${menu ? styles.show : ""}`}>
        <Link className={styles.menuBar_link} to="/">
          Home
        </Link>
        <Link className={styles.menuBar_link} to="/signup">
          Sign up
        </Link>
        <Link className={styles.menuBar_link} to="/login">
          Log in
        </Link>
        <Link className={styles.menuBar_link} to="/t_s">
          Terms & Conditions
        </Link>
        <Link className={styles.menuBar_link} to="/privacyAndPolicy">
          Privacy Policy
        </Link>
      </div>
    </div>
  );
};

export default Signup;
