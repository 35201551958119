import React, { useEffect, useState } from "react";
import { Button, Layout, Menu } from "antd";
import { AiFillDashboard } from "react-icons/ai";
import { FaBookReader, FaUserCircle, FaCalendarAlt } from "react-icons/fa";
import { IoIosHome, IoMdClose } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../../../common/helpers/localStorage.helper";
import com_logo from "../../../../assets/icretegy_logo.png";
import { IcamsRoutes } from "../../../../common/constants/route.constants";
import styles from "./navbar.module.css";
import { useRecoilState } from "recoil";
import { AtomMenuBar } from "../../../../store/genral.store";
import { useResponsiveMenuBar } from "../../../../store/responsiveNav";
import { RiTeamLine } from "react-icons/ri";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";

function Navbar() {
  useResponsiveMenuBar();
  const { Sider } = Layout;
  const [collapsed, setCollapsed] = useRecoilState(AtomMenuBar);
  const navigate = useNavigate();
  const user = getUserInfo() ?? "";
  const [selectedKey, setSelectedKey] = useState("");
  const location = useLocation().pathname;
  const handleMenuClick = (e) => {
    setCollapsed(!collapsed);
    setSelectedKey(e.key);
    navigate(e.key);
  };

  useEffect(() => {
    setSelectedKey(location);
  }, []);

  let home =
    user?.role === "employee"
      ? IcamsRoutes.EmployeeHome
      : user?.role === "ceo"
      ? IcamsRoutes.CeoHome
      : user?.role === "admin"
      ? IcamsRoutes.adminHome
      : user?.role === "subordinate"
      ? IcamsRoutes.subordinate
      : user?.role === "peer"
      ? IcamsRoutes.inline
      : "/login";

  const empMenu = [
    {
      key: home,
      label: "Home",
      icon: <IoIosHome />,
    },
    {
      key: `/dashboard/${user?.id}`,
      label: "Dashboard",
      icon: <AiFillDashboard />,
    },
    {
      key: `/calendar/${user.id}`,
      label: "Calendar",
      icon: <FaCalendarAlt />,
    },
    {
      key: `/courses/${user.id}`,
      label: "Courses",
      icon: <FaBookReader />,
    },
    {
      key: `/profile/${user.id}`,
      label: "Profile",
      icon: <FaUserCircle />,
    },
    {
      key: "/user-manual",
      label: "User Manual",
      icon: <BsFillFileEarmarkPdfFill />,
    },
    {
      key: "/currentAssignRole",
      label: "Current Assigned Roles",
      icon: <RiTeamLine />,
    },
  ];

  const ceoMenu = [
    {
      key: home,
      label: "Home",
      icon: <IoIosHome />,
    },
    {
      key: "Dashboard",
      label: "Dashboard",
      icon: <AiFillDashboard />,
      children: [
        {
          key: `/ceo-dashboard/${user.id}`,
          label: "Overall DashBoard",
          icon: <AiFillDashboard />,
        },
        {
          key: `/dashboard/${user.id}`,
          label: "My Dashboard",
          icon: <AiFillDashboard />,
        },
      ],
    },
    {
      key: `/ceoCalender/${user.id}`,
      label: "Calendar",
      icon: <FaCalendarAlt />,
    },
    {
      key: `/ceoCourses/${user.id}`,
      label: "Courses",
      icon: <FaBookReader />,
    },
    {
      key: "/user-manual",
      label: "User Manual",
      icon: <FaUserCircle />,
    },
  ];

  const adminMenu = [
    {
      key: home,
      label: "Home",
      icon: <IoIosHome />,
    },
    {
      key: "/adminWorkShops",
      label: "iCT Workshops",
      icon: <AiFillDashboard />,
    },
    {
      key: "/adminCourses",
      label: "Leadership Library",
      icon: <AiFillDashboard />,
    },
    {
      key: "/adminCalender",
      label: "Calendar",
      icon: <FaCalendarAlt />,
    },
    {
      key: `/chatDetail/${user.id}`,
      label: "Chat",
      icon: <FaUserCircle />,
    },
    {
      key: `/adminUserManual/${user.id}`,
      label: "User Manual",
      icon: <FaUserCircle />,
    },
    {
      key: "/adminUserList",
      label: "User List",
      icon: <FaUserCircle />,
    },
  ];

  const subAud = [
    {
      key: IcamsRoutes.subordinate,
      label: "Subordinate",
      icon: <AiFillDashboard />,
    },
    {
      key: `/calendar/${user.id}`,
      label: "Calendar",
      icon: <AiFillDashboard />,
    },
    {
      key: "/user-manual",
      label: "User Manual",
      icon: <FaUserCircle />,
    },
  ];

  const inline = [
    {
      key: IcamsRoutes.inline,
      label: "Peers",
      icon: <AiFillDashboard />,
    },
    {
      key: `/calendar/${user.id}`,
      label: "Calendar",
      icon: <AiFillDashboard />,
    },
    {
      key: "/user-manual",
      label: "User Manual",
      icon: <FaUserCircle />,
    },
  ];

  const empmenu = empMenu.map((empNavlink) => ({
    key: empNavlink.key,
    icon: empNavlink.icon,
    label: empNavlink.label,
    path: empNavlink.path,
  }));

  const adminmenu = adminMenu.map((adminNavlink) => ({
    key: adminNavlink.key,
    icon: adminNavlink.icon,
    label: adminNavlink.label,
    path: adminNavlink.path,
  }));

  const subMenu = subAud.map((adminNavlink) => ({
    key: adminNavlink.key,
    icon: adminNavlink.icon,
    label: adminNavlink.label,
    path: adminNavlink.path,
  }));

  const inlMenu = inline.map((adminNavlink) => ({
    key: adminNavlink.key,
    icon: adminNavlink.icon,
    label: adminNavlink.label,
    path: adminNavlink.path,
  }));

  const getMenuStyle = (key) => ({
    backgroundColor: key === selectedKey ? "#595293" : "#171151", // Example: red background for selected item
    fontSize: "1.1rem",
  });

  const renderMenuItems = (menuItems) =>
    menuItems.map((item) => ({
      ...item,
      style: getMenuStyle(item.key),
    }));

  const handleCloseCollapsed = () => {
    setCollapsed(true);
  };
  return (
    <div>
      <Sider
        className={collapsed ? styles.close : styles.manubarWrp}
        trigger={null}
        theme="light"
        collapsed={collapsed}
        collapsedWidth={0}
      >
        {/* {(!collapsed && selectedKey==='/home') && <div className={styles.closeBtn}><IoMdClose className={styles.menuCloBtn} onClick={() => handleCloseCollapsed() } /></div>} */}
        <div className={styles.sider_logo}>
          <img src={com_logo} />
        </div>
        <Button
          type="text"
          icon={
            !collapsed && (
              <IoMdClose
                className={styles.menuCloBtn}
                style={{
                  marginLeft: collapsed ? "1.5rem" : "0",
                  marginTop: collapsed ? "1.5rem" : "0",
                }}
              />
            )
          }
          onClick={() => setCollapsed(!collapsed)}
          className={styles.menubarControlBtn}
        />
        <Menu
          style={{ backgroundColor: "#171151", fontSize: "1.1rem" }}
          mode="inline"
          defaultActiveFirst={[selectedKey]}
          selectedKey={[selectedKey]}
          items={
            user.role === "employee"
              ? renderMenuItems(empmenu)
              : user.role === "ceo"
              ? renderMenuItems(ceoMenu)
              : user?.role === "admin"
              ? renderMenuItems(adminmenu)
              : user?.role === "peer"
              ? renderMenuItems(inlMenu)
              : renderMenuItems(subMenu)
          }
          onClick={handleMenuClick}
        />
      </Sider>
    </div>
  );
}

export default Navbar;
