import { Button, Flex, Dropdown, Space, Divider, Badge } from "antd";
import React, { useEffect, useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";
import { FaUserCircle } from "react-icons/fa";
import { MdOutlineLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import styles from "./smHeader.module.css";
import {
  getToken,
  getUserInfo,
  removeToken,
} from "../../common/helpers/localStorage.helper";
import { baseURL } from "../../Constant";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  AtomIsLogin,
  AtomMenuBar,
  AtomNotificationCount,
  AtomUserInfo,
} from "../../store/genral.store";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoMdMenu, IoMdClose } from "react-icons/io";

import axios from "axios";

const SmHeader = () => {
  // Variables
  const userInfo = useRecoilValue(AtomUserInfo);
  const setIsLogin = useSetRecoilState(AtomIsLogin);
  const [collapsed, setCollapsed] = useRecoilState(AtomMenuBar);
  const token = getToken("token");
  const [notificationList, setNotificationList] = useState([]);
  const [notificationCount, setNotificationCount] = useRecoilState(
    AtomNotificationCount
  );
  const user = getUserInfo();
  const navigate = useNavigate();

  // Functions
  const handleLogout = () => {
    removeToken("token");
    removeToken("userInfo");
    navigate("/login");
    setIsLogin(false);
  };
  const fetchNotificationData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/notification_bar`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the access token in the headers
        },
      });
      let data = response?.data ?? [];
      setNotificationList(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleNotificationClick = () => {
    fetchNotificationData();
    setNotificationCount(0);
  };

  // Constant
  const items = [
    {
      key: "1",
      label: (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={handleLogout}
        >
          <MdOutlineLogout style={{ marginRight: 10 }} /> Logout
        </div>
      ),
    },
  ];

  // console.log("collapsed",collapsed);
  return (
    <>
      <Flex
        justify={"space-between"}
        style={{ borderBottom: "1px solid white" }}
      >
        <div className={styles.headerLeft}>
          {/* <Button
            type="text"
            icon={
              collapsed ? (
                <IoMdMenu className={styles.menuOpBtn} />
              ) : (
                <IoMdClose
                  className={styles.menuCloBtn}
                  style={{
                    marginLeft: collapsed ? "1.5rem" : "0",
                    marginTop: collapsed ? "1.5rem" : "0",
                  }}
                />
              )
            }
            onClick={() => setCollapsed(!collapsed)}
            className={styles.menubarControlBtn}
          /> */}
          <Button
            type="text"
            icon={
              collapsed &&
                <IoMdMenu className={styles.menuOpBtn} />}
            onClick={() => setCollapsed(!collapsed)}
            className={styles.menubarControlBtn}
          /> 
          {userInfo.role !== "admin" && (
            <div className={styles.headerLogo}>
              <img src={`${baseURL}${user?.comp_logo}`} />
            </div>
          )}
        </div>
        <div
          className="demo-logo-vertical"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 1rem",
          }}
        >
          <Dropdown
            placement="bottom"
            trigger={["click"]}
            dropdownRender={() => {
              return (
                <div className={styles.dpWrp}>
                  {notificationList?.map((val) => {
                    return (
                      <div className={styles.flex}>
                        <span
                          style={{
                            minHeight: 7,
                            minWidth: 7,
                            borderRadius: "50%",
                            backgroundColor: "#000",
                            marginTop: 7,
                          }}
                        ></span>
                        <div className={styles.description}>{val.action}</div>
                      </div>
                    );
                  })}
                </div>
              );
            }}
          >
            <div
              className={styles.notification}
              onClick={handleNotificationClick}
            >
              <Badge size="default" count={notificationCount}>
                <IoIosNotificationsOutline style={{ fontSize: 30 }} />
              </Badge>
            </div>
          </Dropdown>
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {userInfo?.profile_photo ? (
                <div className={styles.profile_photo}>
                  <img src={`${baseURL}${userInfo?.profile_photo}`} alt="img" />
                </div>
              ) : (
                <FaUserCircle
                  style={{
                    width: "2rem",
                    height: "2rem",
                    marginRight: ".5rem",
                  }}
                />
              )}
              <h3 className={styles.profileName}>
                {userInfo?.user?.first_name}
                {window.innerWidth > 600 && ` ${userInfo?.user?.last_name}`}
              </h3>
            </div>
          </Dropdown>
        </div>
      </Flex>
    </>
  );
};

export default SmHeader;
