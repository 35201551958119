import React, { useEffect, useRef, useState } from "react";
import {
  Layout,
  theme,
  Badge,
  Calendar,
  Modal,
  Divider,
  Button,
  Tooltip,
} from "antd";
import styles from "./calenderList.module.css";
import Navbar from "../Navbar/Navbar";
import {
  getToken,
  getUserInfo,
} from "../../../../common/helpers/localStorage.helper";
import { useNavigate } from "react-router-dom";
import SmHeader from "../../../SmHeader/SmHeader";
import { AtomUserInfo } from "../../../../store/genral.store";
import { useRecoilValue } from "recoil";
import GreenDot from "../../../../assets/GreenDot.png";
import orangeDot from "../../../../assets/orangeDot.png";
import axios from "axios";
import { baseURL } from "../../../../Constant";
import dayjs from "dayjs";
import Chatbox from "../../Chatbox/Chatbox";
// import { usePdf } from "@mikecousins/react-pdf";
import { toastNotify } from "../../../shared/Toast/Toast";
import { GrLinkPrevious, GrLinkNext } from "react-icons/gr";
import docs from "../../../../assets/FDCA.pdf";
import { CiSaveDown1 } from "react-icons/ci";
import { Document, Page } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const CalendarList = () => {
  //variables
  const token = getToken("token");
  const [collapsed, setCollapsed] = useState(false);
  const userInfo = useRecoilValue(AtomUserInfo);
  const { Content } = Layout;
  const user = getUserInfo();
  const {
    token: { borderRadiusLG },
  } = theme.useToken();
  const [tasks, setTasks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [page, setPage] = useState(1);
  // const canvasRef = useRef(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const navigate = useNavigate();
  const [calPdf, setCalPdf] = useState("");
  // const { pdfDocument } = usePdf({
  //   file: calPdf ? calPdf : docs ,
  //   page,
  //   canvasRef,
  // });

  // useEffect(() => {
  //   if (pdfDocument) {
  //     console.log('PDF loaded successfully');
  //     // You can perform any actions you need here after the PDF is loaded
  //   }
  // }, [pdfDocument]);

  //callbacks
  useEffect(() => {
    if (userInfo?.user?.id) {
      let _formattedDate = dayjs().format("YYYY-MM");
      let _year = _formattedDate.slice(0, 4);
      let _month = _formattedDate.slice(5);
      fetchData(userInfo?.user?.id, Number(_year), Number(_month), dayjs());
      fecthUserCalPdf();
    }
  }, [userInfo?.user]);

  //functions

  const fecthUserCalPdf = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/get_calendar_pdf/${user?.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      const fullUrl = `${baseURL}${data?.pdf_link}`;
      setCalPdf(fullUrl);
    } catch (error) {
      toastNotify("Error fetching data:", error);
    }
  };

  const fetchData = async (id, year, month, selectedDate) => {
    setTasks([]);
    let _payload = {
      month: month,
      year: year,
    };
    const response = await axios({
      method: "post",
      url: `${baseURL}/calender/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: _payload,
    });
    let data = response.data ?? [];
    let list = [];
    let _uPComlist = [];
    for (const val of data) {
      let empdetail = { ...val };
      if (val.type === "warning") {
        let endMonth = dayjs(selectedDate).endOf("month");
        empdetail["starting_date"] = dayjs(val.starting_date).format(
          "YYYY-MM-DD"
        );
        empdetail["date"] = endMonth.format("YYYY-MM-DD");

        for (const course of val?.listofCourse) {
          let _course = { ...course };
          _course["starting_date"] = dayjs(val.starting_date).format(
            "YYYY-MM-DD"
          );
          _uPComlist.push(_course);
        }
      } else {
        empdetail["date"] = dayjs(val.starting_date).format("YYYY-MM-DD");
      }

      if (_uPComlist.length > 0) {
        empdetail["_list"] = _uPComlist;
      }
      list.push(empdetail);
    }
    setTasks(list);
  };

  const handleRedirect = (val) => {
    setShowModal(false);
    if (user?.role === "admin" || user?.role === "ceo") {
      if (user?.role === "admin") {
        navigate(`/adminCourseDetail/${val.id}`);
      } else {
        navigate(`/courses-details/${val.id}`);
      }
    } else {
      if (val.type === "presontor") {
        navigate(`/courses-details/${val.id}`);
      } else if (val.type === "inline") {
        navigate(`/inline-Detials/${val.id}`);
      } else if (val.type === "subordinate") {
        navigate(`/sub-Detials/${val.id}`);
      }
    }
    setTasks([]);
  };

  const renderStatus = (status, task) => {
    if (status === "success") {
      return (
        <div className={styles.Dot}>
          <img src={GreenDot} />
        </div>
      );
    }
    if (status === "error") {
      return <div className={`${styles.Dot} ${styles.redDot}`}></div>;
    }
    if (status === "warning") {
      return (
        <div className={styles.Dot}>
          <img src={orangeDot} />
        </div>
      );
    }
    return <div></div>;
  };

  const handleDateClick = (value) => {
    const task = tasks.find(
      (task) => task.date === value?.format("YYYY-MM-DD")
    );
    if (task) {
      setShowModal(true);
      setSelectedTask(task);
    }
  };

  const handlePanelChange = (date) => {
    let _formattedDate = dayjs(date).format("YYYY-MM");
    let _year = _formattedDate.slice(0, 4);
    let _month = _formattedDate.slice(5);
    fetchData(userInfo?.user?.id, Number(_year), Number(_month), date);
  };

  const openModal = (data) => {
    setPage(0);

    setOpen(true);
    setModalData(data);
    setTimeout(() => {
      setPage(1);
    }, 500);
  };

  // const handleChange = (e) => {
  //   let _value = Number(e.target.value);
  //   if (isNaN(_value)) return toastNotify("Plaese enter a Number.", "error");

  //   // if (_value > pdfDocument?.numPages) {
  //     toastNotify(
  //       `Page number should not be greater than ${pdfDocument?.numPages}`,
  //       "error"
  //     );
  //     return;
  //   } else {
  //     setPage(_value);
  //   }
  // };

  const closeModal = () => {
    setOpen(false);
    setModalData({});
  };

  // const downloadPdf = (pdf) => {
  //   debugger
  //   if (!pdf) {
  //     toastNotify("Canvas is not ready yet.");
  //     return;
  //   }
  //   const dataUrl = pdf.toDataURL("pdf");
  //   const link = document.createElement("a");
  //   link.href = dataUrl;
  //   link.download = "downloaded.pdf";
  //   link.click();
  // };

  const downloadPdf = (pdf) => {
    const filename = "Download-Pdf.pdf";
    fetch(pdf)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("Error downloading the file:", error));
  };

  // const [pdfUrl, setPdfUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  const previousPage = () => changePage(-1);
  const nextPage = () => changePage(1);

  return (
    <Layout>
      <Navbar collapsed={collapsed} />
      <Layout>
        <Content className={styles.calenderPageWrp}>
          <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />
          <div className={styles.calenderpage}>
            <div className={styles.calpdfWrp}>
              <h1>Calendar</h1>

              <button
                onClick={() => openModal(calPdf)}
                className={styles.fullScreenBtn}
              >
                Calendar Pdf
              </button>
            </div>
            <div className={styles.calenderWrp}>
              <div className={styles.calender}>
                <Calendar
                  className={styles.cal}
                  onSelect={handleDateClick}
                  onPanelChange={handlePanelChange}
                  cellRender={(date) => {
                    const task = tasks.find(
                      (task) => task.date === date.format("YYYY-MM-DD")
                    );
                    if (task) {
                      let _isSub =
                        task.course_name === "Task Due for Peer & Subordinate"
                          ? true
                          : false;
                      let isAdmin =
                        task.course_name === "Task Due for iCretegy Advisor"
                          ? true
                          : false;
                      return (
                        <div
                          className={
                            _isSub
                              ? styles.subOrdinateBg
                              : isAdmin
                              ? styles.adminBg
                              : styles[task.type + "1"]
                          }
                        >
                          {task.type !== "warning" &&
                            window.innerWidth > 800 && (
                              <div className={styles.selDate}>
                                Date :
                                {dayjs(task?.starting_date).format(
                                  "MMM DD, YYYY"
                                )}
                              </div>
                            )}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              marginTop:
                                window.innerWidth < 600 ||
                                task.type === "warning"
                                  ? "1rem"
                                  : "0rem",
                            }}
                          >
                            {renderStatus(task.type, task)}
                            <div className={styles.contenWrp}>
                              {task.course_name}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  }}
                />
                {/* <Calendar cellRender={cellRender} /> */}
              </div>
            </div>
          </div>

          {showModal && (
            <Modal
              title={selectedTask.employee}
              visible={showModal}
              onOk={() => setShowModal(false)}
              footer={[]}
              onCancel={() => setShowModal(false)}
            >
              <div>
                {selectedTask.course_name}

                {selectedTask?.type === "success" && (
                  <p>
                    Starting Date :{" "}
                    {dayjs(selectedTask.starting_date).format("MMM DD, YYYY")}
                  </p>
                )}

                {(selectedTask.type === "success" ||
                  selectedTask.type === "error") &&
                  selectedTask?.listofCourse?.map((val, index) => {
                    return (
                      <li key={index} className={styles.flexBetween}>
                        <div>{val.course}</div>
                        {selectedTask.course_name !== "Upcoming Courses" && (
                          <div className={styles.modalViewMoreBtn}>
                            <Button
                              className={styles.Mbtn}
                              onClick={() => {
                                handleRedirect(val);
                              }}
                            >
                              View More
                            </Button>
                          </div>
                        )}
                      </li>
                    );
                  })}
                {selectedTask.type === "warning" &&
                  selectedTask?._list?.map((val, index) => {
                    return (
                      <ul className={styles.upComCourseList}>
                        <li key={index} className={styles.flexBetween}>
                          <div>{val.course}</div>
                          <p>
                            Starting Date :{" "}
                            {dayjs(val.starting_date).format("MMM DD, YYYY")}
                          </p>
                        </li>
                      </ul>
                    );
                  })}
              </div>
            </Modal>
          )}

          {open && (
            <Modal
              centered
              open={open}
              onOk={() => setOpen(false)}
              onCancel={() => closeModal(false)}
              width={1100}
            >
              {modalData && (
                <div className={styles.modalDataPdf}>
                  <div className={styles.controllsAndInput}>
                    <div className={styles.displayControlls}>
                      <button
                        className={styles.contrBtn}
                        onClick={previousPage}
                        disabled={pageNumber <= 1}
                      >
                        <GrLinkPrevious />
                      </button>
                      <p>
                        Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                        {numPages || "--"}
                      </p>
                      <button
                        className={styles.contrBtn}
                        // disabled={page === pdfDocument?.numPages}
                        onClick={nextPage}
                        disabled={pageNumber >= numPages}
                      >
                        <GrLinkNext />
                      </button>
                    </div>

                    <div
                      className={styles.downloadPdf}
                      onClick={() => downloadPdf(calPdf)}
                    >
                      <Tooltip title="Download">
                        <CiSaveDown1 />
                      </Tooltip>
                    </div>
                  </div>

                  {/* {!calPdf && <span>Loading...</span>} */}
                  {/* {calPdf &&  <canvas ref={canvasRef} className={styles.pdfCanvas} />} */}
                  {calPdf && (
                    <>
                      <Document
                        file={calPdf}
                        className={styles.pdfCanvas}
                        loading={<div>Loading PDF...</div>}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page
                          pageNumber={pageNumber}
                          scale={window.innerWidth> 600 ? 1.4 : 0.5}
                        />
                      </Document>
                    </>
                  )}
                </div>
              )}
            </Modal>
          )}
        </Content>
      </Layout>
      <Chatbox />
    </Layout>
  );
};
export default CalendarList;
