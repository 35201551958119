import React, { useState, useEffect } from "react";
import {
  Layout,
  theme,
  Button,
  Modal,
  ConfigProvider,
  Popconfirm,
  message,
  Table,
} from "antd";
import Navbar from "../../../../components/AfterLogin/Employee/Navbar/Navbar";
import SmHeader from "../../../../components/SmHeader/SmHeader";
import styles from "./adminTP.module.css";
import {
  getToken,
  getUserInfo,
} from "../../../../common/helpers/localStorage.helper";
import { baseURL } from "../../../../Constant";
import axios from "axios";
import TTInput from "./../../../../components/shared/TTInput/TTInput";
import { Toast, toastNotify } from "../../../../components/shared/Toast/Toast";
import { toast } from "react-toastify";
import TTSelect from "../../../../components/shared/TTSelect/TTSelect";
import { Link, useParams } from "react-router-dom";
import { RiDeleteBin5Fill, RiDeleteBin5Line } from "react-icons/ri";
import { color } from "@amcharts/amcharts4/core";
import dayjs from "dayjs";
import DatePicker from "react-date-picker";

const AdminWorkshopPanel = () => {
  // Variables
  const token = getToken("token");
  const { id } = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [taskObject, setTaskObject] = useState([]);
  const [taskDetail, setTaskDetail] = useState({
    task_name: "",
    question: "",
    course: "",
    courseId: "",
  });
  console.log(taskDetail);
  const { Content } = Layout;
  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  const [isModal, setIsModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  //functions
  const showAddModal = () => {
    setIsModal(true);
    setIsEdit(false);
  };
  const handleClose = () => {
    setIsModal(false);
    setTaskDetail({});
  };
  const showEditModal = (rowData) => {
    setIsModal(true);
    setIsEdit(true);
    setTaskDetail({
      id: rowData.id,
      task_name: rowData?.task_name,
      question: rowData?.question,
      course: rowData?.courseId,
      // empDeadDate: rowData?.deadline_date,
      // perrSubDeadDate: rowData?.peersub_deadline_date,
      // adminDeadaDate: rowData?.admin_deadline_date,
    });
  };

  const handleInputChange = (e) => {
    const { name, data } = e;
    setTaskDetail((prevState) => ({
      ...prevState,
      [name]: data,
    }));
  };

  const fetchData = async () => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/showTask/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      setTaskData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCourseData = async () => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/showCourses`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      let list = [];
      for (const course of data) {
        let obj = {};
        obj["id"] = course?.id;
        obj["label"] = course?.course_name;
        obj["value"] = course?.course_name;
        list.push(obj);
      }
      setCourseOptions(list);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleValidation = (data) => {
    if (!data?.question) return "question Should Not Be Empty";
    if (!data?.task_name) return "task_name Should Not Be Empty";
    return true;
  };
  const handleSubmit = async () => {
    let validationResult = handleValidation(taskDetail);

    if (validationResult === true) {
      let payload = {
        course: parseInt(id),
        question: taskDetail.question,
        task_name: taskDetail?.task_name,
        // deadline_date: dayjs(taskDetail?.empDeadDate).format("YYYY-MM-DD"),
        // peersub_deadline_date: dayjs(taskDetail?.perrSubDeadDate).format(
        //   "YYYY-MM-DD"
        // ),
        // admin_deadline_date: dayjs(taskDetail?.adminDeadaDate).format(
        //   "YYYY-MM-DD"
        // ),
      };
      try {
        const response = await axios({
          method: "post",
          data: payload,
          url: `${baseURL}/addTask`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response?.data;
        let _taskdata = [...taskData];
        _taskdata.push(data);

        toast.success("Task added successfully!");
        setTaskData(_taskdata);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsModal(false);
      setTaskDetail({});
    } else {
      toastNotify(validationResult, "error");
    }
  };

  // console.log(taskData)

  const handleUpdateData = async () => {
    // let payload = {
    //   id: taskDetail.id,
    //   course: taskDetail.courseId,
    //   question: taskDetail.question,
    //   task_name: taskDetail?.task_name,
    // };
    let payload = {
      id: taskDetail.id,
      question: taskDetail.question,
      task_name: taskDetail?.task_name,
      // deadline_date: dayjs(taskDetail?.empDeadDate).format("YYYY-MM-DD"),
      // peersub_deadline_date: dayjs(taskDetail?.perrSubDeadDate).format(
      //   "YYYY-MM-DD"
      // ),
      // admin_deadline_date: dayjs(taskDetail?.adminDeadaDate).format(
      //   "YYYY-MM-DD"
      // ),
    };
    try {
      const response = await axios({
        method: "post",
        data: payload,
        url: `${baseURL}/updateTask/${payload.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      let _taskdata = [...taskData];
      let index = taskData.findIndex((task) => task.id === data.id);
      _taskdata.splice(index, 1, data);
      setTaskData(_taskdata);
      toast.success("Task updated Successfully");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setTaskDetail({});
    setIsModal(false);
  };

  const confirm = () => {
    message.success("Click on Yes");
  };
  const cancel = (e) => {
    message.error("Click on No");
  };

  const handleDeleteData = async (rowData) => {
    console.log(rowData.id);
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/deleteTask/${rowData.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = response?.data;
      const _taskList = [...taskData];
      let index = taskData.findIndex((task) => task.id === data.id);
      _taskList.splice(index, 1);
      setTaskData(_taskList);
      toast.success("Task Deleted Successfully");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //callbacks
  useEffect(() => {
    fetchData();
    fetchCourseData();
  }, []);

  const columns = [
    {
      title: "Task No",
      render: (text, record, index) => {
        let idx = index + 1;
        return <div>{idx}</div>;
      },
    },
    // {
    //   title: "Tasks",
    //   render: (record) => {
    //     return <div>{record?.task_name}</div>;
    //   },
    // },
    {
      title: "Question",
      render: (record) => {
        return <div>{record?.question}</div>;
      },
    },
    {
      title: "Edit",
      render: (record) => {
        return (
          <Button
            className={styles.tableBtn}
            onClick={() => showEditModal(record)}
          >
            Edit
          </Button>
        );
      },
    },

    {
      title: "Delete",
      render: (record) => {
        return  <Popconfirm
        title="Delete the task"
        description="Are You Sure You Want to Delete This  Task?"
        onConfirm={() => handleDeleteData(record)}
        onCancel={cancel}
        okText="Yes"
        cancelText="No"
      >
       <Link style={{color:'red'}}> <RiDeleteBin5Fill/></Link>
      </Popconfirm>
      },
    },
  ];

  //others

  // const onEmpChange = (date) => {
  //   debugger;
  //   let _task = { ...taskDetail };
  //   _task["empDeadDate"] = date;
  //   setTaskDetail(_task);
  // };
  // const onPeerSubChange = (date) => {
  //   let _task = { ...taskDetail };
  //   _task["perrSubDeadDate"] = date;
  //   setTaskDetail(_task);
  // };
  // const onAdminChange = (date) => {
  //   let _task = { ...taskDetail };
  //   _task["adminDeadaDate"] = date;
  //   setTaskDetail(_task);
  // };

  return (
    <div className={styles.dashboardWrapper}>
      <Layout>
        <Navbar collapsed={collapsed} />
        <Layout>
          <Content>
            <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />

            <div
              style={{
                padding: 24,
                minHeight: 100,
                background: "#e8e8e8",
                borderRadius: borderRadiusLG,
              }}
            >
              <div className={styles.admin_panel}>
                <div className={styles.workShopList_header}>
                  <h1>Tasks</h1>
                  <Button onClick={showAddModal}>Add Task</Button>
                </div>
                <div className={styles.admin_panel_companylist}>
                  <ConfigProvider
                    theme={{
                      components: {
                        Table: {
                          headerBg: "#171151",
                          headerColor: "#fff",
                          cellFontSize: 16,
                          borderColor: "#171151",
                          fontFamily: "Aptos",
                        },
                      },
                    }}
                  >
                    <Table
                      dataSource={taskData}
                      columns={columns}
                      pagination={false}
                    />
                  </ConfigProvider>
                </div>
              </div>
            </div>
            <ConfigProvider
              theme={{
                components: {
                  Modal: {
                    titleFontSize: 24,
                  },
                },
              }}
            >
              <Modal
                title={isEdit ? "Edit Task" : "Add New Task"}
                open={isModal}
                onCancel={handleClose}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
              >
                <div className={styles.modelFormWrp}>
                  <TTInput
                    label="Task Name:"
                    name="task_name"
                    value={taskDetail?.task_name}
                    onChange={handleInputChange}
                    placeholder="Task Name"
                  />
                  <TTInput
                    label="Question :"
                    name="question"
                    value={taskDetail?.question}
                    onChange={handleInputChange}
                    placeholder="Questions"
                  />
                  {/* <div className={styles.coursemodalDate}>
                    <label>Presentor Deadline Date:</label>
                    <DatePicker
                      clearIcon={false}
                      format="dd-MM-yyyy"
                      value={dayjs(taskDetail?.empDeadDate)}
                      onChange={onEmpChange}
                    />
                  </div>
                  <div className={styles.coursemodalDate}>
                    <label>Peer & Subordinate Deadline Date:</label>
                    <DatePicker
                      clearIcon={false}
                      format="dd-MM-yyyy"
                      value={dayjs(taskDetail?.perrSubDeadDate)}
                      onChange={onPeerSubChange}
                    />
                  </div>
                  <div className={styles.coursemodalDate}>
                    <label>Advisor Deadline Date:</label>
                    <DatePicker
                      clearIcon={false}
                      format="dd-MM-yyyy"
                      value={dayjs(taskDetail?.adminDeadaDate)}
                      onChange={onAdminChange}
                    />
                  </div> */}
                </div>
                <div className={styles.modelButton}>
                  <Button onClick={isEdit ? handleUpdateData : handleSubmit}>
                    {isEdit ? "Update" : "Upload"}
                  </Button>
                </div>
              </Modal>
            </ConfigProvider>
          </Content>
          <Toast />
        </Layout>
      </Layout>
    </div>
  );
};

export default AdminWorkshopPanel;
