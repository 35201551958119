  import React, { useState } from "react";
  import styles from "./header.module.css";
  import { Link } from "react-router-dom";
  import header_logo from '../../../assets/icretegy_logo.png'
  import { IoMdMenu,IoMdClose } from "react-icons/io";

  function Header({ setHeader  }) {
    const [menu, setMenu] = useState(false);

    const handleOpenMenu = () =>{
      setMenu(!menu);
      setHeader(!menu)
    }
    return (
      <>
      <nav>
        <Link to="/" className={styles.header_logo}>
          <img src={header_logo}/>
        </Link>
        <div className={styles.menu} onClick={() => handleOpenMenu()}>
        
        

         {menu ?<IoMdClose />   : <IoMdMenu />  } 

        </div>
        <div className={styles.navlinks}>
          <Link className={styles.navlink} to="/login" >Login</Link>
          <Link className={styles.navlink} to="/signup">Register</Link>
        </div>
      </nav>
    
      </>
    );
  }

  export default Header;
