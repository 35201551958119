import React, { useState, useEffect, useRef } from "react";
import { Layout, theme, Card, Button } from "antd";
import Navbar from "../../../../components/AfterLogin/Employee/Navbar/Navbar";
import SmHeader from "../../../../components/SmHeader/SmHeader";
import styles from "./adminhome.module.css";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  getToken,
  getUserInfo,
} from "../../../../common/helpers/localStorage.helper";
import { baseURL } from "../../../../Constant";
import img from "../../../../assets/iWalkTheTalk_Logo_final.png";
import OneToOne from "../../../../assets/one_to_one_coaching.png";
import teamWork from "../../../../assets/teamWork.png";
import groupCoaching from "../../../../assets/groupCoaching.png";
import leadership from "../../../../assets/leadership.png";
import Regulatory from "../../../../assets/beforelogin_services_images/ro.png";
import leadership_img from "../../../../assets/beforelogin_services_images/ra.png";
import Technical from "../../../../assets/beforelogin_services_images/tw.png";
import pharmaExcellence from "../../../../assets/beforelogin_services_images/pi.png";
import DMF from "../../../../assets/beforelogin_services_images/lw.png";
import QualityCompliance from "../../../../assets/beforelogin_services_images/bs.png";



const AdminHome = () => {
  // Variables
  const token = getToken("token");
  const user = getUserInfo();
  const navigate = useNavigate();
  const { id } = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const { Content } = Layout;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  //callbacks
  useEffect(() => {
    if (!token) navigate("/login");
  }, [id, token]);

  const DataRender = (icons, label, content) => {
    return (
      <div className={styles.AdminHome_services_cardlist}>
        <div className={styles.adminhome_card_icons}>
          <img src={icons} />
        </div>
        <div className={styles.adminHome_serCard_content}>
          <h2>{label}</h2>
          <p>{content}</p>
        </div>
      </div>
    );
  };

  const courses = (img, BGcolor, title, content, link) => {
    return (
      <div className={styles.servicesCard}>
        <div
          className={styles.servicesCard_img}
          style={{ backgroundColor: BGcolor }}
        >
          <img src={img} />
        </div>
        <h3 dangerouslySetInnerHTML={{ __html: title }}></h3>
        <div className={styles.AdminHomePContent}>
          <p dangerouslySetInnerHTML={{ __html: content }}></p>
        </div>
        <div className={styles.serviceReadMore}>
          <Button style={{ backgroundColor: BGcolor, color: "white" }}>
            <a href={link} target="blank">
              Read More
            </a>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.dashboardWrapper}>
      <Layout>
        <Navbar collapsed={collapsed} />
        <div>
          <div>
            <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />

            <div
              style={{
                padding: 24,
                background: "#e8e8e8",
                borderRadius: borderRadiusLG,
              }}
            >
              <div className={styles.AdminHome_main}>
                <img src={img} />
                {/* <animated.img
                  src={img}
                  style={useSpring({
                    from: { opacity: 0, transform: "translateY(-100px)" },
                    to: { opacity: 1, transform: "translateY(0)" },
                    config: { duration: 1500 },
                  })}
                /> */}
              </div>

              <div className={styles.AdminHome_services}>
                <h1>Services</h1>

                <div className={styles.AdminHome_servicescards}>
                  {courses(
                    Regulatory,
                    "#cd001a",
                    "iRegulatoryServices<sup>®</sup>",
                    `End to End (E2E) <strong>Regulatory Affairs</strong> and Regulatory Operations (<strong>eCTD</strong>) services across the globe`,
                    "fade-right",
                    "https://icretegy.com/service/iRegulatoryServices/"
                  )}
                  {courses(
                    leadership_img,
                    "#ef6a00",
                    "iLeadershipWorkshop<sup>®</sup>",
                    "<strong>Caoch2Coachee</strong>: Sharpen your leadership skills to enhance Strategic Execution to the Next level",
                    "fade-up",
                    "https://icretegy.com/service/iLeadershipWorkshop/"
                  )}
                  {courses(
                    Technical,
                    "#f2cd00",
                    "iTechnicalWorkshop<sup>®</sup>",
                    "<strong>Train2Trainers</strong>: To enhance best practices in Technical areas using On-Site Workshops & Trainings",
                    "fade-left",
                    "https://icretegy.com/service/iTechnicalWorkshop/"
                  )}
                  {courses(
                    pharmaExcellence,
                    "#79c300",
                    "iPharmaExcellence<sup>®</sup>",
                    "<strong>Innovation</strong> through Mascot based training, ChatBot, & other Excellence ideas to enhance your productivity & output.",
                    "fade-right",
                    "https://icretegy.com/service/iPharmaExcellence/"
                  )}
                  {courses(
                    DMF,
                    "#1961ae",
                    "iDMFReview&Compliance<sup>®</sup>",
                    "End to End (E2E) <strong>API DMF services</strong> across the globe including supplier Quality Audits, GMP Inspection",
                    "fade-down",
                    "https://icretegy.com/service/iTechnicalTools/"
                  )}
                  {courses(
                    QualityCompliance,
                    "#61007d",
                    "iQualityCompliance<sup>®</sup>",
                    "<strong>cGMP Audits, Pre-Approval (PAI)</strong> inspection and Post surveillance Inspection for FDF dosage form.",
                    "fade-left",
                    "https://icretegy.com/service/iQualityCompliance/"
                  )}
                </div>
              </div>
              <div className={styles.AdminHome_services_list}>
                <h1>Leadership Workshop</h1>
                <div className={styles.AdminHome_workshopCards}>
                  <Link to="/adminOnOComp">
                    {DataRender(OneToOne, "One-On-One Coaching")}
                  </Link>
                  {DataRender(teamWork, "Team Coaching")}
                  {DataRender(groupCoaching, "Group Coaching ")}
                  <Link to="/adminCompany">
                    {DataRender(leadership, "On-site Leadership Workshop")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default AdminHome;
