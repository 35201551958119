import React, { useEffect, useState } from "react";
import {
  Layout,
  theme,
  Calendar,
  Modal,
  Button,
  Select,
  ConfigProvider,
} from "antd";
import styles from "./ceoCal.module.css";
import Navbar from "../../../components/AfterLogin/Employee/Navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import SmHeader from "../../../components/SmHeader/SmHeader";
import GreenDot from "../../../assets/GreenDot.png";
import orangeDot from "../../../assets/orangeDot.png";
import axios from "axios";
import { baseURL } from "../../../Constant";
import { getToken } from "../../../common/helpers/localStorage.helper";
import dayjs from "dayjs";
import { getUserInfo } from "./../../../common/helpers/localStorage.helper";

const CeoCalender = () => {
  //variables
  const [collapsed, setCollapsed] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const token = getToken("token");
  const user = getUserInfo();
  const { Content } = Layout;
  const {
    token: { borderRadiusLG },
  } = theme.useToken();
  const [userOptions, setUserOptions] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  let _formattedDate = dayjs().format("YYYY-MM");
  let _year = _formattedDate.slice(0, 4);
  let _month = _formattedDate.slice(5);
  const [selectedMonth, setSelectedMonth] = useState(_month);
  const [selectedYear, setSelectedYear] = useState(_year);
  const [selectedTask, setSelectedTask] = useState(null);
  const navigate = useNavigate();

  //callbacks
  useEffect(() => {
    if (!id) {
      fetchUsers();
    }
  }, []);

  useEffect(() => {
    if (user?.id && id) {
      getUserList(dayjs(), id);
    }
  }, [user?.id, id]);

  //functions
  const fetchUsers = async (id) => {
    try {
      const url = `${baseURL}/showUsers`;

      const response = await axios({
        method: "get",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let _list = [];
      for (const user of response?.data ?? []) {
        let obj = {};
        obj["key"] = user?.user?.id;
        obj["label"] = `${user.user.first_name} ${user.user.last_name}`;
        obj["value"] = `${user.user.first_name} ${user.user.last_name}`;
        _list.push(obj);
      }
      let _selEmp = _list[0] ?? {};
      setSelectedOption(_selEmp);
      let _formattedDate = dayjs().format("YYYY-MM");
      let _year = _formattedDate.slice(0, 4);
      let _month = _formattedDate.slice(5);
      handleEmpChange(
        _selEmp.label,
        _list,
        Number(_year),
        Number(_month),
        dayjs()
      );
      setUserOptions(_list);
    } catch (error) {}
  };
  const handleDateClick = (value) => {
    const task = tasks.find(
      (task) => task.date === value?.format("YYYY-MM-DD")
    );
    if (task) {
      setShowModal(true);
      setSelectedTask(task);
    }
  };


  const getUserList = async (selectedDate, id) => {
    const response = await axios({
      method: "get",
      url: `${baseURL}/show_ceo_dashboard2/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const userList = response?.data?.employees ?? [];
    let list = [];

    for (const user of userList) {
      let obj = {};
      obj["key"] = user?.user?.id;
      obj["label"] = `${user.user.first_name} ${user.user.last_name}`;
      obj["value"] = `${user.user.first_name} ${user.user.last_name}`;
      list.push(obj);
    }
    if (userList.length > 0) {
      let _selEmp = userList[0] ?? {};
      setSelectedOption(_selEmp);
      let _formattedDate = dayjs().format("YYYY-MM");
      let _year = _formattedDate.slice(0, 4);
      let _month = _formattedDate.slice(5);
      let _name = _selEmp.user.first_name + " " + _selEmp.user.last_name;
      handleEmpChange(_name, list, Number(_year), Number(_month), selectedDate);
    }
    setUserOptions(list);
  };

  const handlePanelChange = (date) => {
    let _formattedDate = dayjs(date).format("YYYY-MM");
    let _year = _formattedDate.slice(0, 4);
    let _month = _formattedDate.slice(5);
    setSelectedMonth(_month);
    setSelectedYear(_year);
    handleEmpChange(
      selectedOption.label,
      userOptions,
      Number(_year),
      Number(_month),
      date
    );
  };

  const handleRedirect = (val) => {
    setShowModal(false);
    if (user?.role === "admin" || user?.role === "ceo") {
      if (user?.role === "admin") {
        navigate(`/adminCourseDetail/${val.id}`);
      } else {
        navigate(`/courses-details/${val.id}`);
      }
    } else {
      if (val.type === "presontor") {
        navigate(`/courses-details/${val.id}`);
      } else if (val.type === "inline") {
        navigate(`/inline-Detials/${val.id}`);
      } else if (val.type === "subordinate") {
        navigate(`/sub-Detials/${val.id}`);
      }
    }
    setTasks([]);
  };

  const renderStatus = (status) => {
    if (status === "success") {
      return (
        <div className={styles.Dot}>
          <img src={GreenDot} />
        </div>
      );
    }
    if (status === "error") {
      return (
        <div className={`${styles.Dot} ${styles.redDot}`}>
          {/* <img src={GreenDot} /> */}
        </div>
      );
    }
    if (status === "warning") {
      return (
        <div className={styles.Dot}>
          <img src={orangeDot} />
        </div>
      );
    }
    return <div></div>;
  };

  const handleEmpChange = async (
    selectedCourse,
    cList,
    year,
    month,
    selectedDate
  ) => {
    let _cList = [...cList];
    let index = _cList?.findIndex((val) => val.label === selectedCourse);
    let _selOpt = _cList[index];
    setSelectedOption(_selOpt);

    try {
      let _payload = {
        month: month,
        year: year,
      };
      const response = await axios({
        method: "post",
        data: _payload,
        url: `${baseURL}/calender/${_selOpt?.key}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = response.data;
      let _uPComlist = [];
      let newDatalist = [];
      for (const val of data) {
        let empdetail = { ...val };
        if (val.type === "warning") {
          let endMonth = dayjs(selectedDate).endOf("month");
          empdetail["starting_date"] = dayjs(val.starting_date).format(
            "YYYY-MM-DD"
          );
          empdetail["date"] = endMonth.format("YYYY-MM-DD");

          for (const course of val?.listofCourse) {
            let _course = { ...course };
            _course["starting_date"] = dayjs(val.starting_date).format(
              "YYYY-MM-DD"
            );
            _uPComlist.push(_course);
          }
        } else {
          empdetail["date"] = dayjs(val.starting_date).format("YYYY-MM-DD");
        }

        if (_uPComlist.length > 0) {
          empdetail["_list"] = _uPComlist;
        }
        newDatalist.push(empdetail);
      }
      setTasks(newDatalist);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Layout>
      <Navbar collapsed={collapsed} />
      <Layout>
        <Content className={styles.calenderPageWrp}>
          <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />
          <div className={styles.calenderpage}>
            <div className={styles.calenderWrp}>
              <div className={styles.CalHeader}>
                <h1>Calendar</h1>
                <Select
                  className={styles.emplistDropDown}
                  value={selectedOption}
                  onChange={(val) =>
                    handleEmpChange(
                      val,
                      userOptions,
                      Number(selectedYear),
                      Number(selectedMonth),
                      dayjs()
                    )
                  }
                  options={userOptions}
                />
              </div>
              <div className={styles.calender}>
                  <Calendar
                    className={styles.cal}
                    onPanelChange={handlePanelChange}
                    onSelect={handleDateClick}
                    cellRender={(date) => {
                      const task = tasks.find(
                        (task) => task.date === date.format("YYYY-MM-DD")
                      );
                      if (task) {
                        let _isSub =
                          task.course_name === "Task Due for Peer & Subordinate"
                            ? true
                            : false;
                        let isAdmin =
                          task.course_name === "Task Due for iCretegy Advisor"
                            ? true
                            : false;
                        if (!isAdmin || user.role === "admin")
                          return (
                            <div
                              className={
                                _isSub
                                  ? styles.subOrdinateBg
                                  : isAdmin
                                  ? styles.adminBg
                                  : styles[task.type + "1"]
                              }
                            >
                              {(task.type !== "warning" && window.innerWidth > 800) && 
                                <div className={styles.selDate}>
                                
                                  Date :
                                  {dayjs(task?.starting_date).format(
                                    "MMM DD, YYYY"
                                  )}
                                </div>
                              }
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  marginTop:
                                    (window.innerWidth < 600 || task.type === "warning") ? "1rem" : "0rem",
                                }}
                              >
                                {renderStatus(task.type)}
                                <div className={styles.contenWrp}>
                                  {task.course_name}
                                </div>
                              </div>
                            </div>
                          );
                      }
                    }}
                  />
              </div>
            </div>
          </div>

          {showModal && (
            <Modal
              title={selectedTask.employee}
              visible={showModal}
              onOk={() => setShowModal(false)}
              footer={[]}
              onCancel={() => setShowModal(false)}
            >
              <div>
                {selectedTask.course_name}

                {selectedTask?.type === "success" && (
                  <p>
                    Starting Date :{" "}
                    {dayjs(selectedTask.starting_date).format("MMM DD, YYYY")}
                  </p>
                )}

                {(selectedTask.type === "success" ||
                  selectedTask.type === "error") &&
                  selectedTask?.listofCourse?.map((val, index) => {
                    return (
                      <li key={index} className={styles.flexBetween}>
                        <div>{val.course}</div>
                        {selectedTask.course_name !== "Upcoming Courses" && (
                          <div className={styles.modalViewMoreBtn}>
                            <Button
                              className={styles.Mbtn}
                              onClick={() => {
                                handleRedirect(val);
                              }}
                            >
                              View More
                            </Button>
                          </div>
                        )}
                      </li>
                    );
                  })}
                {selectedTask.type === "warning" &&
                  selectedTask?._list?.map((val, index) => {
                    return (
                      <ul className={styles.upComCourseList}>
                        <li key={index} className={styles.flexBetween}>
                          <div>{val.course}</div>
                          <p>
                            Starting Date :{" "}
                            {dayjs(val.starting_date).format("MMM DD, YYYY")}
                          </p>
                        </li>
                      </ul>
                    );
                  })}
              </div>
            </Modal>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};
export default CeoCalender;
