import React from 'react'
import styles from './ttInput.module.css'
import { Input } from 'antd';



const TTInput = ({ value, name, onChange,placeholder,...props  }) => {
  // Variables

  // Functions
  const handleChange = (e) => {
    const _name = name ?? "";
    const { value } = e.target;
    if (props.type === "number" && isNaN(Number(value))) return;
    else onChange && onChange({ data: value, name:_name ?? "" });
  }


  return (
    <div className={`${styles.inputWrapper} ${props.parentClassName ? props?.parentClassName : ""}`} >
      {
        props?.label && 
        <label className={styles.label} >{props.label}</label>
      }
        <Input  disabled={props.disabled} onKeyUp={props?.onKeyUp} type={props.type ?? "text"}  name={name} value={value} onChange={handleChange} placeholder={placeholder ?? ""} className={`${props?.className} ${styles.wrapper}  ${props.disabled ? styles.disabled : ""}`} />
    </div>
  )
}

export default TTInput