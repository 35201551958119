import React, { useState, useEffect } from "react";
import { Layout, theme, Dropdown, Modal, Divider, Button } from "antd";
import Navbar from "../../../../components/AfterLogin/Employee/Navbar/Navbar";
import SmHeader from "../../../../components/SmHeader/SmHeader";
import styles from "./adminEmpList.module.css";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  getToken,
  getUserInfo,
} from "../../../../common/helpers/localStorage.helper";
import { baseURL } from "../../../../Constant";
import { FaUserCircle } from "react-icons/fa";
import axios from "axios";
import TTSelect from "../../../../components/shared/TTSelect/TTSelect";
import TTInput from "../../../../components/shared/TTInput/TTInput";
import { Toast, toastNotify } from "../../../../components/shared/Toast/Toast";
import dayjs from "dayjs";
import DatePicker from "react-date-picker";

const AdminEmpList = () => {
  // Variables
  const token = getToken("token");
  const navigate = useNavigate();
  const { id } = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const [empList, setEmpList] = useState([]);
  const [allCourseList, setAllCourseList] = useState([]);
  const [empEnrollCourse, setEmpEnrollCourse] = useState({
    course: null,
    gap: null,
    courseId: null,
    enrolled_user: null,
    starting_date: new Date(),
    empDeadDate: new Date(),
    perrSubDeadDate: new Date(),
    adminDeadaDate: new Date(),
  });
  const { Content } = Layout;
  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  //constants
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEmpModalOpen, setIsEmpModalOpen] = useState(false);
  const showModal = (id) => {
    setEmpEnrollCourse({ ...empEnrollCourse, ["enrolled_user"]: id });
    setIsModalOpen(true);
  };
  const opemAddModal = () => {
    setIsEmpModalOpen(true);
    setFormdata({
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirmPass: "",
      role: "",
    });
  };

  const [formdata, setFormdata] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirmPass: "",
    role: "",
  });

  const handleChange = (e) => {
    const { name, data } = e;
    setEmpEnrollCourse({
      ...empEnrollCourse,
      ["courseId"]: data?.id,
      ["course"]: data?.value,
    });
  };
  const handleGapChange = (e) => {
    debugger
    const { name, data } = e;
    setEmpEnrollCourse({
      ...empEnrollCourse,
      [name]: data?.value,
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEmpEnrollCourse({
      course: null,
      courseId: null,
      enrolled_user: null,
      starting_date: new Date(),
      empDeadDate: new Date(),
      perrSubDeadDate: new Date(),
      adminDeadaDate: new Date(),
    });
  };
  const handleEmpCancel = () => {
    setIsEmpModalOpen(false);
  };

  //functions
  const fetchData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/showUsers/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      setEmpList(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async () => {
    let payload = {
      course: empEnrollCourse.courseId,
      assessment_gap:empEnrollCourse?.gap,
      starting_date: dayjs(empEnrollCourse?.starting_date).format("YYYY-MM-DD"),
      enrolled_user: empEnrollCourse.enrolled_user,
      deadline_date: dayjs(empEnrollCourse?.empDeadDate).format("YYYY-MM-DD"),
      peersub_deadline_date: dayjs(empEnrollCourse?.perrSubDeadDate).format(
        "YYYY-MM-DD"
      ),
      admin_deadline_date: dayjs(empEnrollCourse?.adminDeadaDate).format(
        "YYYY-MM-DD"
      ),
    };

    try {
      const response = await axios({
        method: "post",
        data: payload,
        url: `${baseURL}/assign_course_to_employee`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      debugger;
      if (response.status === 201) {
        toastNotify("User Enroll Successfully!", "success");
        setIsModalOpen(false);
        setEmpEnrollCourse({
          course: null,
          courseId: null,
          enrolled_user: null,
          starting_date: new Date(),
          empDeadDate: new Date(),
          perrSubDeadDate: new Date(),
          adminDeadaDate: new Date(),
        });
      } else {
        toastNotify("Something went wrong!", "error");
      }
    } catch (error) {
      toastNotify(error?.response?.data[0], "error");
    }
  };

  const handleEmpSubmit = async () => {
    let _payload = { ...formdata };
    delete _payload["confirmPass"];

    _payload.role = _payload.role.value;

    console.log(_payload, "data");
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/inline_register`,
        data: _payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 201) {
        toastNotify("User registered successfully!", "success");
        setIsEmpModalOpen(false);
        fetchData();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange = (e) => {
    const { data, name } = e;
    let _formData = { ...formdata };
    _formData[name] = data;
    setFormdata(_formData);
  };

  const handleKeyUp = (e) => {
    if (e.code === "Enter") handleSubmit();
  };

  const fetchCourseData = async () => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/showCourses`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      console.log(data);
      let list = [];
      for (const course of data) {
        let obj = {};
        obj["id"] = course.id;
        obj["label"] = course?.course_name;
        obj["value"] = course?.course_name;
        list.push(obj);
      }
      setAllCourseList(list);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //callbacks
  useEffect(() => {
    if (id) {
      fetchData();
      fetchCourseData();
    }
    if (!token) navigate("/login");
  }, [id, token]);

  //others

  const DataRender = (employee) => {
    return (
      <Dropdown
        placement="bottom"
        trigger={["click"]}
        dropdownRender={() => {
          return (
            <div className={styles.optionsWrp}>
              <div
                className={styles.option_1}
                onClick={() => showModal(employee?.user?.id)}
              >
                <p>Enroll</p>
              </div>
              <Divider className={styles.divider} />
              <div className={styles.option_2}>
                <Link
                  to={`/courses/${employee?.user?.id}`}
                  className={styles.option_2_course}
                >
                  Courses
                </Link>
              </div>
              <div className={styles.option_2}>
                <Link
                  to={`/profile/${employee?.user?.id}`}
                  className={styles.option_2_course}
                >
                  Profile
                </Link>
              </div>
            </div>
          );
        }}
      >
        <div className={styles.AdminEmpList_card}>
          <div className={styles.AdminEmpList_card_img_wrp}>
            {employee.profile_photo ? (
              <img src={`${baseURL}${employee.profile_photo}`} />
            ) : (
              <FaUserCircle />
            )}
          </div>
          <h2>{employee?.user?.username}</h2>
          <p>{employee?.company_name}</p>
        </div>
      </Dropdown>
    );
  };

  const renderInputTemp = (label, value, name, isChnaged) => {
    return (
      <TTInput
        type={
          name === "password" || name === "confirmPass" ? "password" : "text"
        }
        onKeyUp={handleKeyUp}
        value={value}
        label={label}
        name={name}
        onChange={handleInputChange}
      />
    );
  };

  const roleOptios = [
    {
      key: 1,
      label: "Peer",
      value: "peer",
    },
    {
      key: 2,
      label: "Sub-ordinate",
      value: "subor",
    },
  ];

  const onEmpChange = (date) => {
    let _empEnrollCourse = { ...empEnrollCourse };
    _empEnrollCourse["starting_date"] = date;
    setEmpEnrollCourse(_empEnrollCourse);
  };

  const onEmpDeadChange = (date) => {
    let _empEnrollCourse = { ...empEnrollCourse };
    _empEnrollCourse["empDeadDate"] = date;
    setEmpEnrollCourse(_empEnrollCourse);
  };
  const onPeerSubChange = (date) => {
    let _empEnrollCourse = { ...empEnrollCourse };
    _empEnrollCourse["perrSubDeadDate"] = date;
    setEmpEnrollCourse(_empEnrollCourse);
  };
  const onAdminChange = (date) => {
    let _empEnrollCourse = { ...empEnrollCourse };
    _empEnrollCourse["adminDeadaDate"] = date;
    setEmpEnrollCourse(_empEnrollCourse);
  };

  const gapList = [
    { key: 1, label: "1", value: "1" },
    { key: 2, label: "2 ", value: "2" },
    { key: 3, label: "3 ", value: "3" },
    { key: 4, label: "4 ", value: "4" },
    { key: 5, label: "5 ", value: "5" },
    { key: 6, label: "6 ", value: "6" },
    { key: 7, label: "7 ", value: "7" },
    { key: 8, label: "8 ", value: "8" },
    { key: 9, label: "9 ", value: "9" },
    { key: 10, label: "10 ", value: "10" },
    { key: 11, label: "11", value: "11 "},
    { key: 12, label: "12", value: "12 "},
  ];
  return (
    <div className={styles.dashboardWrapper}>
      <Layout>
        <Navbar collapsed={collapsed} />
        <Layout>
          <Content>
            <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />
            <div
              style={{
                padding: 24,
                minHeight: 100,
                background: "#e8e8e8",
                borderRadius: borderRadiusLG,
              }}
              className={styles.EmplistWrp}
            >
              <div className={styles.AdminEmpList}>
                <div className={styles.headderEmplist}>
                  <h1> Presenters List</h1>
                  <Button onClick={() => opemAddModal()}>
                    Add Subordinate / Peer
                  </Button>
                </div>
                <div className={styles.AdminEmpList_cards}>
                  {empList?.map((elist, index) => {
                    return <div key={index}>{DataRender(elist)}</div>;
                  })}
                </div>
              </div>
            </div>
            <Modal
              title="Courses List"
              open={isModalOpen}
              onCancel={handleCancel}
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
            >
              <TTSelect
                label="Course"
                name="course"
                value={empEnrollCourse.course}
                options={allCourseList}
                onChange={handleChange}
              />
              <TTSelect
                label="Gap"
                name="gap"
                value={empEnrollCourse?.gap}
                options={gapList}
                onChange={handleGapChange}
              />
              <div className={styles.coursemodalDate}>
                <label>Starting Date:</label>
                <DatePicker
                  clearIcon={false}
                  format="dd-MM-yyyy"
                  value={dayjs(empEnrollCourse?.starting_date)}
                  onChange={onEmpChange}
                />
              </div>
              <div className={styles.coursemodalDate}>
                <label>Presentor Deadline Date:</label>
                <DatePicker
                  clearIcon={false}
                  format="dd-MM-yyyy"
                  value={dayjs(empEnrollCourse?.empDeadDate)}
                  onChange={onEmpDeadChange}
                />
              </div>
              <div className={styles.coursemodalDate}>
                <label>Peer & Subordinate Deadline Date:</label>
                <DatePicker
                  clearIcon={false}
                  format="dd-MM-yyyy"
                  value={dayjs(empEnrollCourse?.perrSubDeadDate)}
                  onChange={onPeerSubChange}
                />
              </div>
              <div className={styles.coursemodalDate}>
                <label>Advisor Deadline Date:</label>
                <DatePicker
                  clearIcon={false}
                  format="dd-MM-yyyy"
                  value={dayjs(empEnrollCourse?.adminDeadaDate)}
                  onChange={onAdminChange}
                />
              </div>
              <Button type="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </Modal>
            <Modal
              title="Courses List"
              open={isEmpModalOpen}
              onCancel={handleEmpCancel}
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
            >
              <div className={styles.form}>
                <div className={styles.basic_info}>
                  {renderInputTemp("Username", formdata?.username, "username")}
                  {renderInputTemp(
                    "First Name",
                    formdata?.first_name,
                    "first_name"
                  )}
                  {renderInputTemp(
                    "Last Name",
                    formdata?.last_name,
                    "last_name"
                  )}
                  {renderInputTemp("Email", formdata?.email, "email")}
                  {renderInputTemp("Password", formdata?.password, "password")}
                  {renderInputTemp(
                    "Confirm Password",
                    formdata.confirmPass,
                    "confirmPass"
                  )}
                  <TTSelect
                    label="Role"
                    name="role"
                    value={formdata.role}
                    options={roleOptios}
                    onChange={handleInputChange}
                  />
                </div>
                <Button onClick={handleEmpSubmit} className={styles.signup_btn}>
                  Add Subordinate / Peer
                </Button>
              </div>
            </Modal>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default AdminEmpList;
