import React, { useState, useEffect } from "react";
import {
  Layout,
  theme,
  Divider,
  Table,
  Select,
  Badge,
  ConfigProvider,
  Empty,
  Tooltip,
} from "antd";
import Navbar from "../Navbar/Navbar";
import styles from "./dashboard.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getToken,
  getUserInfo,
} from "../../../../common/helpers/localStorage.helper";
import { baseURL } from "../../../../Constant";
import SmHeader from "../../../SmHeader/SmHeader";
import ApexCharts from "react-apexcharts";
import { useRecoilValue } from "recoil";
import ReactApexChart from "react-apexcharts";
import { AtomUserInfo } from "../../../../store/genral.store";
import iCretegy from "../../../../assets/Vani's Patel.JPG";
import alpesh from "../../../../assets/alpesh.png";
import grupreet_Profile from "../../../../assets/gurpreet_ceo.png";
import axios from "axios";
import { MdMenuBook } from "react-icons/md";
import { MdDateRange } from "react-icons/md";
import { BiSolidUserDetail } from "react-icons/bi";
import Chatbox from "../../Chatbox/Chatbox";

const Dashboard = () => {
  // Variables
  const [data, setData] = useState({
    courses: [],
    tasks: [],
    inline: {},
    subordinate: {},
  });
  const token = getToken("token");
  const user = getUserInfo();
  const navigate = useNavigate();
  const { id } = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const userInfo = useRecoilValue(AtomUserInfo);
  const [courseData, setCourseData] = useState([]);
  const { Content } = Layout;
  const {
    token: { borderRadiusLG },
  } = theme.useToken();
  const [selectedOption, setSelectedOption] = useState({});
  const [selfAssessmentGrpahData, SetSelfAssessmentGrpahData] = useState({});
  const [inlineGrpahData, SetInlineGrpahData] = useState({});
  const [adminGrpahData, SetAdminGrpahData] = useState({});
  const [subOrGrpahData, SetSubOrGrpahData] = useState({});
  const [selfAvgGraph, setSelfAvgGraph] = useState([]);
  const [inlineGraph, setInlineGraph] = useState([]);
  const [adminGraph, setAdminGraph] = useState([]);
  const [subOrGraph, setSubOrGraph] = useState([]);
  const [courseListOption, setCourseListOption] = useState([]);
  const [dataSeries, setDataSeries] = useState([]);
  const [ceoDetail, setCeoDetail] = useState({});
  const SaAvg = Number(selfAssessmentGrpahData.sa_avg).toFixed(2);
  const InAvg = Number(inlineGrpahData.inline_avg).toFixed(2);
  const AdAvg = Number(adminGrpahData.admin_avg).toFixed(2);
  const SubAvg = Number(subOrGrpahData.sub_avg).toFixed(2);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //callbacks
  useEffect(() => {
    if (id) {
      fetchData();
      fetchDashboardGraphData();
    }
    if (!token) navigate("/login");
  }, [id, token]);

  //functions
  const fetchData = async () => {
    try {
      const response = await fetch(`${baseURL}/dashboard/${id}`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const inlineDetail = data?.inline_details;
      const selfDetail = data?.self_details;
      const subordinateDetail = data?.subordinate_details;
      const dashGraphData = data?.graph_data;
      setCeoDetail(data?.ceo_details);
      setData({
        courses: data?.course,
        tasks: data?.tasks,
        inline: inlineDetail,
        subordinate: subordinateDetail,
        selfDetail,
      });
      fetchCourseData(selfDetail?.is_oneonone);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCourseData = async (isOneOnOne) => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/showUserCourses/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the access token in the headers
        },
      });
      const data = await response?.data;
      setCourseData(data);
      let _courseList = [];
      for (const course in data) {
        let obj = {};
        obj["key"] = data[course].id;
        obj["label"] = data[course].course.course_name;
        obj["value"] = data[course].course.course_name;
        _courseList.push(obj);
      }
      if (_courseList.length > 0) {
        let _selCourse = _courseList[0] ?? {};
        setSelectedOption(_selCourse);
        handleFilterChange(_selCourse?.label, _courseList, isOneOnOne);
      }
      setCourseListOption(_courseList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDashboardGraphData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/dash_employee/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the access token in the headers
        },
      });
      const data = await response?.data;
      SetSelfAssessmentGrpahData(data?.self_assessment_dict);
      SetInlineGrpahData(data?.inline_dict);
      SetAdminGrpahData(data?.admin_dict);
      SetSubOrGrpahData(data?.sub_dict);
      let selfMonth = data?.self_assessment_dict?.sa_month_avg;
      let inline_month_avg = data?.inline_dict?.inline_month_avg;
      let admin_month_avg = data?.admin_dict?.admin_month_avg;
      let sub_month_avg = data?.sub_dict?.sub_month_avg;
      let self_ass_list = [];
      let inline_list = [];
      let admin_list = [];
      let sub_list = [];

      for (const key in selfMonth) {
        self_ass_list.push(selfMonth[key]);
      }
      for (const key in inline_month_avg) {
        inline_list.push(inline_month_avg[key]);
      }
      for (const key in admin_month_avg) {
        admin_list.push(admin_month_avg[key]);
      }
      for (const key in sub_month_avg) {
        sub_list.push(sub_month_avg[key]);
      }

      setSelfAvgGraph(self_ass_list);
      setInlineGraph(inline_list);
      setAdminGraph(admin_list);
      setSubOrGraph(sub_list);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFilterChange = async (selectedCourse, cList, isOneOnOne) => {
    let _cList = [...cList];
    let index = _cList?.findIndex((val) => val.label === selectedCourse);
    let _selOpt = _cList[index];
    setSelectedOption(_selOpt);
    try {
      let obj = {};
      obj["course_name"] = _selOpt.label;
      const response = await axios({
        method: "post",
        data: obj,
        url: `${baseURL}/monthly_data_entered/${_selOpt?.key}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = response.data;
      let monthList = response?.data?.month_list;
      let _chartData = { ...chartData };
      let _options = { ...chartData.options };
      let _xaxis = { ..._options.xaxis };
      _xaxis["categories"] = monthList;
      _options["xaxis"] = _xaxis;
      _chartData["options"] = _options;
      setChartData(_chartData);

      let _obj = data[_selOpt.label];
      let serislist = [];
      let _includes = ["Peer (CFT) Assessment", "Sub-Ordinate Assessment"];
      for (const key in _obj) {
        if (_includes.includes(key) && isOneOnOne) continue;
        let obj = {};
        obj["name"] = key;
        obj["data"] = _obj[key];
        serislist.push(obj);
      }
      setDataSeries(serislist);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const renderEmpty = (desc) => {
    return <Empty description={desc} />;
  };

  //others

  const responsive = [
    {
      breakpoint: 1400,
      options: {
        chart: {
          height: 160,
          width: 100,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
      },
    },
    {
      breakpoint: 1200,
      options: {
        chart: {
          height: 160,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
      },
    },
    {
      breakpoint: 991,
      options: {
        chart: {
          height: 140,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
      },
    },
    {
      breakpoint: 781,
      options: {
        chart: {
          height: 160,
        },

        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
      },
    },
    {
      breakpoint: 600,
      options: {
        chart: {
          height: 160,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
      },
    },
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 120,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
      },
    },
  ];

  const responsiveArea = [
    {
      breakpoint: 1200,
      options: {
        chart: {
          width: 150,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
      },
    },
    {
      breakpoint: 1200,
      options: {
        chart: {
          width: 50,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
      },
    },
    {
      breakpoint: 991,
      options: {
        chart: {
          height: 40,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
      },
    },
    {
      breakpoint: 781,
      options: {
        chart: {
          width: 100,
        },

        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
      },
    },
    {
      breakpoint: 600,
      options: {
        chart: {
          width: 100,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
      },
    },
    // {
    //   breakpoint: 480,
    //   options: {
    //     chart: {
    //       width: 250,
    //     },
    //     plotOptions: {
    //       bar: {
    //         horizontal: false,
    //       },
    //     },
    //   },
    // },
  ];

  const chartData1 = {
    series1: [SaAvg],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "18px",
            },
          },
        },
      },
      fill: {
        type: "color",
        color: ["#249efa"],
      },
      stroke: {
        lineCap: "round",
      },
      responsive: [
        {
          breakpoint: 1440,
          options: {
            chart: {
              height: 240,
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
          },
        },
        {
          breakpoint: 991,
          options: {
            chart: {
              height: 180,
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
          },
        },
        {
          breakpoint: 781,
          options: {
            chart: {
              height: 180,
            },

            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
          },
        },
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 200,
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
          },
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 200,
            },
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
          },
        },
      ],
    },
  };

  const chartData2 = {
    series2: [InAvg],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "18px",
            },
          },
        },
      },
      fill: {
        type: "color",
        colors: ["#24e5a4"],
      },
      stroke: {
        lineCap: "round",
      },
      responsive: responsive,
    },
  };

  const chartData3 = {
    series3: [SubAvg],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "18px",
            },
          },
        },
      },
      fill: {
        type: "color",
        colors: ["#FFA33C"],
      },
      stroke: {
        lineCap: "round",
      },
      responsive: responsive,
    },
  };

  const chartData4 = {
    series4: [AdAvg],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            colors: ["#EF4040"],
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "18px",
            },
          },
        },
      },
      fill: {
        type: "color",
        colors: ["#fd5f76"],
      },
      stroke: {
        lineCap: "round",
      },
      responsive: responsive,
    },
  };

  const areaChartData1 = {
    series1: [{ data: selfAvgGraph }],
    options: {
      chart: {
        type: "area",
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      fill: {
        type: "color",
        color: ["#249efa"],
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        enabled: true,
        x: {
          show: true,
          formatter: function(value) {
            // Assuming `value` is a month index (0-11)
            return monthNames[value - 1]; // Adjust if `value` is 1-based index
          }
        }
      },
    },
  };

  const areaChartData2 = {
    series2: [
      {
        name: "Value",
        data: inlineGraph,
      },
    ],

    options: {
      chart: {
        type: "area",
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      fill: {
        type: "color",
        colors: ["#fcba39"],
      },
      responsiveArea: responsiveArea,
      yaxis: {
        show: false,
      },
      tooltip: {
        enabled: true,
        x: {
          show: true,
          formatter: function(value) {
            // Assuming `value` is a month index (0-11)
            return monthNames[value - 1]; // Adjust if `value` is 1-based index
          }
        }
      },
    },
  };

  const areaChartData3 = {
    series3: [
      {
        name: "Value",
        data: subOrGraph,
      },
    ],

    options: {
      chart: {
        type: "area",
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      fill: {
        type: "color",
        colors: ["#fcba39"],
      },
      responsiveArea: responsiveArea,
      yaxis: {
        show: false,
      },
      tooltip: {
        enabled: true,
        x: {
          show: true,
          formatter: function(value) {
            // Assuming `value` is a month index (0-11)
            return monthNames[value - 1]; // Adjust if `value` is 1-based index
          }
        }
      },
    },
  };

  const areaChartData4 = {
    series4: [
      {
        name: "Value",
        data: adminGraph,
      },
    ],
    options: {
      chart: {
        type: "area",
        sparkline: {
          enabled: true,
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "color",
        colors: ["#EF4040"], // Set the color for the area chart
      },
      responsiveArea: responsiveArea,
      yaxis: {
        show: false,
      },
      tooltip: {
        enabled: true,
        x: {
          show: true,
          formatter: function(value) {
            // Assuming `value` is a month index (0-11)
            return monthNames[value - 1]; // Adjust if `value` is 1-based index
          }
        }
      },
    },
  };

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          horizontal: false,
          columnWidth: "65%",
          endingShape: "rounded",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      yaxis: {
        title: {
          text: "Assessment",
        },
        min: 0,
        max: 10,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  });

  const course = [
    {
      title: <div className={styles.TableHeaderSRNo}>Sr No</div>,
      dataIndex: "",
      key: "",
      render: (text, record, index) => {
        let idx = index + 1;
        return <div className={styles.tableSrNoData}>{idx}</div>;
      },
    },
    {
      title: (
        <div className={styles.CourseHeader}>
          <MdMenuBook fontSize="30px" /> &nbsp; Legendary Lessons
        </div>
      ),
      dataIndex: "course_name",
      key: "course_name",
      render: (text, record) => {
        return (
          <div className={styles.CourseData}>{record?.course?.course_name}</div>
        );
      },
    },
    {
      title: (
        <div className={styles.tableDateHeader}>
          <MdDateRange fontSize="30px" /> &nbsp; Date
        </div>
      ),
      dataIndex: "last_update_date",
      key: "last_update_date",
      render: (text, record) => {
        let date = record?.starting_date;
        let parsedDate = new Date(date);
        let options = { month: "long", day: "2-digit", year: "numeric" };
        let formattedDate = parsedDate.toLocaleDateString("en-US", options);
        return <div className={styles.tableDateData}>{formattedDate}</div>;
      },
    },
    {
      title: (
        <div className={styles.tableAction}>
          <BiSolidUserDetail fontSize="30px" /> &nbsp; Action
        </div>
      ),
      render: (val, record) => {
        let id = record?.id;

        return (
          <div className={styles.tableActoinButton}>
            <Link to={`/courses-details/${id}`}>
              <button className={styles.btn}>View Detail</button>
            </Link>
          </div>
        );
      },
    },
  ];

  const chartDataCEoDash = {
    series: [AdAvg],

    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "18px",
            },
          },
        },
      },
      fill: {
        type: "color",
        color: ["#249efa"],
      },
      stroke: {
        lineCap: "round",
      },
      responsive: [
        {
          breakpoint: 1440,
          options: {
            chart: {
              height: 240,
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
          },
        },
        {
          breakpoint: 991,
          options: {
            chart: {
              height: 180,
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
          },
        },
        {
          breakpoint: 781,
          options: {
            chart: {
              height: 180,
            },

            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
          },
        },
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 200,
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
          },
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 200,
            },
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
          },
        },
      ],
    },
  };

  const areaChartDataCeoDash = {
    series: [
      {
        name: "Value",
        data: adminGraph,
      },
    ],
    options: {
      chart: {
        type: "area",
        sparkline: {
          enabled: true,
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "color",
        colors: ["#EF4040"], // Set the color for the area chart
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        enabled: true,
        x: {
          show: true,
          formatter: function(value) {
            // Assuming `value` is a month index (0-11)
            return monthNames[value - 1]; // Adjust if `value` is 1-based index
          }
        }
      },
    },
  };

  //Templates

  const renderDiv = () => {
    return (
      <div className={styles.dashboard_employee_graph_1}>
        <div className={styles.sec1}>
          <div className={styles.sec1Card1}>
            <div className={styles.profilePhto}>
              <img src={`${baseURL}${data?.selfDetail?.profile_photo}`} />
            </div>
            <Tooltip title={`${data?.selfDetail?.user?.first_name}`}>
              <h2 className={styles.ellips}>
                Self Assessment by {data?.selfDetail?.user?.first_name}
              </h2>
            </Tooltip>
          </div>
          <div className={styles.sec1CardChart1}>
            <ReactApexChart
              options={chartData1.options}
              series={chartData1.series1}
              type="radialBar"
              height={260}
            />
            <h3>Rank : {SaAvg / 10}</h3>
          </div>
          <div className={styles.sec1CardChart2}>
            <ReactApexChart
              options={areaChartData1.options}
              series={areaChartData1.series1}
              type="area"
              height={120}
            />
          </div>
        </div>
        <div className={styles.sec2}>
          <div className={styles.sec2card1}>
            <h2>Peer (CFT) Assessment</h2>
            <div className={styles.sec2Card1ChartWrp}>
              <div>
                <ReactApexChart
                  options={chartData2.options}
                  series={chartData2.series2}
                  type="radialBar"
                  height={180}
                />
              </div>
              <div className={styles.sec2Card1ChartWrp}>
                <ReactApexChart
                  options={areaChartData2.options}
                  series={areaChartData2.series2}
                  type="area"
                  height={50}
                />
              </div>
            </div>
            <h3 className={styles.RankHeading}>Rank : {InAvg / 10}</h3>
          </div>
          <div className={styles.sec2card1}>
            <h2>Subordinate Assessment</h2>
            <div className={styles.sec2Card1ChartWrp}>
              <div>
                <ReactApexChart
                  options={chartData3.options}
                  series={chartData3.series3}
                  type="radialBar"
                  height={180}
                />
              </div>
              <div className={styles.sec2Card1ChartWrp}>
                <ReactApexChart
                  options={areaChartData3.options}
                  series={areaChartData3.series3}
                  type="area"
                  height={60}
                />
              </div>
            </div>
            <h3 className={styles.RankHeading}>Rank : {SubAvg / 10}</h3>
          </div>

          <div className={styles.sec2card1}>
            <h2>iCretegy Assessment</h2>
            <div className={styles.sec2Card1ChartWrp}>
              <div>
                <ReactApexChart
                  options={chartData4.options}
                  series={chartData4.series4}
                  type="radialBar"
                  height={180}
                />
              </div>
              <div className={styles.sec2Card1ChartWrp}>
                <ReactApexChart
                  options={areaChartData4.options}
                  series={areaChartData4.series4}
                  type="area"
                  height={60}
                />
              </div>
            </div>
            <h3 className={styles.RankHeading}>Rank : {AdAvg / 10}</h3>
          </div>
        </div>
        <div className={styles.sec3}>
          <h1>
            360<sup>o</sup> Reviews
          </h1>
          <Badge.Ribbon
            text="Director"
            color="#171151"
            style={{ marginTop: "-.8rem", fontSize: ".9rem" }}
          >
            <div className={styles.sec3Card1}>
              <div className={styles.sec3Card1Img}>
                <img src={`${baseURL}${ceoDetail?.profile_photo}`} />
              </div>
              <div className={styles.sec3Card1Content}>
                <h2>{ceoDetail?.user?.first_name}</h2>
                <p>{ceoDetail?.designation},</p>
                <p>{ceoDetail?.company_name}</p>
              </div>
            </div>
          </Badge.Ribbon>
          <Divider style={{ margin: ".1rem" }} />
          {!data?.selfDetail?.is_oneonone && (
            <>
              <Badge.Ribbon
                color="#171151"
                text="Peer (CFT)"
                style={{ marginTop: "-.8rem", fontSize: ".9rem" }}
              >
                <div className={styles.sec3Card1}>
                  <div className={styles.sec3Card1Img}>
                    <img src={`${baseURL}${data?.inline?.profile_photo}`} />
                  </div>
                  <div className={styles.sec3Card1Content}>
                    <h2>
                      {data?.inline?.user?.first_name}{" "}
                      {data?.inline?.user?.last_name}
                    </h2>
                    <p>
                      {data?.inline?.designation} - {data?.inline?.department}
                    </p>
                    <p>{data?.inline?.company_name}</p>
                  </div>
                </div>
              </Badge.Ribbon>
              <Divider style={{ margin: ".1rem" }} />
              <Badge.Ribbon
                color="#171151"
                text="Subordinate"
                style={{ marginTop: "-.8rem", fontSize: ".9rem" }}
              >
                <div className={styles.sec3Card1}>
                  <div className={styles.sec3Card1Img}>
                    <img
                      src={`${baseURL}${data?.subordinate?.profile_photo}`}
                    />
                  </div>
                  <div className={styles.sec3Card1Content}>
                    <h2>
                      {data?.subordinate?.user?.first_name}{" "}
                      {data?.subordinate?.user?.last_name}
                    </h2>
                    <p>
                      {data?.subordinate?.designation} -{" "}
                      {data?.subordinate?.department}
                    </p>
                    <p>{data?.subordinate?.company_name}</p>
                  </div>
                </div>
              </Badge.Ribbon>
              <Divider style={{ margin: ".1rem" }} />
            </>
          )}

          <Badge.Ribbon
            color="#171151"
            text="iCAMS Advisory"
            style={{ marginTop: "-.8rem", fontSize: ".9rem" }}
          >
            <div className={styles.sec3Card1}>
              {data?.selfDetail?.role === "ceo" && (
                <>
                  <div className={styles.sec3Card1Img}>
                    <img src={alpesh} />
                  </div>{" "}
                  <div className={styles.sec3Card1Content}>
                    <h2>Mr. Alpesh Patel</h2>
                    <p>CEO - iCretegy,</p>
                    <p>iCretegy</p>
                  </div>
                </>
              )}
              {data?.selfDetail?.role !== "ceo" && (
                <>
                  <div className={styles.sec3Card1Img}>
                    <img src={iCretegy} />
                  </div>{" "}
                  <div className={styles.sec3Card1Content}>
                    <h2>Ms. Vani Patel</h2>
                    <p>iCAMS Advisor,</p>
                    <p>iCretegy</p>
                  </div>
                </>
              )}
            </div>
          </Badge.Ribbon>
        </div>
      </div>
    );
  };

  const renderDiv2 = () => {
    return (
      <div className={styles.dashboard_employee_graph_1}>
        <div className={styles.sec1IsOnO}>
          <div className={styles.sec1Card1}>
            <div className={styles.profilePhto}>
              <img src={`${baseURL}${data?.selfDetail?.profile_photo}`} />
            </div>
            <Tooltip title={`${data?.selfDetail?.user?.first_name}`}>
              <h2 className={styles.ellips}>
                Self Assessment by {data?.selfDetail?.user?.first_name}
              </h2>
            </Tooltip>
          </div>
          <div className={styles.sec1CardChart1OnO}>
            <ReactApexChart
              options={chartData1.options}
              series={chartData1.series1}
              type="radialBar"
              height={260}
            />
            <h3>Rank : {SaAvg / 10}</h3>
          </div>
          <div className={styles.sec1CardChart2OnO}>
            <ReactApexChart
              options={areaChartData1.options}
              series={areaChartData1.series1}
              type="area"
              height={120}
            />
          </div>
        </div>
        <div className={styles.sec1IsOnO}>
          <div className={styles.sec1Card2}>
            <Tooltip title={`${data?.selfDetail?.user?.first_name}`}>
              <h2 className={styles.ellips}>iCretegy Assessment</h2>
            </Tooltip>
          </div>
          <div className={styles.sec1CardChart1OnO}>
            <ReactApexChart
              options={chartDataCEoDash.options}
              series={chartDataCEoDash.series}
              type="radialBar"
              height={260}
            />
            <h3>Rank : {AdAvg / 10}</h3>
          </div>
          <div className={styles.sec1CardChart2OnO}>
            <ReactApexChart
              options={areaChartDataCeoDash.options}
              series={areaChartDataCeoDash.series}
              type="area"
              height={120}
            />
          </div>
        </div>

        <div className={styles.sec3IsOnO}>
          <h1>
            360<sup>o</sup> Reviews
          </h1>
          <Badge.Ribbon
            text="Director"
            color="#171151"
            style={{ marginTop: "-.8rem", fontSize: ".9rem" }}
          >
            <div className={styles.sec3Card1}>
              <div className={styles.sec3Card1Img}>
                <img src={`${baseURL}${ceoDetail?.profile_photo}`} />
              </div>
              <div className={styles.sec3Card1Content}>
                <h2>{ceoDetail?.user?.first_name}</h2>
                <p>{ceoDetail?.designation},</p>
                <p>{ceoDetail?.company_name}</p>
              </div>
            </div>
          </Badge.Ribbon>
          <Divider style={{ margin: ".1rem" }} />
          {!data?.selfDetail?.is_oneonone && (
            <>
              <Badge.Ribbon
                color="#171151"
                text="Peer (CFT)"
                style={{ marginTop: "-.8rem", fontSize: ".9rem" }}
              >
                <div className={styles.sec3Card1}>
                  <div className={styles.sec3Card1Img}>
                    <img src={`${baseURL}${data?.inline?.profile_photo}`} />
                  </div>
                  <div className={styles.sec3Card1Content}>
                    <h2>
                      {data?.inline?.user?.first_name}{" "}
                      {data?.inline?.user?.last_name}
                    </h2>
                    <p>
                      {data?.inline?.designation} - {data?.inline?.department}
                    </p>
                    <p>{data?.inline?.company_name}</p>
                  </div>
                </div>
              </Badge.Ribbon>
              <Divider style={{ margin: ".1rem" }} />
              <Badge.Ribbon
                color="#171151"
                text="Subordinate"
                style={{ marginTop: "-.8rem", fontSize: ".9rem" }}
              >
                <div className={styles.sec3Card1}>
                  <div className={styles.sec3Card1Img}>
                    <img
                      src={`${baseURL}${data?.subordinate?.profile_photo}`}
                    />
                  </div>
                  <div className={styles.sec3Card1Content}>
                    <h2>
                      {data?.subordinate?.user?.first_name}{" "}
                      {data?.subordinate?.user?.last_name}
                    </h2>
                    <p>
                      {data?.subordinate?.designation} -{" "}
                      {data?.subordinate?.department}
                    </p>
                    <p>{data?.subordinate?.company_name}</p>
                  </div>
                </div>
              </Badge.Ribbon>
              <Divider style={{ margin: ".1rem" }} />
            </>
          )}

          <Badge.Ribbon
            color="#171151"
            text={data?.selfDetail?.role !== "ceo" ? "iCAMS Advisory" : "CEO"}
            style={{ marginTop: "-.8rem", fontSize: ".9rem" }}
          >
            <div className={styles.sec3Card1}>
              {data?.selfDetail?.role === "ceo" && (
                <>
                  <div className={styles.sec3Card1Img}>
                    <img src={alpesh} />
                  </div>{" "}
                  <div className={styles.sec3Card1Content}>
                    <h2>Mr. Alpesh Patel</h2>
                    <p>CEO - iCretegy,</p>
                    <p>iCretegy</p>
                  </div>
                </>
              )}
              {data?.selfDetail?.role !== "ceo" && (
                <>
                  <div className={styles.sec3Card1Img}>
                    <img src={iCretegy} />
                  </div>{" "}
                  <div className={styles.sec3Card1Content}>
                    <h2>Ms. Vani Patel</h2>
                    <p>iCAMS Advisor,</p>
                    <p>iCretegy</p>
                  </div>
                </>
              )}
            </div>
          </Badge.Ribbon>
        </div>
      </div>
    );
  };

  const renderEmpPage = () => {
    return (
      <div
        style={{
          padding: 24,
          minHeight: 100,
          borderRadius: borderRadiusLG,
        }}
        className={styles.DashboardWrp}
        id="dashboard"
      >
        <h1 className={styles.HeadingClass}>
          {/* Leadership Performance Spectrum */}
          Leadership Performance Spectrum of all 5 Lessons
        </h1>

        {!data?.selfDetail?.is_oneonone && renderDiv()}
        {data?.selfDetail?.is_oneonone && renderDiv2()}

        <div className={styles.dashboard_employee_lavel_graph}>
          <h1 style={{ margin: 0 }}>Assigned Courses</h1>
          <div className={styles.chart}>
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    padding: 0,
                  },
                },
              }}
            >
              <Table
                dataSource={courseData ?? []}
                columns={course ?? ""}
                pagination={false}
              />
            </ConfigProvider>
          </div>
        </div>

        <div className={styles.dashboard_employee_lavel_graph}>
          <div className={styles.graphHeader}>
            <h1>Lesson Wise Monthly Assessment</h1>
            <div>
              <Select
                value={selectedOption}
                onChange={(val) => handleFilterChange(val, courseListOption)}
                options={courseListOption}
              />
            </div>
          </div>
          <div className={styles.chart}>
            <ApexCharts
              options={chartData.options}
              series={dataSeries || []}
              type="bar"
              height={400}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCeoPage = () => {
    return (
      <div
        style={{
          padding: 24,
          minHeight: 100,
          borderRadius: borderRadiusLG,
        }}
        className={styles.DashboardWrp}
        id="dashboard"
      >
        <h1 className={styles.HeadingClass}>
          {/* Leadership Performance Spectrum */}
          Leadership Performance Spectrum of all 5 Lessons
        </h1>

        {!data?.selfDetail?.is_oneonone && renderDiv()}
        {data?.selfDetail?.is_oneonone && renderDiv2()}

        <div className={styles.dashboard_employee_lavel_graph}>
          <h1 style={{ margin: 0 }}>Assigned Courses</h1>
          <div className={styles.chart}>
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    padding: 0,
                  },
                },
              }}
            >
              <Table
                dataSource={courseData ?? []}
                columns={course ?? ""}
                pagination={false}
              />
            </ConfigProvider>
          </div>
        </div>

        <div className={styles.dashboard_employee_lavel_graph}>
          <div className={styles.graphHeader}>
            <h1>Lesson Wise Monthly Assessment</h1>
            <div>
              <Select
                value={selectedOption}
                onChange={(val) =>
                  handleFilterChange(
                    val,
                    courseListOption,
                    data?.selfDetail?.is_oneonone
                  )
                }
                options={courseListOption}
              />
            </div>
          </div>
          <div className={styles.chart}>
            <ApexCharts
              options={chartData.options}
              series={dataSeries || []}
              type="bar"
              height={400}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Layout>
        <Navbar collapsed={collapsed} />
        <Layout>
          <Content className={styles.EmpDashboard}>
            <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />

            {data?.selfDetail?.role === "ceo" &&
              courseData.length === 0 &&
              renderEmpty(
                "Oops, no data! as currently none of the course is assigned to you."
              )}

            {(data?.selfDetail?.role === "employee" ||
              data?.selfDetail?.role === "ceo") &&
              data?.selfDetail.is_oneonone &&
              renderCeoPage()}

            {data?.selfDetail?.role === "employee" &&
              !data?.selfDetail.is_oneonone &&
              renderEmpPage()}
          </Content>
        </Layout>
        <Chatbox />
      </Layout>
    </div>
  );
};

export default Dashboard;
