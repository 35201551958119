import React, { useEffect, useState } from "react";
import styles from "./t_s.module.css";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Link } from "react-router-dom";

const T_S = () => {
  // Template
  const [menu, setMenu] = useState(false);

  return (
    <div className="beforeLoginContenet">
      <div className={styles.header}>
        <Header setHeader={setMenu} />
      </div>
      <div className={styles.tsCon}>
        <h1>Terms & Conditions</h1>
        <p>
          Welcome to the iCAMS (iCret Accountability Management Software)
          website. By accessing and using our website, you agree to comply with
          the following terms and conditions: <br /> <br />
          General Information: The content of this website is provided for
          general information and use only. It is subject to change without
          prior notice.
          <br />
          <br /> Accuracy of Information: We strive to ensure that the
          information provided on this website is accurate and up to date.
          However, we make no representations or warranties of any kind, express
          or implied, regarding the completeness, accuracy, reliability,
          suitability, or availability of the website or the information,
          products, services, or related graphics contained on the website for
          any purpose.
          <br />
          <br /> Limitation of Liability: iCAMS shall not be liable for any loss
          or damage, including but not limited to indirect or consequential loss
          or damage, or any loss or damage whatsoever arising from the use of
          this website. It is your own responsibility to evaluate the
          suitability of any information, products, services, or related
          graphics available on this website for your specific requirements.
          <br /> <br />
          Third-Party Links: This website may include links to external websites
          for your convenience and further information. However, iCAMS has no
          control over the nature, content, and availability of these external
          sites. The inclusion of any links does not necessarily imply a
          recommendation or endorse the views expressed within them. We shall
          not be held responsible for any content displayed on linked websites.
          <br />
          <br /> Intellectual Property: All materials, including but not limited
          to the design, layout, look, appearance, and graphics of this website,
          are either owned by iCAMS or licensed to us. Reproduction of these
          materials is prohibited without explicit consent, except in accordance
          with the copyright notice provided on the website.
          <br />
          <br />
          Website Availability: While we make every effort to ensure the
          continuous availability and smooth operation of this website, iCAMS
          shall not be held responsible for any temporary unavailability or
          technical issues beyond our control that may disrupt access to the
          website.
          <br />
          <br /> Unauthorized Use: Any unauthorized use of this website may give
          rise to a claim for damages and/or be considered a criminal offense.
          <br />
          <br />
          Governing Law: Your use of this website and any dispute arising out of
          such use is subject to the laws of India. Any legal action or
          proceeding shall be brought exclusively in the courts located in
          Gujarat, India.
          <br />
          <br />
          Force Majeure: iCAMS shall not be liable for any delay or failure to
          perform our obligations if such delay or failure is caused by
          circumstances beyond our reasonable control, including but not limited
          to natural disasters, acts of government, labour disputes, or
          technical failures.
          <br />
          <br /> We encourage you to review these terms and conditions
          regularly, as they may be updated or modified without prior notice. By
          continuing to use this website, you acknowledge and agree to be bound
          by the most recent version of these terms and conditions.
          <br />
          <br /> If you have any questions or concerns regarding these terms and
          conditions, please contact us at{" "}
          <a
            style={{ textDecoration: "none", color: "blue" }}
            href="mailto:inquiry@icretegy.com"
          >
            inquiry@icretegy.com
          </a>
          .
        </p>
      </div>

      <div className={styles.footer}>
        <Footer />
      </div>
      <div className={`${styles.open} ${menu ? styles.show : ""}`}>
        <Link className={styles.menuBar_link} to="/">
          Home
        </Link>
        <Link className={styles.menuBar_link} to="/signup">
          Sign up
        </Link>
        <Link className={styles.menuBar_link} to="/login">
          Log in
        </Link>
        <Link className={styles.menuBar_link} to="/t_s">
          Terms & Conditions
        </Link>
        <Link className={styles.menuBar_link} to="/privacyAndPolicy">
          Privacy Policy
        </Link>
      </div>
    </div>
  );
};

export default T_S;
