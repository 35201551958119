import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { AtomMenuBar } from "./genral.store";

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const documentChangeHandler = () => setMatches(mediaQueryList.matches);

    mediaQueryList.addEventListener('change', documentChangeHandler);
    setMatches(mediaQueryList.matches);

    return () => {
      mediaQueryList.removeEventListener('change', documentChangeHandler);
    };
  }, [query]);

  return matches;
};

export const useResponsiveMenuBar = () => {
  const [collapsed, setCollapsed] = useRecoilState(AtomMenuBar);
  const isLargeScreen = useMediaQuery('(min-width: 600px)'); // Media query for 600px

  useEffect(() => {
    setCollapsed(!isLargeScreen); // Set to false if screen is 600px or above, true otherwise
  }, [isLargeScreen, setCollapsed]);
};
