// export const SignupUrl = 'https://icamsapp.tesseracttechnolabs.com/register';
// export const LoginUrl = 'https://icamsapp.tesseracttechnolabs.com/login';
// export const dashboard = 'https://icamsapp.tesseracttechnolabs.com/dashboard';
// export const show_courses_url = 'https://icamsapp.tesseracttechnolabs.com/show_course';
// export const baseURL = "https://icamsapp.tesseracttechnolabs.com"
// export const SignupUrl = 'http://192.168.1.12:8000/register';
// export const LoginUrl = 'http://192.168.1.12:8000/login';
// export const dashboard = 'http://192.168.1.12:8000/dashboard';
// export const show_courses_url = 'http://192.168.1.12:8000/show_course';
// export const baseURL = "http://192.168.1.12:8000"
export const SignupUrl = 'https://api.icams.co.in/register';
export const LoginUrl = 'https://api.icams.co.in/login';
export const dashboard = 'https://api.icams.co.in/dashboard';
export const show_courses_url = 'https://api.icams.co.in/show_course';
export const baseURL = "https://api.icams.co.in";

export const months = {
     1: {month:'May 2024',date:'May 23, 2024'},
     2: {month:'Jun 2024',date:'Jun 27, 2024'},
     3: {month:'July 2024',date:'Jul 25, 2024'},
     4: {month:'August 2024',date:'Aug 22, 2024'},
     5: {month:'September 2024',date:'Sep 26, 2024'},
     6: {month:'October 2024',date:'Oct 24, 2024'},
     7: {month:'November 2024',date:'Nov 21, 2024'},
     8: {month:'December 2024',date:'Dec 26, 2024'},
     9: {month:'January 2025',date:'Jan 23, 2025'},
     10: {month:'Februaryy  2025',date:'Feb 27, 2025'},
     11: {month:'March 2025',date:'Mar 27, 2025'},
     12: {month:'April 2025',date:'Apr 24, 2025'},
}


export const indianStates = [
    {
        "label": "Andaman and Nicobar Islands",
        "value": "Andaman and Nicobar Islands"
    },
    {
        "label": "Andhra Pradesh",
        "value": "Andhra Pradesh"
    },
    {
        "label": "Arunachal Pradesh",
        "value": "Arunachal Pradesh"
    },
    {
        "label": "Assam",
        "value": "Assam"
    },
    {
        "label": "Bihar",
        "value": "Bihar"
    },
    {
        "label": "Chandigarh",
        "value": "Chandigarh"
    },
    {
        "label": "Chhattisgarh",
        "value": "Chhattisgarh"
    },
    {
        "label": "Dadra and Nagar Haveli and Daman and Diu",
        "value": "Dadra and Nagar Haveli and Daman and Diu"
    },
    {
        "label": "Delhi",
        "value": "Delhi"
    },
    {
        "label": "Goa",
        "value": "Goa"
    },
    {
        "label": "Gujarat",
        "value": "Gujarat"
    },
    {
        "label": "Haryana",
        "value": "Haryana"
    },
    {
        "label": "Himachal Pradesh",
        "value": "Himachal Pradesh"
    },
    {
        "label": "Jammu and Kashmir",
        "value": "Jammu and Kashmir"
    },
    {
        "label": "Jharkhand",
        "value": "Jharkhand"
    },
    {
        "label": "Karnataka",
        "value": "Karnataka"
    },
    {
        "label": "Kerala",
        "value": "Kerala"
    },
    {
        "label": "Ladakh",
        "value": "Ladakh"
    },
    {
        "label": "Lakshadweep",
        "value": "Lakshadweep"
    },
    {
        "label": "Madhya Pradesh",
        "value": "Madhya Pradesh"
    },
    {
        "label": "Maharashtra",
        "value": "Maharashtra"
    },
    {
        "label": "Manipur",
        "value": "Manipur"
    },
    {
        "label": "Meghalaya",
        "value": "Meghalaya"
    },
    {
        "label": "Mizoram",
        "value": "Mizoram"
    },
    {
        "label": "Nagaland",
        "value": "Nagaland"
    },
    {
        "label": "Odisha",
        "value": "Odisha"
    },
    {
        "label": "Puducherry",
        "value": "Puducherry"
    },
    {
        "label": "Punjab",
        "value": "Punjab"
    },
    {
        "label": "Rajasthan",
        "value": "Rajasthan"
    },
    {
        "label": "Sikkim",
        "value": "Sikkim"
    },
    {
        "label": "Tamil Nadu",
        "value": "Tamil Nadu"
    },
    {
        "label": "Telangana",
        "value": "Telangana"
    },
    {
        "label": "Tripura",
        "value": "Tripura"
    },
    {
        "label": "Uttar Pradesh",
        "value": "Uttar Pradesh"
    },
    {
        "label": "Uttarakhand",
        "value": "Uttarakhand"
    },
    {
        "label": "West Bengal",
        "value": "West Bengal"
    }
];
export const countyList = [ 
{
    "label": "INDIA",
    "value": "INDIA"
},
{
    "label": "USA",
    "value": "USA"
}]
export const indianCities = {
    
    "Andaman and Nicobar Islands": [
        {
            "label": "Bamboo Flat",
            "value": "Bamboo Flat"
        },
        {
            "label": "Nicobar",
            "value": "Nicobar"
        },
        {
            "label": "Port Blair",
            "value": "Port Blair"
        },
        {
            "label": "South Andaman",
            "value": "South Andaman"
        }
    ],
    "Andhra Pradesh": [
        {
            "label": "Addanki",
            "value": "Addanki"
        },
        {
            "label": "Adoni",
            "value": "Adoni"
        },
        {
            "label": "Akasahebpet",
            "value": "Akasahebpet"
        },
        {
            "label": "Akividu",
            "value": "Akividu"
        },
        {
            "label": "Akkarampalle",
            "value": "Akkarampalle"
        },
        {
            "label": "Amalapuram",
            "value": "Amalapuram"
        },
        {
            "label": "Amudalavalasa",
            "value": "Amudalavalasa"
        },
        {
            "label": "Anakapalle",
            "value": "Anakapalle"
        },
        {
            "label": "Anantapur",
            "value": "Anantapur"
        },
        {
            "label": "Atmakur",
            "value": "Atmakur"
        },
        {
            "label": "Attili",
            "value": "Attili"
        },
        {
            "label": "Avanigadda",
            "value": "Avanigadda"
        },
        {
            "label": "Badvel",
            "value": "Badvel"
        },
        {
            "label": "Banganapalle",
            "value": "Banganapalle"
        },
        {
            "label": "Bapatla",
            "value": "Bapatla"
        },
        {
            "label": "Betamcherla",
            "value": "Betamcherla"
        },
        {
            "label": "Bhattiprolu",
            "value": "Bhattiprolu"
        },
        {
            "label": "Bhimavaram",
            "value": "Bhimavaram"
        },
        {
            "label": "Bhimunipatnam",
            "value": "Bhimunipatnam"
        },
        {
            "label": "Bobbili",
            "value": "Bobbili"
        },
        {
            "label": "Challapalle",
            "value": "Challapalle"
        },
        {
            "label": "Chemmumiahpet",
            "value": "Chemmumiahpet"
        },
        {
            "label": "Chilakalurupet",
            "value": "Chilakalurupet"
        },
        {
            "label": "Chinnachowk",
            "value": "Chinnachowk"
        },
        {
            "label": "Chipurupalle",
            "value": "Chipurupalle"
        },
        {
            "label": "Chirala",
            "value": "Chirala"
        },
        {
            "label": "Chittoor",
            "value": "Chittoor"
        },
        {
            "label": "Chodavaram",
            "value": "Chodavaram"
        },
        {
            "label": "Cuddapah",
            "value": "Cuddapah"
        },
        {
            "label": "Cumbum",
            "value": "Cumbum"
        },
        {
            "label": "Darsi",
            "value": "Darsi"
        },
        {
            "label": "Dharmavaram",
            "value": "Dharmavaram"
        },
        {
            "label": "Dhone",
            "value": "Dhone"
        },
        {
            "label": "Diguvametta",
            "value": "Diguvametta"
        },
        {
            "label": "East Godavari",
            "value": "East Godavari"
        },
        {
            "label": "Elamanchili",
            "value": "Elamanchili"
        },
        {
            "label": "Ellore",
            "value": "Ellore"
        },
        {
            "label": "Emmiganur",
            "value": "Emmiganur"
        },
        {
            "label": "Erraguntla",
            "value": "Erraguntla"
        },
        {
            "label": "Etikoppaka",
            "value": "Etikoppaka"
        },
        {
            "label": "Gajuwaka",
            "value": "Gajuwaka"
        },
        {
            "label": "Ganguvada",
            "value": "Ganguvada"
        },
        {
            "label": "Gannavaram",
            "value": "Gannavaram"
        },
        {
            "label": "Giddalur",
            "value": "Giddalur"
        },
        {
            "label": "Gokavaram",
            "value": "Gokavaram"
        },
        {
            "label": "Gorantla",
            "value": "Gorantla"
        },
        {
            "label": "GovindapuramChilakaluripetGuntur",
            "value": "GovindapuramChilakaluripetGuntur"
        },
        {
            "label": "Gudivada",
            "value": "Gudivada"
        },
        {
            "label": "Gudlavalleru",
            "value": "Gudlavalleru"
        },
        {
            "label": "Gudur",
            "value": "Gudur"
        },
        {
            "label": "Guntakal Junction",
            "value": "Guntakal Junction"
        },
        {
            "label": "Guntur",
            "value": "Guntur"
        },
        {
            "label": "Hindupur",
            "value": "Hindupur"
        },
        {
            "label": "Ichchapuram",
            "value": "Ichchapuram"
        },
        {
            "label": "Jaggayyapeta",
            "value": "Jaggayyapeta"
        },
        {
            "label": "Jammalamadugu",
            "value": "Jammalamadugu"
        },
        {
            "label": "Kadiri",
            "value": "Kadiri"
        },
        {
            "label": "Kaikalur",
            "value": "Kaikalur"
        },
        {
            "label": "Kakinada",
            "value": "Kakinada"
        },
        {
            "label": "Kalyandurg",
            "value": "Kalyandurg"
        },
        {
            "label": "Kamalapuram",
            "value": "Kamalapuram"
        },
        {
            "label": "Kandukur",
            "value": "Kandukur"
        },
        {
            "label": "Kanigiri",
            "value": "Kanigiri"
        },
        {
            "label": "Kankipadu",
            "value": "Kankipadu"
        },
        {
            "label": "Kanuru",
            "value": "Kanuru"
        },
        {
            "label": "Kavali",
            "value": "Kavali"
        },
        {
            "label": "Kolanukonda",
            "value": "Kolanukonda"
        },
        {
            "label": "Kondapalle",
            "value": "Kondapalle"
        },
        {
            "label": "Korukollu",
            "value": "Korukollu"
        },
        {
            "label": "Kosigi",
            "value": "Kosigi"
        },
        {
            "label": "Kovvur",
            "value": "Kovvur"
        },
        {
            "label": "Krishna",
            "value": "Krishna"
        },
        {
            "label": "Kuppam",
            "value": "Kuppam"
        },
        {
            "label": "Kurnool",
            "value": "Kurnool"
        },
        {
            "label": "Macherla",
            "value": "Macherla"
        },
        {
            "label": "Machilipatnam",
            "value": "Machilipatnam"
        },
        {
            "label": "Madanapalle",
            "value": "Madanapalle"
        },
        {
            "label": "Madugula",
            "value": "Madugula"
        },
        {
            "label": "Mandapeta",
            "value": "Mandapeta"
        },
        {
            "label": "Mandasa",
            "value": "Mandasa"
        },
        {
            "label": "Mangalagiri",
            "value": "Mangalagiri"
        },
        {
            "label": "Markapur",
            "value": "Markapur"
        },
        {
            "label": "Nagari",
            "value": "Nagari"
        },
        {
            "label": "Nagireddipalli",
            "value": "Nagireddipalli"
        },
        {
            "label": "Nandigama",
            "value": "Nandigama"
        },
        {
            "label": "Nandikotkur",
            "value": "Nandikotkur"
        },
        {
            "label": "Nandyal",
            "value": "Nandyal"
        },
        {
            "label": "Narasannapeta",
            "value": "Narasannapeta"
        },
        {
            "label": "Narasapur",
            "value": "Narasapur"
        },
        {
            "label": "Narasaraopet",
            "value": "Narasaraopet"
        },
        {
            "label": "Narasingapuram",
            "value": "Narasingapuram"
        },
        {
            "label": "Narayanavanam",
            "value": "Narayanavanam"
        },
        {
            "label": "Narsipatnam",
            "value": "Narsipatnam"
        },
        {
            "label": "Nayudupet",
            "value": "Nayudupet"
        },
        {
            "label": "Nellore",
            "value": "Nellore"
        },
        {
            "label": "Nidadavole",
            "value": "Nidadavole"
        },
        {
            "label": "Nuzvid",
            "value": "Nuzvid"
        },
        {
            "label": "Ongole",
            "value": "Ongole"
        },
        {
            "label": "Pakala",
            "value": "Pakala"
        },
        {
            "label": "Palakollu",
            "value": "Palakollu"
        },
        {
            "label": "Palasa",
            "value": "Palasa"
        },
        {
            "label": "Palkonda",
            "value": "Palkonda"
        },
        {
            "label": "Pallevada",
            "value": "Pallevada"
        },
        {
            "label": "Palmaner",
            "value": "Palmaner"
        },
        {
            "label": "Parlakimidi",
            "value": "Parlakimidi"
        },
        {
            "label": "Parvatipuram",
            "value": "Parvatipuram"
        },
        {
            "label": "Pavuluru",
            "value": "Pavuluru"
        },
        {
            "label": "Pedana",
            "value": "Pedana"
        },
        {
            "label": "pedda nakkalapalem",
            "value": "pedda nakkalapalem"
        },
        {
            "label": "Peddapuram",
            "value": "Peddapuram"
        },
        {
            "label": "Penugonda",
            "value": "Penugonda"
        },
        {
            "label": "Penukonda",
            "value": "Penukonda"
        },
        {
            "label": "Phirangipuram",
            "value": "Phirangipuram"
        },
        {
            "label": "Pippara",
            "value": "Pippara"
        },
        {
            "label": "Pithapuram",
            "value": "Pithapuram"
        },
        {
            "label": "Polavaram",
            "value": "Polavaram"
        },
        {
            "label": "Ponnur",
            "value": "Ponnur"
        },
        {
            "label": "Ponnuru",
            "value": "Ponnuru"
        },
        {
            "label": "Prakasam",
            "value": "Prakasam"
        },
        {
            "label": "Proddatur",
            "value": "Proddatur"
        },
        {
            "label": "Pulivendla",
            "value": "Pulivendla"
        },
        {
            "label": "Punganuru",
            "value": "Punganuru"
        },
        {
            "label": "Puttaparthi",
            "value": "Puttaparthi"
        },
        {
            "label": "Puttur",
            "value": "Puttur"
        },
        {
            "label": "Rajahmundry",
            "value": "Rajahmundry"
        },
        {
            "label": "Ramachandrapuram",
            "value": "Ramachandrapuram"
        },
        {
            "label": "Ramanayyapeta",
            "value": "Ramanayyapeta"
        },
        {
            "label": "Ramapuram",
            "value": "Ramapuram"
        },
        {
            "label": "Rampachodavaram",
            "value": "Rampachodavaram"
        },
        {
            "label": "Rayachoti",
            "value": "Rayachoti"
        },
        {
            "label": "Rayadrug",
            "value": "Rayadrug"
        },
        {
            "label": "Razam",
            "value": "Razam"
        },
        {
            "label": "Razampeta",
            "value": "Razampeta"
        },
        {
            "label": "Razole",
            "value": "Razole"
        },
        {
            "label": "Renigunta",
            "value": "Renigunta"
        },
        {
            "label": "Repalle",
            "value": "Repalle"
        },
        {
            "label": "Salur",
            "value": "Salur"
        },
        {
            "label": "Samalkot",
            "value": "Samalkot"
        },
        {
            "label": "Sattenapalle",
            "value": "Sattenapalle"
        },
        {
            "label": "Singarayakonda",
            "value": "Singarayakonda"
        },
        {
            "label": "Sompeta",
            "value": "Sompeta"
        },
        {
            "label": "Srikakulam",
            "value": "Srikakulam"
        },
        {
            "label": "Srisailain",
            "value": "Srisailain"
        },
        {
            "label": "Suluru",
            "value": "Suluru"
        },
        {
            "label": "Tadepalle",
            "value": "Tadepalle"
        },
        {
            "label": "Tadepallegudem",
            "value": "Tadepallegudem"
        },
        {
            "label": "Tadpatri",
            "value": "Tadpatri"
        },
        {
            "label": "Tanuku",
            "value": "Tanuku"
        },
        {
            "label": "Tekkali",
            "value": "Tekkali"
        },
        {
            "label": "Tirumala",
            "value": "Tirumala"
        },
        {
            "label": "Tirupati",
            "value": "Tirupati"
        },
        {
            "label": "Tuni",
            "value": "Tuni"
        },
        {
            "label": "Uravakonda",
            "value": "Uravakonda"
        },
        {
            "label": "vadlamuru",
            "value": "vadlamuru"
        },
        {
            "label": "Vadlapudi",
            "value": "Vadlapudi"
        },
        {
            "label": "Venkatagiri",
            "value": "Venkatagiri"
        },
        {
            "label": "Vepagunta",
            "value": "Vepagunta"
        },
        {
            "label": "Vetapalem",
            "value": "Vetapalem"
        },
        {
            "label": "Vijayawada",
            "value": "Vijayawada"
        },
        {
            "label": "Vinukonda",
            "value": "Vinukonda"
        },
        {
            "label": "Visakhapatnam",
            "value": "Visakhapatnam"
        },
        {
            "label": "Vizianagaram",
            "value": "Vizianagaram"
        },
        {
            "label": "Vizianagaram District",
            "value": "Vizianagaram District"
        },
        {
            "label": "Vuyyuru",
            "value": "Vuyyuru"
        },
        {
            "label": "West Godavari",
            "value": "West Godavari"
        },
        {
            "label": "Yanam",
            "value": "Yanam"
        },
        {
            "label": "Yanamalakuduru",
            "value": "Yanamalakuduru"
        },
        {
            "label": "Yarada",
            "value": "Yarada"
        }
    ],
    "Arunachal Pradesh": [
        {
            "label": "Along",
            "value": "Along"
        },
        {
            "label": "Anjaw",
            "value": "Anjaw"
        },
        {
            "label": "Basar",
            "value": "Basar"
        },
        {
            "label": "Bomdila",
            "value": "Bomdila"
        },
        {
            "label": "Changlang",
            "value": "Changlang"
        },
        {
            "label": "Dibang Valley",
            "value": "Dibang Valley"
        },
        {
            "label": "East Kameng",
            "value": "East Kameng"
        },
        {
            "label": "East Siang",
            "value": "East Siang"
        },
        {
            "label": "Hayuliang",
            "value": "Hayuliang"
        },
        {
            "label": "Itanagar",
            "value": "Itanagar"
        },
        {
            "label": "Khonsa",
            "value": "Khonsa"
        },
        {
            "label": "Kurung Kumey",
            "value": "Kurung Kumey"
        },
        {
            "label": "Lohit District",
            "value": "Lohit District"
        },
        {
            "label": "Lower Dibang Valley",
            "value": "Lower Dibang Valley"
        },
        {
            "label": "Lower Subansiri",
            "value": "Lower Subansiri"
        },
        {
            "label": "Margherita",
            "value": "Margherita"
        },
        {
            "label": "Naharlagun",
            "value": "Naharlagun"
        },
        {
            "label": "Pasighat",
            "value": "Pasighat"
        },
        {
            "label": "Tawang",
            "value": "Tawang"
        },
        {
            "label": "Tezu",
            "value": "Tezu"
        },
        {
            "label": "Tirap",
            "value": "Tirap"
        },
        {
            "label": "Upper Siang",
            "value": "Upper Siang"
        },
        {
            "label": "Upper Subansiri",
            "value": "Upper Subansiri"
        },
        {
            "label": "West Kameng",
            "value": "West Kameng"
        },
        {
            "label": "West Siang",
            "value": "West Siang"
        },
        {
            "label": "Ziro",
            "value": "Ziro"
        }
    ],
    "Assam": [
        {
            "label": "Abhayapuri",
            "value": "Abhayapuri"
        },
        {
            "label": "Amguri",
            "value": "Amguri"
        },
        {
            "label": "Badarpur",
            "value": "Badarpur"
        },
        {
            "label": "Baksa",
            "value": "Baksa"
        },
        {
            "label": "Barpathar",
            "value": "Barpathar"
        },
        {
            "label": "Barpeta",
            "value": "Barpeta"
        },
        {
            "label": "Barpeta Road",
            "value": "Barpeta Road"
        },
        {
            "label": "Basugaon",
            "value": "Basugaon"
        },
        {
            "label": "Bihpuriagaon",
            "value": "Bihpuriagaon"
        },
        {
            "label": "Bijni",
            "value": "Bijni"
        },
        {
            "label": "Bilasipara",
            "value": "Bilasipara"
        },
        {
            "label": "Bokajan",
            "value": "Bokajan"
        },
        {
            "label": "Bokakhat",
            "value": "Bokakhat"
        },
        {
            "label": "Bongaigaon",
            "value": "Bongaigaon"
        },
        {
            "label": "Cachar",
            "value": "Cachar"
        },
        {
            "label": "Chabua",
            "value": "Chabua"
        },
        {
            "label": "Chapar",
            "value": "Chapar"
        },
        {
            "label": "Chirang",
            "value": "Chirang"
        },
        {
            "label": "Darrang",
            "value": "Darrang"
        },
        {
            "label": "Dergaon",
            "value": "Dergaon"
        },
        {
            "label": "Dhekiajuli",
            "value": "Dhekiajuli"
        },
        {
            "label": "Dhemaji",
            "value": "Dhemaji"
        },
        {
            "label": "Dhing",
            "value": "Dhing"
        },
        {
            "label": "Dhubri",
            "value": "Dhubri"
        },
        {
            "label": "Dibrugarh",
            "value": "Dibrugarh"
        },
        {
            "label": "Digboi",
            "value": "Digboi"
        },
        {
            "label": "Dima Hasao District",
            "value": "Dima Hasao District"
        },
        {
            "label": "Diphu",
            "value": "Diphu"
        },
        {
            "label": "Dispur",
            "value": "Dispur"
        },
        {
            "label": "Duliagaon",
            "value": "Duliagaon"
        },
        {
            "label": "Dum Duma",
            "value": "Dum Duma"
        },
        {
            "label": "Gauripur",
            "value": "Gauripur"
        },
        {
            "label": "Goalpara",
            "value": "Goalpara"
        },
        {
            "label": "Gohpur",
            "value": "Gohpur"
        },
        {
            "label": "Golaghat",
            "value": "Golaghat"
        },
        {
            "label": "Golakganj",
            "value": "Golakganj"
        },
        {
            "label": "Goshaingaon",
            "value": "Goshaingaon"
        },
        {
            "label": "Guwahati",
            "value": "Guwahati"
        },
        {
            "label": "Haflong",
            "value": "Haflong"
        },
        {
            "label": "Hailakandi",
            "value": "Hailakandi"
        },
        {
            "label": "Hajo",
            "value": "Hajo"
        },
        {
            "label": "Hojai",
            "value": "Hojai"
        },
        {
            "label": "Howli",
            "value": "Howli"
        },
        {
            "label": "Jogighopa",
            "value": "Jogighopa"
        },
        {
            "label": "Jorhat",
            "value": "Jorhat"
        },
        {
            "label": "Kamrup",
            "value": "Kamrup"
        },
        {
            "label": "Kamrup Metropolitan",
            "value": "Kamrup Metropolitan"
        },
        {
            "label": "Karbi Anglong",
            "value": "Karbi Anglong"
        },
        {
            "label": "Karimganj",
            "value": "Karimganj"
        },
        {
            "label": "Kharupatia",
            "value": "Kharupatia"
        },
        {
            "label": "Kokrajhar",
            "value": "Kokrajhar"
        },
        {
            "label": "Lakhimpur",
            "value": "Lakhimpur"
        },
        {
            "label": "Lakhipur",
            "value": "Lakhipur"
        },
        {
            "label": "Lala",
            "value": "Lala"
        },
        {
            "label": "Lumding Railway Colony",
            "value": "Lumding Railway Colony"
        },
        {
            "label": "Mahur",
            "value": "Mahur"
        },
        {
            "label": "Maibong",
            "value": "Maibong"
        },
        {
            "label": "Makum",
            "value": "Makum"
        },
        {
            "label": "Mangaldai",
            "value": "Mangaldai"
        },
        {
            "label": "Mariani",
            "value": "Mariani"
        },
        {
            "label": "Moranha",
            "value": "Moranha"
        },
        {
            "label": "Morigaon",
            "value": "Morigaon"
        },
        {
            "label": "Nagaon",
            "value": "Nagaon"
        },
        {
            "label": "Nahorkatiya",
            "value": "Nahorkatiya"
        },
        {
            "label": "Nalbari",
            "value": "Nalbari"
        },
        {
            "label": "Namrup",
            "value": "Namrup"
        },
        {
            "label": "Nazira",
            "value": "Nazira"
        },
        {
            "label": "North Guwahati",
            "value": "North Guwahati"
        },
        {
            "label": "North Lakhimpur",
            "value": "North Lakhimpur"
        },
        {
            "label": "Numaligarh",
            "value": "Numaligarh"
        },
        {
            "label": "Palasbari",
            "value": "Palasbari"
        },
        {
            "label": "Raha",
            "value": "Raha"
        },
        {
            "label": "Rangapara",
            "value": "Rangapara"
        },
        {
            "label": "Rangia",
            "value": "Rangia"
        },
        {
            "label": "Sapatgram",
            "value": "Sapatgram"
        },
        {
            "label": "Sarupathar",
            "value": "Sarupathar"
        },
        {
            "label": "Sibsagar",
            "value": "Sibsagar"
        },
        {
            "label": "Silapathar",
            "value": "Silapathar"
        },
        {
            "label": "Silchar",
            "value": "Silchar"
        },
        {
            "label": "Soalkuchi",
            "value": "Soalkuchi"
        },
        {
            "label": "Sonari",
            "value": "Sonari"
        },
        {
            "label": "Sonitpur",
            "value": "Sonitpur"
        },
        {
            "label": "Sorbhog",
            "value": "Sorbhog"
        },
        {
            "label": "Tezpur",
            "value": "Tezpur"
        },
        {
            "label": "Tinsukia",
            "value": "Tinsukia"
        },
        {
            "label": "Titabar",
            "value": "Titabar"
        },
        {
            "label": "Udalguri",
            "value": "Udalguri"
        }
    ],
    "Bihar": [
        {
            "label": "Amarpur",
            "value": "Amarpur"
        },
        {
            "label": "Araria",
            "value": "Araria"
        },
        {
            "label": "Arrah",
            "value": "Arrah"
        },
        {
            "label": "Arwal",
            "value": "Arwal"
        },
        {
            "label": "Asarganj",
            "value": "Asarganj"
        },
        {
            "label": "Aurangabad",
            "value": "Aurangabad"
        },
        {
            "label": "Bagaha",
            "value": "Bagaha"
        },
        {
            "label": "Bahadurganj",
            "value": "Bahadurganj"
        },
        {
            "label": "Bairagnia",
            "value": "Bairagnia"
        },
        {
            "label": "Baisi",
            "value": "Baisi"
        },
        {
            "label": "Bakhtiyarpur",
            "value": "Bakhtiyarpur"
        },
        {
            "label": "Bangaon",
            "value": "Bangaon"
        },
        {
            "label": "Banka",
            "value": "Banka"
        },
        {
            "label": "Banmankhi",
            "value": "Banmankhi"
        },
        {
            "label": "Bar Bigha",
            "value": "Bar Bigha"
        },
        {
            "label": "Barauli",
            "value": "Barauli"
        },
        {
            "label": "Barh",
            "value": "Barh"
        },
        {
            "label": "Barhiya",
            "value": "Barhiya"
        },
        {
            "label": "Bariarpur",
            "value": "Bariarpur"
        },
        {
            "label": "Baruni",
            "value": "Baruni"
        },
        {
            "label": "Begusarai",
            "value": "Begusarai"
        },
        {
            "label": "Belsand",
            "value": "Belsand"
        },
        {
            "label": "Bettiah",
            "value": "Bettiah"
        },
        {
            "label": "Bhabhua",
            "value": "Bhabhua"
        },
        {
            "label": "Bhagalpur",
            "value": "Bhagalpur"
        },
        {
            "label": "Bhagirathpur",
            "value": "Bhagirathpur"
        },
        {
            "label": "Bhawanipur",
            "value": "Bhawanipur"
        },
        {
            "label": "Bhojpur",
            "value": "Bhojpur"
        },
        {
            "label": "Bihar Sharif",
            "value": "Bihar Sharif"
        },
        {
            "label": "Bihariganj",
            "value": "Bihariganj"
        },
        {
            "label": "Bikramganj",
            "value": "Bikramganj"
        },
        {
            "label": "Birpur",
            "value": "Birpur"
        },
        {
            "label": "Buddh Gaya",
            "value": "Buddh Gaya"
        },
        {
            "label": "Buxar",
            "value": "Buxar"
        },
        {
            "label": "Chakia",
            "value": "Chakia"
        },
        {
            "label": "Chapra",
            "value": "Chapra"
        },
        {
            "label": "Chhatapur",
            "value": "Chhatapur"
        },
        {
            "label": "Colgong",
            "value": "Colgong"
        },
        {
            "label": "Dalsingh Sarai",
            "value": "Dalsingh Sarai"
        },
        {
            "label": "Darbhanga",
            "value": "Darbhanga"
        },
        {
            "label": "Daudnagar",
            "value": "Daudnagar"
        },
        {
            "label": "Dehri",
            "value": "Dehri"
        },
        {
            "label": "Dhaka",
            "value": "Dhaka"
        },
        {
            "label": "Dighwara",
            "value": "Dighwara"
        },
        {
            "label": "Dinapore",
            "value": "Dinapore"
        },
        {
            "label": "Dumra",
            "value": "Dumra"
        },
        {
            "label": "Dumraon",
            "value": "Dumraon"
        },
        {
            "label": "Fatwa",
            "value": "Fatwa"
        },
        {
            "label": "Forbesganj",
            "value": "Forbesganj"
        },
        {
            "label": "Gaya",
            "value": "Gaya"
        },
        {
            "label": "Ghoga",
            "value": "Ghoga"
        },
        {
            "label": "Gopalganj",
            "value": "Gopalganj"
        },
        {
            "label": "Hajipur",
            "value": "Hajipur"
        },
        {
            "label": "Hilsa",
            "value": "Hilsa"
        },
        {
            "label": "Hisua",
            "value": "Hisua"
        },
        {
            "label": "Islampur",
            "value": "Islampur"
        },
        {
            "label": "Jagdispur",
            "value": "Jagdispur"
        },
        {
            "label": "Jahanabad",
            "value": "Jahanabad"
        },
        {
            "label": "Jamalpur",
            "value": "Jamalpur"
        },
        {
            "label": "Jamui",
            "value": "Jamui"
        },
        {
            "label": "Jaynagar",
            "value": "Jaynagar"
        },
        {
            "label": "Jehanabad",
            "value": "Jehanabad"
        },
        {
            "label": "Jha-Jha",
            "value": "Jha-Jha"
        },
        {
            "label": "Jhanjharpur",
            "value": "Jhanjharpur"
        },
        {
            "label": "Jogbani",
            "value": "Jogbani"
        },
        {
            "label": "Kaimur District",
            "value": "Kaimur District"
        },
        {
            "label": "Kasba",
            "value": "Kasba"
        },
        {
            "label": "Katihar",
            "value": "Katihar"
        },
        {
            "label": "Khagaria",
            "value": "Khagaria"
        },
        {
            "label": "Khagaul",
            "value": "Khagaul"
        },
        {
            "label": "Kharagpur",
            "value": "Kharagpur"
        },
        {
            "label": "Khusropur",
            "value": "Khusropur"
        },
        {
            "label": "Kishanganj",
            "value": "Kishanganj"
        },
        {
            "label": "Koath",
            "value": "Koath"
        },
        {
            "label": "Koelwar",
            "value": "Koelwar"
        },
        {
            "label": "Lakhisarai",
            "value": "Lakhisarai"
        },
        {
            "label": "Lalganj",
            "value": "Lalganj"
        },
        {
            "label": "Luckeesarai",
            "value": "Luckeesarai"
        },
        {
            "label": "Madhepura",
            "value": "Madhepura"
        },
        {
            "label": "Madhubani",
            "value": "Madhubani"
        },
        {
            "label": "Maharajgani",
            "value": "Maharajgani"
        },
        {
            "label": "Mairwa",
            "value": "Mairwa"
        },
        {
            "label": "Maner",
            "value": "Maner"
        },
        {
            "label": "Manihari",
            "value": "Manihari"
        },
        {
            "label": "Marhaura",
            "value": "Marhaura"
        },
        {
            "label": "Masaurhi Buzurg",
            "value": "Masaurhi Buzurg"
        },
        {
            "label": "Mohiuddi nagar",
            "value": "Mohiuddi nagar"
        },
        {
            "label": "Mokameh",
            "value": "Mokameh"
        },
        {
            "label": "Monghyr",
            "value": "Monghyr"
        },
        {
            "label": "Mothihari",
            "value": "Mothihari"
        },
        {
            "label": "Munger",
            "value": "Munger"
        },
        {
            "label": "Murliganj",
            "value": "Murliganj"
        },
        {
            "label": "Muzaffarpur",
            "value": "Muzaffarpur"
        },
        {
            "label": "Nabinagar",
            "value": "Nabinagar"
        },
        {
            "label": "Nalanda",
            "value": "Nalanda"
        },
        {
            "label": "Nasriganj",
            "value": "Nasriganj"
        },
        {
            "label": "Naugachhia",
            "value": "Naugachhia"
        },
        {
            "label": "Nawada",
            "value": "Nawada"
        },
        {
            "label": "Nirmali",
            "value": "Nirmali"
        },
        {
            "label": "Pashchim Champaran",
            "value": "Pashchim Champaran"
        },
        {
            "label": "Patna",
            "value": "Patna"
        },
        {
            "label": "Piro",
            "value": "Piro"
        },
        {
            "label": "Pupri",
            "value": "Pupri"
        },
        {
            "label": "Purba Champaran",
            "value": "Purba Champaran"
        },
        {
            "label": "Purnia",
            "value": "Purnia"
        },
        {
            "label": "Rafiganj",
            "value": "Rafiganj"
        },
        {
            "label": "Raghunathpur",
            "value": "Raghunathpur"
        },
        {
            "label": "Rajgir",
            "value": "Rajgir"
        },
        {
            "label": "Ramnagar",
            "value": "Ramnagar"
        },
        {
            "label": "Raxaul",
            "value": "Raxaul"
        },
        {
            "label": "Revelganj",
            "value": "Revelganj"
        },
        {
            "label": "Rohtas",
            "value": "Rohtas"
        },
        {
            "label": "Rusera",
            "value": "Rusera"
        },
        {
            "label": "Sagauli",
            "value": "Sagauli"
        },
        {
            "label": "Saharsa",
            "value": "Saharsa"
        },
        {
            "label": "Samastipur",
            "value": "Samastipur"
        },
        {
            "label": "Saran",
            "value": "Saran"
        },
        {
            "label": "Shahbazpur",
            "value": "Shahbazpur"
        },
        {
            "label": "Shahpur",
            "value": "Shahpur"
        },
        {
            "label": "Sheikhpura",
            "value": "Sheikhpura"
        },
        {
            "label": "Sheohar",
            "value": "Sheohar"
        },
        {
            "label": "Sherghati",
            "value": "Sherghati"
        },
        {
            "label": "Silao",
            "value": "Silao"
        },
        {
            "label": "Sitamarhi",
            "value": "Sitamarhi"
        },
        {
            "label": "Siwan",
            "value": "Siwan"
        },
        {
            "label": "Supaul",
            "value": "Supaul"
        },
        {
            "label": "Teghra",
            "value": "Teghra"
        },
        {
            "label": "Tekari",
            "value": "Tekari"
        },
        {
            "label": "Thakurganj",
            "value": "Thakurganj"
        },
        {
            "label": "Vaishali",
            "value": "Vaishali"
        },
        {
            "label": "Waris Aliganj",
            "value": "Waris Aliganj"
        }
    ],
    "Chandigarh": [
        {
            "label": "Chandigarh",
            "value": "Chandigarh"
        }
    ],
    "Chhattisgarh": [
        {
            "label": "Akaltara",
            "value": "Akaltara"
        },
        {
            "label": "Ambagarh Chauki",
            "value": "Ambagarh Chauki"
        },
        {
            "label": "Ambikapur",
            "value": "Ambikapur"
        },
        {
            "label": "Arang",
            "value": "Arang"
        },
        {
            "label": "Baikunthpur",
            "value": "Baikunthpur"
        },
        {
            "label": "Balod",
            "value": "Balod"
        },
        {
            "label": "Baloda",
            "value": "Baloda"
        },
        {
            "label": "Baloda Bazar",
            "value": "Baloda Bazar"
        },
        {
            "label": "Basna",
            "value": "Basna"
        },
        {
            "label": "Bastar",
            "value": "Bastar"
        },
        {
            "label": "Bemetara",
            "value": "Bemetara"
        },
        {
            "label": "Bhanpuri",
            "value": "Bhanpuri"
        },
        {
            "label": "Bhatapara",
            "value": "Bhatapara"
        },
        {
            "label": "Bhatgaon",
            "value": "Bhatgaon"
        },
        {
            "label": "Bhilai",
            "value": "Bhilai"
        },
        {
            "label": "Bijapur",
            "value": "Bijapur"
        },
        {
            "label": "Bilaspur",
            "value": "Bilaspur"
        },
        {
            "label": "Champa",
            "value": "Champa"
        },
        {
            "label": "Chhuikhadan",
            "value": "Chhuikhadan"
        },
        {
            "label": "Dantewada",
            "value": "Dantewada"
        },
        {
            "label": "Deori",
            "value": "Deori"
        },
        {
            "label": "Dhamtari",
            "value": "Dhamtari"
        },
        {
            "label": "Dongargaon",
            "value": "Dongargaon"
        },
        {
            "label": "Dongargarh",
            "value": "Dongargarh"
        },
        {
            "label": "Durg",
            "value": "Durg"
        },
        {
            "label": "Gandai",
            "value": "Gandai"
        },
        {
            "label": "Gariaband",
            "value": "Gariaband"
        },
        {
            "label": "Gaurela",
            "value": "Gaurela"
        },
        {
            "label": "Gharghoda",
            "value": "Gharghoda"
        },
        {
            "label": "Gidam",
            "value": "Gidam"
        },
        {
            "label": "Jagdalpur",
            "value": "Jagdalpur"
        },
        {
            "label": "Janjgir",
            "value": "Janjgir"
        },
        {
            "label": "Janjgir-Champa",
            "value": "Janjgir-Champa"
        },
        {
            "label": "Jashpur",
            "value": "Jashpur"
        },
        {
            "label": "Jashpurnagar",
            "value": "Jashpurnagar"
        },
        {
            "label": "Junagarh",
            "value": "Junagarh"
        },
        {
            "label": "Kabeerdham",
            "value": "Kabeerdham"
        },
        {
            "label": "Kanker",
            "value": "Kanker"
        },
        {
            "label": "Katghora",
            "value": "Katghora"
        },
        {
            "label": "Kawardha",
            "value": "Kawardha"
        },
        {
            "label": "Khairagarh",
            "value": "Khairagarh"
        },
        {
            "label": "Khamharia",
            "value": "Khamharia"
        },
        {
            "label": "Kharod",
            "value": "Kharod"
        },
        {
            "label": "Kharsia",
            "value": "Kharsia"
        },
        {
            "label": "Kirandul",
            "value": "Kirandul"
        },
        {
            "label": "Kondagaon",
            "value": "Kondagaon"
        },
        {
            "label": "Korba",
            "value": "Korba"
        },
        {
            "label": "Koria",
            "value": "Koria"
        },
        {
            "label": "Kota",
            "value": "Kota"
        },
        {
            "label": "Kotaparh",
            "value": "Kotaparh"
        },
        {
            "label": "Kumhari",
            "value": "Kumhari"
        },
        {
            "label": "Kurud",
            "value": "Kurud"
        },
        {
            "label": "Lormi",
            "value": "Lormi"
        },
        {
            "label": "Mahasamund",
            "value": "Mahasamund"
        },
        {
            "label": "Mungeli",
            "value": "Mungeli"
        },
        {
            "label": "Narayanpur",
            "value": "Narayanpur"
        },
        {
            "label": "Narharpur",
            "value": "Narharpur"
        },
        {
            "label": "Pandaria",
            "value": "Pandaria"
        },
        {
            "label": "Pandatarai",
            "value": "Pandatarai"
        },
        {
            "label": "Pasan",
            "value": "Pasan"
        },
        {
            "label": "Patan",
            "value": "Patan"
        },
        {
            "label": "Pathalgaon",
            "value": "Pathalgaon"
        },
        {
            "label": "Pendra",
            "value": "Pendra"
        },
        {
            "label": "Pithora",
            "value": "Pithora"
        },
        {
            "label": "Raigarh",
            "value": "Raigarh"
        },
        {
            "label": "Raipur",
            "value": "Raipur"
        },
        {
            "label": "Raj Nandgaon",
            "value": "Raj Nandgaon"
        },
        {
            "label": "Ramanuj Ganj",
            "value": "Ramanuj Ganj"
        },
        {
            "label": "Ratanpur",
            "value": "Ratanpur"
        },
        {
            "label": "Sakti",
            "value": "Sakti"
        },
        {
            "label": "Saraipali",
            "value": "Saraipali"
        },
        {
            "label": "Sarangarh",
            "value": "Sarangarh"
        },
        {
            "label": "Seorinarayan",
            "value": "Seorinarayan"
        },
        {
            "label": "Simga",
            "value": "Simga"
        },
        {
            "label": "Surguja",
            "value": "Surguja"
        },
        {
            "label": "Takhatpur",
            "value": "Takhatpur"
        },
        {
            "label": "Umarkot",
            "value": "Umarkot"
        },
        {
            "label": "Uttar Bastar Kanker",
            "value": "Uttar Bastar Kanker"
        }
    ],
    "Dadra and Nagar Haveli and Daman and Diu": [
        {
            "label": "Amli",
            "value": "Amli"
        },
        {
            "label": "Dadra",
            "value": "Dadra"
        },
        {
            "label": "Dadra & Nagar Haveli",
            "value": "Dadra & Nagar Haveli"
        },
        {
            "label": "Daman",
            "value": "Daman"
        },
        {
            "label": "Diu",
            "value": "Diu"
        },
        {
            "label": "Silvassa",
            "value": "Silvassa"
        }
    ],
    "Delhi": [
        {
            "label": "Alipur",
            "value": "Alipur"
        },
        {
            "label": "Bawana",
            "value": "Bawana"
        },
        {
            "label": "Central Delhi",
            "value": "Central Delhi"
        },
        {
            "label": "Delhi",
            "value": "Delhi"
        },
        {
            "label": "Deoli",
            "value": "Deoli"
        },
        {
            "label": "East Delhi",
            "value": "East Delhi"
        },
        {
            "label": "Karol Bagh",
            "value": "Karol Bagh"
        },
        {
            "label": "Najafgarh",
            "value": "Najafgarh"
        },
        {
            "label": "Nangloi Jat",
            "value": "Nangloi Jat"
        },
        {
            "label": "Narela",
            "value": "Narela"
        },
        {
            "label": "New Delhi",
            "value": "New Delhi"
        },
        {
            "label": "North Delhi",
            "value": "North Delhi"
        },
        {
            "label": "North East Delhi",
            "value": "North East Delhi"
        },
        {
            "label": "North West Delhi",
            "value": "North West Delhi"
        },
        {
            "label": "Pitampura",
            "value": "Pitampura"
        },
        {
            "label": "Rohini",
            "value": "Rohini"
        },
        {
            "label": "South Delhi",
            "value": "South Delhi"
        },
        {
            "label": "South West Delhi",
            "value": "South West Delhi"
        },
        {
            "label": "West Delhi",
            "value": "West Delhi"
        }
    ],
    "Goa": [
        {
            "label": "Aldona",
            "value": "Aldona"
        },
        {
            "label": "Arambol",
            "value": "Arambol"
        },
        {
            "label": "Baga",
            "value": "Baga"
        },
        {
            "label": "Bambolim",
            "value": "Bambolim"
        },
        {
            "label": "Bandora",
            "value": "Bandora"
        },
        {
            "label": "Benaulim",
            "value": "Benaulim"
        },
        {
            "label": "Calangute",
            "value": "Calangute"
        },
        {
            "label": "Candolim",
            "value": "Candolim"
        },
        {
            "label": "Carapur",
            "value": "Carapur"
        },
        {
            "label": "Cavelossim",
            "value": "Cavelossim"
        },
        {
            "label": "Chicalim",
            "value": "Chicalim"
        },
        {
            "label": "Chinchinim",
            "value": "Chinchinim"
        },
        {
            "label": "Colovale",
            "value": "Colovale"
        },
        {
            "label": "Colva",
            "value": "Colva"
        },
        {
            "label": "Cortalim",
            "value": "Cortalim"
        },
        {
            "label": "Cuncolim",
            "value": "Cuncolim"
        },
        {
            "label": "Curchorem",
            "value": "Curchorem"
        },
        {
            "label": "Curti",
            "value": "Curti"
        },
        {
            "label": "Davorlim",
            "value": "Davorlim"
        },
        {
            "label": "Dicholi",
            "value": "Dicholi"
        },
        {
            "label": "Goa Velha",
            "value": "Goa Velha"
        },
        {
            "label": "Guirim",
            "value": "Guirim"
        },
        {
            "label": "Jua",
            "value": "Jua"
        },
        {
            "label": "Kankon",
            "value": "Kankon"
        },
        {
            "label": "Madgaon",
            "value": "Madgaon"
        },
        {
            "label": "Mapuca",
            "value": "Mapuca"
        },
        {
            "label": "Morjim",
            "value": "Morjim"
        },
        {
            "label": "Mormugao",
            "value": "Mormugao"
        },
        {
            "label": "Navelim",
            "value": "Navelim"
        },
        {
            "label": "North Goa",
            "value": "North Goa"
        },
        {
            "label": "Palle",
            "value": "Palle"
        },
        {
            "label": "Panaji",
            "value": "Panaji"
        },
        {
            "label": "Pernem",
            "value": "Pernem"
        },
        {
            "label": "Ponda",
            "value": "Ponda"
        },
        {
            "label": "Quepem",
            "value": "Quepem"
        },
        {
            "label": "Queula",
            "value": "Queula"
        },
        {
            "label": "Raia",
            "value": "Raia"
        },
        {
            "label": "Saligao",
            "value": "Saligao"
        },
        {
            "label": "Sancoale",
            "value": "Sancoale"
        },
        {
            "label": "Sanguem",
            "value": "Sanguem"
        },
        {
            "label": "Sanquelim",
            "value": "Sanquelim"
        },
        {
            "label": "Sanvordem",
            "value": "Sanvordem"
        },
        {
            "label": "Serula",
            "value": "Serula"
        },
        {
            "label": "Solim",
            "value": "Solim"
        },
        {
            "label": "South Goa",
            "value": "South Goa"
        },
        {
            "label": "Taleigao",
            "value": "Taleigao"
        },
        {
            "label": "Vagator",
            "value": "Vagator"
        },
        {
            "label": "Valpoy",
            "value": "Valpoy"
        },
        {
            "label": "Varca",
            "value": "Varca"
        },
        {
            "label": "Vasco da Gama",
            "value": "Vasco da Gama"
        }
    ],
    "Gujarat": [
        {
            "label": "Abrama",
            "value": "Abrama"
        },
        {
            "label": "Adalaj",
            "value": "Adalaj"
        },
        {
            "label": "Agol",
            "value": "Agol"
        },
        {
            "label": "Ahmedabad",
            "value": "Ahmedabad"
        },
        {
            "label": "Ahwa",
            "value": "Ahwa"
        },
        {
            "label": "Akrund",
            "value": "Akrund"
        },
        {
            "label": "Amod",
            "value": "Amod"
        },
        {
            "label": "Amreli",
            "value": "Amreli"
        },
        {
            "label": "Amroli",
            "value": "Amroli"
        },
        {
            "label": "Anand",
            "value": "Anand"
        },
        {
            "label": "Anjar",
            "value": "Anjar"
        },
        {
            "label": "Ankleshwar",
            "value": "Ankleshwar"
        },
        {
            "label": "Babra",
            "value": "Babra"
        },
        {
            "label": "Bagasara",
            "value": "Bagasara"
        },
        {
            "label": "Bagasra",
            "value": "Bagasra"
        },
        {
            "label": "Bakharla",
            "value": "Bakharla"
        },
        {
            "label": "Balagam",
            "value": "Balagam"
        },
        {
            "label": "Balasinor",
            "value": "Balasinor"
        },
        {
            "label": "Balisana",
            "value": "Balisana"
        },
        {
            "label": "Bamanbore",
            "value": "Bamanbore"
        },
        {
            "label": "Banas Kantha",
            "value": "Banas Kantha"
        },
        {
            "label": "Bandia",
            "value": "Bandia"
        },
        {
            "label": "Bantva",
            "value": "Bantva"
        },
        {
            "label": "Bardoli",
            "value": "Bardoli"
        },
        {
            "label": "Bedi",
            "value": "Bedi"
        },
        {
            "label": "Bhachau",
            "value": "Bhachau"
        },
        {
            "label": "Bhadran",
            "value": "Bhadran"
        },
        {
            "label": "Bhandu",
            "value": "Bhandu"
        },
        {
            "label": "Bhanvad",
            "value": "Bhanvad"
        },
        {
            "label": "Bharuch",
            "value": "Bharuch"
        },
        {
            "label": "Bhatha",
            "value": "Bhatha"
        },
        {
            "label": "Bhavnagar",
            "value": "Bhavnagar"
        },
        {
            "label": "Bhayavadar",
            "value": "Bhayavadar"
        },
        {
            "label": "Bhildi",
            "value": "Bhildi"
        },
        {
            "label": "Bhojpur Dharampur",
            "value": "Bhojpur Dharampur"
        },
        {
            "label": "Bhuj",
            "value": "Bhuj"
        },
        {
            "label": "Bilimora",
            "value": "Bilimora"
        },
        {
            "label": "Bilkha",
            "value": "Bilkha"
        },
        {
            "label": "Borsad",
            "value": "Borsad"
        },
        {
            "label": "Botad",
            "value": "Botad"
        },
        {
            "label": "Chaklasi",
            "value": "Chaklasi"
        },
        {
            "label": "Chalala",
            "value": "Chalala"
        },
        {
            "label": "Chaloda",
            "value": "Chaloda"
        },
        {
            "label": "Champaner",
            "value": "Champaner"
        },
        {
            "label": "Chanasma",
            "value": "Chanasma"
        },
        {
            "label": "Chhala",
            "value": "Chhala"
        },
        {
            "label": "Chhota Udepur",
            "value": "Chhota Udepur"
        },
        {
            "label": "Chikhli",
            "value": "Chikhli"
        },
        {
            "label": "Chotila",
            "value": "Chotila"
        },
        {
            "label": "Chuda",
            "value": "Chuda"
        },
        {
            "label": "Dabhoda",
            "value": "Dabhoda"
        },
        {
            "label": "Dabhoi",
            "value": "Dabhoi"
        },
        {
            "label": "Dahegam",
            "value": "Dahegam"
        },
        {
            "label": "Dahod",
            "value": "Dahod"
        },
        {
            "label": "Dakor",
            "value": "Dakor"
        },
        {
            "label": "Damnagar",
            "value": "Damnagar"
        },
        {
            "label": "Dandi",
            "value": "Dandi"
        },
        {
            "label": "Dangs (India)",
            "value": "Dangs (India)"
        },
        {
            "label": "Danta",
            "value": "Danta"
        },
        {
            "label": "Dayapar",
            "value": "Dayapar"
        },
        {
            "label": "Delvada",
            "value": "Delvada"
        },
        {
            "label": "Delwada",
            "value": "Delwada"
        },
        {
            "label": "Detroj",
            "value": "Detroj"
        },
        {
            "label": "Devbhumi Dwarka",
            "value": "Devbhumi Dwarka"
        },
        {
            "label": "Devgadh Bariya",
            "value": "Devgadh Bariya"
        },
        {
            "label": "Dhandhuka",
            "value": "Dhandhuka"
        },
        {
            "label": "Dhanera",
            "value": "Dhanera"
        },
        {
            "label": "Dhansura",
            "value": "Dhansura"
        },
        {
            "label": "Dharampur",
            "value": "Dharampur"
        },
        {
            "label": "Dharasana",
            "value": "Dharasana"
        },
        {
            "label": "Dhari",
            "value": "Dhari"
        },
        {
            "label": "Dhasa",
            "value": "Dhasa"
        },
        {
            "label": "Dhola",
            "value": "Dhola"
        },
        {
            "label": "Dholera",
            "value": "Dholera"
        },
        {
            "label": "Dholka",
            "value": "Dholka"
        },
        {
            "label": "Dhoraji",
            "value": "Dhoraji"
        },
        {
            "label": "Dhrangadhra",
            "value": "Dhrangadhra"
        },
        {
            "label": "Dhrol",
            "value": "Dhrol"
        },
        {
            "label": "Dhuwaran",
            "value": "Dhuwaran"
        },
        {
            "label": "Disa",
            "value": "Disa"
        },
        {
            "label": "Dohad",
            "value": "Dohad"
        },
        {
            "label": "Dumkhal",
            "value": "Dumkhal"
        },
        {
            "label": "Dungarpur",
            "value": "Dungarpur"
        },
        {
            "label": "Dwarka",
            "value": "Dwarka"
        },
        {
            "label": "Gadhada",
            "value": "Gadhada"
        },
        {
            "label": "Gandevi",
            "value": "Gandevi"
        },
        {
            "label": "Gandhidham",
            "value": "Gandhidham"
        },
        {
            "label": "Gandhinagar",
            "value": "Gandhinagar"
        },
        {
            "label": "Gariadhar",
            "value": "Gariadhar"
        },
        {
            "label": "Ghodasar",
            "value": "Ghodasar"
        },
        {
            "label": "Ghogha",
            "value": "Ghogha"
        },
        {
            "label": "Gir Somnath",
            "value": "Gir Somnath"
        },
        {
            "label": "Godhra",
            "value": "Godhra"
        },
        {
            "label": "Gondal",
            "value": "Gondal"
        },
        {
            "label": "Gorwa",
            "value": "Gorwa"
        },
        {
            "label": "Halenda",
            "value": "Halenda"
        },
        {
            "label": "Halol",
            "value": "Halol"
        },
        {
            "label": "Halvad",
            "value": "Halvad"
        },
        {
            "label": "Hansot",
            "value": "Hansot"
        },
        {
            "label": "Harij",
            "value": "Harij"
        },
        {
            "label": "Harsol",
            "value": "Harsol"
        },
        {
            "label": "Hathuran",
            "value": "Hathuran"
        },
        {
            "label": "Himatnagar",
            "value": "Himatnagar"
        },
        {
            "label": "Idar",
            "value": "Idar"
        },
        {
            "label": "Jakhau",
            "value": "Jakhau"
        },
        {
            "label": "Jalalpore",
            "value": "Jalalpore"
        },
        {
            "label": "Jalalpur",
            "value": "Jalalpur"
        },
        {
            "label": "Jalia",
            "value": "Jalia"
        },
        {
            "label": "Jambuda",
            "value": "Jambuda"
        },
        {
            "label": "Jambusar",
            "value": "Jambusar"
        },
        {
            "label": "Jamnagar",
            "value": "Jamnagar"
        },
        {
            "label": "Jarod",
            "value": "Jarod"
        },
        {
            "label": "Jasdan",
            "value": "Jasdan"
        },
        {
            "label": "Jetalpur",
            "value": "Jetalpur"
        },
        {
            "label": "Jetalsar",
            "value": "Jetalsar"
        },
        {
            "label": "Jetpur",
            "value": "Jetpur"
        },
        {
            "label": "Jetpur (Navagadh)",
            "value": "Jetpur (Navagadh)"
        },
        {
            "label": "Jhalod",
            "value": "Jhalod"
        },
        {
            "label": "Jhulasan",
            "value": "Jhulasan"
        },
        {
            "label": "Jodhpur",
            "value": "Jodhpur"
        },
        {
            "label": "Jodhpur (Ahmedabad)",
            "value": "Jodhpur (Ahmedabad)"
        },
        {
            "label": "Jodia",
            "value": "Jodia"
        },
        {
            "label": "Jodiya Bandar",
            "value": "Jodiya Bandar"
        },
        {
            "label": "Junagadh",
            "value": "Junagadh"
        },
        {
            "label": "Kachchh",
            "value": "Kachchh"
        },
        {
            "label": "Kachholi",
            "value": "Kachholi"
        },
        {
            "label": "Kadi",
            "value": "Kadi"
        },
        {
            "label": "Kadod",
            "value": "Kadod"
        },
        {
            "label": "Kalavad",
            "value": "Kalavad"
        },
        {
            "label": "Kalol",
            "value": "Kalol"
        },
        {
            "label": "Kandla",
            "value": "Kandla"
        },
        {
            "label": "Kandla port",
            "value": "Kandla port"
        },
        {
            "label": "Kanodar",
            "value": "Kanodar"
        },
        {
            "label": "Kapadvanj",
            "value": "Kapadvanj"
        },
        {
            "label": "Karamsad",
            "value": "Karamsad"
        },
        {
            "label": "Kariana",
            "value": "Kariana"
        },
        {
            "label": "Karjan",
            "value": "Karjan"
        },
        {
            "label": "Kathor",
            "value": "Kathor"
        },
        {
            "label": "Katpur",
            "value": "Katpur"
        },
        {
            "label": "Kawant",
            "value": "Kawant"
        },
        {
            "label": "Kayavarohan",
            "value": "Kayavarohan"
        },
        {
            "label": "Kerwada",
            "value": "Kerwada"
        },
        {
            "label": "Keshod",
            "value": "Keshod"
        },
        {
            "label": "Khambhalia",
            "value": "Khambhalia"
        },
        {
            "label": "Khambhat",
            "value": "Khambhat"
        },
        {
            "label": "Khavda",
            "value": "Khavda"
        },
        {
            "label": "Kheda",
            "value": "Kheda"
        },
        {
            "label": "Khedbrahma",
            "value": "Khedbrahma"
        },
        {
            "label": "Khedoi",
            "value": "Khedoi"
        },
        {
            "label": "Kherali",
            "value": "Kherali"
        },
        {
            "label": "Kheralu",
            "value": "Kheralu"
        },
        {
            "label": "Kodinar",
            "value": "Kodinar"
        },
        {
            "label": "Kosamba",
            "value": "Kosamba"
        },
        {
            "label": "Kothara",
            "value": "Kothara"
        },
        {
            "label": "Kotharia",
            "value": "Kotharia"
        },
        {
            "label": "Kukarmunda",
            "value": "Kukarmunda"
        },
        {
            "label": "Kukma",
            "value": "Kukma"
        },
        {
            "label": "Kundla",
            "value": "Kundla"
        },
        {
            "label": "Kutch district",
            "value": "Kutch district"
        },
        {
            "label": "Kutiyana",
            "value": "Kutiyana"
        },
        {
            "label": "Ladol",
            "value": "Ladol"
        },
        {
            "label": "Lakhpat",
            "value": "Lakhpat"
        },
        {
            "label": "Lakhtar",
            "value": "Lakhtar"
        },
        {
            "label": "Lalpur",
            "value": "Lalpur"
        },
        {
            "label": "Langhnaj",
            "value": "Langhnaj"
        },
        {
            "label": "Lathi",
            "value": "Lathi"
        },
        {
            "label": "Limbdi",
            "value": "Limbdi"
        },
        {
            "label": "Limkheda",
            "value": "Limkheda"
        },
        {
            "label": "Lunavada",
            "value": "Lunavada"
        },
        {
            "label": "Madhavpur Ghed",
            "value": "Madhavpur Ghed"
        },
        {
            "label": "Madhi",
            "value": "Madhi"
        },
        {
            "label": "Mahemdavad",
            "value": "Mahemdavad"
        },
        {
            "label": "Mahesana",
            "value": "Mahesana"
        },
        {
            "label": "Mahisa",
            "value": "Mahisa"
        },
        {
            "label": "Mahudha",
            "value": "Mahudha"
        },
        {
            "label": "Mahuva",
            "value": "Mahuva"
        },
        {
            "label": "Mahuva (Surat)",
            "value": "Mahuva (Surat)"
        },
        {
            "label": "Malpur",
            "value": "Malpur"
        },
        {
            "label": "Manavadar",
            "value": "Manavadar"
        },
        {
            "label": "Mandal",
            "value": "Mandal"
        },
        {
            "label": "Mandvi",
            "value": "Mandvi"
        },
        {
            "label": "Mandvi (Surat)",
            "value": "Mandvi (Surat)"
        },
        {
            "label": "Mangrol",
            "value": "Mangrol"
        },
        {
            "label": "Mangrol (Junagadh)",
            "value": "Mangrol (Junagadh)"
        },
        {
            "label": "Mansa",
            "value": "Mansa"
        },
        {
            "label": "Meghraj",
            "value": "Meghraj"
        },
        {
            "label": "Mehsana",
            "value": "Mehsana"
        },
        {
            "label": "Mendarda",
            "value": "Mendarda"
        },
        {
            "label": "Mithapur",
            "value": "Mithapur"
        },
        {
            "label": "Modasa",
            "value": "Modasa"
        },
        {
            "label": "Morbi",
            "value": "Morbi"
        },
        {
            "label": "Morva (Hadaf)",
            "value": "Morva (Hadaf)"
        },
        {
            "label": "Morwa",
            "value": "Morwa"
        },
        {
            "label": "Mundra",
            "value": "Mundra"
        },
        {
            "label": "Nadiad",
            "value": "Nadiad"
        },
        {
            "label": "Nagwa",
            "value": "Nagwa"
        },
        {
            "label": "Naldhara",
            "value": "Naldhara"
        },
        {
            "label": "Naliya",
            "value": "Naliya"
        },
        {
            "label": "Nargol",
            "value": "Nargol"
        },
        {
            "label": "Narmada",
            "value": "Narmada"
        },
        {
            "label": "Naroda",
            "value": "Naroda"
        },
        {
            "label": "Navsari",
            "value": "Navsari"
        },
        {
            "label": "Nikora",
            "value": "Nikora"
        },
        {
            "label": "Nizar",
            "value": "Nizar"
        },
        {
            "label": "Odadar",
            "value": "Odadar"
        },
        {
            "label": "Okha",
            "value": "Okha"
        },
        {
            "label": "Olpad",
            "value": "Olpad"
        },
        {
            "label": "Paddhari",
            "value": "Paddhari"
        },
        {
            "label": "Padra",
            "value": "Padra"
        },
        {
            "label": "Palanpur",
            "value": "Palanpur"
        },
        {
            "label": "Palanswa",
            "value": "Palanswa"
        },
        {
            "label": "Palitana",
            "value": "Palitana"
        },
        {
            "label": "Paliyad",
            "value": "Paliyad"
        },
        {
            "label": "Palsana",
            "value": "Palsana"
        },
        {
            "label": "Panch Mahals",
            "value": "Panch Mahals"
        },
        {
            "label": "Panchmahal district",
            "value": "Panchmahal district"
        },
        {
            "label": "Pardi",
            "value": "Pardi"
        },
        {
            "label": "Parnera",
            "value": "Parnera"
        },
        {
            "label": "Patan",
            "value": "Patan"
        },
        {
            "label": "Pavi Jetpur",
            "value": "Pavi Jetpur"
        },
        {
            "label": "Petlad",
            "value": "Petlad"
        },
        {
            "label": "Pipavav",
            "value": "Pipavav"
        },
        {
            "label": "Piplod",
            "value": "Piplod"
        },
        {
            "label": "Porbandar",
            "value": "Porbandar"
        },
        {
            "label": "Prabhas Patan",
            "value": "Prabhas Patan"
        },
        {
            "label": "Prantij",
            "value": "Prantij"
        },
        {
            "label": "Radhanpur",
            "value": "Radhanpur"
        },
        {
            "label": "Rajkot",
            "value": "Rajkot"
        },
        {
            "label": "Rajpipla",
            "value": "Rajpipla"
        },
        {
            "label": "Rajula",
            "value": "Rajula"
        },
        {
            "label": "Ranavav",
            "value": "Ranavav"
        },
        {
            "label": "Ranpur",
            "value": "Ranpur"
        },
        {
            "label": "Rapar",
            "value": "Rapar"
        },
        {
            "label": "Reha",
            "value": "Reha"
        },
        {
            "label": "Roha",
            "value": "Roha"
        },
        {
            "label": "Sabar Kantha",
            "value": "Sabar Kantha"
        },
        {
            "label": "Sachin",
            "value": "Sachin"
        },
        {
            "label": "Salaya",
            "value": "Salaya"
        },
        {
            "label": "Samakhiali",
            "value": "Samakhiali"
        },
        {
            "label": "Sanand",
            "value": "Sanand"
        },
        {
            "label": "Sankheda",
            "value": "Sankheda"
        },
        {
            "label": "Sarbhon",
            "value": "Sarbhon"
        },
        {
            "label": "Sardoi",
            "value": "Sardoi"
        },
        {
            "label": "Sarkhej",
            "value": "Sarkhej"
        },
        {
            "label": "Sathamba",
            "value": "Sathamba"
        },
        {
            "label": "Savarkundla",
            "value": "Savarkundla"
        },
        {
            "label": "Savli",
            "value": "Savli"
        },
        {
            "label": "Sayla",
            "value": "Sayla"
        },
        {
            "label": "Shahpur",
            "value": "Shahpur"
        },
        {
            "label": "Shivrajpur",
            "value": "Shivrajpur"
        },
        {
            "label": "Siddhpur",
            "value": "Siddhpur"
        },
        {
            "label": "Sihor",
            "value": "Sihor"
        },
        {
            "label": "Sikka",
            "value": "Sikka"
        },
        {
            "label": "Sinor",
            "value": "Sinor"
        },
        {
            "label": "Sojitra",
            "value": "Sojitra"
        },
        {
            "label": "Songadh",
            "value": "Songadh"
        },
        {
            "label": "Supedi",
            "value": "Supedi"
        },
        {
            "label": "Surat",
            "value": "Surat"
        },
        {
            "label": "Surendranagar",
            "value": "Surendranagar"
        },
        {
            "label": "Sutrapada",
            "value": "Sutrapada"
        },
        {
            "label": "Talaja",
            "value": "Talaja"
        },
        {
            "label": "Tankara",
            "value": "Tankara"
        },
        {
            "label": "Tapi",
            "value": "Tapi"
        },
        {
            "label": "Than",
            "value": "Than"
        },
        {
            "label": "Thangadh",
            "value": "Thangadh"
        },
        {
            "label": "Tharad",
            "value": "Tharad"
        },
        {
            "label": "Thasra",
            "value": "Thasra"
        },
        {
            "label": "The Dangs",
            "value": "The Dangs"
        },
        {
            "label": "Umarpada",
            "value": "Umarpada"
        },
        {
            "label": "Umrala",
            "value": "Umrala"
        },
        {
            "label": "Umreth",
            "value": "Umreth"
        },
        {
            "label": "Un",
            "value": "Un"
        },
        {
            "label": "Una",
            "value": "Una"
        },
        {
            "label": "Unjha",
            "value": "Unjha"
        },
        {
            "label": "Upleta",
            "value": "Upleta"
        },
        {
            "label": "Utran",
            "value": "Utran"
        },
        {
            "label": "Vadgam",
            "value": "Vadgam"
        },
        {
            "label": "Vadnagar",
            "value": "Vadnagar"
        },
        {
            "label": "Vadodara",
            "value": "Vadodara"
        },
        {
            "label": "Vaghodia",
            "value": "Vaghodia"
        },
        {
            "label": "Vaghodia INA",
            "value": "Vaghodia INA"
        },
        {
            "label": "Vallabh Vidyanagar",
            "value": "Vallabh Vidyanagar"
        },
        {
            "label": "Vallabhipur",
            "value": "Vallabhipur"
        },
        {
            "label": "Valsad",
            "value": "Valsad"
        },
        {
            "label": "Vanala",
            "value": "Vanala"
        },
        {
            "label": "Vansda",
            "value": "Vansda"
        },
        {
            "label": "Vanthli",
            "value": "Vanthli"
        },
        {
            "label": "Vapi",
            "value": "Vapi"
        },
        {
            "label": "Vartej",
            "value": "Vartej"
        },
        {
            "label": "Vasa",
            "value": "Vasa"
        },
        {
            "label": "Vasavad",
            "value": "Vasavad"
        },
        {
            "label": "Vaso",
            "value": "Vaso"
        },
        {
            "label": "Vataman",
            "value": "Vataman"
        },
        {
            "label": "Vejalpur",
            "value": "Vejalpur"
        },
        {
            "label": "Veraval",
            "value": "Veraval"
        },
        {
            "label": "Vijapur",
            "value": "Vijapur"
        },
        {
            "label": "Vinchhiya",
            "value": "Vinchhiya"
        },
        {
            "label": "Viramgam",
            "value": "Viramgam"
        },
        {
            "label": "Virpur",
            "value": "Virpur"
        },
        {
            "label": "Visavadar",
            "value": "Visavadar"
        },
        {
            "label": "Visnagar",
            "value": "Visnagar"
        },
        {
            "label": "Vyara",
            "value": "Vyara"
        },
        {
            "label": "Wadhai",
            "value": "Wadhai"
        },
        {
            "label": "Wadhwan",
            "value": "Wadhwan"
        },
        {
            "label": "Waghai",
            "value": "Waghai"
        },
        {
            "label": "Wankaner",
            "value": "Wankaner"
        }
    ],
    "Haryana": [
        {
            "label": "Ambala",
            "value": "Ambala"
        },
        {
            "label": "Asandh",
            "value": "Asandh"
        },
        {
            "label": "Ateli Mandi",
            "value": "Ateli Mandi"
        },
        {
            "label": "Bahadurgarh",
            "value": "Bahadurgarh"
        },
        {
            "label": "Bara Uchana",
            "value": "Bara Uchana"
        },
        {
            "label": "Barwala",
            "value": "Barwala"
        },
        {
            "label": "Bawal",
            "value": "Bawal"
        },
        {
            "label": "Beri Khas",
            "value": "Beri Khas"
        },
        {
            "label": "Bhiwani",
            "value": "Bhiwani"
        },
        {
            "label": "Bilaspur",
            "value": "Bilaspur"
        },
        {
            "label": "Buriya",
            "value": "Buriya"
        },
        {
            "label": "Charkhi Dadri",
            "value": "Charkhi Dadri"
        },
        {
            "label": "Chhachhrauli",
            "value": "Chhachhrauli"
        },
        {
            "label": "Dabwali",
            "value": "Dabwali"
        },
        {
            "label": "Dharuhera",
            "value": "Dharuhera"
        },
        {
            "label": "Ellenabad",
            "value": "Ellenabad"
        },
        {
            "label": "Faridabad",
            "value": "Faridabad"
        },
        {
            "label": "Farrukhnagar",
            "value": "Farrukhnagar"
        },
        {
            "label": "Fatehabad",
            "value": "Fatehabad"
        },
        {
            "label": "Firozpur Jhirka",
            "value": "Firozpur Jhirka"
        },
        {
            "label": "Gharaunda",
            "value": "Gharaunda"
        },
        {
            "label": "Gohana",
            "value": "Gohana"
        },
        {
            "label": "Gorakhpur",
            "value": "Gorakhpur"
        },
        {
            "label": "Gurgaon",
            "value": "Gurgaon"
        },
        {
            "label": "Hansi",
            "value": "Hansi"
        },
        {
            "label": "Hasanpur",
            "value": "Hasanpur"
        },
        {
            "label": "Hisar",
            "value": "Hisar"
        },
        {
            "label": "Hodal",
            "value": "Hodal"
        },
        {
            "label": "Inda Chhoi",
            "value": "Inda Chhoi"
        },
        {
            "label": "Indri",
            "value": "Indri"
        },
        {
            "label": "Jagadhri",
            "value": "Jagadhri"
        },
        {
            "label": "Jakhal",
            "value": "Jakhal"
        },
        {
            "label": "Jhajjar",
            "value": "Jhajjar"
        },
        {
            "label": "Jind",
            "value": "Jind"
        },
        {
            "label": "Kaithal",
            "value": "Kaithal"
        },
        {
            "label": "Kalanaur",
            "value": "Kalanaur"
        },
        {
            "label": "Kalanwali",
            "value": "Kalanwali"
        },
        {
            "label": "Kanina Khas",
            "value": "Kanina Khas"
        },
        {
            "label": "Karnal",
            "value": "Karnal"
        },
        {
            "label": "Kharkhauda",
            "value": "Kharkhauda"
        },
        {
            "label": "Kheri Sampla",
            "value": "Kheri Sampla"
        },
        {
            "label": "Kurukshetra",
            "value": "Kurukshetra"
        },
        {
            "label": "Ladwa",
            "value": "Ladwa"
        },
        {
            "label": "Loharu",
            "value": "Loharu"
        },
        {
            "label": "Maham",
            "value": "Maham"
        },
        {
            "label": "Mahendragarh",
            "value": "Mahendragarh"
        },
        {
            "label": "Mandholi Kalan",
            "value": "Mandholi Kalan"
        },
        {
            "label": "Mustafabad",
            "value": "Mustafabad"
        },
        {
            "label": "Narayangarh",
            "value": "Narayangarh"
        },
        {
            "label": "Narnaul",
            "value": "Narnaul"
        },
        {
            "label": "Narnaund",
            "value": "Narnaund"
        },
        {
            "label": "Narwana",
            "value": "Narwana"
        },
        {
            "label": "Nilokheri",
            "value": "Nilokheri"
        },
        {
            "label": "Nuh",
            "value": "Nuh"
        },
        {
            "label": "Palwal",
            "value": "Palwal"
        },
        {
            "label": "Panchkula",
            "value": "Panchkula"
        },
        {
            "label": "Panipat",
            "value": "Panipat"
        },
        {
            "label": "Pataudi",
            "value": "Pataudi"
        },
        {
            "label": "Pehowa",
            "value": "Pehowa"
        },
        {
            "label": "Pinjaur",
            "value": "Pinjaur"
        },
        {
            "label": "Punahana",
            "value": "Punahana"
        },
        {
            "label": "Pundri",
            "value": "Pundri"
        },
        {
            "label": "Radaur",
            "value": "Radaur"
        },
        {
            "label": "Rania",
            "value": "Rania"
        },
        {
            "label": "Ratia",
            "value": "Ratia"
        },
        {
            "label": "Rewari",
            "value": "Rewari"
        },
        {
            "label": "Rohtak",
            "value": "Rohtak"
        },
        {
            "label": "Safidon",
            "value": "Safidon"
        },
        {
            "label": "Samalkha",
            "value": "Samalkha"
        },
        {
            "label": "Shadipur Julana",
            "value": "Shadipur Julana"
        },
        {
            "label": "Shahabad",
            "value": "Shahabad"
        },
        {
            "label": "Sirsa",
            "value": "Sirsa"
        },
        {
            "label": "Sohna",
            "value": "Sohna"
        },
        {
            "label": "Sonipat",
            "value": "Sonipat"
        },
        {
            "label": "Taoru",
            "value": "Taoru"
        },
        {
            "label": "Thanesar",
            "value": "Thanesar"
        },
        {
            "label": "Tohana",
            "value": "Tohana"
        },
        {
            "label": "Tosham",
            "value": "Tosham"
        },
        {
            "label": "Uklana",
            "value": "Uklana"
        },
        {
            "label": "Yamunanagar",
            "value": "Yamunanagar"
        }
    ],
    "Himachal Pradesh": [
        {
            "label": "Arki",
            "value": "Arki"
        },
        {
            "label": "Baddi",
            "value": "Baddi"
        },
        {
            "label": "Banjar",
            "value": "Banjar"
        },
        {
            "label": "Bilaspur",
            "value": "Bilaspur"
        },
        {
            "label": "Chamba",
            "value": "Chamba"
        },
        {
            "label": "Chaupal",
            "value": "Chaupal"
        },
        {
            "label": "Chowari",
            "value": "Chowari"
        },
        {
            "label": "Chuari Khas",
            "value": "Chuari Khas"
        },
        {
            "label": "Dagshai",
            "value": "Dagshai"
        },
        {
            "label": "Dalhousie",
            "value": "Dalhousie"
        },
        {
            "label": "Daulatpur",
            "value": "Daulatpur"
        },
        {
            "label": "Dera Gopipur",
            "value": "Dera Gopipur"
        },
        {
            "label": "Dharamsala",
            "value": "Dharamsala"
        },
        {
            "label": "Gagret",
            "value": "Gagret"
        },
        {
            "label": "Ghumarwin",
            "value": "Ghumarwin"
        },
        {
            "label": "Hamirpur",
            "value": "Hamirpur"
        },
        {
            "label": "Jawala Mukhi",
            "value": "Jawala Mukhi"
        },
        {
            "label": "Jogindarnagar",
            "value": "Jogindarnagar"
        },
        {
            "label": "Jubbal",
            "value": "Jubbal"
        },
        {
            "label": "Jutogh",
            "value": "Jutogh"
        },
        {
            "label": "Kalka",
            "value": "Kalka"
        },
        {
            "label": "Kangar",
            "value": "Kangar"
        },
        {
            "label": "Kangra",
            "value": "Kangra"
        },
        {
            "label": "Kasauli",
            "value": "Kasauli"
        },
        {
            "label": "Kinnaur",
            "value": "Kinnaur"
        },
        {
            "label": "Kotkhai",
            "value": "Kotkhai"
        },
        {
            "label": "Kotla",
            "value": "Kotla"
        },
        {
            "label": "Kulu",
            "value": "Kulu"
        },
        {
            "label": "Kyelang",
            "value": "Kyelang"
        },
        {
            "label": "Lahul and Spiti",
            "value": "Lahul and Spiti"
        },
        {
            "label": "Manali",
            "value": "Manali"
        },
        {
            "label": "Mandi",
            "value": "Mandi"
        },
        {
            "label": "Nadaun",
            "value": "Nadaun"
        },
        {
            "label": "Nagar",
            "value": "Nagar"
        },
        {
            "label": "Nagrota",
            "value": "Nagrota"
        },
        {
            "label": "Nahan",
            "value": "Nahan"
        },
        {
            "label": "Nalagarh",
            "value": "Nalagarh"
        },
        {
            "label": "Palampur",
            "value": "Palampur"
        },
        {
            "label": "Pandoh",
            "value": "Pandoh"
        },
        {
            "label": "Paonta Sahib",
            "value": "Paonta Sahib"
        },
        {
            "label": "Parwanoo",
            "value": "Parwanoo"
        },
        {
            "label": "Rajgarh",
            "value": "Rajgarh"
        },
        {
            "label": "Rampur",
            "value": "Rampur"
        },
        {
            "label": "Rohru",
            "value": "Rohru"
        },
        {
            "label": "Sabathu",
            "value": "Sabathu"
        },
        {
            "label": "Santokhgarh",
            "value": "Santokhgarh"
        },
        {
            "label": "Sarahan",
            "value": "Sarahan"
        },
        {
            "label": "Sarka Ghat",
            "value": "Sarka Ghat"
        },
        {
            "label": "Seoni",
            "value": "Seoni"
        },
        {
            "label": "Shimla",
            "value": "Shimla"
        },
        {
            "label": "Sirmaur",
            "value": "Sirmaur"
        },
        {
            "label": "Solan",
            "value": "Solan"
        },
        {
            "label": "Sundarnagar",
            "value": "Sundarnagar"
        },
        {
            "label": "Theog",
            "value": "Theog"
        },
        {
            "label": "Tira Sujanpur",
            "value": "Tira Sujanpur"
        },
        {
            "label": "Una",
            "value": "Una"
        },
        {
            "label": "Yol",
            "value": "Yol"
        }
    ],
    "Jammu and Kashmir": [
        {
            "label": "Akhnur",
            "value": "Akhnur"
        },
        {
            "label": "Anantnag",
            "value": "Anantnag"
        },
        {
            "label": "Awantipur",
            "value": "Awantipur"
        },
        {
            "label": "Badgam",
            "value": "Badgam"
        },
        {
            "label": "Bandipore",
            "value": "Bandipore"
        },
        {
            "label": "Banihal",
            "value": "Banihal"
        },
        {
            "label": "Baramula",
            "value": "Baramula"
        },
        {
            "label": "Batoti",
            "value": "Batoti"
        },
        {
            "label": "Bhadarwah",
            "value": "Bhadarwah"
        },
        {
            "label": "Bijbehara",
            "value": "Bijbehara"
        },
        {
            "label": "Bishnah",
            "value": "Bishnah"
        },
        {
            "label": "Doda",
            "value": "Doda"
        },
        {
            "label": "Ganderbal",
            "value": "Ganderbal"
        },
        {
            "label": "Gho Brahmanan de",
            "value": "Gho Brahmanan de"
        },
        {
            "label": "Hajan",
            "value": "Hajan"
        },
        {
            "label": "Hiranagar",
            "value": "Hiranagar"
        },
        {
            "label": "Jammu",
            "value": "Jammu"
        },
        {
            "label": "Jaurian",
            "value": "Jaurian"
        },
        {
            "label": "Kathua",
            "value": "Kathua"
        },
        {
            "label": "Katra",
            "value": "Katra"
        },
        {
            "label": "Khaur",
            "value": "Khaur"
        },
        {
            "label": "Kishtwar",
            "value": "Kishtwar"
        },
        {
            "label": "Kud",
            "value": "Kud"
        },
        {
            "label": "Kulgam",
            "value": "Kulgam"
        },
        {
            "label": "Kupwara",
            "value": "Kupwara"
        },
        {
            "label": "Ladakh",
            "value": "Ladakh"
        },
        {
            "label": "Magam",
            "value": "Magam"
        },
        {
            "label": "Nawanshahr",
            "value": "Nawanshahr"
        },
        {
            "label": "Noria",
            "value": "Noria"
        },
        {
            "label": "Padam",
            "value": "Padam"
        },
        {
            "label": "Pahlgam",
            "value": "Pahlgam"
        },
        {
            "label": "Parol",
            "value": "Parol"
        },
        {
            "label": "Pattan",
            "value": "Pattan"
        },
        {
            "label": "Pulwama",
            "value": "Pulwama"
        },
        {
            "label": "Punch",
            "value": "Punch"
        },
        {
            "label": "Qazigund",
            "value": "Qazigund"
        },
        {
            "label": "Rajaori",
            "value": "Rajaori"
        },
        {
            "label": "Rajauri",
            "value": "Rajauri"
        },
        {
            "label": "Ramban",
            "value": "Ramban"
        },
        {
            "label": "Ramgarh",
            "value": "Ramgarh"
        },
        {
            "label": "Ramnagar",
            "value": "Ramnagar"
        },
        {
            "label": "Riasi",
            "value": "Riasi"
        },
        {
            "label": "Samba",
            "value": "Samba"
        },
        {
            "label": "Shupiyan",
            "value": "Shupiyan"
        },
        {
            "label": "Sopur",
            "value": "Sopur"
        },
        {
            "label": "Soyibug",
            "value": "Soyibug"
        },
        {
            "label": "Srinagar",
            "value": "Srinagar"
        },
        {
            "label": "Sumbal",
            "value": "Sumbal"
        },
        {
            "label": "Thang",
            "value": "Thang"
        },
        {
            "label": "Thanna Mandi",
            "value": "Thanna Mandi"
        },
        {
            "label": "Tral",
            "value": "Tral"
        },
        {
            "label": "Tsrar Sharif",
            "value": "Tsrar Sharif"
        },
        {
            "label": "Udhampur",
            "value": "Udhampur"
        },
        {
            "label": "Uri",
            "value": "Uri"
        }
    ],
    "Jharkhand": [
        {
            "label": "Bagra",
            "value": "Bagra"
        },
        {
            "label": "Barka Kana",
            "value": "Barka Kana"
        },
        {
            "label": "Barki Saria",
            "value": "Barki Saria"
        },
        {
            "label": "Barwadih",
            "value": "Barwadih"
        },
        {
            "label": "Bhojudih",
            "value": "Bhojudih"
        },
        {
            "label": "Bokaro",
            "value": "Bokaro"
        },
        {
            "label": "Bundu",
            "value": "Bundu"
        },
        {
            "label": "Chaibasa",
            "value": "Chaibasa"
        },
        {
            "label": "Chakradharpur",
            "value": "Chakradharpur"
        },
        {
            "label": "Chakulia",
            "value": "Chakulia"
        },
        {
            "label": "Chandil",
            "value": "Chandil"
        },
        {
            "label": "Chas",
            "value": "Chas"
        },
        {
            "label": "Chatra",
            "value": "Chatra"
        },
        {
            "label": "Chiria",
            "value": "Chiria"
        },
        {
            "label": "Daltonganj",
            "value": "Daltonganj"
        },
        {
            "label": "Deogarh",
            "value": "Deogarh"
        },
        {
            "label": "Dhanbad",
            "value": "Dhanbad"
        },
        {
            "label": "Dhanwar",
            "value": "Dhanwar"
        },
        {
            "label": "Dugda",
            "value": "Dugda"
        },
        {
            "label": "Dumka",
            "value": "Dumka"
        },
        {
            "label": "Garhwa",
            "value": "Garhwa"
        },
        {
            "label": "Ghatsila",
            "value": "Ghatsila"
        },
        {
            "label": "Giridih",
            "value": "Giridih"
        },
        {
            "label": "Gobindpur",
            "value": "Gobindpur"
        },
        {
            "label": "Godda",
            "value": "Godda"
        },
        {
            "label": "Gomoh",
            "value": "Gomoh"
        },
        {
            "label": "Gopinathpur",
            "value": "Gopinathpur"
        },
        {
            "label": "Gua",
            "value": "Gua"
        },
        {
            "label": "Gumia",
            "value": "Gumia"
        },
        {
            "label": "Gumla",
            "value": "Gumla"
        },
        {
            "label": "Hazaribag",
            "value": "Hazaribag"
        },
        {
            "label": "Hazaribagh",
            "value": "Hazaribagh"
        },
        {
            "label": "Hesla",
            "value": "Hesla"
        },
        {
            "label": "Husainabad",
            "value": "Husainabad"
        },
        {
            "label": "Jagannathpur",
            "value": "Jagannathpur"
        },
        {
            "label": "Jamadoba",
            "value": "Jamadoba"
        },
        {
            "label": "Jamshedpur",
            "value": "Jamshedpur"
        },
        {
            "label": "Jamtara",
            "value": "Jamtara"
        },
        {
            "label": "Jasidih",
            "value": "Jasidih"
        },
        {
            "label": "Jharia",
            "value": "Jharia"
        },
        {
            "label": "Jugsalai",
            "value": "Jugsalai"
        },
        {
            "label": "Jumri Tilaiya",
            "value": "Jumri Tilaiya"
        },
        {
            "label": "Kalikapur",
            "value": "Kalikapur"
        },
        {
            "label": "Kandra",
            "value": "Kandra"
        },
        {
            "label": "Kanke",
            "value": "Kanke"
        },
        {
            "label": "Katras",
            "value": "Katras"
        },
        {
            "label": "Kenduadih",
            "value": "Kenduadih"
        },
        {
            "label": "Kharsawan",
            "value": "Kharsawan"
        },
        {
            "label": "Khunti",
            "value": "Khunti"
        },
        {
            "label": "Kodarma",
            "value": "Kodarma"
        },
        {
            "label": "Kuju",
            "value": "Kuju"
        },
        {
            "label": "Latehar",
            "value": "Latehar"
        },
        {
            "label": "Lohardaga",
            "value": "Lohardaga"
        },
        {
            "label": "Madhupur",
            "value": "Madhupur"
        },
        {
            "label": "Malkera",
            "value": "Malkera"
        },
        {
            "label": "Manoharpur",
            "value": "Manoharpur"
        },
        {
            "label": "Mugma",
            "value": "Mugma"
        },
        {
            "label": "Mushabani",
            "value": "Mushabani"
        },
        {
            "label": "Neturhat",
            "value": "Neturhat"
        },
        {
            "label": "Nirsa",
            "value": "Nirsa"
        },
        {
            "label": "Noamundi",
            "value": "Noamundi"
        },
        {
            "label": "Pakur",
            "value": "Pakur"
        },
        {
            "label": "Palamu",
            "value": "Palamu"
        },
        {
            "label": "Pashchim Singhbhum",
            "value": "Pashchim Singhbhum"
        },
        {
            "label": "patamda",
            "value": "patamda"
        },
        {
            "label": "Pathardih",
            "value": "Pathardih"
        },
        {
            "label": "Purba Singhbhum",
            "value": "Purba Singhbhum"
        },
        {
            "label": "Ramgarh",
            "value": "Ramgarh"
        },
        {
            "label": "Ranchi",
            "value": "Ranchi"
        },
        {
            "label": "Ray",
            "value": "Ray"
        },
        {
            "label": "Sahibganj",
            "value": "Sahibganj"
        },
        {
            "label": "Saraikela",
            "value": "Saraikela"
        },
        {
            "label": "Sarubera",
            "value": "Sarubera"
        },
        {
            "label": "Sijua",
            "value": "Sijua"
        },
        {
            "label": "Simdega",
            "value": "Simdega"
        },
        {
            "label": "Sini",
            "value": "Sini"
        },
        {
            "label": "Topchanchi",
            "value": "Topchanchi"
        }
    ],
    "Karnataka": [
        {
            "label": "Afzalpur",
            "value": "Afzalpur"
        },
        {
            "label": "Ajjampur",
            "value": "Ajjampur"
        },
        {
            "label": "Aland",
            "value": "Aland"
        },
        {
            "label": "Alnavar",
            "value": "Alnavar"
        },
        {
            "label": "Alur",
            "value": "Alur"
        },
        {
            "label": "Anekal",
            "value": "Anekal"
        },
        {
            "label": "Ankola",
            "value": "Ankola"
        },
        {
            "label": "Annigeri",
            "value": "Annigeri"
        },
        {
            "label": "Arkalgud",
            "value": "Arkalgud"
        },
        {
            "label": "Arsikere",
            "value": "Arsikere"
        },
        {
            "label": "Athni",
            "value": "Athni"
        },
        {
            "label": "Aurad",
            "value": "Aurad"
        },
        {
            "label": "Badami",
            "value": "Badami"
        },
        {
            "label": "Bagalkot",
            "value": "Bagalkot"
        },
        {
            "label": "Bagepalli",
            "value": "Bagepalli"
        },
        {
            "label": "Bail-Hongal",
            "value": "Bail-Hongal"
        },
        {
            "label": "Ballari",
            "value": "Ballari"
        },
        {
            "label": "Banavar",
            "value": "Banavar"
        },
        {
            "label": "Bangalore Rural",
            "value": "Bangalore Rural"
        },
        {
            "label": "Bangalore Urban",
            "value": "Bangalore Urban"
        },
        {
            "label": "Bangarapet",
            "value": "Bangarapet"
        },
        {
            "label": "Bannur",
            "value": "Bannur"
        },
        {
            "label": "Bantval",
            "value": "Bantval"
        },
        {
            "label": "Basavakalyan",
            "value": "Basavakalyan"
        },
        {
            "label": "Basavana Bagevadi",
            "value": "Basavana Bagevadi"
        },
        {
            "label": "Belagavi",
            "value": "Belagavi"
        },
        {
            "label": "Belluru",
            "value": "Belluru"
        },
        {
            "label": "Beltangadi",
            "value": "Beltangadi"
        },
        {
            "label": "Belur",
            "value": "Belur"
        },
        {
            "label": "Bengaluru",
            "value": "Bengaluru"
        },
        {
            "label": "Bhadravati",
            "value": "Bhadravati"
        },
        {
            "label": "Bhalki",
            "value": "Bhalki"
        },
        {
            "label": "Bhatkal",
            "value": "Bhatkal"
        },
        {
            "label": "Bidar",
            "value": "Bidar"
        },
        {
            "label": "Bilgi",
            "value": "Bilgi"
        },
        {
            "label": "Birur",
            "value": "Birur"
        },
        {
            "label": "Byadgi",
            "value": "Byadgi"
        },
        {
            "label": "Byndoor",
            "value": "Byndoor"
        },
        {
            "label": "Canacona",
            "value": "Canacona"
        },
        {
            "label": "Challakere",
            "value": "Challakere"
        },
        {
            "label": "Chamrajnagar",
            "value": "Chamrajnagar"
        },
        {
            "label": "Channagiri",
            "value": "Channagiri"
        },
        {
            "label": "Channapatna",
            "value": "Channapatna"
        },
        {
            "label": "Channarayapatna",
            "value": "Channarayapatna"
        },
        {
            "label": "Chik Ballapur",
            "value": "Chik Ballapur"
        },
        {
            "label": "Chikkaballapur",
            "value": "Chikkaballapur"
        },
        {
            "label": "Chikkamagaluru",
            "value": "Chikkamagaluru"
        },
        {
            "label": "Chiknayakanhalli",
            "value": "Chiknayakanhalli"
        },
        {
            "label": "Chikodi",
            "value": "Chikodi"
        },
        {
            "label": "Chincholi",
            "value": "Chincholi"
        },
        {
            "label": "Chintamani",
            "value": "Chintamani"
        },
        {
            "label": "Chitapur",
            "value": "Chitapur"
        },
        {
            "label": "Chitradurga",
            "value": "Chitradurga"
        },
        {
            "label": "Closepet",
            "value": "Closepet"
        },
        {
            "label": "Coondapoor",
            "value": "Coondapoor"
        },
        {
            "label": "Dakshina Kannada",
            "value": "Dakshina Kannada"
        },
        {
            "label": "Dandeli",
            "value": "Dandeli"
        },
        {
            "label": "Davanagere",
            "value": "Davanagere"
        },
        {
            "label": "Devanhalli",
            "value": "Devanhalli"
        },
        {
            "label": "Dharwad",
            "value": "Dharwad"
        },
        {
            "label": "Dod Ballapur",
            "value": "Dod Ballapur"
        },
        {
            "label": "French Rocks",
            "value": "French Rocks"
        },
        {
            "label": "Gadag",
            "value": "Gadag"
        },
        {
            "label": "Gadag-Betageri",
            "value": "Gadag-Betageri"
        },
        {
            "label": "Gajendragarh",
            "value": "Gajendragarh"
        },
        {
            "label": "Gangawati",
            "value": "Gangawati"
        },
        {
            "label": "Gangolli",
            "value": "Gangolli"
        },
        {
            "label": "Gokak",
            "value": "Gokak"
        },
        {
            "label": "Gokarna",
            "value": "Gokarna"
        },
        {
            "label": "Goribidnur",
            "value": "Goribidnur"
        },
        {
            "label": "Gorur",
            "value": "Gorur"
        },
        {
            "label": "Gubbi",
            "value": "Gubbi"
        },
        {
            "label": "Gudibanda",
            "value": "Gudibanda"
        },
        {
            "label": "Guledagudda",
            "value": "Guledagudda"
        },
        {
            "label": "Gundlupet",
            "value": "Gundlupet"
        },
        {
            "label": "Gurmatkal",
            "value": "Gurmatkal"
        },
        {
            "label": "Hadagalli",
            "value": "Hadagalli"
        },
        {
            "label": "Haliyal",
            "value": "Haliyal"
        },
        {
            "label": "Hampi",
            "value": "Hampi"
        },
        {
            "label": "Hangal",
            "value": "Hangal"
        },
        {
            "label": "Harihar",
            "value": "Harihar"
        },
        {
            "label": "Harpanahalli",
            "value": "Harpanahalli"
        },
        {
            "label": "Hassan",
            "value": "Hassan"
        },
        {
            "label": "Haveri",
            "value": "Haveri"
        },
        {
            "label": "Heggadadevankote",
            "value": "Heggadadevankote"
        },
        {
            "label": "Hirekerur",
            "value": "Hirekerur"
        },
        {
            "label": "Hiriyur",
            "value": "Hiriyur"
        },
        {
            "label": "Holalkere",
            "value": "Holalkere"
        },
        {
            "label": "Hole Narsipur",
            "value": "Hole Narsipur"
        },
        {
            "label": "Homnabad",
            "value": "Homnabad"
        },
        {
            "label": "Honavar",
            "value": "Honavar"
        },
        {
            "label": "Honnali",
            "value": "Honnali"
        },
        {
            "label": "Hosanagara",
            "value": "Hosanagara"
        },
        {
            "label": "Hosangadi",
            "value": "Hosangadi"
        },
        {
            "label": "Hosdurga",
            "value": "Hosdurga"
        },
        {
            "label": "Hoskote",
            "value": "Hoskote"
        },
        {
            "label": "Hospet",
            "value": "Hospet"
        },
        {
            "label": "Hubballi",
            "value": "Hubballi"
        },
        {
            "label": "Hukeri",
            "value": "Hukeri"
        },
        {
            "label": "Hungund",
            "value": "Hungund"
        },
        {
            "label": "Hunsur",
            "value": "Hunsur"
        },
        {
            "label": "Ilkal",
            "value": "Ilkal"
        },
        {
            "label": "Indi",
            "value": "Indi"
        },
        {
            "label": "Jagalur",
            "value": "Jagalur"
        },
        {
            "label": "Jamkhandi",
            "value": "Jamkhandi"
        },
        {
            "label": "Jevargi",
            "value": "Jevargi"
        },
        {
            "label": "Kadur",
            "value": "Kadur"
        },
        {
            "label": "Kalaburgi",
            "value": "Kalaburgi"
        },
        {
            "label": "Kalghatgi",
            "value": "Kalghatgi"
        },
        {
            "label": "Kampli",
            "value": "Kampli"
        },
        {
            "label": "Kankanhalli",
            "value": "Kankanhalli"
        },
        {
            "label": "Karkala",
            "value": "Karkala"
        },
        {
            "label": "Karwar",
            "value": "Karwar"
        },
        {
            "label": "Kavalur",
            "value": "Kavalur"
        },
        {
            "label": "Kerur",
            "value": "Kerur"
        },
        {
            "label": "Khanapur",
            "value": "Khanapur"
        },
        {
            "label": "Kodagu",
            "value": "Kodagu"
        },
        {
            "label": "Kodigenahalli",
            "value": "Kodigenahalli"
        },
        {
            "label": "Kodlipet",
            "value": "Kodlipet"
        },
        {
            "label": "Kolar",
            "value": "Kolar"
        },
        {
            "label": "Kollegal",
            "value": "Kollegal"
        },
        {
            "label": "Konanur",
            "value": "Konanur"
        },
        {
            "label": "Konnur",
            "value": "Konnur"
        },
        {
            "label": "Koppa",
            "value": "Koppa"
        },
        {
            "label": "Koppal",
            "value": "Koppal"
        },
        {
            "label": "Koratagere",
            "value": "Koratagere"
        },
        {
            "label": "Kotturu",
            "value": "Kotturu"
        },
        {
            "label": "Krishnarajpet",
            "value": "Krishnarajpet"
        },
        {
            "label": "Kudachi",
            "value": "Kudachi"
        },
        {
            "label": "Kudligi",
            "value": "Kudligi"
        },
        {
            "label": "Kumsi",
            "value": "Kumsi"
        },
        {
            "label": "Kumta",
            "value": "Kumta"
        },
        {
            "label": "Kundgol",
            "value": "Kundgol"
        },
        {
            "label": "Kunigal",
            "value": "Kunigal"
        },
        {
            "label": "Kurgunta",
            "value": "Kurgunta"
        },
        {
            "label": "Kushalnagar",
            "value": "Kushalnagar"
        },
        {
            "label": "Kushtagi",
            "value": "Kushtagi"
        },
        {
            "label": "Lakshmeshwar",
            "value": "Lakshmeshwar"
        },
        {
            "label": "Lingsugur",
            "value": "Lingsugur"
        },
        {
            "label": "Londa",
            "value": "Londa"
        },
        {
            "label": "Maddagiri",
            "value": "Maddagiri"
        },
        {
            "label": "Maddur",
            "value": "Maddur"
        },
        {
            "label": "Madikeri",
            "value": "Madikeri"
        },
        {
            "label": "Magadi",
            "value": "Magadi"
        },
        {
            "label": "Mahalingpur",
            "value": "Mahalingpur"
        },
        {
            "label": "Malavalli",
            "value": "Malavalli"
        },
        {
            "label": "Malpe",
            "value": "Malpe"
        },
        {
            "label": "Malur",
            "value": "Malur"
        },
        {
            "label": "Mandya",
            "value": "Mandya"
        },
        {
            "label": "Mangaluru",
            "value": "Mangaluru"
        },
        {
            "label": "Manipal",
            "value": "Manipal"
        },
        {
            "label": "Manvi",
            "value": "Manvi"
        },
        {
            "label": "Mayakonda",
            "value": "Mayakonda"
        },
        {
            "label": "Melukote",
            "value": "Melukote"
        },
        {
            "label": "Mudbidri",
            "value": "Mudbidri"
        },
        {
            "label": "Muddebihal",
            "value": "Muddebihal"
        },
        {
            "label": "Mudgal",
            "value": "Mudgal"
        },
        {
            "label": "Mudgere",
            "value": "Mudgere"
        },
        {
            "label": "Mudhol",
            "value": "Mudhol"
        },
        {
            "label": "Mulbagal",
            "value": "Mulbagal"
        },
        {
            "label": "Mulgund",
            "value": "Mulgund"
        },
        {
            "label": "Mulki",
            "value": "Mulki"
        },
        {
            "label": "Mundargi",
            "value": "Mundargi"
        },
        {
            "label": "Mundgod",
            "value": "Mundgod"
        },
        {
            "label": "Munirabad",
            "value": "Munirabad"
        },
        {
            "label": "Murudeshwara",
            "value": "Murudeshwara"
        },
        {
            "label": "Mysuru",
            "value": "Mysuru"
        },
        {
            "label": "Nagamangala",
            "value": "Nagamangala"
        },
        {
            "label": "Nanjangud",
            "value": "Nanjangud"
        },
        {
            "label": "Narasimharajapura",
            "value": "Narasimharajapura"
        },
        {
            "label": "Naregal",
            "value": "Naregal"
        },
        {
            "label": "Nargund",
            "value": "Nargund"
        },
        {
            "label": "Navalgund",
            "value": "Navalgund"
        },
        {
            "label": "Nelamangala",
            "value": "Nelamangala"
        },
        {
            "label": "Nyamti",
            "value": "Nyamti"
        },
        {
            "label": "Pangala",
            "value": "Pangala"
        },
        {
            "label": "Pavugada",
            "value": "Pavugada"
        },
        {
            "label": "Piriyapatna",
            "value": "Piriyapatna"
        },
        {
            "label": "Ponnampet",
            "value": "Ponnampet"
        },
        {
            "label": "Puttur",
            "value": "Puttur"
        },
        {
            "label": "Rabkavi",
            "value": "Rabkavi"
        },
        {
            "label": "Raichur",
            "value": "Raichur"
        },
        {
            "label": "Ramanagara",
            "value": "Ramanagara"
        },
        {
            "label": "Ranibennur",
            "value": "Ranibennur"
        },
        {
            "label": "Raybag",
            "value": "Raybag"
        },
        {
            "label": "Robertsonpet",
            "value": "Robertsonpet"
        },
        {
            "label": "Ron",
            "value": "Ron"
        },
        {
            "label": "Sadalgi",
            "value": "Sadalgi"
        },
        {
            "label": "Sagar",
            "value": "Sagar"
        },
        {
            "label": "Sakleshpur",
            "value": "Sakleshpur"
        },
        {
            "label": "Sandur",
            "value": "Sandur"
        },
        {
            "label": "Sanivarsante",
            "value": "Sanivarsante"
        },
        {
            "label": "Sankeshwar",
            "value": "Sankeshwar"
        },
        {
            "label": "Sargur",
            "value": "Sargur"
        },
        {
            "label": "Saundatti",
            "value": "Saundatti"
        },
        {
            "label": "Savanur",
            "value": "Savanur"
        },
        {
            "label": "Seram",
            "value": "Seram"
        },
        {
            "label": "Shahabad",
            "value": "Shahabad"
        },
        {
            "label": "Shahpur",
            "value": "Shahpur"
        },
        {
            "label": "Shiggaon",
            "value": "Shiggaon"
        },
        {
            "label": "Shikarpur",
            "value": "Shikarpur"
        },
        {
            "label": "Shimoga",
            "value": "Shimoga"
        },
        {
            "label": "Shirhatti",
            "value": "Shirhatti"
        },
        {
            "label": "Shorapur",
            "value": "Shorapur"
        },
        {
            "label": "Shrirangapattana",
            "value": "Shrirangapattana"
        },
        {
            "label": "Siddapur",
            "value": "Siddapur"
        },
        {
            "label": "Sidlaghatta",
            "value": "Sidlaghatta"
        },
        {
            "label": "Sindgi",
            "value": "Sindgi"
        },
        {
            "label": "Sindhnur",
            "value": "Sindhnur"
        },
        {
            "label": "Sira",
            "value": "Sira"
        },
        {
            "label": "Sirsi",
            "value": "Sirsi"
        },
        {
            "label": "Siruguppa",
            "value": "Siruguppa"
        },
        {
            "label": "Someshwar",
            "value": "Someshwar"
        },
        {
            "label": "Somvarpet",
            "value": "Somvarpet"
        },
        {
            "label": "Sorab",
            "value": "Sorab"
        },
        {
            "label": "Sravana Belgola",
            "value": "Sravana Belgola"
        },
        {
            "label": "Sringeri",
            "value": "Sringeri"
        },
        {
            "label": "Srinivaspur",
            "value": "Srinivaspur"
        },
        {
            "label": "Sulya",
            "value": "Sulya"
        },
        {
            "label": "Suntikoppa",
            "value": "Suntikoppa"
        },
        {
            "label": "Talikota",
            "value": "Talikota"
        },
        {
            "label": "Tarikere",
            "value": "Tarikere"
        },
        {
            "label": "Tekkalakote",
            "value": "Tekkalakote"
        },
        {
            "label": "Terdal",
            "value": "Terdal"
        },
        {
            "label": "Tiptur",
            "value": "Tiptur"
        },
        {
            "label": "Tirthahalli",
            "value": "Tirthahalli"
        },
        {
            "label": "Tirumakudal Narsipur",
            "value": "Tirumakudal Narsipur"
        },
        {
            "label": "Tumakuru",
            "value": "Tumakuru"
        },
        {
            "label": "Turuvekere",
            "value": "Turuvekere"
        },
        {
            "label": "Udupi",
            "value": "Udupi"
        },
        {
            "label": "Ullal",
            "value": "Ullal"
        },
        {
            "label": "Uttar Kannada",
            "value": "Uttar Kannada"
        },
        {
            "label": "Vadigenhalli",
            "value": "Vadigenhalli"
        },
        {
            "label": "Vijayapura",
            "value": "Vijayapura"
        },
        {
            "label": "Virarajendrapet",
            "value": "Virarajendrapet"
        },
        {
            "label": "Wadi",
            "value": "Wadi"
        },
        {
            "label": "Yadgir",
            "value": "Yadgir"
        },
        {
            "label": "Yelahanka",
            "value": "Yelahanka"
        },
        {
            "label": "Yelandur",
            "value": "Yelandur"
        },
        {
            "label": "Yelbarga",
            "value": "Yelbarga"
        },
        {
            "label": "Yellapur",
            "value": "Yellapur"
        }
    ],
    "Kerala": [
        {
            "label": "Adur",
            "value": "Adur"
        },
        {
            "label": "Alappuzha",
            "value": "Alappuzha"
        },
        {
            "label": "Aluva",
            "value": "Aluva"
        },
        {
            "label": "Alwaye",
            "value": "Alwaye"
        },
        {
            "label": "Angamali",
            "value": "Angamali"
        },
        {
            "label": "Aroor",
            "value": "Aroor"
        },
        {
            "label": "Arukutti",
            "value": "Arukutti"
        },
        {
            "label": "Attingal",
            "value": "Attingal"
        },
        {
            "label": "Avanoor",
            "value": "Avanoor"
        },
        {
            "label": "Azhikkal",
            "value": "Azhikkal"
        },
        {
            "label": "Badagara",
            "value": "Badagara"
        },
        {
            "label": "Beypore",
            "value": "Beypore"
        },
        {
            "label": "Changanacheri",
            "value": "Changanacheri"
        },
        {
            "label": "Chelakara",
            "value": "Chelakara"
        },
        {
            "label": "Chengannur",
            "value": "Chengannur"
        },
        {
            "label": "Cherpulassery",
            "value": "Cherpulassery"
        },
        {
            "label": "Cherthala",
            "value": "Cherthala"
        },
        {
            "label": "Chetwayi",
            "value": "Chetwayi"
        },
        {
            "label": "Chittur",
            "value": "Chittur"
        },
        {
            "label": "Cochin",
            "value": "Cochin"
        },
        {
            "label": "Dharmadam",
            "value": "Dharmadam"
        },
        {
            "label": "Edakkulam",
            "value": "Edakkulam"
        },
        {
            "label": "Elur",
            "value": "Elur"
        },
        {
            "label": "Erattupetta",
            "value": "Erattupetta"
        },
        {
            "label": "Ernakulam",
            "value": "Ernakulam"
        },
        {
            "label": "Ferokh",
            "value": "Ferokh"
        },
        {
            "label": "Guruvayur",
            "value": "Guruvayur"
        },
        {
            "label": "Idukki",
            "value": "Idukki"
        },
        {
            "label": "Iringal",
            "value": "Iringal"
        },
        {
            "label": "Irinjalakuda",
            "value": "Irinjalakuda"
        },
        {
            "label": "Kadakkavoor",
            "value": "Kadakkavoor"
        },
        {
            "label": "Kalamassery",
            "value": "Kalamassery"
        },
        {
            "label": "Kalavoor",
            "value": "Kalavoor"
        },
        {
            "label": "Kalpatta",
            "value": "Kalpatta"
        },
        {
            "label": "Kannangad",
            "value": "Kannangad"
        },
        {
            "label": "Kannavam",
            "value": "Kannavam"
        },
        {
            "label": "Kannur",
            "value": "Kannur"
        },
        {
            "label": "Kasaragod",
            "value": "Kasaragod"
        },
        {
            "label": "Kasaragod District",
            "value": "Kasaragod District"
        },
        {
            "label": "Kattanam",
            "value": "Kattanam"
        },
        {
            "label": "Kayankulam",
            "value": "Kayankulam"
        },
        {
            "label": "Kizhake Chalakudi",
            "value": "Kizhake Chalakudi"
        },
        {
            "label": "Kodungallur",
            "value": "Kodungallur"
        },
        {
            "label": "Kollam",
            "value": "Kollam"
        },
        {
            "label": "Kotamangalam",
            "value": "Kotamangalam"
        },
        {
            "label": "Kottayam",
            "value": "Kottayam"
        },
        {
            "label": "Kovalam",
            "value": "Kovalam"
        },
        {
            "label": "Kozhikode",
            "value": "Kozhikode"
        },
        {
            "label": "Kumbalam",
            "value": "Kumbalam"
        },
        {
            "label": "Kunnamangalam",
            "value": "Kunnamangalam"
        },
        {
            "label": "Kunnamkulam",
            "value": "Kunnamkulam"
        },
        {
            "label": "Kunnumma",
            "value": "Kunnumma"
        },
        {
            "label": "Kutiatodu",
            "value": "Kutiatodu"
        },
        {
            "label": "Kuttampuzha",
            "value": "Kuttampuzha"
        },
        {
            "label": "Lalam",
            "value": "Lalam"
        },
        {
            "label": "Mahe",
            "value": "Mahe"
        },
        {
            "label": "Malappuram",
            "value": "Malappuram"
        },
        {
            "label": "Manjeri",
            "value": "Manjeri"
        },
        {
            "label": "Manjeshvar",
            "value": "Manjeshvar"
        },
        {
            "label": "Mannarakkat",
            "value": "Mannarakkat"
        },
        {
            "label": "Marayur",
            "value": "Marayur"
        },
        {
            "label": "Mattanur",
            "value": "Mattanur"
        },
        {
            "label": "Mavelikara",
            "value": "Mavelikara"
        },
        {
            "label": "Mavoor",
            "value": "Mavoor"
        },
        {
            "label": "Muluppilagadu",
            "value": "Muluppilagadu"
        },
        {
            "label": "Munnar",
            "value": "Munnar"
        },
        {
            "label": "Muvattupula",
            "value": "Muvattupula"
        },
        {
            "label": "Muvattupuzha",
            "value": "Muvattupuzha"
        },
        {
            "label": "Nadapuram",
            "value": "Nadapuram"
        },
        {
            "label": "Naduvannur",
            "value": "Naduvannur"
        },
        {
            "label": "Nedumangad",
            "value": "Nedumangad"
        },
        {
            "label": "Neyyattinkara",
            "value": "Neyyattinkara"
        },
        {
            "label": "Nileshwar",
            "value": "Nileshwar"
        },
        {
            "label": "Ottappalam",
            "value": "Ottappalam"
        },
        {
            "label": "Palackattumala",
            "value": "Palackattumala"
        },
        {
            "label": "Palakkad district",
            "value": "Palakkad district"
        },
        {
            "label": "Palghat",
            "value": "Palghat"
        },
        {
            "label": "Panamaram",
            "value": "Panamaram"
        },
        {
            "label": "Pappinissheri",
            "value": "Pappinissheri"
        },
        {
            "label": "Paravur Tekkumbhagam",
            "value": "Paravur Tekkumbhagam"
        },
        {
            "label": "Pariyapuram",
            "value": "Pariyapuram"
        },
        {
            "label": "Pathanamthitta",
            "value": "Pathanamthitta"
        },
        {
            "label": "Pattanamtitta",
            "value": "Pattanamtitta"
        },
        {
            "label": "Payyannur",
            "value": "Payyannur"
        },
        {
            "label": "Perinthalmanna",
            "value": "Perinthalmanna"
        },
        {
            "label": "Perumbavoor",
            "value": "Perumbavoor"
        },
        {
            "label": "Perumpavur",
            "value": "Perumpavur"
        },
        {
            "label": "Perya",
            "value": "Perya"
        },
        {
            "label": "Piravam",
            "value": "Piravam"
        },
        {
            "label": "Ponmana",
            "value": "Ponmana"
        },
        {
            "label": "Ponnani",
            "value": "Ponnani"
        },
        {
            "label": "Punalur",
            "value": "Punalur"
        },
        {
            "label": "Ramamangalam",
            "value": "Ramamangalam"
        },
        {
            "label": "Sharanur",
            "value": "Sharanur"
        },
        {
            "label": "Shertallai",
            "value": "Shertallai"
        },
        {
            "label": "Talipparamba",
            "value": "Talipparamba"
        },
        {
            "label": "Tellicherry",
            "value": "Tellicherry"
        },
        {
            "label": "Thanniyam",
            "value": "Thanniyam"
        },
        {
            "label": "Thiruvananthapuram",
            "value": "Thiruvananthapuram"
        },
        {
            "label": "Thrissur",
            "value": "Thrissur"
        },
        {
            "label": "Tirur",
            "value": "Tirur"
        },
        {
            "label": "Tiruvalla",
            "value": "Tiruvalla"
        },
        {
            "label": "Vaikam",
            "value": "Vaikam"
        },
        {
            "label": "Varkala",
            "value": "Varkala"
        },
        {
            "label": "Vayalar",
            "value": "Vayalar"
        },
        {
            "label": "Vettur",
            "value": "Vettur"
        },
        {
            "label": "Wayanad",
            "value": "Wayanad"
        }
    ],
    "Ladakh": [
        {
            "label": "Kargil",
            "value": "Kargil"
        },
        {
            "label": "Leh",
            "value": "Leh"
        }
    ],
    "Lakshadweep": [
        {
            "label": "Kavaratti",
            "value": "Kavaratti"
        },
        {
            "label": "Lakshadweep",
            "value": "Lakshadweep"
        }
    ],
    "Madhya Pradesh": [
        {
            "label": "Agar",
            "value": "Agar"
        },
        {
            "label": "Ajaigarh",
            "value": "Ajaigarh"
        },
        {
            "label": "Akodia",
            "value": "Akodia"
        },
        {
            "label": "Alampur",
            "value": "Alampur"
        },
        {
            "label": "Alirajpur",
            "value": "Alirajpur"
        },
        {
            "label": "Alot",
            "value": "Alot"
        },
        {
            "label": "Amanganj",
            "value": "Amanganj"
        },
        {
            "label": "Amarkantak",
            "value": "Amarkantak"
        },
        {
            "label": "Amarpatan",
            "value": "Amarpatan"
        },
        {
            "label": "Amarwara",
            "value": "Amarwara"
        },
        {
            "label": "Ambah",
            "value": "Ambah"
        },
        {
            "label": "Amla",
            "value": "Amla"
        },
        {
            "label": "Anjad",
            "value": "Anjad"
        },
        {
            "label": "Antri",
            "value": "Antri"
        },
        {
            "label": "Anuppur",
            "value": "Anuppur"
        },
        {
            "label": "Aron",
            "value": "Aron"
        },
        {
            "label": "Ashoknagar",
            "value": "Ashoknagar"
        },
        {
            "label": "Ashta",
            "value": "Ashta"
        },
        {
            "label": "Babai",
            "value": "Babai"
        },
        {
            "label": "Badarwas",
            "value": "Badarwas"
        },
        {
            "label": "Badnawar",
            "value": "Badnawar"
        },
        {
            "label": "Bagh",
            "value": "Bagh"
        },
        {
            "label": "Bagli",
            "value": "Bagli"
        },
        {
            "label": "Baihar",
            "value": "Baihar"
        },
        {
            "label": "Baikunthpur",
            "value": "Baikunthpur"
        },
        {
            "label": "Bakshwaha",
            "value": "Bakshwaha"
        },
        {
            "label": "Balaghat",
            "value": "Balaghat"
        },
        {
            "label": "Baldeogarh",
            "value": "Baldeogarh"
        },
        {
            "label": "Bamna",
            "value": "Bamna"
        },
        {
            "label": "Bamor Kalan",
            "value": "Bamor Kalan"
        },
        {
            "label": "Bamora",
            "value": "Bamora"
        },
        {
            "label": "Banda",
            "value": "Banda"
        },
        {
            "label": "Barela",
            "value": "Barela"
        },
        {
            "label": "Barghat",
            "value": "Barghat"
        },
        {
            "label": "Bargi",
            "value": "Bargi"
        },
        {
            "label": "Barhi",
            "value": "Barhi"
        },
        {
            "label": "Barwani",
            "value": "Barwani"
        },
        {
            "label": "Basoda",
            "value": "Basoda"
        },
        {
            "label": "Begamganj",
            "value": "Begamganj"
        },
        {
            "label": "Beohari",
            "value": "Beohari"
        },
        {
            "label": "Berasia",
            "value": "Berasia"
        },
        {
            "label": "Betma",
            "value": "Betma"
        },
        {
            "label": "Betul",
            "value": "Betul"
        },
        {
            "label": "Betul Bazar",
            "value": "Betul Bazar"
        },
        {
            "label": "Bhabhra",
            "value": "Bhabhra"
        },
        {
            "label": "Bhainsdehi",
            "value": "Bhainsdehi"
        },
        {
            "label": "Bhander",
            "value": "Bhander"
        },
        {
            "label": "Bhanpura",
            "value": "Bhanpura"
        },
        {
            "label": "Bhawaniganj",
            "value": "Bhawaniganj"
        },
        {
            "label": "Bhikangaon",
            "value": "Bhikangaon"
        },
        {
            "label": "Bhind",
            "value": "Bhind"
        },
        {
            "label": "Bhitarwar",
            "value": "Bhitarwar"
        },
        {
            "label": "Bhopal",
            "value": "Bhopal"
        },
        {
            "label": "Biaora",
            "value": "Biaora"
        },
        {
            "label": "Bijawar",
            "value": "Bijawar"
        },
        {
            "label": "Bijrauni",
            "value": "Bijrauni"
        },
        {
            "label": "Bodri",
            "value": "Bodri"
        },
        {
            "label": "Burhanpur",
            "value": "Burhanpur"
        },
        {
            "label": "Burhar",
            "value": "Burhar"
        },
        {
            "label": "Chanderi",
            "value": "Chanderi"
        },
        {
            "label": "Chandia",
            "value": "Chandia"
        },
        {
            "label": "Chandla",
            "value": "Chandla"
        },
        {
            "label": "Chhatarpur",
            "value": "Chhatarpur"
        },
        {
            "label": "Chhindwara",
            "value": "Chhindwara"
        },
        {
            "label": "Chichli",
            "value": "Chichli"
        },
        {
            "label": "Chorhat",
            "value": "Chorhat"
        },
        {
            "label": "Daboh",
            "value": "Daboh"
        },
        {
            "label": "Dabra",
            "value": "Dabra"
        },
        {
            "label": "Damoh",
            "value": "Damoh"
        },
        {
            "label": "Datia",
            "value": "Datia"
        },
        {
            "label": "Deori Khas",
            "value": "Deori Khas"
        },
        {
            "label": "Depalpur",
            "value": "Depalpur"
        },
        {
            "label": "Dewas",
            "value": "Dewas"
        },
        {
            "label": "Dhamnod",
            "value": "Dhamnod"
        },
        {
            "label": "Dhana",
            "value": "Dhana"
        },
        {
            "label": "Dhar",
            "value": "Dhar"
        },
        {
            "label": "Dharampuri",
            "value": "Dharampuri"
        },
        {
            "label": "Dindori",
            "value": "Dindori"
        },
        {
            "label": "Etawa",
            "value": "Etawa"
        },
        {
            "label": "Gadarwara",
            "value": "Gadarwara"
        },
        {
            "label": "Garha Brahman",
            "value": "Garha Brahman"
        },
        {
            "label": "Garhakota",
            "value": "Garhakota"
        },
        {
            "label": "Gautampura",
            "value": "Gautampura"
        },
        {
            "label": "Ghansor",
            "value": "Ghansor"
        },
        {
            "label": "Gogapur",
            "value": "Gogapur"
        },
        {
            "label": "Gohadi",
            "value": "Gohadi"
        },
        {
            "label": "Govindgarh",
            "value": "Govindgarh"
        },
        {
            "label": "Guna",
            "value": "Guna"
        },
        {
            "label": "Gurh",
            "value": "Gurh"
        },
        {
            "label": "Gwalior",
            "value": "Gwalior"
        },
        {
            "label": "Harda",
            "value": "Harda"
        },
        {
            "label": "Harda Khas",
            "value": "Harda Khas"
        },
        {
            "label": "Harpalpur",
            "value": "Harpalpur"
        },
        {
            "label": "Harrai",
            "value": "Harrai"
        },
        {
            "label": "Harsud",
            "value": "Harsud"
        },
        {
            "label": "Hatod",
            "value": "Hatod"
        },
        {
            "label": "Hatta",
            "value": "Hatta"
        },
        {
            "label": "Hindoria",
            "value": "Hindoria"
        },
        {
            "label": "Hoshangabad",
            "value": "Hoshangabad"
        },
        {
            "label": "Iawar",
            "value": "Iawar"
        },
        {
            "label": "Ichhawar",
            "value": "Ichhawar"
        },
        {
            "label": "Iklehra",
            "value": "Iklehra"
        },
        {
            "label": "Indore",
            "value": "Indore"
        },
        {
            "label": "Isagarh",
            "value": "Isagarh"
        },
        {
            "label": "Itarsi",
            "value": "Itarsi"
        },
        {
            "label": "Jabalpur",
            "value": "Jabalpur"
        },
        {
            "label": "Jaisinghnagar",
            "value": "Jaisinghnagar"
        },
        {
            "label": "Jaithari",
            "value": "Jaithari"
        },
        {
            "label": "Jamai",
            "value": "Jamai"
        },
        {
            "label": "Jaora",
            "value": "Jaora"
        },
        {
            "label": "Jatara",
            "value": "Jatara"
        },
        {
            "label": "Jawad",
            "value": "Jawad"
        },
        {
            "label": "Jhabua",
            "value": "Jhabua"
        },
        {
            "label": "Jiran",
            "value": "Jiran"
        },
        {
            "label": "Jobat",
            "value": "Jobat"
        },
        {
            "label": "Kailaras",
            "value": "Kailaras"
        },
        {
            "label": "Kaimori",
            "value": "Kaimori"
        },
        {
            "label": "Kannod",
            "value": "Kannod"
        },
        {
            "label": "Kareli",
            "value": "Kareli"
        },
        {
            "label": "Karera",
            "value": "Karera"
        },
        {
            "label": "Karrapur",
            "value": "Karrapur"
        },
        {
            "label": "Kasrawad",
            "value": "Kasrawad"
        },
        {
            "label": "Katangi",
            "value": "Katangi"
        },
        {
            "label": "Katni",
            "value": "Katni"
        },
        {
            "label": "Khachrod",
            "value": "Khachrod"
        },
        {
            "label": "Khailar",
            "value": "Khailar"
        },
        {
            "label": "Khajuraho Group of Monuments",
            "value": "Khajuraho Group of Monuments"
        },
        {
            "label": "Khamaria",
            "value": "Khamaria"
        },
        {
            "label": "Khandwa",
            "value": "Khandwa"
        },
        {
            "label": "Khargapur",
            "value": "Khargapur"
        },
        {
            "label": "Khargone",
            "value": "Khargone"
        },
        {
            "label": "Khategaon",
            "value": "Khategaon"
        },
        {
            "label": "Khilchipur",
            "value": "Khilchipur"
        },
        {
            "label": "Khirkiyan",
            "value": "Khirkiyan"
        },
        {
            "label": "Khujner",
            "value": "Khujner"
        },
        {
            "label": "Khurai",
            "value": "Khurai"
        },
        {
            "label": "Kolaras",
            "value": "Kolaras"
        },
        {
            "label": "Korwai",
            "value": "Korwai"
        },
        {
            "label": "Kotar",
            "value": "Kotar"
        },
        {
            "label": "Kothi",
            "value": "Kothi"
        },
        {
            "label": "Kotma",
            "value": "Kotma"
        },
        {
            "label": "Kotwa",
            "value": "Kotwa"
        },
        {
            "label": "Kukshi",
            "value": "Kukshi"
        },
        {
            "label": "Kumbhraj",
            "value": "Kumbhraj"
        },
        {
            "label": "Lahar",
            "value": "Lahar"
        },
        {
            "label": "Lakhnadon",
            "value": "Lakhnadon"
        },
        {
            "label": "Leteri",
            "value": "Leteri"
        },
        {
            "label": "Lodhikheda",
            "value": "Lodhikheda"
        },
        {
            "label": "Machalpur",
            "value": "Machalpur"
        },
        {
            "label": "Madhogarh",
            "value": "Madhogarh"
        },
        {
            "label": "Maheshwar",
            "value": "Maheshwar"
        },
        {
            "label": "Mahgawan",
            "value": "Mahgawan"
        },
        {
            "label": "Maihar",
            "value": "Maihar"
        },
        {
            "label": "Majholi",
            "value": "Majholi"
        },
        {
            "label": "Maksi",
            "value": "Maksi"
        },
        {
            "label": "Malhargarh",
            "value": "Malhargarh"
        },
        {
            "label": "Manasa",
            "value": "Manasa"
        },
        {
            "label": "Manawar",
            "value": "Manawar"
        },
        {
            "label": "Mandideep",
            "value": "Mandideep"
        },
        {
            "label": "Mandla",
            "value": "Mandla"
        },
        {
            "label": "Mandleshwar",
            "value": "Mandleshwar"
        },
        {
            "label": "Mandsaur",
            "value": "Mandsaur"
        },
        {
            "label": "Mangawan",
            "value": "Mangawan"
        },
        {
            "label": "Manpur",
            "value": "Manpur"
        },
        {
            "label": "Mau",
            "value": "Mau"
        },
        {
            "label": "Mauganj",
            "value": "Mauganj"
        },
        {
            "label": "Mihona",
            "value": "Mihona"
        },
        {
            "label": "Mohgaon",
            "value": "Mohgaon"
        },
        {
            "label": "Morar",
            "value": "Morar"
        },
        {
            "label": "Morena",
            "value": "Morena"
        },
        {
            "label": "Multai",
            "value": "Multai"
        },
        {
            "label": "Mundi",
            "value": "Mundi"
        },
        {
            "label": "Mungaoli",
            "value": "Mungaoli"
        },
        {
            "label": "Murwara",
            "value": "Murwara"
        },
        {
            "label": "Nagda",
            "value": "Nagda"
        },
        {
            "label": "Nagod",
            "value": "Nagod"
        },
        {
            "label": "Naigarhi",
            "value": "Naigarhi"
        },
        {
            "label": "Nainpur",
            "value": "Nainpur"
        },
        {
            "label": "Namli",
            "value": "Namli"
        },
        {
            "label": "Naraini",
            "value": "Naraini"
        },
        {
            "label": "Narayangarh",
            "value": "Narayangarh"
        },
        {
            "label": "Narsimhapur",
            "value": "Narsimhapur"
        },
        {
            "label": "Narsinghgarh",
            "value": "Narsinghgarh"
        },
        {
            "label": "Narwar",
            "value": "Narwar"
        },
        {
            "label": "Nasrullahganj",
            "value": "Nasrullahganj"
        },
        {
            "label": "Neemuch",
            "value": "Neemuch"
        },
        {
            "label": "Nepanagar",
            "value": "Nepanagar"
        },
        {
            "label": "Orchha",
            "value": "Orchha"
        },
        {
            "label": "Pachmarhi",
            "value": "Pachmarhi"
        },
        {
            "label": "Palera",
            "value": "Palera"
        },
        {
            "label": "Pali",
            "value": "Pali"
        },
        {
            "label": "Panagar",
            "value": "Panagar"
        },
        {
            "label": "Panara",
            "value": "Panara"
        },
        {
            "label": "Pandhana",
            "value": "Pandhana"
        },
        {
            "label": "Pandhurna",
            "value": "Pandhurna"
        },
        {
            "label": "Panna",
            "value": "Panna"
        },
        {
            "label": "Pansemal",
            "value": "Pansemal"
        },
        {
            "label": "Parasia",
            "value": "Parasia"
        },
        {
            "label": "Patan",
            "value": "Patan"
        },
        {
            "label": "Patharia",
            "value": "Patharia"
        },
        {
            "label": "Pawai",
            "value": "Pawai"
        },
        {
            "label": "Petlawad",
            "value": "Petlawad"
        },
        {
            "label": "Piploda",
            "value": "Piploda"
        },
        {
            "label": "Pithampur",
            "value": "Pithampur"
        },
        {
            "label": "Porsa",
            "value": "Porsa"
        },
        {
            "label": "Punasa",
            "value": "Punasa"
        },
        {
            "label": "Raghogarh",
            "value": "Raghogarh"
        },
        {
            "label": "Rahatgarh",
            "value": "Rahatgarh"
        },
        {
            "label": "Raisen",
            "value": "Raisen"
        },
        {
            "label": "Rajgarh",
            "value": "Rajgarh"
        },
        {
            "label": "Rajnagar",
            "value": "Rajnagar"
        },
        {
            "label": "Rajpur",
            "value": "Rajpur"
        },
        {
            "label": "Rampura",
            "value": "Rampura"
        },
        {
            "label": "Ranapur",
            "value": "Ranapur"
        },
        {
            "label": "Ratangarh",
            "value": "Ratangarh"
        },
        {
            "label": "Ratlam",
            "value": "Ratlam"
        },
        {
            "label": "Rehli",
            "value": "Rehli"
        },
        {
            "label": "Rehti",
            "value": "Rehti"
        },
        {
            "label": "Rewa",
            "value": "Rewa"
        },
        {
            "label": "Sabalgarh",
            "value": "Sabalgarh"
        },
        {
            "label": "Sagar",
            "value": "Sagar"
        },
        {
            "label": "Sailana",
            "value": "Sailana"
        },
        {
            "label": "Sanawad",
            "value": "Sanawad"
        },
        {
            "label": "Sanchi",
            "value": "Sanchi"
        },
        {
            "label": "Sanwer",
            "value": "Sanwer"
        },
        {
            "label": "Sarangpur",
            "value": "Sarangpur"
        },
        {
            "label": "Satna",
            "value": "Satna"
        },
        {
            "label": "Satwas",
            "value": "Satwas"
        },
        {
            "label": "Saugor",
            "value": "Saugor"
        },
        {
            "label": "Sausar",
            "value": "Sausar"
        },
        {
            "label": "Sehore",
            "value": "Sehore"
        },
        {
            "label": "Sendhwa",
            "value": "Sendhwa"
        },
        {
            "label": "Seondha",
            "value": "Seondha"
        },
        {
            "label": "Seoni",
            "value": "Seoni"
        },
        {
            "label": "Seoni Malwa",
            "value": "Seoni Malwa"
        },
        {
            "label": "Shahdol",
            "value": "Shahdol"
        },
        {
            "label": "Shahgarh",
            "value": "Shahgarh"
        },
        {
            "label": "Shahpur",
            "value": "Shahpur"
        },
        {
            "label": "Shahpura",
            "value": "Shahpura"
        },
        {
            "label": "Shajapur",
            "value": "Shajapur"
        },
        {
            "label": "Shamgarh",
            "value": "Shamgarh"
        },
        {
            "label": "Sheopur",
            "value": "Sheopur"
        },
        {
            "label": "Shivpuri",
            "value": "Shivpuri"
        },
        {
            "label": "Shujalpur",
            "value": "Shujalpur"
        },
        {
            "label": "Sidhi",
            "value": "Sidhi"
        },
        {
            "label": "Sihora",
            "value": "Sihora"
        },
        {
            "label": "Simaria",
            "value": "Simaria"
        },
        {
            "label": "Singoli",
            "value": "Singoli"
        },
        {
            "label": "Singrauli",
            "value": "Singrauli"
        },
        {
            "label": "Sirmaur",
            "value": "Sirmaur"
        },
        {
            "label": "Sironj",
            "value": "Sironj"
        },
        {
            "label": "Sitamau",
            "value": "Sitamau"
        },
        {
            "label": "Sohagi",
            "value": "Sohagi"
        },
        {
            "label": "Sohagpur",
            "value": "Sohagpur"
        },
        {
            "label": "Sultanpur",
            "value": "Sultanpur"
        },
        {
            "label": "Susner",
            "value": "Susner"
        },
        {
            "label": "Tal",
            "value": "Tal"
        },
        {
            "label": "Talen",
            "value": "Talen"
        },
        {
            "label": "Tarana",
            "value": "Tarana"
        },
        {
            "label": "Tekanpur",
            "value": "Tekanpur"
        },
        {
            "label": "Tendukheda",
            "value": "Tendukheda"
        },
        {
            "label": "Teonthar",
            "value": "Teonthar"
        },
        {
            "label": "Thandla",
            "value": "Thandla"
        },
        {
            "label": "Tikamgarh",
            "value": "Tikamgarh"
        },
        {
            "label": "Tirodi",
            "value": "Tirodi"
        },
        {
            "label": "Udaipura",
            "value": "Udaipura"
        },
        {
            "label": "Ujjain",
            "value": "Ujjain"
        },
        {
            "label": "Ukwa",
            "value": "Ukwa"
        },
        {
            "label": "Umaria",
            "value": "Umaria"
        },
        {
            "label": "Umri",
            "value": "Umri"
        },
        {
            "label": "Unhel",
            "value": "Unhel"
        },
        {
            "label": "Vidisha",
            "value": "Vidisha"
        },
        {
            "label": "Waraseoni",
            "value": "Waraseoni"
        }
    ],
    "Maharashtra": [
        {
            "label": "Achalpur",
            "value": "Achalpur"
        },
        {
            "label": "Adawad",
            "value": "Adawad"
        },
        {
            "label": "Agar Panchaitan",
            "value": "Agar Panchaitan"
        },
        {
            "label": "Aheri",
            "value": "Aheri"
        },
        {
            "label": "Ahmadpur",
            "value": "Ahmadpur"
        },
        {
            "label": "Ahmednagar",
            "value": "Ahmednagar"
        },
        {
            "label": "Airoli",
            "value": "Airoli"
        },
        {
            "label": "Ajara",
            "value": "Ajara"
        },
        {
            "label": "Akalkot",
            "value": "Akalkot"
        },
        {
            "label": "Akluj",
            "value": "Akluj"
        },
        {
            "label": "Akola",
            "value": "Akola"
        },
        {
            "label": "Akolner",
            "value": "Akolner"
        },
        {
            "label": "Akot",
            "value": "Akot"
        },
        {
            "label": "Akrani",
            "value": "Akrani"
        },
        {
            "label": "Alandi",
            "value": "Alandi"
        },
        {
            "label": "Ale",
            "value": "Ale"
        },
        {
            "label": "Alibag",
            "value": "Alibag"
        },
        {
            "label": "Alkuti",
            "value": "Alkuti"
        },
        {
            "label": "Allapalli",
            "value": "Allapalli"
        },
        {
            "label": "Amalner",
            "value": "Amalner"
        },
        {
            "label": "Amarnath",
            "value": "Amarnath"
        },
        {
            "label": "Ambad",
            "value": "Ambad"
        },
        {
            "label": "Ambajogai",
            "value": "Ambajogai"
        },
        {
            "label": "Ambegaon",
            "value": "Ambegaon"
        },
        {
            "label": "Ambernath",
            "value": "Ambernath"
        },
        {
            "label": "Amgaon",
            "value": "Amgaon"
        },
        {
            "label": "Amravati",
            "value": "Amravati"
        },
        {
            "label": "Andheri",
            "value": "Andheri"
        },
        {
            "label": "Andura",
            "value": "Andura"
        },
        {
            "label": "Anjangaon",
            "value": "Anjangaon"
        },
        {
            "label": "Anjarle",
            "value": "Anjarle"
        },
        {
            "label": "Anshing",
            "value": "Anshing"
        },
        {
            "label": "Arag",
            "value": "Arag"
        },
        {
            "label": "Arangaon",
            "value": "Arangaon"
        },
        {
            "label": "Ardhapur",
            "value": "Ardhapur"
        },
        {
            "label": "Argaon",
            "value": "Argaon"
        },
        {
            "label": "Artist Village",
            "value": "Artist Village"
        },
        {
            "label": "Arvi",
            "value": "Arvi"
        },
        {
            "label": "Ashta",
            "value": "Ashta"
        },
        {
            "label": "Ashti",
            "value": "Ashti"
        },
        {
            "label": "Asoda",
            "value": "Asoda"
        },
        {
            "label": "Assaye",
            "value": "Assaye"
        },
        {
            "label": "Astagaon",
            "value": "Astagaon"
        },
        {
            "label": "Aundh Satara",
            "value": "Aundh Satara"
        },
        {
            "label": "Aurangabad",
            "value": "Aurangabad"
        },
        {
            "label": "Ausa",
            "value": "Ausa"
        },
        {
            "label": "Badlapur",
            "value": "Badlapur"
        },
        {
            "label": "Badnapur",
            "value": "Badnapur"
        },
        {
            "label": "Badnera",
            "value": "Badnera"
        },
        {
            "label": "Bagewadi",
            "value": "Bagewadi"
        },
        {
            "label": "Balapur",
            "value": "Balapur"
        },
        {
            "label": "Balapur Akola district",
            "value": "Balapur Akola district"
        },
        {
            "label": "Ballalpur",
            "value": "Ballalpur"
        },
        {
            "label": "Ballard Estate",
            "value": "Ballard Estate"
        },
        {
            "label": "Ballarpur",
            "value": "Ballarpur"
        },
        {
            "label": "Banda Maharashtra",
            "value": "Banda Maharashtra"
        },
        {
            "label": "Bandra",
            "value": "Bandra"
        },
        {
            "label": "Baner",
            "value": "Baner"
        },
        {
            "label": "Bankot",
            "value": "Bankot"
        },
        {
            "label": "Baramati",
            "value": "Baramati"
        },
        {
            "label": "Barsi",
            "value": "Barsi"
        },
        {
            "label": "Basmat",
            "value": "Basmat"
        },
        {
            "label": "Bavdhan",
            "value": "Bavdhan"
        },
        {
            "label": "Bawanbir",
            "value": "Bawanbir"
        },
        {
            "label": "Beed",
            "value": "Beed"
        },
        {
            "label": "Bhadgaon Maharashtra",
            "value": "Bhadgaon Maharashtra"
        },
        {
            "label": "Bhandara",
            "value": "Bhandara"
        },
        {
            "label": "Bhandardara",
            "value": "Bhandardara"
        },
        {
            "label": "Bhandup",
            "value": "Bhandup"
        },
        {
            "label": "Bhayandar",
            "value": "Bhayandar"
        },
        {
            "label": "Bhigvan",
            "value": "Bhigvan"
        },
        {
            "label": "Bhiwandi",
            "value": "Bhiwandi"
        },
        {
            "label": "Bhiwapur",
            "value": "Bhiwapur"
        },
        {
            "label": "Bhokar",
            "value": "Bhokar"
        },
        {
            "label": "Bhokardan",
            "value": "Bhokardan"
        },
        {
            "label": "Bhoom",
            "value": "Bhoom"
        },
        {
            "label": "Bhor",
            "value": "Bhor"
        },
        {
            "label": "Bhudgaon",
            "value": "Bhudgaon"
        },
        {
            "label": "Bhugaon",
            "value": "Bhugaon"
        },
        {
            "label": "Bhusaval",
            "value": "Bhusaval"
        },
        {
            "label": "Bijur",
            "value": "Bijur"
        },
        {
            "label": "Bilashi",
            "value": "Bilashi"
        },
        {
            "label": "Biloli",
            "value": "Biloli"
        },
        {
            "label": "Boisar",
            "value": "Boisar"
        },
        {
            "label": "Borgaon Manju",
            "value": "Borgaon Manju"
        },
        {
            "label": "Borivali",
            "value": "Borivali"
        },
        {
            "label": "Brahmapuri",
            "value": "Brahmapuri"
        },
        {
            "label": "Breach Candy",
            "value": "Breach Candy"
        },
        {
            "label": "Buldana",
            "value": "Buldana"
        },
        {
            "label": "Byculla",
            "value": "Byculla"
        },
        {
            "label": "Chakan",
            "value": "Chakan"
        },
        {
            "label": "Chakur",
            "value": "Chakur"
        },
        {
            "label": "Chalisgaon",
            "value": "Chalisgaon"
        },
        {
            "label": "Chanda",
            "value": "Chanda"
        },
        {
            "label": "Chandgad",
            "value": "Chandgad"
        },
        {
            "label": "Chandor",
            "value": "Chandor"
        },
        {
            "label": "Chandrapur",
            "value": "Chandrapur"
        },
        {
            "label": "Chandur",
            "value": "Chandur"
        },
        {
            "label": "Chandur Bazar",
            "value": "Chandur Bazar"
        },
        {
            "label": "Chausala",
            "value": "Chausala"
        },
        {
            "label": "Chembur",
            "value": "Chembur"
        },
        {
            "label": "Chicholi",
            "value": "Chicholi"
        },
        {
            "label": "Chichondi Patil",
            "value": "Chichondi Patil"
        },
        {
            "label": "Chikhli (Buldhana)",
            "value": "Chikhli (Buldhana)"
        },
        {
            "label": "Chikhli (Jalna)",
            "value": "Chikhli (Jalna)"
        },
        {
            "label": "Chimur",
            "value": "Chimur"
        },
        {
            "label": "Chinchani",
            "value": "Chinchani"
        },
        {
            "label": "Chinchpokli",
            "value": "Chinchpokli"
        },
        {
            "label": "Chiplun",
            "value": "Chiplun"
        },
        {
            "label": "Chopda",
            "value": "Chopda"
        },
        {
            "label": "Colaba",
            "value": "Colaba"
        },
        {
            "label": "Dabhol",
            "value": "Dabhol"
        },
        {
            "label": "Daddi",
            "value": "Daddi"
        },
        {
            "label": "Dahanu",
            "value": "Dahanu"
        },
        {
            "label": "Dahivel",
            "value": "Dahivel"
        },
        {
            "label": "Dapoli",
            "value": "Dapoli"
        },
        {
            "label": "Darwha",
            "value": "Darwha"
        },
        {
            "label": "Daryapur",
            "value": "Daryapur"
        },
        {
            "label": "Dattapur",
            "value": "Dattapur"
        },
        {
            "label": "Daulatabad",
            "value": "Daulatabad"
        },
        {
            "label": "Daund",
            "value": "Daund"
        },
        {
            "label": "Deccan Gymkhana",
            "value": "Deccan Gymkhana"
        },
        {
            "label": "Deglur",
            "value": "Deglur"
        },
        {
            "label": "Dehu",
            "value": "Dehu"
        },
        {
            "label": "Deolali",
            "value": "Deolali"
        },
        {
            "label": "Deolapar",
            "value": "Deolapar"
        },
        {
            "label": "Deoli",
            "value": "Deoli"
        },
        {
            "label": "Deoni",
            "value": "Deoni"
        },
        {
            "label": "Deulgaon Raja",
            "value": "Deulgaon Raja"
        },
        {
            "label": "Devrukh",
            "value": "Devrukh"
        },
        {
            "label": "Dharangaon",
            "value": "Dharangaon"
        },
        {
            "label": "Dharavi",
            "value": "Dharavi"
        },
        {
            "label": "Dharmabad",
            "value": "Dharmabad"
        },
        {
            "label": "Dharur",
            "value": "Dharur"
        },
        {
            "label": "Dhawalpuri",
            "value": "Dhawalpuri"
        },
        {
            "label": "Dhule",
            "value": "Dhule"
        },
        {
            "label": "Dhulia",
            "value": "Dhulia"
        },
        {
            "label": "Dighori",
            "value": "Dighori"
        },
        {
            "label": "Diglur",
            "value": "Diglur"
        },
        {
            "label": "Digras",
            "value": "Digras"
        },
        {
            "label": "Dindori Maharashtra",
            "value": "Dindori Maharashtra"
        },
        {
            "label": "Diveagar",
            "value": "Diveagar"
        },
        {
            "label": "Dombivli",
            "value": "Dombivli"
        },
        {
            "label": "Dondaicha",
            "value": "Dondaicha"
        },
        {
            "label": "Dongri",
            "value": "Dongri"
        },
        {
            "label": "Dudhani",
            "value": "Dudhani"
        },
        {
            "label": "Durgapur",
            "value": "Durgapur"
        },
        {
            "label": "Durgapur Chandrapur",
            "value": "Durgapur Chandrapur"
        },
        {
            "label": "Erandol",
            "value": "Erandol"
        },
        {
            "label": "Faizpur",
            "value": "Faizpur"
        },
        {
            "label": "Fort",
            "value": "Fort"
        },
        {
            "label": "Gadchiroli",
            "value": "Gadchiroli"
        },
        {
            "label": "Gadhinglaj",
            "value": "Gadhinglaj"
        },
        {
            "label": "Gangakher",
            "value": "Gangakher"
        },
        {
            "label": "Gangapur",
            "value": "Gangapur"
        },
        {
            "label": "Ganpatipule",
            "value": "Ganpatipule"
        },
        {
            "label": "Gevrai",
            "value": "Gevrai"
        },
        {
            "label": "Ghargaon",
            "value": "Ghargaon"
        },
        {
            "label": "Ghatanji",
            "value": "Ghatanji"
        },
        {
            "label": "Ghatkopar",
            "value": "Ghatkopar"
        },
        {
            "label": "Ghoti Budrukh",
            "value": "Ghoti Budrukh"
        },
        {
            "label": "Ghugus",
            "value": "Ghugus"
        },
        {
            "label": "Girgaon",
            "value": "Girgaon"
        },
        {
            "label": "Gondia",
            "value": "Gondia"
        },
        {
            "label": "Gorai",
            "value": "Gorai"
        },
        {
            "label": "Goregaon",
            "value": "Goregaon"
        },
        {
            "label": "Guhagar",
            "value": "Guhagar"
        },
        {
            "label": "Hadapsar Pune",
            "value": "Hadapsar Pune"
        },
        {
            "label": "Hadgaon",
            "value": "Hadgaon"
        },
        {
            "label": "Halkarni",
            "value": "Halkarni"
        },
        {
            "label": "Harangul",
            "value": "Harangul"
        },
        {
            "label": "Harnai",
            "value": "Harnai"
        },
        {
            "label": "Helwak",
            "value": "Helwak"
        },
        {
            "label": "Hinganghat",
            "value": "Hinganghat"
        },
        {
            "label": "Hingoli",
            "value": "Hingoli"
        },
        {
            "label": "Hirapur",
            "value": "Hirapur"
        },
        {
            "label": "Hirapur Hamesha",
            "value": "Hirapur Hamesha"
        },
        {
            "label": "Hotgi",
            "value": "Hotgi"
        },
        {
            "label": "Ichalkaranji",
            "value": "Ichalkaranji"
        },
        {
            "label": "Igatpuri",
            "value": "Igatpuri"
        },
        {
            "label": "Indapur",
            "value": "Indapur"
        },
        {
            "label": "Jaisingpur",
            "value": "Jaisingpur"
        },
        {
            "label": "Jaitapur",
            "value": "Jaitapur"
        },
        {
            "label": "Jakhangaon",
            "value": "Jakhangaon"
        },
        {
            "label": "Jalgaon",
            "value": "Jalgaon"
        },
        {
            "label": "Jalgaon Jamod",
            "value": "Jalgaon Jamod"
        },
        {
            "label": "Jalkot",
            "value": "Jalkot"
        },
        {
            "label": "Jalna",
            "value": "Jalna"
        },
        {
            "label": "Jamkhed",
            "value": "Jamkhed"
        },
        {
            "label": "Jamod",
            "value": "Jamod"
        },
        {
            "label": "Janephal",
            "value": "Janephal"
        },
        {
            "label": "Jaoli",
            "value": "Jaoli"
        },
        {
            "label": "Jat Sangli",
            "value": "Jat Sangli"
        },
        {
            "label": "Jategaon",
            "value": "Jategaon"
        },
        {
            "label": "Jawhar",
            "value": "Jawhar"
        },
        {
            "label": "Jaysingpur",
            "value": "Jaysingpur"
        },
        {
            "label": "Jejuri",
            "value": "Jejuri"
        },
        {
            "label": "Jintur",
            "value": "Jintur"
        },
        {
            "label": "Jogeshwari",
            "value": "Jogeshwari"
        },
        {
            "label": "Juhu",
            "value": "Juhu"
        },
        {
            "label": "Junnar",
            "value": "Junnar"
        },
        {
            "label": "Kachurwahi",
            "value": "Kachurwahi"
        },
        {
            "label": "Kadegaon",
            "value": "Kadegaon"
        },
        {
            "label": "Kadus",
            "value": "Kadus"
        },
        {
            "label": "Kagal",
            "value": "Kagal"
        },
        {
            "label": "Kaij",
            "value": "Kaij"
        },
        {
            "label": "Kalamb",
            "value": "Kalamb"
        },
        {
            "label": "Kalamb Osmanabad",
            "value": "Kalamb Osmanabad"
        },
        {
            "label": "Kalamboli",
            "value": "Kalamboli"
        },
        {
            "label": "Kalamnuri",
            "value": "Kalamnuri"
        },
        {
            "label": "Kalas",
            "value": "Kalas"
        },
        {
            "label": "Kali(DK)",
            "value": "Kali(DK)"
        },
        {
            "label": "Kalmeshwar",
            "value": "Kalmeshwar"
        },
        {
            "label": "Kalundri",
            "value": "Kalundri"
        },
        {
            "label": "Kalyan",
            "value": "Kalyan"
        },
        {
            "label": "Kalyani Nagar",
            "value": "Kalyani Nagar"
        },
        {
            "label": "Kamargaon",
            "value": "Kamargaon"
        },
        {
            "label": "Kamatgi",
            "value": "Kamatgi"
        },
        {
            "label": "Kamptee",
            "value": "Kamptee"
        },
        {
            "label": "Kandri",
            "value": "Kandri"
        },
        {
            "label": "Kankauli",
            "value": "Kankauli"
        },
        {
            "label": "Kankavli",
            "value": "Kankavli"
        },
        {
            "label": "Kannad",
            "value": "Kannad"
        },
        {
            "label": "Karad",
            "value": "Karad"
        },
        {
            "label": "Karajagi",
            "value": "Karajagi"
        },
        {
            "label": "Karanja",
            "value": "Karanja"
        },
        {
            "label": "Karanja Lad",
            "value": "Karanja Lad"
        },
        {
            "label": "Karjat",
            "value": "Karjat"
        },
        {
            "label": "Karkamb",
            "value": "Karkamb"
        },
        {
            "label": "Karmala",
            "value": "Karmala"
        },
        {
            "label": "Kasara",
            "value": "Kasara"
        },
        {
            "label": "Kasoda",
            "value": "Kasoda"
        },
        {
            "label": "Kati",
            "value": "Kati"
        },
        {
            "label": "Katol",
            "value": "Katol"
        },
        {
            "label": "Katral",
            "value": "Katral"
        },
        {
            "label": "Khadki",
            "value": "Khadki"
        },
        {
            "label": "Khalapur",
            "value": "Khalapur"
        },
        {
            "label": "Khallar",
            "value": "Khallar"
        },
        {
            "label": "Khamgaon",
            "value": "Khamgaon"
        },
        {
            "label": "Khanapur",
            "value": "Khanapur"
        },
        {
            "label": "Khandala",
            "value": "Khandala"
        },
        {
            "label": "Khangaon",
            "value": "Khangaon"
        },
        {
            "label": "Khapa",
            "value": "Khapa"
        },
        {
            "label": "Kharakvasla",
            "value": "Kharakvasla"
        },
        {
            "label": "Kharda",
            "value": "Kharda"
        },
        {
            "label": "Kharghar",
            "value": "Kharghar"
        },
        {
            "label": "Kharsundi",
            "value": "Kharsundi"
        },
        {
            "label": "Khed",
            "value": "Khed"
        },
        {
            "label": "Khetia",
            "value": "Khetia"
        },
        {
            "label": "Khoni",
            "value": "Khoni"
        },
        {
            "label": "Khopoli",
            "value": "Khopoli"
        },
        {
            "label": "Khuldabad",
            "value": "Khuldabad"
        },
        {
            "label": "Kinwat",
            "value": "Kinwat"
        },
        {
            "label": "Kodoli",
            "value": "Kodoli"
        },
        {
            "label": "Kolhapur",
            "value": "Kolhapur"
        },
        {
            "label": "Kondalwadi",
            "value": "Kondalwadi"
        },
        {
            "label": "Kondhali",
            "value": "Kondhali"
        },
        {
            "label": "Kopar Khairane",
            "value": "Kopar Khairane"
        },
        {
            "label": "Kopargaon",
            "value": "Kopargaon"
        },
        {
            "label": "Kopela",
            "value": "Kopela"
        },
        {
            "label": "Koradi",
            "value": "Koradi"
        },
        {
            "label": "Koregaon",
            "value": "Koregaon"
        },
        {
            "label": "Koynanagar",
            "value": "Koynanagar"
        },
        {
            "label": "Kudal",
            "value": "Kudal"
        },
        {
            "label": "Kuhi",
            "value": "Kuhi"
        },
        {
            "label": "Kurandvad",
            "value": "Kurandvad"
        },
        {
            "label": "Kurankhed",
            "value": "Kurankhed"
        },
        {
            "label": "Kurduvadi",
            "value": "Kurduvadi"
        },
        {
            "label": "Kusumba",
            "value": "Kusumba"
        },
        {
            "label": "Lakhandur",
            "value": "Lakhandur"
        },
        {
            "label": "Lanja",
            "value": "Lanja"
        },
        {
            "label": "Lasalgaon",
            "value": "Lasalgaon"
        },
        {
            "label": "Latur",
            "value": "Latur"
        },
        {
            "label": "Lavasa",
            "value": "Lavasa"
        },
        {
            "label": "Lohogaon",
            "value": "Lohogaon"
        },
        {
            "label": "Lonar",
            "value": "Lonar"
        },
        {
            "label": "Lonavla",
            "value": "Lonavla"
        },
        {
            "label": "Mahabaleshwar",
            "value": "Mahabaleshwar"
        },
        {
            "label": "Mahad",
            "value": "Mahad"
        },
        {
            "label": "Mahape",
            "value": "Mahape"
        },
        {
            "label": "Mahim",
            "value": "Mahim"
        },
        {
            "label": "Maindargi",
            "value": "Maindargi"
        },
        {
            "label": "Majalgaon",
            "value": "Majalgaon"
        },
        {
            "label": "Makhjan",
            "value": "Makhjan"
        },
        {
            "label": "Malabar Hill",
            "value": "Malabar Hill"
        },
        {
            "label": "Malad",
            "value": "Malad"
        },
        {
            "label": "Malegaon",
            "value": "Malegaon"
        },
        {
            "label": "Malkapur",
            "value": "Malkapur"
        },
        {
            "label": "Malvan",
            "value": "Malvan"
        },
        {
            "label": "Manchar",
            "value": "Manchar"
        },
        {
            "label": "Mandangad",
            "value": "Mandangad"
        },
        {
            "label": "Mandhal",
            "value": "Mandhal"
        },
        {
            "label": "Mandwa",
            "value": "Mandwa"
        },
        {
            "label": "Mangaon",
            "value": "Mangaon"
        },
        {
            "label": "Mangrul Pir",
            "value": "Mangrul Pir"
        },
        {
            "label": "Manjlegaon",
            "value": "Manjlegaon"
        },
        {
            "label": "Mankeshwar",
            "value": "Mankeshwar"
        },
        {
            "label": "Mankhurd",
            "value": "Mankhurd"
        },
        {
            "label": "Manmad",
            "value": "Manmad"
        },
        {
            "label": "Manor",
            "value": "Manor"
        },
        {
            "label": "Mansar",
            "value": "Mansar"
        },
        {
            "label": "Manwat",
            "value": "Manwat"
        },
        {
            "label": "Maregaon",
            "value": "Maregaon"
        },
        {
            "label": "Mastiholi",
            "value": "Mastiholi"
        },
        {
            "label": "Masur India",
            "value": "Masur India"
        },
        {
            "label": "Matheran",
            "value": "Matheran"
        },
        {
            "label": "Matunga",
            "value": "Matunga"
        },
        {
            "label": "Mazagaon",
            "value": "Mazagaon"
        },
        {
            "label": "Mehekar",
            "value": "Mehekar"
        },
        {
            "label": "Mehergaon",
            "value": "Mehergaon"
        },
        {
            "label": "Mehkar",
            "value": "Mehkar"
        },
        {
            "label": "Mhasla",
            "value": "Mhasla"
        },
        {
            "label": "Mhasvad",
            "value": "Mhasvad"
        },
        {
            "label": "Miraj",
            "value": "Miraj"
        },
        {
            "label": "Mohadi",
            "value": "Mohadi"
        },
        {
            "label": "Mohol",
            "value": "Mohol"
        },
        {
            "label": "Mohpa",
            "value": "Mohpa"
        },
        {
            "label": "Mokhada taluka",
            "value": "Mokhada taluka"
        },
        {
            "label": "Mora Maharashtra",
            "value": "Mora Maharashtra"
        },
        {
            "label": "Moram",
            "value": "Moram"
        },
        {
            "label": "Morsi",
            "value": "Morsi"
        },
        {
            "label": "Mowad",
            "value": "Mowad"
        },
        {
            "label": "Mudkhed",
            "value": "Mudkhed"
        },
        {
            "label": "Mukher",
            "value": "Mukher"
        },
        {
            "label": "Mul",
            "value": "Mul"
        },
        {
            "label": "Mulher",
            "value": "Mulher"
        },
        {
            "label": "Mulund",
            "value": "Mulund"
        },
        {
            "label": "Mumbai",
            "value": "Mumbai"
        },
        {
            "label": "Mumbai Suburban",
            "value": "Mumbai Suburban"
        },
        {
            "label": "Murbad",
            "value": "Murbad"
        },
        {
            "label": "Murgud",
            "value": "Murgud"
        },
        {
            "label": "Murtajapur",
            "value": "Murtajapur"
        },
        {
            "label": "Murud (Raigad)",
            "value": "Murud (Raigad)"
        },
        {
            "label": "Murud (Ratnagiri)",
            "value": "Murud (Ratnagiri)"
        },
        {
            "label": "Murum",
            "value": "Murum"
        },
        {
            "label": "Nadgaon",
            "value": "Nadgaon"
        },
        {
            "label": "Nagapur",
            "value": "Nagapur"
        },
        {
            "label": "Nagothana",
            "value": "Nagothana"
        },
        {
            "label": "Nagpur",
            "value": "Nagpur"
        },
        {
            "label": "Nagpur Division",
            "value": "Nagpur Division"
        },
        {
            "label": "Nala Sopara",
            "value": "Nala Sopara"
        },
        {
            "label": "Naldurg",
            "value": "Naldurg"
        },
        {
            "label": "Nalegaon",
            "value": "Nalegaon"
        },
        {
            "label": "Nampur",
            "value": "Nampur"
        },
        {
            "label": "Nanded",
            "value": "Nanded"
        },
        {
            "label": "Nandgaon",
            "value": "Nandgaon"
        },
        {
            "label": "Nandnee",
            "value": "Nandnee"
        },
        {
            "label": "Nandura",
            "value": "Nandura"
        },
        {
            "label": "Nandura Buzurg",
            "value": "Nandura Buzurg"
        },
        {
            "label": "Nandurbar",
            "value": "Nandurbar"
        },
        {
            "label": "Narayangaon",
            "value": "Narayangaon"
        },
        {
            "label": "Nardana",
            "value": "Nardana"
        },
        {
            "label": "Nariman Point",
            "value": "Nariman Point"
        },
        {
            "label": "Narkhed",
            "value": "Narkhed"
        },
        {
            "label": "Nashik",
            "value": "Nashik"
        },
        {
            "label": "Nashik Division",
            "value": "Nashik Division"
        },
        {
            "label": "Navapur",
            "value": "Navapur"
        },
        {
            "label": "Navi Mumbai",
            "value": "Navi Mumbai"
        },
        {
            "label": "Neral",
            "value": "Neral"
        },
        {
            "label": "Nerur",
            "value": "Nerur"
        },
        {
            "label": "Nevasa",
            "value": "Nevasa"
        },
        {
            "label": "Nighoj",
            "value": "Nighoj"
        },
        {
            "label": "Nilanga",
            "value": "Nilanga"
        },
        {
            "label": "Nipani",
            "value": "Nipani"
        },
        {
            "label": "Niphad",
            "value": "Niphad"
        },
        {
            "label": "Nira Narsingpur",
            "value": "Nira Narsingpur"
        },
        {
            "label": "Osmanabad",
            "value": "Osmanabad"
        },
        {
            "label": "Ozar",
            "value": "Ozar"
        },
        {
            "label": "Pabal",
            "value": "Pabal"
        },
        {
            "label": "Pachora",
            "value": "Pachora"
        },
        {
            "label": "Pahur Maharashtra",
            "value": "Pahur Maharashtra"
        },
        {
            "label": "Paithan",
            "value": "Paithan"
        },
        {
            "label": "Palghar",
            "value": "Palghar"
        },
        {
            "label": "Pali Raigad",
            "value": "Pali Raigad"
        },
        {
            "label": "Palso",
            "value": "Palso"
        },
        {
            "label": "Panchgani",
            "value": "Panchgani"
        },
        {
            "label": "Pandharpur",
            "value": "Pandharpur"
        },
        {
            "label": "Pandhurli",
            "value": "Pandhurli"
        },
        {
            "label": "Panhala",
            "value": "Panhala"
        },
        {
            "label": "Panvel",
            "value": "Panvel"
        },
        {
            "label": "Parbhani",
            "value": "Parbhani"
        },
        {
            "label": "Parel",
            "value": "Parel"
        },
        {
            "label": "Parli Vaijnath",
            "value": "Parli Vaijnath"
        },
        {
            "label": "Parner",
            "value": "Parner"
        },
        {
            "label": "Parola",
            "value": "Parola"
        },
        {
            "label": "Parseoni",
            "value": "Parseoni"
        },
        {
            "label": "Partur",
            "value": "Partur"
        },
        {
            "label": "Patan",
            "value": "Patan"
        },
        {
            "label": "Pathardi",
            "value": "Pathardi"
        },
        {
            "label": "Pathri",
            "value": "Pathri"
        },
        {
            "label": "Patur",
            "value": "Patur"
        },
        {
            "label": "Paturda",
            "value": "Paturda"
        },
        {
            "label": "Paud",
            "value": "Paud"
        },
        {
            "label": "Pauni",
            "value": "Pauni"
        },
        {
            "label": "Pawni",
            "value": "Pawni"
        },
        {
            "label": "Pedgaon",
            "value": "Pedgaon"
        },
        {
            "label": "Peint",
            "value": "Peint"
        },
        {
            "label": "Pen",
            "value": "Pen"
        },
        {
            "label": "Phaltan",
            "value": "Phaltan"
        },
        {
            "label": "Phulambri",
            "value": "Phulambri"
        },
        {
            "label": "Piliv",
            "value": "Piliv"
        },
        {
            "label": "Pimpalgaon Baswant",
            "value": "Pimpalgaon Baswant"
        },
        {
            "label": "Pimpalgaon Raja",
            "value": "Pimpalgaon Raja"
        },
        {
            "label": "Pimpri",
            "value": "Pimpri"
        },
        {
            "label": "Pimpri-Chinchwad",
            "value": "Pimpri-Chinchwad"
        },
        {
            "label": "Pipri",
            "value": "Pipri"
        },
        {
            "label": "Powai",
            "value": "Powai"
        },
        {
            "label": "Prabhadevi",
            "value": "Prabhadevi"
        },
        {
            "label": "Prakasha",
            "value": "Prakasha"
        },
        {
            "label": "Pulgaon",
            "value": "Pulgaon"
        },
        {
            "label": "Pune",
            "value": "Pune"
        },
        {
            "label": "Pune Division",
            "value": "Pune Division"
        },
        {
            "label": "Puntamba",
            "value": "Puntamba"
        },
        {
            "label": "Pural",
            "value": "Pural"
        },
        {
            "label": "Purna",
            "value": "Purna"
        },
        {
            "label": "Pusad",
            "value": "Pusad"
        },
        {
            "label": "Radhanagari",
            "value": "Radhanagari"
        },
        {
            "label": "Rahata",
            "value": "Rahata"
        },
        {
            "label": "Rahimatpur",
            "value": "Rahimatpur"
        },
        {
            "label": "Rahuri",
            "value": "Rahuri"
        },
        {
            "label": "Raigarh",
            "value": "Raigarh"
        },
        {
            "label": "Raireshwar",
            "value": "Raireshwar"
        },
        {
            "label": "Rajapur",
            "value": "Rajapur"
        },
        {
            "label": "Rajgurunagar",
            "value": "Rajgurunagar"
        },
        {
            "label": "Rajur",
            "value": "Rajur"
        },
        {
            "label": "Rajura",
            "value": "Rajura"
        },
        {
            "label": "Ralegaon",
            "value": "Ralegaon"
        },
        {
            "label": "Ramewadi",
            "value": "Ramewadi"
        },
        {
            "label": "Ramtek",
            "value": "Ramtek"
        },
        {
            "label": "Ratnagiri",
            "value": "Ratnagiri"
        },
        {
            "label": "Raver",
            "value": "Raver"
        },
        {
            "label": "Renapur",
            "value": "Renapur"
        },
        {
            "label": "Renavi",
            "value": "Renavi"
        },
        {
            "label": "Revadanda",
            "value": "Revadanda"
        },
        {
            "label": "Revdanda",
            "value": "Revdanda"
        },
        {
            "label": "Risod",
            "value": "Risod"
        },
        {
            "label": "Roha",
            "value": "Roha"
        },
        {
            "label": "Sailu",
            "value": "Sailu"
        },
        {
            "label": "Sakol",
            "value": "Sakol"
        },
        {
            "label": "Sakoli",
            "value": "Sakoli"
        },
        {
            "label": "Sakri",
            "value": "Sakri"
        },
        {
            "label": "Samudrapur",
            "value": "Samudrapur"
        },
        {
            "label": "Sangameshwar",
            "value": "Sangameshwar"
        },
        {
            "label": "Sangamner",
            "value": "Sangamner"
        },
        {
            "label": "Sangli",
            "value": "Sangli"
        },
        {
            "label": "Sangola",
            "value": "Sangola"
        },
        {
            "label": "Sangrampur Maharashtra",
            "value": "Sangrampur Maharashtra"
        },
        {
            "label": "Saoli",
            "value": "Saoli"
        },
        {
            "label": "Saoner",
            "value": "Saoner"
        },
        {
            "label": "Sarangkheda",
            "value": "Sarangkheda"
        },
        {
            "label": "Saswad",
            "value": "Saswad"
        },
        {
            "label": "Satana",
            "value": "Satana"
        },
        {
            "label": "Satara",
            "value": "Satara"
        },
        {
            "label": "Satara Division",
            "value": "Satara Division"
        },
        {
            "label": "Satpati",
            "value": "Satpati"
        },
        {
            "label": "Savantvadi",
            "value": "Savantvadi"
        },
        {
            "label": "Savda",
            "value": "Savda"
        },
        {
            "label": "Savlaj",
            "value": "Savlaj"
        },
        {
            "label": "Sawantvadi",
            "value": "Sawantvadi"
        },
        {
            "label": "Selu",
            "value": "Selu"
        },
        {
            "label": "Sevagram",
            "value": "Sevagram"
        },
        {
            "label": "Sewri",
            "value": "Sewri"
        },
        {
            "label": "Shahada",
            "value": "Shahada"
        },
        {
            "label": "Shahapur",
            "value": "Shahapur"
        },
        {
            "label": "Shedbal",
            "value": "Shedbal"
        },
        {
            "label": "Shegaon",
            "value": "Shegaon"
        },
        {
            "label": "Shevgaon",
            "value": "Shevgaon"
        },
        {
            "label": "Shikrapur",
            "value": "Shikrapur"
        },
        {
            "label": "Shiraguppi",
            "value": "Shiraguppi"
        },
        {
            "label": "Shirala",
            "value": "Shirala"
        },
        {
            "label": "Shirdi",
            "value": "Shirdi"
        },
        {
            "label": "Shirgaon",
            "value": "Shirgaon"
        },
        {
            "label": "Shirol",
            "value": "Shirol"
        },
        {
            "label": "Shirpur",
            "value": "Shirpur"
        },
        {
            "label": "Shirud",
            "value": "Shirud"
        },
        {
            "label": "Shirwal",
            "value": "Shirwal"
        },
        {
            "label": "Shivaji Nagar",
            "value": "Shivaji Nagar"
        },
        {
            "label": "Shrigonda",
            "value": "Shrigonda"
        },
        {
            "label": "Sillod",
            "value": "Sillod"
        },
        {
            "label": "Sindewahi",
            "value": "Sindewahi"
        },
        {
            "label": "Sindhudurg",
            "value": "Sindhudurg"
        },
        {
            "label": "Sindi",
            "value": "Sindi"
        },
        {
            "label": "Sindkheda",
            "value": "Sindkheda"
        },
        {
            "label": "Sinnar",
            "value": "Sinnar"
        },
        {
            "label": "Sion Mumbai",
            "value": "Sion Mumbai"
        },
        {
            "label": "Sironcha",
            "value": "Sironcha"
        },
        {
            "label": "Sirur",
            "value": "Sirur"
        },
        {
            "label": "Sivala East Godavari district",
            "value": "Sivala East Godavari district"
        },
        {
            "label": "Solapur",
            "value": "Solapur"
        },
        {
            "label": "Sonala",
            "value": "Sonala"
        },
        {
            "label": "Sonegaon",
            "value": "Sonegaon"
        },
        {
            "label": "Songir",
            "value": "Songir"
        },
        {
            "label": "Sonvad",
            "value": "Sonvad"
        },
        {
            "label": "Soygaon",
            "value": "Soygaon"
        },
        {
            "label": "Srivardhan",
            "value": "Srivardhan"
        },
        {
            "label": "Surgana",
            "value": "Surgana"
        },
        {
            "label": "Taklibhan",
            "value": "Taklibhan"
        },
        {
            "label": "Talbid",
            "value": "Talbid"
        },
        {
            "label": "Talegaon Dabhade",
            "value": "Talegaon Dabhade"
        },
        {
            "label": "Talegaon Dhamdhere",
            "value": "Talegaon Dhamdhere"
        },
        {
            "label": "Taloda",
            "value": "Taloda"
        },
        {
            "label": "Talode",
            "value": "Talode"
        },
        {
            "label": "Tarapur",
            "value": "Tarapur"
        },
        {
            "label": "Tardeo",
            "value": "Tardeo"
        },
        {
            "label": "Tasgaon",
            "value": "Tasgaon"
        },
        {
            "label": "Telhara",
            "value": "Telhara"
        },
        {
            "label": "Thalner",
            "value": "Thalner"
        },
        {
            "label": "Thane",
            "value": "Thane"
        },
        {
            "label": "Trimbak",
            "value": "Trimbak"
        },
        {
            "label": "Trombay",
            "value": "Trombay"
        },
        {
            "label": "Tuljapur",
            "value": "Tuljapur"
        },
        {
            "label": "Tumsar",
            "value": "Tumsar"
        },
        {
            "label": "Udgir",
            "value": "Udgir"
        },
        {
            "label": "Ulhasnagar",
            "value": "Ulhasnagar"
        },
        {
            "label": "Umarga",
            "value": "Umarga"
        },
        {
            "label": "Umarkhed",
            "value": "Umarkhed"
        },
        {
            "label": "Umred",
            "value": "Umred"
        },
        {
            "label": "Uran",
            "value": "Uran"
        },
        {
            "label": "Uruli Kanchan",
            "value": "Uruli Kanchan"
        },
        {
            "label": "Vada",
            "value": "Vada"
        },
        {
            "label": "Vadgaon",
            "value": "Vadgaon"
        },
        {
            "label": "Vadner",
            "value": "Vadner"
        },
        {
            "label": "Vaijapur",
            "value": "Vaijapur"
        },
        {
            "label": "Vairag",
            "value": "Vairag"
        },
        {
            "label": "Valsang",
            "value": "Valsang"
        },
        {
            "label": "Vangaon",
            "value": "Vangaon"
        },
        {
            "label": "Varangaon",
            "value": "Varangaon"
        },
        {
            "label": "Vashi",
            "value": "Vashi"
        },
        {
            "label": "Vasind",
            "value": "Vasind"
        },
        {
            "label": "Vatul",
            "value": "Vatul"
        },
        {
            "label": "Velas Maharashtra",
            "value": "Velas Maharashtra"
        },
        {
            "label": "Velneshwar",
            "value": "Velneshwar"
        },
        {
            "label": "Vengurla",
            "value": "Vengurla"
        },
        {
            "label": "Vijaydurg",
            "value": "Vijaydurg"
        },
        {
            "label": "Vikhroli",
            "value": "Vikhroli"
        },
        {
            "label": "Vile Parle",
            "value": "Vile Parle"
        },
        {
            "label": "Vinchur",
            "value": "Vinchur"
        },
        {
            "label": "Virar",
            "value": "Virar"
        },
        {
            "label": "Vita Maharashtra",
            "value": "Vita Maharashtra"
        },
        {
            "label": "Vite",
            "value": "Vite"
        },
        {
            "label": "Wadala",
            "value": "Wadala"
        },
        {
            "label": "Wadgaon",
            "value": "Wadgaon"
        },
        {
            "label": "Wadner",
            "value": "Wadner"
        },
        {
            "label": "Wadwani",
            "value": "Wadwani"
        },
        {
            "label": "Wagholi",
            "value": "Wagholi"
        },
        {
            "label": "Wai",
            "value": "Wai"
        },
        {
            "label": "Wakad",
            "value": "Wakad"
        },
        {
            "label": "Walgaon",
            "value": "Walgaon"
        },
        {
            "label": "Walki",
            "value": "Walki"
        },
        {
            "label": "Wani",
            "value": "Wani"
        },
        {
            "label": "Wardha",
            "value": "Wardha"
        },
        {
            "label": "Warora",
            "value": "Warora"
        },
        {
            "label": "Warud",
            "value": "Warud"
        },
        {
            "label": "Washim",
            "value": "Washim"
        },
        {
            "label": "Worli",
            "value": "Worli"
        },
        {
            "label": "Yaval",
            "value": "Yaval"
        },
        {
            "label": "Yavatmal",
            "value": "Yavatmal"
        },
        {
            "label": "Yeola",
            "value": "Yeola"
        }
    ],
    "Manipur": [
        {
            "label": "Bishnupur",
            "value": "Bishnupur"
        },
        {
            "label": "Chandel",
            "value": "Chandel"
        },
        {
            "label": "Churachandpur",
            "value": "Churachandpur"
        },
        {
            "label": "Imphal East",
            "value": "Imphal East"
        },
        {
            "label": "Imphal West",
            "value": "Imphal West"
        },
        {
            "label": "Jiribam",
            "value": "Jiribam"
        },
        {
            "label": "Kakching",
            "value": "Kakching"
        },
        {
            "label": "Kamjong",
            "value": "Kamjong"
        },
        {
            "label": "Kangpokpi",
            "value": "Kangpokpi"
        },
        {
            "label": "Noney",
            "value": "Noney"
        },
        {
            "label": "Pherzawl",
            "value": "Pherzawl"
        },
        {
            "label": "Senapati",
            "value": "Senapati"
        },
        {
            "label": "Tamenglong",
            "value": "Tamenglong"
        },
        {
            "label": "Tengnoupal",
            "value": "Tengnoupal"
        },
        {
            "label": "Thoubal",
            "value": "Thoubal"
        },
        {
            "label": "Ukhrul",
            "value": "Ukhrul"
        }
    ],
    "Meghalaya": [
        {
            "label": "Cherrapunji",
            "value": "Cherrapunji"
        },
        {
            "label": "East Garo Hills",
            "value": "East Garo Hills"
        },
        {
            "label": "East Jaintia Hills",
            "value": "East Jaintia Hills"
        },
        {
            "label": "East Khasi Hills",
            "value": "East Khasi Hills"
        },
        {
            "label": "Mairang",
            "value": "Mairang"
        },
        {
            "label": "Mankachar",
            "value": "Mankachar"
        },
        {
            "label": "Nongpoh",
            "value": "Nongpoh"
        },
        {
            "label": "Nongstoin",
            "value": "Nongstoin"
        },
        {
            "label": "North Garo Hills",
            "value": "North Garo Hills"
        },
        {
            "label": "Ri-Bhoi",
            "value": "Ri-Bhoi"
        },
        {
            "label": "Shillong",
            "value": "Shillong"
        },
        {
            "label": "South Garo Hills",
            "value": "South Garo Hills"
        },
        {
            "label": "South West Garo Hills",
            "value": "South West Garo Hills"
        },
        {
            "label": "South West Khasi Hills",
            "value": "South West Khasi Hills"
        },
        {
            "label": "Tura",
            "value": "Tura"
        },
        {
            "label": "West Garo Hills",
            "value": "West Garo Hills"
        },
        {
            "label": "West Jaintia Hills",
            "value": "West Jaintia Hills"
        },
        {
            "label": "West Khasi Hills",
            "value": "West Khasi Hills"
        }
    ],
    "Mizoram": [
        {
            "label": "Aizawl",
            "value": "Aizawl"
        },
        {
            "label": "Champhai",
            "value": "Champhai"
        },
        {
            "label": "Darlawn",
            "value": "Darlawn"
        },
        {
            "label": "Khawhai",
            "value": "Khawhai"
        },
        {
            "label": "Kolasib",
            "value": "Kolasib"
        },
        {
            "label": "Lawngtlai",
            "value": "Lawngtlai"
        },
        {
            "label": "Lunglei",
            "value": "Lunglei"
        },
        {
            "label": "Mamit",
            "value": "Mamit"
        },
        {
            "label": "North Vanlaiphai",
            "value": "North Vanlaiphai"
        },
        {
            "label": "Saiha",
            "value": "Saiha"
        },
        {
            "label": "Sairang",
            "value": "Sairang"
        },
        {
            "label": "Saitlaw",
            "value": "Saitlaw"
        },
        {
            "label": "Serchhip",
            "value": "Serchhip"
        },
        {
            "label": "Thenzawl",
            "value": "Thenzawl"
        }
    ],
    "Nagaland": [
        {
            "label": "Dimapur",
            "value": "Dimapur"
        },
        {
            "label": "Kohima",
            "value": "Kohima"
        },
        {
            "label": "Mokokchung",
            "value": "Mokokchung"
        },
        {
            "label": "Mon",
            "value": "Mon"
        },
        {
            "label": "Peren",
            "value": "Peren"
        },
        {
            "label": "Phek",
            "value": "Phek"
        },
        {
            "label": "Tuensang",
            "value": "Tuensang"
        },
        {
            "label": "Tuensang District",
            "value": "Tuensang District"
        },
        {
            "label": "Wokha",
            "value": "Wokha"
        },
        {
            "label": "Zunheboto",
            "value": "Zunheboto"
        }
    ],
    "Odisha": [
        {
            "label": "Angul",
            "value": "Angul"
        },
        {
            "label": "Angul District",
            "value": "Angul District"
        },
        {
            "label": "Asika",
            "value": "Asika"
        },
        {
            "label": "Athagarh",
            "value": "Athagarh"
        },
        {
            "label": "Bada Barabil",
            "value": "Bada Barabil"
        },
        {
            "label": "Balangir",
            "value": "Balangir"
        },
        {
            "label": "Balasore",
            "value": "Balasore"
        },
        {
            "label": "Baleshwar",
            "value": "Baleshwar"
        },
        {
            "label": "Balimila",
            "value": "Balimila"
        },
        {
            "label": "Balugaon",
            "value": "Balugaon"
        },
        {
            "label": "Banapur",
            "value": "Banapur"
        },
        {
            "label": "Banki",
            "value": "Banki"
        },
        {
            "label": "Banposh",
            "value": "Banposh"
        },
        {
            "label": "Baragarh",
            "value": "Baragarh"
        },
        {
            "label": "Barbil",
            "value": "Barbil"
        },
        {
            "label": "Bargarh",
            "value": "Bargarh"
        },
        {
            "label": "Barpali",
            "value": "Barpali"
        },
        {
            "label": "Basudebpur",
            "value": "Basudebpur"
        },
        {
            "label": "Baud",
            "value": "Baud"
        },
        {
            "label": "Baudh",
            "value": "Baudh"
        },
        {
            "label": "Belaguntha",
            "value": "Belaguntha"
        },
        {
            "label": "Bhadrak",
            "value": "Bhadrak"
        },
        {
            "label": "Bhadrakh",
            "value": "Bhadrakh"
        },
        {
            "label": "Bhanjanagar",
            "value": "Bhanjanagar"
        },
        {
            "label": "Bhawanipatna",
            "value": "Bhawanipatna"
        },
        {
            "label": "Bhuban",
            "value": "Bhuban"
        },
        {
            "label": "Bhubaneswar",
            "value": "Bhubaneswar"
        },
        {
            "label": "Binka",
            "value": "Binka"
        },
        {
            "label": "Birmitrapur",
            "value": "Birmitrapur"
        },
        {
            "label": "Bolanikhodan",
            "value": "Bolanikhodan"
        },
        {
            "label": "Brahmapur",
            "value": "Brahmapur"
        },
        {
            "label": "Brajarajnagar",
            "value": "Brajarajnagar"
        },
        {
            "label": "Buguda",
            "value": "Buguda"
        },
        {
            "label": "Burla",
            "value": "Burla"
        },
        {
            "label": "Champua",
            "value": "Champua"
        },
        {
            "label": "Chandbali",
            "value": "Chandbali"
        },
        {
            "label": "Chatrapur",
            "value": "Chatrapur"
        },
        {
            "label": "Chikitigarh",
            "value": "Chikitigarh"
        },
        {
            "label": "Chittarkonda",
            "value": "Chittarkonda"
        },
        {
            "label": "Cuttack",
            "value": "Cuttack"
        },
        {
            "label": "Daitari",
            "value": "Daitari"
        },
        {
            "label": "Deogarh",
            "value": "Deogarh"
        },
        {
            "label": "Dhenkanal",
            "value": "Dhenkanal"
        },
        {
            "label": "Digapahandi",
            "value": "Digapahandi"
        },
        {
            "label": "Gajapati",
            "value": "Gajapati"
        },
        {
            "label": "Ganjam",
            "value": "Ganjam"
        },
        {
            "label": "Gopalpur",
            "value": "Gopalpur"
        },
        {
            "label": "Gudari",
            "value": "Gudari"
        },
        {
            "label": "Gunupur",
            "value": "Gunupur"
        },
        {
            "label": "Hinjilikatu",
            "value": "Hinjilikatu"
        },
        {
            "label": "Hirakud",
            "value": "Hirakud"
        },
        {
            "label": "Jagatsinghpur",
            "value": "Jagatsinghpur"
        },
        {
            "label": "Jajpur",
            "value": "Jajpur"
        },
        {
            "label": "Jaleshwar",
            "value": "Jaleshwar"
        },
        {
            "label": "Jatani",
            "value": "Jatani"
        },
        {
            "label": "Jeypore",
            "value": "Jeypore"
        },
        {
            "label": "Jharsuguda",
            "value": "Jharsuguda"
        },
        {
            "label": "Jharsuguda District",
            "value": "Jharsuguda District"
        },
        {
            "label": "Kaintragarh",
            "value": "Kaintragarh"
        },
        {
            "label": "Kalahandi",
            "value": "Kalahandi"
        },
        {
            "label": "Kamakhyanagar",
            "value": "Kamakhyanagar"
        },
        {
            "label": "Kandhamal",
            "value": "Kandhamal"
        },
        {
            "label": "Kantabanji",
            "value": "Kantabanji"
        },
        {
            "label": "Kantilo",
            "value": "Kantilo"
        },
        {
            "label": "Kendrapara",
            "value": "Kendrapara"
        },
        {
            "label": "Kendujhar",
            "value": "Kendujhar"
        },
        {
            "label": "Kesinga",
            "value": "Kesinga"
        },
        {
            "label": "Khallikot",
            "value": "Khallikot"
        },
        {
            "label": "Kharhial",
            "value": "Kharhial"
        },
        {
            "label": "Khordha",
            "value": "Khordha"
        },
        {
            "label": "Khurda",
            "value": "Khurda"
        },
        {
            "label": "Kiri Buru",
            "value": "Kiri Buru"
        },
        {
            "label": "Kodala",
            "value": "Kodala"
        },
        {
            "label": "Konarka",
            "value": "Konarka"
        },
        {
            "label": "Koraput",
            "value": "Koraput"
        },
        {
            "label": "Kuchaiburi",
            "value": "Kuchaiburi"
        },
        {
            "label": "Kuchinda",
            "value": "Kuchinda"
        },
        {
            "label": "Malkangiri",
            "value": "Malkangiri"
        },
        {
            "label": "Mayurbhanj",
            "value": "Mayurbhanj"
        },
        {
            "label": "Nabarangpur",
            "value": "Nabarangpur"
        },
        {
            "label": "Nayagarh",
            "value": "Nayagarh"
        },
        {
            "label": "Nayagarh District",
            "value": "Nayagarh District"
        },
        {
            "label": "Nilgiri",
            "value": "Nilgiri"
        },
        {
            "label": "Nimaparha",
            "value": "Nimaparha"
        },
        {
            "label": "Nowrangapur",
            "value": "Nowrangapur"
        },
        {
            "label": "Nuapada",
            "value": "Nuapada"
        },
        {
            "label": "Padampur",
            "value": "Padampur"
        },
        {
            "label": "Paradip Garh",
            "value": "Paradip Garh"
        },
        {
            "label": "Patamundai",
            "value": "Patamundai"
        },
        {
            "label": "Patnagarh",
            "value": "Patnagarh"
        },
        {
            "label": "Phulbani",
            "value": "Phulbani"
        },
        {
            "label": "Pipili",
            "value": "Pipili"
        },
        {
            "label": "Polasara",
            "value": "Polasara"
        },
        {
            "label": "Puri",
            "value": "Puri"
        },
        {
            "label": "Purushottampur",
            "value": "Purushottampur"
        },
        {
            "label": "Rambha",
            "value": "Rambha"
        },
        {
            "label": "Raurkela",
            "value": "Raurkela"
        },
        {
            "label": "Rayagada",
            "value": "Rayagada"
        },
        {
            "label": "Remuna",
            "value": "Remuna"
        },
        {
            "label": "Rengali",
            "value": "Rengali"
        },
        {
            "label": "Sambalpur",
            "value": "Sambalpur"
        },
        {
            "label": "Sonepur",
            "value": "Sonepur"
        },
        {
            "label": "Sorada",
            "value": "Sorada"
        },
        {
            "label": "Soro",
            "value": "Soro"
        },
        {
            "label": "Subarnapur",
            "value": "Subarnapur"
        },
        {
            "label": "Sundargarh",
            "value": "Sundargarh"
        },
        {
            "label": "Talcher",
            "value": "Talcher"
        },
        {
            "label": "Tarabha",
            "value": "Tarabha"
        },
        {
            "label": "Titlagarh",
            "value": "Titlagarh"
        },
        {
            "label": "Udayagiri",
            "value": "Udayagiri"
        }
    ],
    "Puducherry": [
        {
            "label": "Karaikal",
            "value": "Karaikal"
        },
        {
            "label": "Mahe",
            "value": "Mahe"
        },
        {
            "label": "Puducherry",
            "value": "Puducherry"
        },
        {
            "label": "Yanam",
            "value": "Yanam"
        }
    ],
    "Punjab": [
        {
            "label": "Abohar",
            "value": "Abohar"
        },
        {
            "label": "Adampur",
            "value": "Adampur"
        },
        {
            "label": "Ajitgarh",
            "value": "Ajitgarh"
        },
        {
            "label": "Ajnala",
            "value": "Ajnala"
        },
        {
            "label": "Akalgarh",
            "value": "Akalgarh"
        },
        {
            "label": "Alawalpur",
            "value": "Alawalpur"
        },
        {
            "label": "Amloh",
            "value": "Amloh"
        },
        {
            "label": "Amritsar",
            "value": "Amritsar"
        },
        {
            "label": "Anandpur Sahib",
            "value": "Anandpur Sahib"
        },
        {
            "label": "Badhni Kalan",
            "value": "Badhni Kalan"
        },
        {
            "label": "Bagha Purana",
            "value": "Bagha Purana"
        },
        {
            "label": "Bakloh",
            "value": "Bakloh"
        },
        {
            "label": "Balachor",
            "value": "Balachor"
        },
        {
            "label": "Banga",
            "value": "Banga"
        },
        {
            "label": "Banur",
            "value": "Banur"
        },
        {
            "label": "Barnala",
            "value": "Barnala"
        },
        {
            "label": "Batala",
            "value": "Batala"
        },
        {
            "label": "Begowal",
            "value": "Begowal"
        },
        {
            "label": "Bhadaur",
            "value": "Bhadaur"
        },
        {
            "label": "Bhatinda",
            "value": "Bhatinda"
        },
        {
            "label": "Bhawanigarh",
            "value": "Bhawanigarh"
        },
        {
            "label": "Bhikhi",
            "value": "Bhikhi"
        },
        {
            "label": "Bhogpur",
            "value": "Bhogpur"
        },
        {
            "label": "Bholath",
            "value": "Bholath"
        },
        {
            "label": "Budhlada",
            "value": "Budhlada"
        },
        {
            "label": "Chima",
            "value": "Chima"
        },
        {
            "label": "Dasuya",
            "value": "Dasuya"
        },
        {
            "label": "Dera Baba Nanak",
            "value": "Dera Baba Nanak"
        },
        {
            "label": "Dera Bassi",
            "value": "Dera Bassi"
        },
        {
            "label": "Dhanaula",
            "value": "Dhanaula"
        },
        {
            "label": "Dhariwal",
            "value": "Dhariwal"
        },
        {
            "label": "Dhilwan",
            "value": "Dhilwan"
        },
        {
            "label": "Dhudi",
            "value": "Dhudi"
        },
        {
            "label": "Dhuri",
            "value": "Dhuri"
        },
        {
            "label": "Dina Nagar",
            "value": "Dina Nagar"
        },
        {
            "label": "Dirba",
            "value": "Dirba"
        },
        {
            "label": "Doraha",
            "value": "Doraha"
        },
        {
            "label": "Faridkot",
            "value": "Faridkot"
        },
        {
            "label": "Fatehgarh Churian",
            "value": "Fatehgarh Churian"
        },
        {
            "label": "Fatehgarh Sahib",
            "value": "Fatehgarh Sahib"
        },
        {
            "label": "Fazilka",
            "value": "Fazilka"
        },
        {
            "label": "Firozpur",
            "value": "Firozpur"
        },
        {
            "label": "Firozpur District",
            "value": "Firozpur District"
        },
        {
            "label": "Gardhiwala",
            "value": "Gardhiwala"
        },
        {
            "label": "Garhshankar",
            "value": "Garhshankar"
        },
        {
            "label": "Ghanaur",
            "value": "Ghanaur"
        },
        {
            "label": "Giddarbaha",
            "value": "Giddarbaha"
        },
        {
            "label": "Gurdaspur",
            "value": "Gurdaspur"
        },
        {
            "label": "Guru Har Sahai",
            "value": "Guru Har Sahai"
        },
        {
            "label": "Hajipur",
            "value": "Hajipur"
        },
        {
            "label": "Hariana",
            "value": "Hariana"
        },
        {
            "label": "Hoshiarpur",
            "value": "Hoshiarpur"
        },
        {
            "label": "Ishanpur",
            "value": "Ishanpur"
        },
        {
            "label": "Jagraon",
            "value": "Jagraon"
        },
        {
            "label": "Jaito",
            "value": "Jaito"
        },
        {
            "label": "Jalalabad",
            "value": "Jalalabad"
        },
        {
            "label": "Jalandhar",
            "value": "Jalandhar"
        },
        {
            "label": "Jandiala",
            "value": "Jandiala"
        },
        {
            "label": "Jandiala Guru",
            "value": "Jandiala Guru"
        },
        {
            "label": "Kalanaur",
            "value": "Kalanaur"
        },
        {
            "label": "Kapurthala",
            "value": "Kapurthala"
        },
        {
            "label": "Kartarpur",
            "value": "Kartarpur"
        },
        {
            "label": "Khamanon",
            "value": "Khamanon"
        },
        {
            "label": "Khanna",
            "value": "Khanna"
        },
        {
            "label": "Kharar",
            "value": "Kharar"
        },
        {
            "label": "Khemkaran",
            "value": "Khemkaran"
        },
        {
            "label": "Kot Isa Khan",
            "value": "Kot Isa Khan"
        },
        {
            "label": "Kotkapura",
            "value": "Kotkapura"
        },
        {
            "label": "Laungowal",
            "value": "Laungowal"
        },
        {
            "label": "Ludhiana",
            "value": "Ludhiana"
        },
        {
            "label": "Machhiwara",
            "value": "Machhiwara"
        },
        {
            "label": "Majitha",
            "value": "Majitha"
        },
        {
            "label": "Makhu",
            "value": "Makhu"
        },
        {
            "label": "Malaut",
            "value": "Malaut"
        },
        {
            "label": "Malerkotla",
            "value": "Malerkotla"
        },
        {
            "label": "Mansa",
            "value": "Mansa"
        },
        {
            "label": "Maur Mandi",
            "value": "Maur Mandi"
        },
        {
            "label": "Moga",
            "value": "Moga"
        },
        {
            "label": "Mohali",
            "value": "Mohali"
        },
        {
            "label": "Morinda",
            "value": "Morinda"
        },
        {
            "label": "Mukerian",
            "value": "Mukerian"
        },
        {
            "label": "Nabha",
            "value": "Nabha"
        },
        {
            "label": "Nakodar",
            "value": "Nakodar"
        },
        {
            "label": "Nangal",
            "value": "Nangal"
        },
        {
            "label": "Nawanshahr",
            "value": "Nawanshahr"
        },
        {
            "label": "Nurmahal",
            "value": "Nurmahal"
        },
        {
            "label": "Nurpur Kalan",
            "value": "Nurpur Kalan"
        },
        {
            "label": "Pathankot",
            "value": "Pathankot"
        },
        {
            "label": "Patiala",
            "value": "Patiala"
        },
        {
            "label": "Patti",
            "value": "Patti"
        },
        {
            "label": "Phagwara",
            "value": "Phagwara"
        },
        {
            "label": "Phillaur",
            "value": "Phillaur"
        },
        {
            "label": "Qadian",
            "value": "Qadian"
        },
        {
            "label": "Rahon",
            "value": "Rahon"
        },
        {
            "label": "Raikot",
            "value": "Raikot"
        },
        {
            "label": "Rajasansi",
            "value": "Rajasansi"
        },
        {
            "label": "Rajpura",
            "value": "Rajpura"
        },
        {
            "label": "Ram Das",
            "value": "Ram Das"
        },
        {
            "label": "Rampura",
            "value": "Rampura"
        },
        {
            "label": "Rupnagar",
            "value": "Rupnagar"
        },
        {
            "label": "Samrala",
            "value": "Samrala"
        },
        {
            "label": "Sanaur",
            "value": "Sanaur"
        },
        {
            "label": "Sangrur",
            "value": "Sangrur"
        },
        {
            "label": "Sardulgarh",
            "value": "Sardulgarh"
        },
        {
            "label": "Shahid Bhagat Singh Nagar",
            "value": "Shahid Bhagat Singh Nagar"
        },
        {
            "label": "Shahkot",
            "value": "Shahkot"
        },
        {
            "label": "Sham Churasi",
            "value": "Sham Churasi"
        },
        {
            "label": "Sirhind-Fategarh",
            "value": "Sirhind-Fategarh"
        },
        {
            "label": "Sri Muktsar Sahib",
            "value": "Sri Muktsar Sahib"
        },
        {
            "label": "Sultanpur Lodhi",
            "value": "Sultanpur Lodhi"
        },
        {
            "label": "Sunam",
            "value": "Sunam"
        },
        {
            "label": "Talwandi Bhai",
            "value": "Talwandi Bhai"
        },
        {
            "label": "Talwara",
            "value": "Talwara"
        },
        {
            "label": "Tarn Taran Sahib",
            "value": "Tarn Taran Sahib"
        },
        {
            "label": "Zira",
            "value": "Zira"
        }
    ],
    "Rajasthan": [
        {
            "label": "Abhaneri",
            "value": "Abhaneri"
        },
        {
            "label": "Abu",
            "value": "Abu"
        },
        {
            "label": "Abu Road",
            "value": "Abu Road"
        },
        {
            "label": "Ajmer",
            "value": "Ajmer"
        },
        {
            "label": "Aklera",
            "value": "Aklera"
        },
        {
            "label": "Alwar",
            "value": "Alwar"
        },
        {
            "label": "Amet",
            "value": "Amet"
        },
        {
            "label": "Anta",
            "value": "Anta"
        },
        {
            "label": "Anupgarh",
            "value": "Anupgarh"
        },
        {
            "label": "Asind",
            "value": "Asind"
        },
        {
            "label": "Bagar",
            "value": "Bagar"
        },
        {
            "label": "Bakani",
            "value": "Bakani"
        },
        {
            "label": "Bali",
            "value": "Bali"
        },
        {
            "label": "Balotra",
            "value": "Balotra"
        },
        {
            "label": "Bandikui",
            "value": "Bandikui"
        },
        {
            "label": "Banswara",
            "value": "Banswara"
        },
        {
            "label": "Baran",
            "value": "Baran"
        },
        {
            "label": "Bari",
            "value": "Bari"
        },
        {
            "label": "Bari Sadri",
            "value": "Bari Sadri"
        },
        {
            "label": "Barmer",
            "value": "Barmer"
        },
        {
            "label": "Basi",
            "value": "Basi"
        },
        {
            "label": "Basni",
            "value": "Basni"
        },
        {
            "label": "Baswa",
            "value": "Baswa"
        },
        {
            "label": "Bayana",
            "value": "Bayana"
        },
        {
            "label": "Beawar",
            "value": "Beawar"
        },
        {
            "label": "Begun",
            "value": "Begun"
        },
        {
            "label": "Behror",
            "value": "Behror"
        },
        {
            "label": "Bhadasar",
            "value": "Bhadasar"
        },
        {
            "label": "Bhadra",
            "value": "Bhadra"
        },
        {
            "label": "Bharatpur",
            "value": "Bharatpur"
        },
        {
            "label": "Bhasawar",
            "value": "Bhasawar"
        },
        {
            "label": "Bhilwara",
            "value": "Bhilwara"
        },
        {
            "label": "Bhindar",
            "value": "Bhindar"
        },
        {
            "label": "Bhinmal",
            "value": "Bhinmal"
        },
        {
            "label": "Bhiwadi",
            "value": "Bhiwadi"
        },
        {
            "label": "Bhuma",
            "value": "Bhuma"
        },
        {
            "label": "Bikaner",
            "value": "Bikaner"
        },
        {
            "label": "Bilara",
            "value": "Bilara"
        },
        {
            "label": "Bissau",
            "value": "Bissau"
        },
        {
            "label": "Borkhera",
            "value": "Borkhera"
        },
        {
            "label": "Bundi",
            "value": "Bundi"
        },
        {
            "label": "Chaksu",
            "value": "Chaksu"
        },
        {
            "label": "Chechat",
            "value": "Chechat"
        },
        {
            "label": "Chhabra",
            "value": "Chhabra"
        },
        {
            "label": "Chhapar",
            "value": "Chhapar"
        },
        {
            "label": "Chhoti Sadri",
            "value": "Chhoti Sadri"
        },
        {
            "label": "Chidawa",
            "value": "Chidawa"
        },
        {
            "label": "Chittaurgarh",
            "value": "Chittaurgarh"
        },
        {
            "label": "Churu",
            "value": "Churu"
        },
        {
            "label": "Dariba",
            "value": "Dariba"
        },
        {
            "label": "Dausa",
            "value": "Dausa"
        },
        {
            "label": "Deoli",
            "value": "Deoli"
        },
        {
            "label": "Deshnoke",
            "value": "Deshnoke"
        },
        {
            "label": "Devgarh",
            "value": "Devgarh"
        },
        {
            "label": "Dhaulpur",
            "value": "Dhaulpur"
        },
        {
            "label": "Didwana",
            "value": "Didwana"
        },
        {
            "label": "Dig",
            "value": "Dig"
        },
        {
            "label": "Dungarpur",
            "value": "Dungarpur"
        },
        {
            "label": "Fatehpur",
            "value": "Fatehpur"
        },
        {
            "label": "Galiakot",
            "value": "Galiakot"
        },
        {
            "label": "Ganganagar",
            "value": "Ganganagar"
        },
        {
            "label": "Gangapur",
            "value": "Gangapur"
        },
        {
            "label": "Govindgarh",
            "value": "Govindgarh"
        },
        {
            "label": "Gulabpura",
            "value": "Gulabpura"
        },
        {
            "label": "Hanumangarh",
            "value": "Hanumangarh"
        },
        {
            "label": "Hindaun",
            "value": "Hindaun"
        },
        {
            "label": "Jahazpur",
            "value": "Jahazpur"
        },
        {
            "label": "Jaipur",
            "value": "Jaipur"
        },
        {
            "label": "Jaisalmer",
            "value": "Jaisalmer"
        },
        {
            "label": "Jaitaran",
            "value": "Jaitaran"
        },
        {
            "label": "Jalor",
            "value": "Jalor"
        },
        {
            "label": "Jalore",
            "value": "Jalore"
        },
        {
            "label": "Jhalawar",
            "value": "Jhalawar"
        },
        {
            "label": "Jhalrapatan",
            "value": "Jhalrapatan"
        },
        {
            "label": "Jhunjhunun",
            "value": "Jhunjhunun"
        },
        {
            "label": "Jobner",
            "value": "Jobner"
        },
        {
            "label": "Jodhpur",
            "value": "Jodhpur"
        },
        {
            "label": "Kaman",
            "value": "Kaman"
        },
        {
            "label": "Kanor",
            "value": "Kanor"
        },
        {
            "label": "Kapren",
            "value": "Kapren"
        },
        {
            "label": "Karanpur",
            "value": "Karanpur"
        },
        {
            "label": "Karauli",
            "value": "Karauli"
        },
        {
            "label": "Kekri",
            "value": "Kekri"
        },
        {
            "label": "Keshorai Patan",
            "value": "Keshorai Patan"
        },
        {
            "label": "Khandela",
            "value": "Khandela"
        },
        {
            "label": "Khanpur",
            "value": "Khanpur"
        },
        {
            "label": "Khetri",
            "value": "Khetri"
        },
        {
            "label": "Kishangarh",
            "value": "Kishangarh"
        },
        {
            "label": "Kota",
            "value": "Kota"
        },
        {
            "label": "Kotputli",
            "value": "Kotputli"
        },
        {
            "label": "Kuchaman",
            "value": "Kuchaman"
        },
        {
            "label": "Kuchera",
            "value": "Kuchera"
        },
        {
            "label": "Kumher",
            "value": "Kumher"
        },
        {
            "label": "Kushalgarh",
            "value": "Kushalgarh"
        },
        {
            "label": "Lachhmangarh Sikar",
            "value": "Lachhmangarh Sikar"
        },
        {
            "label": "Ladnun",
            "value": "Ladnun"
        },
        {
            "label": "Lakheri",
            "value": "Lakheri"
        },
        {
            "label": "Lalsot",
            "value": "Lalsot"
        },
        {
            "label": "Losal",
            "value": "Losal"
        },
        {
            "label": "Mahwah",
            "value": "Mahwah"
        },
        {
            "label": "Makrana",
            "value": "Makrana"
        },
        {
            "label": "Malpura",
            "value": "Malpura"
        },
        {
            "label": "Mandal",
            "value": "Mandal"
        },
        {
            "label": "Mandalgarh",
            "value": "Mandalgarh"
        },
        {
            "label": "Mandawar",
            "value": "Mandawar"
        },
        {
            "label": "Mangrol",
            "value": "Mangrol"
        },
        {
            "label": "Manohar Thana",
            "value": "Manohar Thana"
        },
        {
            "label": "Manoharpur",
            "value": "Manoharpur"
        },
        {
            "label": "Meethari Marwar",
            "value": "Meethari Marwar"
        },
        {
            "label": "Merta",
            "value": "Merta"
        },
        {
            "label": "Mundwa",
            "value": "Mundwa"
        },
        {
            "label": "Nadbai",
            "value": "Nadbai"
        },
        {
            "label": "Nagar",
            "value": "Nagar"
        },
        {
            "label": "Nagaur",
            "value": "Nagaur"
        },
        {
            "label": "Nainwa",
            "value": "Nainwa"
        },
        {
            "label": "Napasar",
            "value": "Napasar"
        },
        {
            "label": "Naraina",
            "value": "Naraina"
        },
        {
            "label": "Nasirabad",
            "value": "Nasirabad"
        },
        {
            "label": "Nathdwara",
            "value": "Nathdwara"
        },
        {
            "label": "Nawa",
            "value": "Nawa"
        },
        {
            "label": "Nawalgarh",
            "value": "Nawalgarh"
        },
        {
            "label": "Neem ka Thana",
            "value": "Neem ka Thana"
        },
        {
            "label": "Nimaj",
            "value": "Nimaj"
        },
        {
            "label": "Nimbahera",
            "value": "Nimbahera"
        },
        {
            "label": "Niwai",
            "value": "Niwai"
        },
        {
            "label": "Nohar",
            "value": "Nohar"
        },
        {
            "label": "Nokha",
            "value": "Nokha"
        },
        {
            "label": "Padampur",
            "value": "Padampur"
        },
        {
            "label": "Pali",
            "value": "Pali"
        },
        {
            "label": "Partapur",
            "value": "Partapur"
        },
        {
            "label": "Parvatsar",
            "value": "Parvatsar"
        },
        {
            "label": "Phalodi",
            "value": "Phalodi"
        },
        {
            "label": "Phulera",
            "value": "Phulera"
        },
        {
            "label": "Pilani",
            "value": "Pilani"
        },
        {
            "label": "Pilibangan",
            "value": "Pilibangan"
        },
        {
            "label": "Pindwara",
            "value": "Pindwara"
        },
        {
            "label": "Pipar",
            "value": "Pipar"
        },
        {
            "label": "Pirawa",
            "value": "Pirawa"
        },
        {
            "label": "Pokaran",
            "value": "Pokaran"
        },
        {
            "label": "Pratapgarh",
            "value": "Pratapgarh"
        },
        {
            "label": "Pushkar",
            "value": "Pushkar"
        },
        {
            "label": "Raipur",
            "value": "Raipur"
        },
        {
            "label": "Raisinghnagar",
            "value": "Raisinghnagar"
        },
        {
            "label": "Rajakhera",
            "value": "Rajakhera"
        },
        {
            "label": "Rajaldesar",
            "value": "Rajaldesar"
        },
        {
            "label": "Rajgarh",
            "value": "Rajgarh"
        },
        {
            "label": "Rajsamand",
            "value": "Rajsamand"
        },
        {
            "label": "Ramganj Mandi",
            "value": "Ramganj Mandi"
        },
        {
            "label": "Ramgarh",
            "value": "Ramgarh"
        },
        {
            "label": "Rani",
            "value": "Rani"
        },
        {
            "label": "Ratangarh",
            "value": "Ratangarh"
        },
        {
            "label": "Rawatbhata",
            "value": "Rawatbhata"
        },
        {
            "label": "Rawatsar",
            "value": "Rawatsar"
        },
        {
            "label": "Ringas",
            "value": "Ringas"
        },
        {
            "label": "Sadri",
            "value": "Sadri"
        },
        {
            "label": "Salumbar",
            "value": "Salumbar"
        },
        {
            "label": "Sambhar",
            "value": "Sambhar"
        },
        {
            "label": "Samdari",
            "value": "Samdari"
        },
        {
            "label": "Sanchor",
            "value": "Sanchor"
        },
        {
            "label": "Sangaria",
            "value": "Sangaria"
        },
        {
            "label": "Sangod",
            "value": "Sangod"
        },
        {
            "label": "Sardarshahr",
            "value": "Sardarshahr"
        },
        {
            "label": "Sarwar",
            "value": "Sarwar"
        },
        {
            "label": "Sawai Madhopur",
            "value": "Sawai Madhopur"
        },
        {
            "label": "Shahpura",
            "value": "Shahpura"
        },
        {
            "label": "Sheoganj",
            "value": "Sheoganj"
        },
        {
            "label": "Sikar",
            "value": "Sikar"
        },
        {
            "label": "Sirohi",
            "value": "Sirohi"
        },
        {
            "label": "Siwana",
            "value": "Siwana"
        },
        {
            "label": "Sojat",
            "value": "Sojat"
        },
        {
            "label": "Sri Dungargarh",
            "value": "Sri Dungargarh"
        },
        {
            "label": "Sri Madhopur",
            "value": "Sri Madhopur"
        },
        {
            "label": "Sujangarh",
            "value": "Sujangarh"
        },
        {
            "label": "Suket",
            "value": "Suket"
        },
        {
            "label": "Sunel",
            "value": "Sunel"
        },
        {
            "label": "Surajgarh",
            "value": "Surajgarh"
        },
        {
            "label": "Suratgarh",
            "value": "Suratgarh"
        },
        {
            "label": "Takhatgarh",
            "value": "Takhatgarh"
        },
        {
            "label": "Taranagar",
            "value": "Taranagar"
        },
        {
            "label": "Tijara",
            "value": "Tijara"
        },
        {
            "label": "Todabhim",
            "value": "Todabhim"
        },
        {
            "label": "Todaraisingh",
            "value": "Todaraisingh"
        },
        {
            "label": "Tonk",
            "value": "Tonk"
        },
        {
            "label": "Udaipur",
            "value": "Udaipur"
        },
        {
            "label": "Udpura",
            "value": "Udpura"
        },
        {
            "label": "Uniara",
            "value": "Uniara"
        },
        {
            "label": "Wer",
            "value": "Wer"
        }
    ],
    "Sikkim": [
        {
            "label": "East District",
            "value": "East District"
        },
        {
            "label": "Gangtok",
            "value": "Gangtok"
        },
        {
            "label": "Gyalshing",
            "value": "Gyalshing"
        },
        {
            "label": "Jorethang",
            "value": "Jorethang"
        },
        {
            "label": "Mangan",
            "value": "Mangan"
        },
        {
            "label": "Namchi",
            "value": "Namchi"
        },
        {
            "label": "Naya Bazar",
            "value": "Naya Bazar"
        },
        {
            "label": "North District",
            "value": "North District"
        },
        {
            "label": "Rangpo",
            "value": "Rangpo"
        },
        {
            "label": "Singtam",
            "value": "Singtam"
        },
        {
            "label": "South District",
            "value": "South District"
        },
        {
            "label": "West District",
            "value": "West District"
        }
    ],
    "Tamil Nadu": [
        {
            "label": "Abiramam",
            "value": "Abiramam"
        },
        {
            "label": "Adirampattinam",
            "value": "Adirampattinam"
        },
        {
            "label": "Aduthurai",
            "value": "Aduthurai"
        },
        {
            "label": "Alagapuram",
            "value": "Alagapuram"
        },
        {
            "label": "Alandur",
            "value": "Alandur"
        },
        {
            "label": "Alanganallur",
            "value": "Alanganallur"
        },
        {
            "label": "Alangayam",
            "value": "Alangayam"
        },
        {
            "label": "Alangudi",
            "value": "Alangudi"
        },
        {
            "label": "Alangulam",
            "value": "Alangulam"
        },
        {
            "label": "Alappakkam",
            "value": "Alappakkam"
        },
        {
            "label": "Alwa Tirunagari",
            "value": "Alwa Tirunagari"
        },
        {
            "label": "Ambasamudram",
            "value": "Ambasamudram"
        },
        {
            "label": "Ambattur",
            "value": "Ambattur"
        },
        {
            "label": "Ambur",
            "value": "Ambur"
        },
        {
            "label": "Ammapettai",
            "value": "Ammapettai"
        },
        {
            "label": "Anamalais",
            "value": "Anamalais"
        },
        {
            "label": "Andippatti",
            "value": "Andippatti"
        },
        {
            "label": "Annamalainagar",
            "value": "Annamalainagar"
        },
        {
            "label": "Annavasal",
            "value": "Annavasal"
        },
        {
            "label": "Annur",
            "value": "Annur"
        },
        {
            "label": "Anthiyur",
            "value": "Anthiyur"
        },
        {
            "label": "Arakkonam",
            "value": "Arakkonam"
        },
        {
            "label": "Arantangi",
            "value": "Arantangi"
        },
        {
            "label": "Arcot",
            "value": "Arcot"
        },
        {
            "label": "Arimalam",
            "value": "Arimalam"
        },
        {
            "label": "Ariyalur",
            "value": "Ariyalur"
        },
        {
            "label": "Arni",
            "value": "Arni"
        },
        {
            "label": "Arumbavur",
            "value": "Arumbavur"
        },
        {
            "label": "Arumuganeri",
            "value": "Arumuganeri"
        },
        {
            "label": "Aruppukkottai",
            "value": "Aruppukkottai"
        },
        {
            "label": "Aruvankad",
            "value": "Aruvankad"
        },
        {
            "label": "Attayyampatti",
            "value": "Attayyampatti"
        },
        {
            "label": "Attur",
            "value": "Attur"
        },
        {
            "label": "Auroville",
            "value": "Auroville"
        },
        {
            "label": "Avadi",
            "value": "Avadi"
        },
        {
            "label": "Avinashi",
            "value": "Avinashi"
        },
        {
            "label": "Ayakudi",
            "value": "Ayakudi"
        },
        {
            "label": "Ayyampettai",
            "value": "Ayyampettai"
        },
        {
            "label": "Belur",
            "value": "Belur"
        },
        {
            "label": "Bhavani",
            "value": "Bhavani"
        },
        {
            "label": "Bodinayakkanur",
            "value": "Bodinayakkanur"
        },
        {
            "label": "Chengam",
            "value": "Chengam"
        },
        {
            "label": "Chennai",
            "value": "Chennai"
        },
        {
            "label": "Chennimalai",
            "value": "Chennimalai"
        },
        {
            "label": "Chetput",
            "value": "Chetput"
        },
        {
            "label": "Chettipalaiyam",
            "value": "Chettipalaiyam"
        },
        {
            "label": "Cheyyar",
            "value": "Cheyyar"
        },
        {
            "label": "Cheyyur",
            "value": "Cheyyur"
        },
        {
            "label": "Chidambaram",
            "value": "Chidambaram"
        },
        {
            "label": "Chingleput",
            "value": "Chingleput"
        },
        {
            "label": "Chinna Salem",
            "value": "Chinna Salem"
        },
        {
            "label": "Chinnamanur",
            "value": "Chinnamanur"
        },
        {
            "label": "Chinnasekkadu",
            "value": "Chinnasekkadu"
        },
        {
            "label": "Cholapuram",
            "value": "Cholapuram"
        },
        {
            "label": "Coimbatore",
            "value": "Coimbatore"
        },
        {
            "label": "Colachel",
            "value": "Colachel"
        },
        {
            "label": "Cuddalore",
            "value": "Cuddalore"
        },
        {
            "label": "Cumbum",
            "value": "Cumbum"
        },
        {
            "label": "Denkanikota",
            "value": "Denkanikota"
        },
        {
            "label": "Desur",
            "value": "Desur"
        },
        {
            "label": "Devadanappatti",
            "value": "Devadanappatti"
        },
        {
            "label": "Devakottai",
            "value": "Devakottai"
        },
        {
            "label": "Dhali",
            "value": "Dhali"
        },
        {
            "label": "Dharapuram",
            "value": "Dharapuram"
        },
        {
            "label": "Dharmapuri",
            "value": "Dharmapuri"
        },
        {
            "label": "Dindigul",
            "value": "Dindigul"
        },
        {
            "label": "Dusi",
            "value": "Dusi"
        },
        {
            "label": "Elayirampannai",
            "value": "Elayirampannai"
        },
        {
            "label": "Elumalai",
            "value": "Elumalai"
        },
        {
            "label": "Eral",
            "value": "Eral"
        },
        {
            "label": "Eraniel",
            "value": "Eraniel"
        },
        {
            "label": "Erode",
            "value": "Erode"
        },
        {
            "label": "Erumaippatti",
            "value": "Erumaippatti"
        },
        {
            "label": "Ettaiyapuram",
            "value": "Ettaiyapuram"
        },
        {
            "label": "Gandhi Nagar",
            "value": "Gandhi Nagar"
        },
        {
            "label": "Gangaikondan",
            "value": "Gangaikondan"
        },
        {
            "label": "Gangavalli",
            "value": "Gangavalli"
        },
        {
            "label": "Gingee",
            "value": "Gingee"
        },
        {
            "label": "Gobichettipalayam",
            "value": "Gobichettipalayam"
        },
        {
            "label": "Gudalur",
            "value": "Gudalur"
        },
        {
            "label": "Gudiyatham",
            "value": "Gudiyatham"
        },
        {
            "label": "Guduvancheri",
            "value": "Guduvancheri"
        },
        {
            "label": "Gummidipundi",
            "value": "Gummidipundi"
        },
        {
            "label": "Harur",
            "value": "Harur"
        },
        {
            "label": "Hosur",
            "value": "Hosur"
        },
        {
            "label": "Idappadi",
            "value": "Idappadi"
        },
        {
            "label": "Ilampillai",
            "value": "Ilampillai"
        },
        {
            "label": "Iluppur",
            "value": "Iluppur"
        },
        {
            "label": "Injambakkam",
            "value": "Injambakkam"
        },
        {
            "label": "Irugur",
            "value": "Irugur"
        },
        {
            "label": "Jalakandapuram",
            "value": "Jalakandapuram"
        },
        {
            "label": "Jalarpet",
            "value": "Jalarpet"
        },
        {
            "label": "Jayamkondacholapuram",
            "value": "Jayamkondacholapuram"
        },
        {
            "label": "Kadambur",
            "value": "Kadambur"
        },
        {
            "label": "Kadayanallur",
            "value": "Kadayanallur"
        },
        {
            "label": "Kalakkadu",
            "value": "Kalakkadu"
        },
        {
            "label": "Kalavai",
            "value": "Kalavai"
        },
        {
            "label": "Kallakkurichchi",
            "value": "Kallakkurichchi"
        },
        {
            "label": "Kallidaikurichi",
            "value": "Kallidaikurichi"
        },
        {
            "label": "Kallupatti",
            "value": "Kallupatti"
        },
        {
            "label": "Kalugumalai",
            "value": "Kalugumalai"
        },
        {
            "label": "Kamuthi",
            "value": "Kamuthi"
        },
        {
            "label": "Kanadukattan",
            "value": "Kanadukattan"
        },
        {
            "label": "Kancheepuram",
            "value": "Kancheepuram"
        },
        {
            "label": "Kanchipuram",
            "value": "Kanchipuram"
        },
        {
            "label": "Kangayam",
            "value": "Kangayam"
        },
        {
            "label": "Kanniyakumari",
            "value": "Kanniyakumari"
        },
        {
            "label": "Karaikkudi",
            "value": "Karaikkudi"
        },
        {
            "label": "Karamadai",
            "value": "Karamadai"
        },
        {
            "label": "Karambakkudi",
            "value": "Karambakkudi"
        },
        {
            "label": "Kariapatti",
            "value": "Kariapatti"
        },
        {
            "label": "Karumbakkam",
            "value": "Karumbakkam"
        },
        {
            "label": "Karur",
            "value": "Karur"
        },
        {
            "label": "Katpadi",
            "value": "Katpadi"
        },
        {
            "label": "Kattivakkam",
            "value": "Kattivakkam"
        },
        {
            "label": "Kattupputtur",
            "value": "Kattupputtur"
        },
        {
            "label": "Kaveripatnam",
            "value": "Kaveripatnam"
        },
        {
            "label": "Kayalpattinam",
            "value": "Kayalpattinam"
        },
        {
            "label": "Kayattar",
            "value": "Kayattar"
        },
        {
            "label": "Keelakarai",
            "value": "Keelakarai"
        },
        {
            "label": "Kelamangalam",
            "value": "Kelamangalam"
        },
        {
            "label": "Kil Bhuvanagiri",
            "value": "Kil Bhuvanagiri"
        },
        {
            "label": "Kilvelur",
            "value": "Kilvelur"
        },
        {
            "label": "Kiranur",
            "value": "Kiranur"
        },
        {
            "label": "Kodaikanal",
            "value": "Kodaikanal"
        },
        {
            "label": "Kodumudi",
            "value": "Kodumudi"
        },
        {
            "label": "Kombai",
            "value": "Kombai"
        },
        {
            "label": "Konganapuram",
            "value": "Konganapuram"
        },
        {
            "label": "Koothanallur",
            "value": "Koothanallur"
        },
        {
            "label": "Koradachcheri",
            "value": "Koradachcheri"
        },
        {
            "label": "Korampallam",
            "value": "Korampallam"
        },
        {
            "label": "Kotagiri",
            "value": "Kotagiri"
        },
        {
            "label": "Kottaiyur",
            "value": "Kottaiyur"
        },
        {
            "label": "Kovilpatti",
            "value": "Kovilpatti"
        },
        {
            "label": "Krishnagiri",
            "value": "Krishnagiri"
        },
        {
            "label": "Kulattur",
            "value": "Kulattur"
        },
        {
            "label": "Kulittalai",
            "value": "Kulittalai"
        },
        {
            "label": "Kumaralingam",
            "value": "Kumaralingam"
        },
        {
            "label": "Kumbakonam",
            "value": "Kumbakonam"
        },
        {
            "label": "Kunnattur",
            "value": "Kunnattur"
        },
        {
            "label": "Kurinjippadi",
            "value": "Kurinjippadi"
        },
        {
            "label": "Kuttalam",
            "value": "Kuttalam"
        },
        {
            "label": "Kuzhithurai",
            "value": "Kuzhithurai"
        },
        {
            "label": "Lalgudi",
            "value": "Lalgudi"
        },
        {
            "label": "Madambakkam",
            "value": "Madambakkam"
        },
        {
            "label": "Madipakkam",
            "value": "Madipakkam"
        },
        {
            "label": "Madukkarai",
            "value": "Madukkarai"
        },
        {
            "label": "Madukkur",
            "value": "Madukkur"
        },
        {
            "label": "Madurai",
            "value": "Madurai"
        },
        {
            "label": "Madurantakam",
            "value": "Madurantakam"
        },
        {
            "label": "Mallapuram",
            "value": "Mallapuram"
        },
        {
            "label": "Mallasamudram",
            "value": "Mallasamudram"
        },
        {
            "label": "Mallur",
            "value": "Mallur"
        },
        {
            "label": "Manali",
            "value": "Manali"
        },
        {
            "label": "Manalurpettai",
            "value": "Manalurpettai"
        },
        {
            "label": "Manamadurai",
            "value": "Manamadurai"
        },
        {
            "label": "Manappakkam",
            "value": "Manappakkam"
        },
        {
            "label": "Manapparai",
            "value": "Manapparai"
        },
        {
            "label": "Manavalakurichi",
            "value": "Manavalakurichi"
        },
        {
            "label": "Mandapam",
            "value": "Mandapam"
        },
        {
            "label": "Mangalam",
            "value": "Mangalam"
        },
        {
            "label": "Mannargudi",
            "value": "Mannargudi"
        },
        {
            "label": "Marakkanam",
            "value": "Marakkanam"
        },
        {
            "label": "Marandahalli",
            "value": "Marandahalli"
        },
        {
            "label": "Masinigudi",
            "value": "Masinigudi"
        },
        {
            "label": "Mattur",
            "value": "Mattur"
        },
        {
            "label": "Mayiladuthurai",
            "value": "Mayiladuthurai"
        },
        {
            "label": "Melur",
            "value": "Melur"
        },
        {
            "label": "Mettuppalaiyam",
            "value": "Mettuppalaiyam"
        },
        {
            "label": "Mettur",
            "value": "Mettur"
        },
        {
            "label": "Minjur",
            "value": "Minjur"
        },
        {
            "label": "Mohanur",
            "value": "Mohanur"
        },
        {
            "label": "Mudukulattur",
            "value": "Mudukulattur"
        },
        {
            "label": "Mulanur",
            "value": "Mulanur"
        },
        {
            "label": "Musiri",
            "value": "Musiri"
        },
        {
            "label": "Muttupet",
            "value": "Muttupet"
        },
        {
            "label": "Naduvattam",
            "value": "Naduvattam"
        },
        {
            "label": "Nagapattinam",
            "value": "Nagapattinam"
        },
        {
            "label": "Nagercoil",
            "value": "Nagercoil"
        },
        {
            "label": "Namagiripettai",
            "value": "Namagiripettai"
        },
        {
            "label": "Namakkal",
            "value": "Namakkal"
        },
        {
            "label": "Nambiyur",
            "value": "Nambiyur"
        },
        {
            "label": "Nambutalai",
            "value": "Nambutalai"
        },
        {
            "label": "Nandambakkam",
            "value": "Nandambakkam"
        },
        {
            "label": "Nangavalli",
            "value": "Nangavalli"
        },
        {
            "label": "Nangilickondan",
            "value": "Nangilickondan"
        },
        {
            "label": "Nanguneri",
            "value": "Nanguneri"
        },
        {
            "label": "Nannilam",
            "value": "Nannilam"
        },
        {
            "label": "Naravarikuppam",
            "value": "Naravarikuppam"
        },
        {
            "label": "Nattam",
            "value": "Nattam"
        },
        {
            "label": "Nattarasankottai",
            "value": "Nattarasankottai"
        },
        {
            "label": "Needamangalam",
            "value": "Needamangalam"
        },
        {
            "label": "Neelankarai",
            "value": "Neelankarai"
        },
        {
            "label": "Negapatam",
            "value": "Negapatam"
        },
        {
            "label": "Nellikkuppam",
            "value": "Nellikkuppam"
        },
        {
            "label": "Nilakottai",
            "value": "Nilakottai"
        },
        {
            "label": "Nilgiris",
            "value": "Nilgiris"
        },
        {
            "label": "Odugattur",
            "value": "Odugattur"
        },
        {
            "label": "Omalur",
            "value": "Omalur"
        },
        {
            "label": "Ooty",
            "value": "Ooty"
        },
        {
            "label": "Padmanabhapuram",
            "value": "Padmanabhapuram"
        },
        {
            "label": "Palakkodu",
            "value": "Palakkodu"
        },
        {
            "label": "Palamedu",
            "value": "Palamedu"
        },
        {
            "label": "Palani",
            "value": "Palani"
        },
        {
            "label": "Palavakkam",
            "value": "Palavakkam"
        },
        {
            "label": "Palladam",
            "value": "Palladam"
        },
        {
            "label": "Pallappatti",
            "value": "Pallappatti"
        },
        {
            "label": "Pallattur",
            "value": "Pallattur"
        },
        {
            "label": "Pallavaram",
            "value": "Pallavaram"
        },
        {
            "label": "Pallikondai",
            "value": "Pallikondai"
        },
        {
            "label": "Pallipattu",
            "value": "Pallipattu"
        },
        {
            "label": "Pallippatti",
            "value": "Pallippatti"
        },
        {
            "label": "Panruti",
            "value": "Panruti"
        },
        {
            "label": "Papanasam",
            "value": "Papanasam"
        },
        {
            "label": "Papireddippatti",
            "value": "Papireddippatti"
        },
        {
            "label": "Papparappatti",
            "value": "Papparappatti"
        },
        {
            "label": "Paramagudi",
            "value": "Paramagudi"
        },
        {
            "label": "Pattukkottai",
            "value": "Pattukkottai"
        },
        {
            "label": "Pennadam",
            "value": "Pennadam"
        },
        {
            "label": "Pennagaram",
            "value": "Pennagaram"
        },
        {
            "label": "Pennathur",
            "value": "Pennathur"
        },
        {
            "label": "Peraiyur",
            "value": "Peraiyur"
        },
        {
            "label": "Perambalur",
            "value": "Perambalur"
        },
        {
            "label": "Peranamallur",
            "value": "Peranamallur"
        },
        {
            "label": "Peranampattu",
            "value": "Peranampattu"
        },
        {
            "label": "Peravurani",
            "value": "Peravurani"
        },
        {
            "label": "Periyakulam",
            "value": "Periyakulam"
        },
        {
            "label": "Periyanayakkanpalaiyam",
            "value": "Periyanayakkanpalaiyam"
        },
        {
            "label": "Periyanegamam",
            "value": "Periyanegamam"
        },
        {
            "label": "Periyapatti",
            "value": "Periyapatti"
        },
        {
            "label": "Periyapattinam",
            "value": "Periyapattinam"
        },
        {
            "label": "Perundurai",
            "value": "Perundurai"
        },
        {
            "label": "Perungudi",
            "value": "Perungudi"
        },
        {
            "label": "Perur",
            "value": "Perur"
        },
        {
            "label": "Pollachi",
            "value": "Pollachi"
        },
        {
            "label": "Polur",
            "value": "Polur"
        },
        {
            "label": "Ponnamaravati",
            "value": "Ponnamaravati"
        },
        {
            "label": "Ponneri",
            "value": "Ponneri"
        },
        {
            "label": "Poonamalle",
            "value": "Poonamalle"
        },
        {
            "label": "Porur",
            "value": "Porur"
        },
        {
            "label": "Pudukkottai",
            "value": "Pudukkottai"
        },
        {
            "label": "Puduppatti",
            "value": "Puduppatti"
        },
        {
            "label": "Pudur",
            "value": "Pudur"
        },
        {
            "label": "Puduvayal",
            "value": "Puduvayal"
        },
        {
            "label": "Puliyangudi",
            "value": "Puliyangudi"
        },
        {
            "label": "Puliyur",
            "value": "Puliyur"
        },
        {
            "label": "Pullambadi",
            "value": "Pullambadi"
        },
        {
            "label": "Punjai Puliyampatti",
            "value": "Punjai Puliyampatti"
        },
        {
            "label": "Rajapalaiyam",
            "value": "Rajapalaiyam"
        },
        {
            "label": "Ramanathapuram",
            "value": "Ramanathapuram"
        },
        {
            "label": "Rameswaram",
            "value": "Rameswaram"
        },
        {
            "label": "Ranipet",
            "value": "Ranipet"
        },
        {
            "label": "Rasipuram",
            "value": "Rasipuram"
        },
        {
            "label": "Saint Thomas Mount",
            "value": "Saint Thomas Mount"
        },
        {
            "label": "Salem",
            "value": "Salem"
        },
        {
            "label": "Sathankulam",
            "value": "Sathankulam"
        },
        {
            "label": "Sathyamangalam",
            "value": "Sathyamangalam"
        },
        {
            "label": "Sattur",
            "value": "Sattur"
        },
        {
            "label": "Sayalkudi",
            "value": "Sayalkudi"
        },
        {
            "label": "Seven Pagodas",
            "value": "Seven Pagodas"
        },
        {
            "label": "Sholinghur",
            "value": "Sholinghur"
        },
        {
            "label": "Singanallur",
            "value": "Singanallur"
        },
        {
            "label": "Singapperumalkovil",
            "value": "Singapperumalkovil"
        },
        {
            "label": "Sirkazhi",
            "value": "Sirkazhi"
        },
        {
            "label": "Sirumugai",
            "value": "Sirumugai"
        },
        {
            "label": "Sivaganga",
            "value": "Sivaganga"
        },
        {
            "label": "Sivagiri",
            "value": "Sivagiri"
        },
        {
            "label": "Sivakasi",
            "value": "Sivakasi"
        },
        {
            "label": "Srimushnam",
            "value": "Srimushnam"
        },
        {
            "label": "Sriperumbudur",
            "value": "Sriperumbudur"
        },
        {
            "label": "Srivaikuntam",
            "value": "Srivaikuntam"
        },
        {
            "label": "Srivilliputhur",
            "value": "Srivilliputhur"
        },
        {
            "label": "Suchindram",
            "value": "Suchindram"
        },
        {
            "label": "Sulur",
            "value": "Sulur"
        },
        {
            "label": "Surandai",
            "value": "Surandai"
        },
        {
            "label": "Swamimalai",
            "value": "Swamimalai"
        },
        {
            "label": "Tambaram",
            "value": "Tambaram"
        },
        {
            "label": "Tanjore",
            "value": "Tanjore"
        },
        {
            "label": "Taramangalam",
            "value": "Taramangalam"
        },
        {
            "label": "Tattayyangarpettai",
            "value": "Tattayyangarpettai"
        },
        {
            "label": "Thanjavur",
            "value": "Thanjavur"
        },
        {
            "label": "Tharangambadi",
            "value": "Tharangambadi"
        },
        {
            "label": "Theni",
            "value": "Theni"
        },
        {
            "label": "Thenkasi",
            "value": "Thenkasi"
        },
        {
            "label": "Thirukattupalli",
            "value": "Thirukattupalli"
        },
        {
            "label": "Thiruthani",
            "value": "Thiruthani"
        },
        {
            "label": "Thiruvaiyaru",
            "value": "Thiruvaiyaru"
        },
        {
            "label": "Thiruvallur",
            "value": "Thiruvallur"
        },
        {
            "label": "Thiruvarur",
            "value": "Thiruvarur"
        },
        {
            "label": "Thiruvidaimaruthur",
            "value": "Thiruvidaimaruthur"
        },
        {
            "label": "Thoothukudi",
            "value": "Thoothukudi"
        },
        {
            "label": "Tindivanam",
            "value": "Tindivanam"
        },
        {
            "label": "Tinnanur",
            "value": "Tinnanur"
        },
        {
            "label": "Tiruchchendur",
            "value": "Tiruchchendur"
        },
        {
            "label": "Tiruchengode",
            "value": "Tiruchengode"
        },
        {
            "label": "Tiruchirappalli",
            "value": "Tiruchirappalli"
        },
        {
            "label": "Tirukkoyilur",
            "value": "Tirukkoyilur"
        },
        {
            "label": "Tirumullaivasal",
            "value": "Tirumullaivasal"
        },
        {
            "label": "Tirunelveli",
            "value": "Tirunelveli"
        },
        {
            "label": "Tirunelveli Kattabo",
            "value": "Tirunelveli Kattabo"
        },
        {
            "label": "Tiruppalaikudi",
            "value": "Tiruppalaikudi"
        },
        {
            "label": "Tirupparangunram",
            "value": "Tirupparangunram"
        },
        {
            "label": "Tiruppur",
            "value": "Tiruppur"
        },
        {
            "label": "Tiruppuvanam",
            "value": "Tiruppuvanam"
        },
        {
            "label": "Tiruttangal",
            "value": "Tiruttangal"
        },
        {
            "label": "Tiruvannamalai",
            "value": "Tiruvannamalai"
        },
        {
            "label": "Tiruvottiyur",
            "value": "Tiruvottiyur"
        },
        {
            "label": "Tisaiyanvilai",
            "value": "Tisaiyanvilai"
        },
        {
            "label": "Tondi",
            "value": "Tondi"
        },
        {
            "label": "Turaiyur",
            "value": "Turaiyur"
        },
        {
            "label": "Udangudi",
            "value": "Udangudi"
        },
        {
            "label": "Udumalaippettai",
            "value": "Udumalaippettai"
        },
        {
            "label": "Uppiliyapuram",
            "value": "Uppiliyapuram"
        },
        {
            "label": "Usilampatti",
            "value": "Usilampatti"
        },
        {
            "label": "Uttamapalaiyam",
            "value": "Uttamapalaiyam"
        },
        {
            "label": "Uttiramerur",
            "value": "Uttiramerur"
        },
        {
            "label": "Uttukkuli",
            "value": "Uttukkuli"
        },
        {
            "label": "V.S.K.Valasai (Dindigul-Dist.)",
            "value": "V.S.K.Valasai (Dindigul-Dist.)"
        },
        {
            "label": "Vadakku Valliyur",
            "value": "Vadakku Valliyur"
        },
        {
            "label": "Vadakku Viravanallur",
            "value": "Vadakku Viravanallur"
        },
        {
            "label": "Vadamadurai",
            "value": "Vadamadurai"
        },
        {
            "label": "Vadippatti",
            "value": "Vadippatti"
        },
        {
            "label": "Valangaiman",
            "value": "Valangaiman"
        },
        {
            "label": "Valavanur",
            "value": "Valavanur"
        },
        {
            "label": "Vallam",
            "value": "Vallam"
        },
        {
            "label": "Valparai",
            "value": "Valparai"
        },
        {
            "label": "Vandalur",
            "value": "Vandalur"
        },
        {
            "label": "Vandavasi",
            "value": "Vandavasi"
        },
        {
            "label": "Vaniyambadi",
            "value": "Vaniyambadi"
        },
        {
            "label": "Vasudevanallur",
            "value": "Vasudevanallur"
        },
        {
            "label": "Vattalkundu",
            "value": "Vattalkundu"
        },
        {
            "label": "Vedaraniyam",
            "value": "Vedaraniyam"
        },
        {
            "label": "Vedasandur",
            "value": "Vedasandur"
        },
        {
            "label": "Velankanni",
            "value": "Velankanni"
        },
        {
            "label": "Vellanur",
            "value": "Vellanur"
        },
        {
            "label": "Vellore",
            "value": "Vellore"
        },
        {
            "label": "Velur",
            "value": "Velur"
        },
        {
            "label": "Vengavasal",
            "value": "Vengavasal"
        },
        {
            "label": "Vettaikkaranpudur",
            "value": "Vettaikkaranpudur"
        },
        {
            "label": "Vettavalam",
            "value": "Vettavalam"
        },
        {
            "label": "Vijayapuri",
            "value": "Vijayapuri"
        },
        {
            "label": "Vikravandi",
            "value": "Vikravandi"
        },
        {
            "label": "Vilattikulam",
            "value": "Vilattikulam"
        },
        {
            "label": "Villupuram",
            "value": "Villupuram"
        },
        {
            "label": "Viraganur",
            "value": "Viraganur"
        },
        {
            "label": "Virudhunagar",
            "value": "Virudhunagar"
        },
        {
            "label": "Vriddhachalam",
            "value": "Vriddhachalam"
        },
        {
            "label": "Walajapet",
            "value": "Walajapet"
        },
        {
            "label": "Wallajahbad",
            "value": "Wallajahbad"
        },
        {
            "label": "Wellington",
            "value": "Wellington"
        }
    ],
    "Telangana": [
        {
            "label": "Adilabad",
            "value": "Adilabad"
        },
        {
            "label": "Alampur",
            "value": "Alampur"
        },
        {
            "label": "Andol",
            "value": "Andol"
        },
        {
            "label": "Asifabad",
            "value": "Asifabad"
        },
        {
            "label": "Balapur",
            "value": "Balapur"
        },
        {
            "label": "Banswada",
            "value": "Banswada"
        },
        {
            "label": "Bellampalli",
            "value": "Bellampalli"
        },
        {
            "label": "Bhadrachalam",
            "value": "Bhadrachalam"
        },
        {
            "label": "Bhadradri Kothagudem",
            "value": "Bhadradri Kothagudem"
        },
        {
            "label": "Bhaisa",
            "value": "Bhaisa"
        },
        {
            "label": "Bhongir",
            "value": "Bhongir"
        },
        {
            "label": "Bodhan",
            "value": "Bodhan"
        },
        {
            "label": "Chandur",
            "value": "Chandur"
        },
        {
            "label": "Chatakonda",
            "value": "Chatakonda"
        },
        {
            "label": "Dasnapur",
            "value": "Dasnapur"
        },
        {
            "label": "Devarkonda",
            "value": "Devarkonda"
        },
        {
            "label": "Dornakal",
            "value": "Dornakal"
        },
        {
            "label": "Farrukhnagar",
            "value": "Farrukhnagar"
        },
        {
            "label": "Gaddi Annaram",
            "value": "Gaddi Annaram"
        },
        {
            "label": "Gadwal",
            "value": "Gadwal"
        },
        {
            "label": "Ghatkesar",
            "value": "Ghatkesar"
        },
        {
            "label": "Gopalur",
            "value": "Gopalur"
        },
        {
            "label": "Gudur",
            "value": "Gudur"
        },
        {
            "label": "Hyderabad",
            "value": "Hyderabad"
        },
        {
            "label": "Jagtial",
            "value": "Jagtial"
        },
        {
            "label": "Jangaon",
            "value": "Jangaon"
        },
        {
            "label": "Jayashankar Bhupalapally",
            "value": "Jayashankar Bhupalapally"
        },
        {
            "label": "Jogulamba Gadwal",
            "value": "Jogulamba Gadwal"
        },
        {
            "label": "Kagaznagar",
            "value": "Kagaznagar"
        },
        {
            "label": "Kamareddi",
            "value": "Kamareddi"
        },
        {
            "label": "Kamareddy",
            "value": "Kamareddy"
        },
        {
            "label": "Karimnagar",
            "value": "Karimnagar"
        },
        {
            "label": "Khammam",
            "value": "Khammam"
        },
        {
            "label": "Kodar",
            "value": "Kodar"
        },
        {
            "label": "Koratla",
            "value": "Koratla"
        },
        {
            "label": "Kothapet",
            "value": "Kothapet"
        },
        {
            "label": "Kottagudem",
            "value": "Kottagudem"
        },
        {
            "label": "Kottapalli",
            "value": "Kottapalli"
        },
        {
            "label": "Kukatpally",
            "value": "Kukatpally"
        },
        {
            "label": "Kyathampalle",
            "value": "Kyathampalle"
        },
        {
            "label": "Lakshettipet",
            "value": "Lakshettipet"
        },
        {
            "label": "Lal Bahadur Nagar",
            "value": "Lal Bahadur Nagar"
        },
        {
            "label": "Mahabubabad",
            "value": "Mahabubabad"
        },
        {
            "label": "Mahbubnagar",
            "value": "Mahbubnagar"
        },
        {
            "label": "Malkajgiri",
            "value": "Malkajgiri"
        },
        {
            "label": "Mancheral",
            "value": "Mancheral"
        },
        {
            "label": "Mandamarri",
            "value": "Mandamarri"
        },
        {
            "label": "Manthani",
            "value": "Manthani"
        },
        {
            "label": "Manuguru",
            "value": "Manuguru"
        },
        {
            "label": "Medak",
            "value": "Medak"
        },
        {
            "label": "Medchal",
            "value": "Medchal"
        },
        {
            "label": "Medchal Malkajgiri",
            "value": "Medchal Malkajgiri"
        },
        {
            "label": "Mirialguda",
            "value": "Mirialguda"
        },
        {
            "label": "Nagar Karnul",
            "value": "Nagar Karnul"
        },
        {
            "label": "Nalgonda",
            "value": "Nalgonda"
        },
        {
            "label": "Narayanpet",
            "value": "Narayanpet"
        },
        {
            "label": "Narsingi",
            "value": "Narsingi"
        },
        {
            "label": "Naspur",
            "value": "Naspur"
        },
        {
            "label": "Nirmal",
            "value": "Nirmal"
        },
        {
            "label": "Nizamabad",
            "value": "Nizamabad"
        },
        {
            "label": "Paloncha",
            "value": "Paloncha"
        },
        {
            "label": "Palwancha",
            "value": "Palwancha"
        },
        {
            "label": "Patancheru",
            "value": "Patancheru"
        },
        {
            "label": "Peddapalli",
            "value": "Peddapalli"
        },
        {
            "label": "Quthbullapur",
            "value": "Quthbullapur"
        },
        {
            "label": "Rajanna Sircilla",
            "value": "Rajanna Sircilla"
        },
        {
            "label": "Ramagundam",
            "value": "Ramagundam"
        },
        {
            "label": "Ramgundam",
            "value": "Ramgundam"
        },
        {
            "label": "Rangareddi",
            "value": "Rangareddi"
        },
        {
            "label": "Sadaseopet",
            "value": "Sadaseopet"
        },
        {
            "label": "Sangareddi",
            "value": "Sangareddi"
        },
        {
            "label": "Sathupalli",
            "value": "Sathupalli"
        },
        {
            "label": "Secunderabad",
            "value": "Secunderabad"
        },
        {
            "label": "Serilingampalle",
            "value": "Serilingampalle"
        },
        {
            "label": "Siddipet",
            "value": "Siddipet"
        },
        {
            "label": "Singapur",
            "value": "Singapur"
        },
        {
            "label": "Sirpur",
            "value": "Sirpur"
        },
        {
            "label": "Sirsilla",
            "value": "Sirsilla"
        },
        {
            "label": "Sriramnagar",
            "value": "Sriramnagar"
        },
        {
            "label": "Suriapet",
            "value": "Suriapet"
        },
        {
            "label": "Tandur",
            "value": "Tandur"
        },
        {
            "label": "Uppal Kalan",
            "value": "Uppal Kalan"
        },
        {
            "label": "Vemalwada",
            "value": "Vemalwada"
        },
        {
            "label": "Vikarabad",
            "value": "Vikarabad"
        },
        {
            "label": "Wanparti",
            "value": "Wanparti"
        },
        {
            "label": "Warangal",
            "value": "Warangal"
        },
        {
            "label": "Yellandu",
            "value": "Yellandu"
        },
        {
            "label": "Zahirabad",
            "value": "Zahirabad"
        }
    ],
    "Tripura": [
        {
            "label": "Agartala",
            "value": "Agartala"
        },
        {
            "label": "Amarpur",
            "value": "Amarpur"
        },
        {
            "label": "Ambasa",
            "value": "Ambasa"
        },
        {
            "label": "Barjala",
            "value": "Barjala"
        },
        {
            "label": "Belonia",
            "value": "Belonia"
        },
        {
            "label": "Dhalai",
            "value": "Dhalai"
        },
        {
            "label": "Dharmanagar",
            "value": "Dharmanagar"
        },
        {
            "label": "Gomati",
            "value": "Gomati"
        },
        {
            "label": "Kailashahar",
            "value": "Kailashahar"
        },
        {
            "label": "Kamalpur",
            "value": "Kamalpur"
        },
        {
            "label": "Khowai",
            "value": "Khowai"
        },
        {
            "label": "North Tripura",
            "value": "North Tripura"
        },
        {
            "label": "Ranir Bazar",
            "value": "Ranir Bazar"
        },
        {
            "label": "Sabrum",
            "value": "Sabrum"
        },
        {
            "label": "Sonamura",
            "value": "Sonamura"
        },
        {
            "label": "South Tripura",
            "value": "South Tripura"
        },
        {
            "label": "Udaipur",
            "value": "Udaipur"
        },
        {
            "label": "Unakoti",
            "value": "Unakoti"
        },
        {
            "label": "West Tripura",
            "value": "West Tripura"
        }
    ],
    "Uttar Pradesh": [
        {
            "label": "Achhnera",
            "value": "Achhnera"
        },
        {
            "label": "Afzalgarh",
            "value": "Afzalgarh"
        },
        {
            "label": "Agra",
            "value": "Agra"
        },
        {
            "label": "Ahraura",
            "value": "Ahraura"
        },
        {
            "label": "Aidalpur",
            "value": "Aidalpur"
        },
        {
            "label": "Airwa",
            "value": "Airwa"
        },
        {
            "label": "Akbarpur",
            "value": "Akbarpur"
        },
        {
            "label": "Akola",
            "value": "Akola"
        },
        {
            "label": "Aliganj",
            "value": "Aliganj"
        },
        {
            "label": "Aligarh",
            "value": "Aligarh"
        },
        {
            "label": "Allahabad",
            "value": "Allahabad"
        },
        {
            "label": "Allahganj",
            "value": "Allahganj"
        },
        {
            "label": "Amanpur",
            "value": "Amanpur"
        },
        {
            "label": "Amauli",
            "value": "Amauli"
        },
        {
            "label": "Ambahta",
            "value": "Ambahta"
        },
        {
            "label": "Ambedkar Nagar",
            "value": "Ambedkar Nagar"
        },
        {
            "label": "Amethi",
            "value": "Amethi"
        },
        {
            "label": "Amroha",
            "value": "Amroha"
        },
        {
            "label": "Anandnagar",
            "value": "Anandnagar"
        },
        {
            "label": "Antu",
            "value": "Antu"
        },
        {
            "label": "Anupshahr",
            "value": "Anupshahr"
        },
        {
            "label": "Aonla",
            "value": "Aonla"
        },
        {
            "label": "Araul",
            "value": "Araul"
        },
        {
            "label": "Asalatganj",
            "value": "Asalatganj"
        },
        {
            "label": "Atarra",
            "value": "Atarra"
        },
        {
            "label": "Atrauli",
            "value": "Atrauli"
        },
        {
            "label": "Atraulia",
            "value": "Atraulia"
        },
        {
            "label": "Auraiya",
            "value": "Auraiya"
        },
        {
            "label": "Auras",
            "value": "Auras"
        },
        {
            "label": "Ayodhya",
            "value": "Ayodhya"
        },
        {
            "label": "Azamgarh",
            "value": "Azamgarh"
        },
        {
            "label": "Azizpur",
            "value": "Azizpur"
        },
        {
            "label": "Baberu",
            "value": "Baberu"
        },
        {
            "label": "Babina",
            "value": "Babina"
        },
        {
            "label": "Babrala",
            "value": "Babrala"
        },
        {
            "label": "Babugarh",
            "value": "Babugarh"
        },
        {
            "label": "Bachhraon",
            "value": "Bachhraon"
        },
        {
            "label": "Bachhrawan",
            "value": "Bachhrawan"
        },
        {
            "label": "Baghpat",
            "value": "Baghpat"
        },
        {
            "label": "Baghra",
            "value": "Baghra"
        },
        {
            "label": "Bah",
            "value": "Bah"
        },
        {
            "label": "Baheri",
            "value": "Baheri"
        },
        {
            "label": "Bahjoi",
            "value": "Bahjoi"
        },
        {
            "label": "Bahraich",
            "value": "Bahraich"
        },
        {
            "label": "Bahraigh",
            "value": "Bahraigh"
        },
        {
            "label": "Bahsuma",
            "value": "Bahsuma"
        },
        {
            "label": "Bahua",
            "value": "Bahua"
        },
        {
            "label": "Bajna",
            "value": "Bajna"
        },
        {
            "label": "Bakewar",
            "value": "Bakewar"
        },
        {
            "label": "Baksar",
            "value": "Baksar"
        },
        {
            "label": "Balamau",
            "value": "Balamau"
        },
        {
            "label": "Baldeo",
            "value": "Baldeo"
        },
        {
            "label": "Baldev",
            "value": "Baldev"
        },
        {
            "label": "Ballia",
            "value": "Ballia"
        },
        {
            "label": "Balrampur",
            "value": "Balrampur"
        },
        {
            "label": "Banat",
            "value": "Banat"
        },
        {
            "label": "Banbasa",
            "value": "Banbasa"
        },
        {
            "label": "Banda",
            "value": "Banda"
        },
        {
            "label": "Bangarmau",
            "value": "Bangarmau"
        },
        {
            "label": "Bansdih",
            "value": "Bansdih"
        },
        {
            "label": "Bansgaon",
            "value": "Bansgaon"
        },
        {
            "label": "Bansi",
            "value": "Bansi"
        },
        {
            "label": "Banthra",
            "value": "Banthra"
        },
        {
            "label": "Bara Banki",
            "value": "Bara Banki"
        },
        {
            "label": "Baragaon",
            "value": "Baragaon"
        },
        {
            "label": "Baraut",
            "value": "Baraut"
        },
        {
            "label": "Bareilly",
            "value": "Bareilly"
        },
        {
            "label": "Barhalganj",
            "value": "Barhalganj"
        },
        {
            "label": "Barkhera",
            "value": "Barkhera"
        },
        {
            "label": "Barkhera Kalan",
            "value": "Barkhera Kalan"
        },
        {
            "label": "Barokhar",
            "value": "Barokhar"
        },
        {
            "label": "Barsana",
            "value": "Barsana"
        },
        {
            "label": "Barwar (Lakhimpur Kheri)",
            "value": "Barwar (Lakhimpur Kheri)"
        },
        {
            "label": "Basti",
            "value": "Basti"
        },
        {
            "label": "Behat",
            "value": "Behat"
        },
        {
            "label": "Bela",
            "value": "Bela"
        },
        {
            "label": "Belthara",
            "value": "Belthara"
        },
        {
            "label": "Beniganj",
            "value": "Beniganj"
        },
        {
            "label": "Beswan",
            "value": "Beswan"
        },
        {
            "label": "Bewar",
            "value": "Bewar"
        },
        {
            "label": "Bhadarsa",
            "value": "Bhadarsa"
        },
        {
            "label": "Bhadohi",
            "value": "Bhadohi"
        },
        {
            "label": "Bhagwantnagar",
            "value": "Bhagwantnagar"
        },
        {
            "label": "Bharatpura",
            "value": "Bharatpura"
        },
        {
            "label": "Bhargain",
            "value": "Bhargain"
        },
        {
            "label": "Bharthana",
            "value": "Bharthana"
        },
        {
            "label": "Bharwari",
            "value": "Bharwari"
        },
        {
            "label": "Bhaupur",
            "value": "Bhaupur"
        },
        {
            "label": "Bhimtal",
            "value": "Bhimtal"
        },
        {
            "label": "Bhinga",
            "value": "Bhinga"
        },
        {
            "label": "Bhognipur",
            "value": "Bhognipur"
        },
        {
            "label": "Bhongaon",
            "value": "Bhongaon"
        },
        {
            "label": "Bidhnu",
            "value": "Bidhnu"
        },
        {
            "label": "Bidhuna",
            "value": "Bidhuna"
        },
        {
            "label": "Bighapur",
            "value": "Bighapur"
        },
        {
            "label": "Bighapur Khurd",
            "value": "Bighapur Khurd"
        },
        {
            "label": "Bijnor",
            "value": "Bijnor"
        },
        {
            "label": "Bikapur",
            "value": "Bikapur"
        },
        {
            "label": "Bilari",
            "value": "Bilari"
        },
        {
            "label": "Bilariaganj",
            "value": "Bilariaganj"
        },
        {
            "label": "Bilaspur",
            "value": "Bilaspur"
        },
        {
            "label": "Bilgram",
            "value": "Bilgram"
        },
        {
            "label": "Bilhaur",
            "value": "Bilhaur"
        },
        {
            "label": "Bilsanda",
            "value": "Bilsanda"
        },
        {
            "label": "Bilsi",
            "value": "Bilsi"
        },
        {
            "label": "Bilthra",
            "value": "Bilthra"
        },
        {
            "label": "Binauli",
            "value": "Binauli"
        },
        {
            "label": "Binaur",
            "value": "Binaur"
        },
        {
            "label": "Bindki",
            "value": "Bindki"
        },
        {
            "label": "Birdpur",
            "value": "Birdpur"
        },
        {
            "label": "Birpur",
            "value": "Birpur"
        },
        {
            "label": "Bisalpur",
            "value": "Bisalpur"
        },
        {
            "label": "Bisanda Buzurg",
            "value": "Bisanda Buzurg"
        },
        {
            "label": "Bisauli",
            "value": "Bisauli"
        },
        {
            "label": "Bisenda Buzurg",
            "value": "Bisenda Buzurg"
        },
        {
            "label": "Bishunpur Urf Maharajganj",
            "value": "Bishunpur Urf Maharajganj"
        },
        {
            "label": "Biswan",
            "value": "Biswan"
        },
        {
            "label": "Bithur",
            "value": "Bithur"
        },
        {
            "label": "Budaun",
            "value": "Budaun"
        },
        {
            "label": "Budhana",
            "value": "Budhana"
        },
        {
            "label": "Bulandshahr",
            "value": "Bulandshahr"
        },
        {
            "label": "Captainganj",
            "value": "Captainganj"
        },
        {
            "label": "Chail",
            "value": "Chail"
        },
        {
            "label": "Chakia",
            "value": "Chakia"
        },
        {
            "label": "Chandauli",
            "value": "Chandauli"
        },
        {
            "label": "Chandauli District",
            "value": "Chandauli District"
        },
        {
            "label": "Chandausi",
            "value": "Chandausi"
        },
        {
            "label": "Chandpur",
            "value": "Chandpur"
        },
        {
            "label": "Chanduasi",
            "value": "Chanduasi"
        },
        {
            "label": "Charkhari",
            "value": "Charkhari"
        },
        {
            "label": "Charthawal",
            "value": "Charthawal"
        },
        {
            "label": "Chhaprauli",
            "value": "Chhaprauli"
        },
        {
            "label": "Chharra",
            "value": "Chharra"
        },
        {
            "label": "Chhata",
            "value": "Chhata"
        },
        {
            "label": "Chhibramau",
            "value": "Chhibramau"
        },
        {
            "label": "Chhitauni",
            "value": "Chhitauni"
        },
        {
            "label": "Chhutmalpur",
            "value": "Chhutmalpur"
        },
        {
            "label": "Chillupar",
            "value": "Chillupar"
        },
        {
            "label": "Chirgaon",
            "value": "Chirgaon"
        },
        {
            "label": "Chitrakoot",
            "value": "Chitrakoot"
        },
        {
            "label": "Chitrakoot Dham",
            "value": "Chitrakoot Dham"
        },
        {
            "label": "Chopan",
            "value": "Chopan"
        },
        {
            "label": "Chunar",
            "value": "Chunar"
        },
        {
            "label": "Churk",
            "value": "Churk"
        },
        {
            "label": "Colonelganj",
            "value": "Colonelganj"
        },
        {
            "label": "Dadri",
            "value": "Dadri"
        },
        {
            "label": "Dalmau",
            "value": "Dalmau"
        },
        {
            "label": "Dankaur",
            "value": "Dankaur"
        },
        {
            "label": "Daraganj",
            "value": "Daraganj"
        },
        {
            "label": "Daranagar",
            "value": "Daranagar"
        },
        {
            "label": "Dasna",
            "value": "Dasna"
        },
        {
            "label": "Dataganj",
            "value": "Dataganj"
        },
        {
            "label": "Daurala",
            "value": "Daurala"
        },
        {
            "label": "Dayal Bagh",
            "value": "Dayal Bagh"
        },
        {
            "label": "Deoband",
            "value": "Deoband"
        },
        {
            "label": "Deogarh",
            "value": "Deogarh"
        },
        {
            "label": "Deoranian",
            "value": "Deoranian"
        },
        {
            "label": "Deoria",
            "value": "Deoria"
        },
        {
            "label": "Derapur",
            "value": "Derapur"
        },
        {
            "label": "Dewa",
            "value": "Dewa"
        },
        {
            "label": "Dhampur",
            "value": "Dhampur"
        },
        {
            "label": "Dhanaura",
            "value": "Dhanaura"
        },
        {
            "label": "Dhanghata",
            "value": "Dhanghata"
        },
        {
            "label": "Dharau",
            "value": "Dharau"
        },
        {
            "label": "Dhaurahra",
            "value": "Dhaurahra"
        },
        {
            "label": "Dibai",
            "value": "Dibai"
        },
        {
            "label": "Divrasai",
            "value": "Divrasai"
        },
        {
            "label": "Dohrighat",
            "value": "Dohrighat"
        },
        {
            "label": "Domariaganj",
            "value": "Domariaganj"
        },
        {
            "label": "Dostpur",
            "value": "Dostpur"
        },
        {
            "label": "Dudhi",
            "value": "Dudhi"
        },
        {
            "label": "Etah",
            "value": "Etah"
        },
        {
            "label": "Etawah",
            "value": "Etawah"
        },
        {
            "label": "Etmadpur",
            "value": "Etmadpur"
        },
        {
            "label": "Faizabad",
            "value": "Faizabad"
        },
        {
            "label": "Farah",
            "value": "Farah"
        },
        {
            "label": "Faridnagar",
            "value": "Faridnagar"
        },
        {
            "label": "Faridpur",
            "value": "Faridpur"
        },
        {
            "label": "Farrukhabad",
            "value": "Farrukhabad"
        },
        {
            "label": "Fatehabad",
            "value": "Fatehabad"
        },
        {
            "label": "Fatehganj West",
            "value": "Fatehganj West"
        },
        {
            "label": "Fatehgarh",
            "value": "Fatehgarh"
        },
        {
            "label": "Fatehpur",
            "value": "Fatehpur"
        },
        {
            "label": "Fatehpur (Barabanki)",
            "value": "Fatehpur (Barabanki)"
        },
        {
            "label": "Fatehpur Chaurasi",
            "value": "Fatehpur Chaurasi"
        },
        {
            "label": "Fatehpur Sikri",
            "value": "Fatehpur Sikri"
        },
        {
            "label": "Firozabad",
            "value": "Firozabad"
        },
        {
            "label": "Fyzabad",
            "value": "Fyzabad"
        },
        {
            "label": "Gahlon",
            "value": "Gahlon"
        },
        {
            "label": "Gahmar",
            "value": "Gahmar"
        },
        {
            "label": "Gaini",
            "value": "Gaini"
        },
        {
            "label": "Gajraula",
            "value": "Gajraula"
        },
        {
            "label": "Gangoh",
            "value": "Gangoh"
        },
        {
            "label": "Ganj Dundawara",
            "value": "Ganj Dundawara"
        },
        {
            "label": "Ganj Dundwara",
            "value": "Ganj Dundwara"
        },
        {
            "label": "Ganj Muradabad",
            "value": "Ganj Muradabad"
        },
        {
            "label": "Garautha",
            "value": "Garautha"
        },
        {
            "label": "Garhi Pukhta",
            "value": "Garhi Pukhta"
        },
        {
            "label": "Garhmuktesar",
            "value": "Garhmuktesar"
        },
        {
            "label": "Garhwa",
            "value": "Garhwa"
        },
        {
            "label": "Gauriganj",
            "value": "Gauriganj"
        },
        {
            "label": "Gautam Buddha Nagar",
            "value": "Gautam Buddha Nagar"
        },
        {
            "label": "Gawan",
            "value": "Gawan"
        },
        {
            "label": "Ghatampur",
            "value": "Ghatampur"
        },
        {
            "label": "Ghaziabad",
            "value": "Ghaziabad"
        },
        {
            "label": "Ghazipur",
            "value": "Ghazipur"
        },
        {
            "label": "Ghiror",
            "value": "Ghiror"
        },
        {
            "label": "Ghorawal",
            "value": "Ghorawal"
        },
        {
            "label": "Ghosi",
            "value": "Ghosi"
        },
        {
            "label": "Gohand",
            "value": "Gohand"
        },
        {
            "label": "Gokul",
            "value": "Gokul"
        },
        {
            "label": "Gola Bazar",
            "value": "Gola Bazar"
        },
        {
            "label": "Gola Gokarannath",
            "value": "Gola Gokarannath"
        },
        {
            "label": "Gonda",
            "value": "Gonda"
        },
        {
            "label": "Gopamau",
            "value": "Gopamau"
        },
        {
            "label": "Gorakhpur",
            "value": "Gorakhpur"
        },
        {
            "label": "Gosainganj",
            "value": "Gosainganj"
        },
        {
            "label": "Goshainganj",
            "value": "Goshainganj"
        },
        {
            "label": "Govardhan",
            "value": "Govardhan"
        },
        {
            "label": "Greater Noida",
            "value": "Greater Noida"
        },
        {
            "label": "Gulaothi",
            "value": "Gulaothi"
        },
        {
            "label": "Gunnaur",
            "value": "Gunnaur"
        },
        {
            "label": "Gursahaiganj",
            "value": "Gursahaiganj"
        },
        {
            "label": "Gursarai",
            "value": "Gursarai"
        },
        {
            "label": "Gyanpur",
            "value": "Gyanpur"
        },
        {
            "label": "Haldaur",
            "value": "Haldaur"
        },
        {
            "label": "Hamirpur",
            "value": "Hamirpur"
        },
        {
            "label": "Handia",
            "value": "Handia"
        },
        {
            "label": "Hapur",
            "value": "Hapur"
        },
        {
            "label": "Haraipur",
            "value": "Haraipur"
        },
        {
            "label": "Haraiya",
            "value": "Haraiya"
        },
        {
            "label": "Harchandpur",
            "value": "Harchandpur"
        },
        {
            "label": "Hardoi",
            "value": "Hardoi"
        },
        {
            "label": "Harduaganj",
            "value": "Harduaganj"
        },
        {
            "label": "Hasanpur",
            "value": "Hasanpur"
        },
        {
            "label": "Hastinapur",
            "value": "Hastinapur"
        },
        {
            "label": "Hata",
            "value": "Hata"
        },
        {
            "label": "Hata (India)",
            "value": "Hata (India)"
        },
        {
            "label": "Hathras",
            "value": "Hathras"
        },
        {
            "label": "Hulas",
            "value": "Hulas"
        },
        {
            "label": "Ibrahimpur",
            "value": "Ibrahimpur"
        },
        {
            "label": "Iglas",
            "value": "Iglas"
        },
        {
            "label": "Ikauna",
            "value": "Ikauna"
        },
        {
            "label": "Indergarh",
            "value": "Indergarh"
        },
        {
            "label": "Indragarh",
            "value": "Indragarh"
        },
        {
            "label": "Islamnagar",
            "value": "Islamnagar"
        },
        {
            "label": "Islamnagar (Badaun)",
            "value": "Islamnagar (Badaun)"
        },
        {
            "label": "Itaunja",
            "value": "Itaunja"
        },
        {
            "label": "Itimadpur",
            "value": "Itimadpur"
        },
        {
            "label": "Jagdishpur",
            "value": "Jagdishpur"
        },
        {
            "label": "Jagnair",
            "value": "Jagnair"
        },
        {
            "label": "Jahanabad",
            "value": "Jahanabad"
        },
        {
            "label": "Jahanabad (Pilibhit)",
            "value": "Jahanabad (Pilibhit)"
        },
        {
            "label": "Jahangirabad",
            "value": "Jahangirabad"
        },
        {
            "label": "Jahangirpur",
            "value": "Jahangirpur"
        },
        {
            "label": "Jainpur",
            "value": "Jainpur"
        },
        {
            "label": "Jais",
            "value": "Jais"
        },
        {
            "label": "Jalalabad",
            "value": "Jalalabad"
        },
        {
            "label": "Jalali",
            "value": "Jalali"
        },
        {
            "label": "Jalalpur",
            "value": "Jalalpur"
        },
        {
            "label": "Jalaun",
            "value": "Jalaun"
        },
        {
            "label": "Jalesar",
            "value": "Jalesar"
        },
        {
            "label": "Janghai",
            "value": "Janghai"
        },
        {
            "label": "Jansath",
            "value": "Jansath"
        },
        {
            "label": "Jarwa",
            "value": "Jarwa"
        },
        {
            "label": "Jarwal",
            "value": "Jarwal"
        },
        {
            "label": "Jasrana",
            "value": "Jasrana"
        },
        {
            "label": "Jaswantnagar",
            "value": "Jaswantnagar"
        },
        {
            "label": "Jaunpur",
            "value": "Jaunpur"
        },
        {
            "label": "Jewar",
            "value": "Jewar"
        },
        {
            "label": "Jhajhar",
            "value": "Jhajhar"
        },
        {
            "label": "Jhalu",
            "value": "Jhalu"
        },
        {
            "label": "Jhansi",
            "value": "Jhansi"
        },
        {
            "label": "Jhinjhak",
            "value": "Jhinjhak"
        },
        {
            "label": "Jhinjhana",
            "value": "Jhinjhana"
        },
        {
            "label": "Jhusi",
            "value": "Jhusi"
        },
        {
            "label": "Jiyanpur",
            "value": "Jiyanpur"
        },
        {
            "label": "Jyotiba Phule Nagar",
            "value": "Jyotiba Phule Nagar"
        },
        {
            "label": "Kabrai",
            "value": "Kabrai"
        },
        {
            "label": "Kachhwa",
            "value": "Kachhwa"
        },
        {
            "label": "Kadaura",
            "value": "Kadaura"
        },
        {
            "label": "Kadipur",
            "value": "Kadipur"
        },
        {
            "label": "Kagarol",
            "value": "Kagarol"
        },
        {
            "label": "Kaimganj",
            "value": "Kaimganj"
        },
        {
            "label": "Kairana",
            "value": "Kairana"
        },
        {
            "label": "Kakori",
            "value": "Kakori"
        },
        {
            "label": "Kakrala",
            "value": "Kakrala"
        },
        {
            "label": "Kalinagar",
            "value": "Kalinagar"
        },
        {
            "label": "Kalpi",
            "value": "Kalpi"
        },
        {
            "label": "Kalyanpur",
            "value": "Kalyanpur"
        },
        {
            "label": "Kamalganj",
            "value": "Kamalganj"
        },
        {
            "label": "Kampil",
            "value": "Kampil"
        },
        {
            "label": "Kandhla",
            "value": "Kandhla"
        },
        {
            "label": "Kannauj",
            "value": "Kannauj"
        },
        {
            "label": "Kanpur",
            "value": "Kanpur"
        },
        {
            "label": "Kanpur Dehat",
            "value": "Kanpur Dehat"
        },
        {
            "label": "Kant",
            "value": "Kant"
        },
        {
            "label": "Kanth",
            "value": "Kanth"
        },
        {
            "label": "Kaptanganj",
            "value": "Kaptanganj"
        },
        {
            "label": "Kara",
            "value": "Kara"
        },
        {
            "label": "Karari",
            "value": "Karari"
        },
        {
            "label": "Karbigwan",
            "value": "Karbigwan"
        },
        {
            "label": "Karchana",
            "value": "Karchana"
        },
        {
            "label": "Karhal",
            "value": "Karhal"
        },
        {
            "label": "Kasganj",
            "value": "Kasganj"
        },
        {
            "label": "Katra",
            "value": "Katra"
        },
        {
            "label": "Kausani",
            "value": "Kausani"
        },
        {
            "label": "Kaushambi District",
            "value": "Kaushambi District"
        },
        {
            "label": "Kemri",
            "value": "Kemri"
        },
        {
            "label": "Khada",
            "value": "Khada"
        },
        {
            "label": "Khaga",
            "value": "Khaga"
        },
        {
            "label": "Khailar",
            "value": "Khailar"
        },
        {
            "label": "Khair",
            "value": "Khair"
        },
        {
            "label": "Khairabad",
            "value": "Khairabad"
        },
        {
            "label": "Khalilabad",
            "value": "Khalilabad"
        },
        {
            "label": "Khanpur",
            "value": "Khanpur"
        },
        {
            "label": "Kharela",
            "value": "Kharela"
        },
        {
            "label": "Khargupur",
            "value": "Khargupur"
        },
        {
            "label": "Kharkhauda",
            "value": "Kharkhauda"
        },
        {
            "label": "Khatauli",
            "value": "Khatauli"
        },
        {
            "label": "Khekra",
            "value": "Khekra"
        },
        {
            "label": "Kheri",
            "value": "Kheri"
        },
        {
            "label": "Khudaganj",
            "value": "Khudaganj"
        },
        {
            "label": "Khurja",
            "value": "Khurja"
        },
        {
            "label": "Khutar",
            "value": "Khutar"
        },
        {
            "label": "Kirakat",
            "value": "Kirakat"
        },
        {
            "label": "Kiraoli",
            "value": "Kiraoli"
        },
        {
            "label": "Kiratpur",
            "value": "Kiratpur"
        },
        {
            "label": "Kishanpur",
            "value": "Kishanpur"
        },
        {
            "label": "Kishanpur baral",
            "value": "Kishanpur baral"
        },
        {
            "label": "Kishni",
            "value": "Kishni"
        },
        {
            "label": "Kithor",
            "value": "Kithor"
        },
        {
            "label": "Konch",
            "value": "Konch"
        },
        {
            "label": "Kopaganj",
            "value": "Kopaganj"
        },
        {
            "label": "Kosi",
            "value": "Kosi"
        },
        {
            "label": "Kota",
            "value": "Kota"
        },
        {
            "label": "Kotra",
            "value": "Kotra"
        },
        {
            "label": "Kuchesar",
            "value": "Kuchesar"
        },
        {
            "label": "Kudarkot",
            "value": "Kudarkot"
        },
        {
            "label": "Kulpahar",
            "value": "Kulpahar"
        },
        {
            "label": "Kunda",
            "value": "Kunda"
        },
        {
            "label": "Kundarkhi",
            "value": "Kundarkhi"
        },
        {
            "label": "Kundarki",
            "value": "Kundarki"
        },
        {
            "label": "Kurara",
            "value": "Kurara"
        },
        {
            "label": "Kurebharsaidkhanpur",
            "value": "Kurebharsaidkhanpur"
        },
        {
            "label": "Kushinagar",
            "value": "Kushinagar"
        },
        {
            "label": "Kusmara",
            "value": "Kusmara"
        },
        {
            "label": "Kuthaund",
            "value": "Kuthaund"
        },
        {
            "label": "Laharpur",
            "value": "Laharpur"
        },
        {
            "label": "Lakhimpur",
            "value": "Lakhimpur"
        },
        {
            "label": "Lakhna",
            "value": "Lakhna"
        },
        {
            "label": "Lalganj",
            "value": "Lalganj"
        },
        {
            "label": "Lalitpur",
            "value": "Lalitpur"
        },
        {
            "label": "Lambhua",
            "value": "Lambhua"
        },
        {
            "label": "Lar",
            "value": "Lar"
        },
        {
            "label": "Lawar",
            "value": "Lawar"
        },
        {
            "label": "Lawar Khas",
            "value": "Lawar Khas"
        },
        {
            "label": "Loni",
            "value": "Loni"
        },
        {
            "label": "Lucknow",
            "value": "Lucknow"
        },
        {
            "label": "Lucknow District",
            "value": "Lucknow District"
        },
        {
            "label": "Machhali Shahar",
            "value": "Machhali Shahar"
        },
        {
            "label": "Machhlishahr",
            "value": "Machhlishahr"
        },
        {
            "label": "Madhoganj",
            "value": "Madhoganj"
        },
        {
            "label": "Madhogarh",
            "value": "Madhogarh"
        },
        {
            "label": "Maghar",
            "value": "Maghar"
        },
        {
            "label": "Mahaban",
            "value": "Mahaban"
        },
        {
            "label": "Maharajganj",
            "value": "Maharajganj"
        },
        {
            "label": "Mahmudabad",
            "value": "Mahmudabad"
        },
        {
            "label": "Mahoba",
            "value": "Mahoba"
        },
        {
            "label": "Maholi",
            "value": "Maholi"
        },
        {
            "label": "Mahrajganj",
            "value": "Mahrajganj"
        },
        {
            "label": "Mahrajganj (Raebareli)",
            "value": "Mahrajganj (Raebareli)"
        },
        {
            "label": "Mahroni",
            "value": "Mahroni"
        },
        {
            "label": "Mahul",
            "value": "Mahul"
        },
        {
            "label": "Mailani",
            "value": "Mailani"
        },
        {
            "label": "Mainpuri",
            "value": "Mainpuri"
        },
        {
            "label": "Majhupur",
            "value": "Majhupur"
        },
        {
            "label": "Makanpur",
            "value": "Makanpur"
        },
        {
            "label": "Malasa",
            "value": "Malasa"
        },
        {
            "label": "Malihabad",
            "value": "Malihabad"
        },
        {
            "label": "Mandawar",
            "value": "Mandawar"
        },
        {
            "label": "Maniar",
            "value": "Maniar"
        },
        {
            "label": "Manikpur",
            "value": "Manikpur"
        },
        {
            "label": "Manjhanpur",
            "value": "Manjhanpur"
        },
        {
            "label": "Mankapur",
            "value": "Mankapur"
        },
        {
            "label": "Marahra",
            "value": "Marahra"
        },
        {
            "label": "Mariahu",
            "value": "Mariahu"
        },
        {
            "label": "Mataundh",
            "value": "Mataundh"
        },
        {
            "label": "Mathura",
            "value": "Mathura"
        },
        {
            "label": "Mau",
            "value": "Mau"
        },
        {
            "label": "Mau Aima",
            "value": "Mau Aima"
        },
        {
            "label": "Mau Aimma",
            "value": "Mau Aimma"
        },
        {
            "label": "Maudaha",
            "value": "Maudaha"
        },
        {
            "label": "Maurawan",
            "value": "Maurawan"
        },
        {
            "label": "Mawana",
            "value": "Mawana"
        },
        {
            "label": "Mawar",
            "value": "Mawar"
        },
        {
            "label": "Meerut",
            "value": "Meerut"
        },
        {
            "label": "Mehdawal",
            "value": "Mehdawal"
        },
        {
            "label": "Mehnagar",
            "value": "Mehnagar"
        },
        {
            "label": "Mehndawal",
            "value": "Mehndawal"
        },
        {
            "label": "Milak",
            "value": "Milak"
        },
        {
            "label": "Milkipur",
            "value": "Milkipur"
        },
        {
            "label": "Miranpur",
            "value": "Miranpur"
        },
        {
            "label": "Miranpur Katra",
            "value": "Miranpur Katra"
        },
        {
            "label": "Mirganj",
            "value": "Mirganj"
        },
        {
            "label": "Mirzapur",
            "value": "Mirzapur"
        },
        {
            "label": "Misrikh",
            "value": "Misrikh"
        },
        {
            "label": "Mohan",
            "value": "Mohan"
        },
        {
            "label": "Mohanpur",
            "value": "Mohanpur"
        },
        {
            "label": "Moradabad",
            "value": "Moradabad"
        },
        {
            "label": "Moth",
            "value": "Moth"
        },
        {
            "label": "Mubarakpur",
            "value": "Mubarakpur"
        },
        {
            "label": "Mughal Sarai",
            "value": "Mughal Sarai"
        },
        {
            "label": "Muhammadabad",
            "value": "Muhammadabad"
        },
        {
            "label": "Mukteshwar",
            "value": "Mukteshwar"
        },
        {
            "label": "Mungra Badshahpur",
            "value": "Mungra Badshahpur"
        },
        {
            "label": "Munsyari",
            "value": "Munsyari"
        },
        {
            "label": "Muradabad",
            "value": "Muradabad"
        },
        {
            "label": "Muradnagar",
            "value": "Muradnagar"
        },
        {
            "label": "Mursan",
            "value": "Mursan"
        },
        {
            "label": "Musafir-Khana",
            "value": "Musafir-Khana"
        },
        {
            "label": "Musafirkhana",
            "value": "Musafirkhana"
        },
        {
            "label": "Muzaffarnagar",
            "value": "Muzaffarnagar"
        },
        {
            "label": "Nadigaon",
            "value": "Nadigaon"
        },
        {
            "label": "Nagina",
            "value": "Nagina"
        },
        {
            "label": "Nagla",
            "value": "Nagla"
        },
        {
            "label": "Nagram",
            "value": "Nagram"
        },
        {
            "label": "Najibabad",
            "value": "Najibabad"
        },
        {
            "label": "Nakur",
            "value": "Nakur"
        },
        {
            "label": "Nanauta",
            "value": "Nanauta"
        },
        {
            "label": "Nandgaon",
            "value": "Nandgaon"
        },
        {
            "label": "Nanpara",
            "value": "Nanpara"
        },
        {
            "label": "Narauli",
            "value": "Narauli"
        },
        {
            "label": "Naraura",
            "value": "Naraura"
        },
        {
            "label": "Narora",
            "value": "Narora"
        },
        {
            "label": "Naugama",
            "value": "Naugama"
        },
        {
            "label": "Naurangpur",
            "value": "Naurangpur"
        },
        {
            "label": "Nautanwa",
            "value": "Nautanwa"
        },
        {
            "label": "Nawabganj",
            "value": "Nawabganj"
        },
        {
            "label": "Nawabganj (Barabanki)",
            "value": "Nawabganj (Barabanki)"
        },
        {
            "label": "Nawabganj (Bareilly)",
            "value": "Nawabganj (Bareilly)"
        },
        {
            "label": "Newara",
            "value": "Newara"
        },
        {
            "label": "Nichlaul",
            "value": "Nichlaul"
        },
        {
            "label": "Nigoh",
            "value": "Nigoh"
        },
        {
            "label": "Nihtaur",
            "value": "Nihtaur"
        },
        {
            "label": "Niwari",
            "value": "Niwari"
        },
        {
            "label": "Nizamabad",
            "value": "Nizamabad"
        },
        {
            "label": "Noida",
            "value": "Noida"
        },
        {
            "label": "Nurpur",
            "value": "Nurpur"
        },
        {
            "label": "Obra",
            "value": "Obra"
        },
        {
            "label": "Orai",
            "value": "Orai"
        },
        {
            "label": "Oran",
            "value": "Oran"
        },
        {
            "label": "Pachperwa",
            "value": "Pachperwa"
        },
        {
            "label": "Padrauna",
            "value": "Padrauna"
        },
        {
            "label": "Pahasu",
            "value": "Pahasu"
        },
        {
            "label": "Paigaon",
            "value": "Paigaon"
        },
        {
            "label": "Pali",
            "value": "Pali"
        },
        {
            "label": "Palia Kalan",
            "value": "Palia Kalan"
        },
        {
            "label": "Paras Rampur",
            "value": "Paras Rampur"
        },
        {
            "label": "Parichha",
            "value": "Parichha"
        },
        {
            "label": "Parichhatgarh",
            "value": "Parichhatgarh"
        },
        {
            "label": "Parshadepur",
            "value": "Parshadepur"
        },
        {
            "label": "Pathakpura",
            "value": "Pathakpura"
        },
        {
            "label": "Patiali",
            "value": "Patiali"
        },
        {
            "label": "Patti",
            "value": "Patti"
        },
        {
            "label": "Pawayan",
            "value": "Pawayan"
        },
        {
            "label": "Payagpur",
            "value": "Payagpur"
        },
        {
            "label": "Phalauda",
            "value": "Phalauda"
        },
        {
            "label": "Phaphamau",
            "value": "Phaphamau"
        },
        {
            "label": "Phaphund",
            "value": "Phaphund"
        },
        {
            "label": "Phariha",
            "value": "Phariha"
        },
        {
            "label": "Pheona",
            "value": "Pheona"
        },
        {
            "label": "Phulpur",
            "value": "Phulpur"
        },
        {
            "label": "Pichhaura",
            "value": "Pichhaura"
        },
        {
            "label": "Pihani",
            "value": "Pihani"
        },
        {
            "label": "Pilibhit",
            "value": "Pilibhit"
        },
        {
            "label": "Pilkhua",
            "value": "Pilkhua"
        },
        {
            "label": "Pilkhuwa",
            "value": "Pilkhuwa"
        },
        {
            "label": "Pinahat",
            "value": "Pinahat"
        },
        {
            "label": "Pipraich",
            "value": "Pipraich"
        },
        {
            "label": "Pipri",
            "value": "Pipri"
        },
        {
            "label": "Pratapgarh",
            "value": "Pratapgarh"
        },
        {
            "label": "Prayagraj (Allahabad)",
            "value": "Prayagraj (Allahabad)"
        },
        {
            "label": "Pukhrayan",
            "value": "Pukhrayan"
        },
        {
            "label": "Puranpur",
            "value": "Puranpur"
        },
        {
            "label": "Purmafi",
            "value": "Purmafi"
        },
        {
            "label": "Purwa",
            "value": "Purwa"
        },
        {
            "label": "Qadirganj",
            "value": "Qadirganj"
        },
        {
            "label": "Rabupura",
            "value": "Rabupura"
        },
        {
            "label": "Radha Kund",
            "value": "Radha Kund"
        },
        {
            "label": "Radhakund",
            "value": "Radhakund"
        },
        {
            "label": "Raebareli",
            "value": "Raebareli"
        },
        {
            "label": "Rajapur",
            "value": "Rajapur"
        },
        {
            "label": "Ramkola",
            "value": "Ramkola"
        },
        {
            "label": "Ramnagar",
            "value": "Ramnagar"
        },
        {
            "label": "Rampur",
            "value": "Rampur"
        },
        {
            "label": "Rampura",
            "value": "Rampura"
        },
        {
            "label": "Ranipur",
            "value": "Ranipur"
        },
        {
            "label": "Ranipur Barsi",
            "value": "Ranipur Barsi"
        },
        {
            "label": "Rasra",
            "value": "Rasra"
        },
        {
            "label": "Rasulabad",
            "value": "Rasulabad"
        },
        {
            "label": "Rath",
            "value": "Rath"
        },
        {
            "label": "Raya",
            "value": "Raya"
        },
        {
            "label": "Rehar",
            "value": "Rehar"
        },
        {
            "label": "Renukoot",
            "value": "Renukoot"
        },
        {
            "label": "Renukut",
            "value": "Renukut"
        },
        {
            "label": "Reoti",
            "value": "Reoti"
        },
        {
            "label": "Reotipur",
            "value": "Reotipur"
        },
        {
            "label": "Richha",
            "value": "Richha"
        },
        {
            "label": "Robertsganj",
            "value": "Robertsganj"
        },
        {
            "label": "Rudarpur",
            "value": "Rudarpur"
        },
        {
            "label": "Rudauli",
            "value": "Rudauli"
        },
        {
            "label": "Rura",
            "value": "Rura"
        },
        {
            "label": "Sabalpur",
            "value": "Sabalpur"
        },
        {
            "label": "Sachendi",
            "value": "Sachendi"
        },
        {
            "label": "Sadabad",
            "value": "Sadabad"
        },
        {
            "label": "Sadat",
            "value": "Sadat"
        },
        {
            "label": "Safipur",
            "value": "Safipur"
        },
        {
            "label": "Saharanpur",
            "value": "Saharanpur"
        },
        {
            "label": "Sahaspur",
            "value": "Sahaspur"
        },
        {
            "label": "Sahaswan",
            "value": "Sahaswan"
        },
        {
            "label": "Sahawar",
            "value": "Sahawar"
        },
        {
            "label": "Sahibabad",
            "value": "Sahibabad"
        },
        {
            "label": "Sahpau",
            "value": "Sahpau"
        },
        {
            "label": "Saidpur",
            "value": "Saidpur"
        },
        {
            "label": "Sakhanu",
            "value": "Sakhanu"
        },
        {
            "label": "Sakit",
            "value": "Sakit"
        },
        {
            "label": "Salempur",
            "value": "Salempur"
        },
        {
            "label": "Salon",
            "value": "Salon"
        },
        {
            "label": "Sambhal",
            "value": "Sambhal"
        },
        {
            "label": "Samthar",
            "value": "Samthar"
        },
        {
            "label": "Sandi",
            "value": "Sandi"
        },
        {
            "label": "Sandila",
            "value": "Sandila"
        },
        {
            "label": "Sant Kabir Nagar",
            "value": "Sant Kabir Nagar"
        },
        {
            "label": "Sant Ravi Das Nagar",
            "value": "Sant Ravi Das Nagar"
        },
        {
            "label": "Sarai Akil",
            "value": "Sarai Akil"
        },
        {
            "label": "Sarai Ekdil",
            "value": "Sarai Ekdil"
        },
        {
            "label": "Sarai Mir",
            "value": "Sarai Mir"
        },
        {
            "label": "Sarauli",
            "value": "Sarauli"
        },
        {
            "label": "Sardhana",
            "value": "Sardhana"
        },
        {
            "label": "Sarila",
            "value": "Sarila"
        },
        {
            "label": "Sarurpur",
            "value": "Sarurpur"
        },
        {
            "label": "Sasni",
            "value": "Sasni"
        },
        {
            "label": "Satrikh",
            "value": "Satrikh"
        },
        {
            "label": "Saurikh",
            "value": "Saurikh"
        },
        {
            "label": "Sector",
            "value": "Sector"
        },
        {
            "label": "Seohara",
            "value": "Seohara"
        },
        {
            "label": "Shahabad",
            "value": "Shahabad"
        },
        {
            "label": "Shahganj",
            "value": "Shahganj"
        },
        {
            "label": "Shahi",
            "value": "Shahi"
        },
        {
            "label": "Shahjahanpur",
            "value": "Shahjahanpur"
        },
        {
            "label": "Shahpur",
            "value": "Shahpur"
        },
        {
            "label": "Shamli",
            "value": "Shamli"
        },
        {
            "label": "Shamsabad",
            "value": "Shamsabad"
        },
        {
            "label": "Shankargarh",
            "value": "Shankargarh"
        },
        {
            "label": "Shergarh",
            "value": "Shergarh"
        },
        {
            "label": "Sherkot",
            "value": "Sherkot"
        },
        {
            "label": "Shibnagar",
            "value": "Shibnagar"
        },
        {
            "label": "Shikarpur",
            "value": "Shikarpur"
        },
        {
            "label": "Shikarpur (Bulandshahr)",
            "value": "Shikarpur (Bulandshahr)"
        },
        {
            "label": "Shikohabad",
            "value": "Shikohabad"
        },
        {
            "label": "Shishgarh",
            "value": "Shishgarh"
        },
        {
            "label": "Shivrajpur",
            "value": "Shivrajpur"
        },
        {
            "label": "Shrawasti",
            "value": "Shrawasti"
        },
        {
            "label": "Siddharthnagar",
            "value": "Siddharthnagar"
        },
        {
            "label": "Siddhaur",
            "value": "Siddhaur"
        },
        {
            "label": "Sidhauli",
            "value": "Sidhauli"
        },
        {
            "label": "Sidhpura",
            "value": "Sidhpura"
        },
        {
            "label": "Sikandarabad",
            "value": "Sikandarabad"
        },
        {
            "label": "Sikandarpur",
            "value": "Sikandarpur"
        },
        {
            "label": "Sikandra",
            "value": "Sikandra"
        },
        {
            "label": "Sikandra Rao",
            "value": "Sikandra Rao"
        },
        {
            "label": "Sikandrabad",
            "value": "Sikandrabad"
        },
        {
            "label": "Sirathu",
            "value": "Sirathu"
        },
        {
            "label": "Sirsa",
            "value": "Sirsa"
        },
        {
            "label": "Sirsaganj",
            "value": "Sirsaganj"
        },
        {
            "label": "Sirsi",
            "value": "Sirsi"
        },
        {
            "label": "Sisauli",
            "value": "Sisauli"
        },
        {
            "label": "Siswa Bazar",
            "value": "Siswa Bazar"
        },
        {
            "label": "Sitapur",
            "value": "Sitapur"
        },
        {
            "label": "Sonbhadra",
            "value": "Sonbhadra"
        },
        {
            "label": "Soron",
            "value": "Soron"
        },
        {
            "label": "Suar",
            "value": "Suar"
        },
        {
            "label": "Sultanpur",
            "value": "Sultanpur"
        },
        {
            "label": "Surianwan",
            "value": "Surianwan"
        },
        {
            "label": "Tajpur",
            "value": "Tajpur"
        },
        {
            "label": "Talbahat",
            "value": "Talbahat"
        },
        {
            "label": "Talgram",
            "value": "Talgram"
        },
        {
            "label": "Tanda",
            "value": "Tanda"
        },
        {
            "label": "Terha",
            "value": "Terha"
        },
        {
            "label": "Thakurdwara",
            "value": "Thakurdwara"
        },
        {
            "label": "Thana Bhawan",
            "value": "Thana Bhawan"
        },
        {
            "label": "Tigri",
            "value": "Tigri"
        },
        {
            "label": "Tikaitnagar",
            "value": "Tikaitnagar"
        },
        {
            "label": "Tikri",
            "value": "Tikri"
        },
        {
            "label": "Tilhar",
            "value": "Tilhar"
        },
        {
            "label": "Tilsahri",
            "value": "Tilsahri"
        },
        {
            "label": "Tindwari",
            "value": "Tindwari"
        },
        {
            "label": "Titron",
            "value": "Titron"
        },
        {
            "label": "Tori Fatehpur",
            "value": "Tori Fatehpur"
        },
        {
            "label": "Tori-Fatehpur",
            "value": "Tori-Fatehpur"
        },
        {
            "label": "Tulsipur",
            "value": "Tulsipur"
        },
        {
            "label": "Tundla",
            "value": "Tundla"
        },
        {
            "label": "Ugu",
            "value": "Ugu"
        },
        {
            "label": "Ujhani",
            "value": "Ujhani"
        },
        {
            "label": "Umri",
            "value": "Umri"
        },
        {
            "label": "Un",
            "value": "Un"
        },
        {
            "label": "Unnao",
            "value": "Unnao"
        },
        {
            "label": "Usawan",
            "value": "Usawan"
        },
        {
            "label": "Usehat",
            "value": "Usehat"
        },
        {
            "label": "Uska",
            "value": "Uska"
        },
        {
            "label": "Utraula",
            "value": "Utraula"
        },
        {
            "label": "Varanasi",
            "value": "Varanasi"
        },
        {
            "label": "Vindhyachal",
            "value": "Vindhyachal"
        },
        {
            "label": "Vrindavan",
            "value": "Vrindavan"
        },
        {
            "label": "Walterganj",
            "value": "Walterganj"
        },
        {
            "label": "Wazirganj",
            "value": "Wazirganj"
        },
        {
            "label": "Yusufpur",
            "value": "Yusufpur"
        },
        {
            "label": "Zafarabad",
            "value": "Zafarabad"
        },
        {
            "label": "Zaidpur",
            "value": "Zaidpur"
        },
        {
            "label": "Zamania",
            "value": "Zamania"
        }
    ],
    "Uttarakhand": [
        {
            "label": "Almora",
            "value": "Almora"
        },
        {
            "label": "Bageshwar",
            "value": "Bageshwar"
        },
        {
            "label": "Barkot",
            "value": "Barkot"
        },
        {
            "label": "Bazpur",
            "value": "Bazpur"
        },
        {
            "label": "Bhim Tal",
            "value": "Bhim Tal"
        },
        {
            "label": "Bhowali",
            "value": "Bhowali"
        },
        {
            "label": "Birbhaddar",
            "value": "Birbhaddar"
        },
        {
            "label": "Chakrata",
            "value": "Chakrata"
        },
        {
            "label": "Chamoli",
            "value": "Chamoli"
        },
        {
            "label": "Champawat",
            "value": "Champawat"
        },
        {
            "label": "Clement Town",
            "value": "Clement Town"
        },
        {
            "label": "Dehradun",
            "value": "Dehradun"
        },
        {
            "label": "Devaprayag",
            "value": "Devaprayag"
        },
        {
            "label": "Dharchula",
            "value": "Dharchula"
        },
        {
            "label": "Doiwala",
            "value": "Doiwala"
        },
        {
            "label": "Dugadda",
            "value": "Dugadda"
        },
        {
            "label": "Dwarahat",
            "value": "Dwarahat"
        },
        {
            "label": "Garhwal",
            "value": "Garhwal"
        },
        {
            "label": "Haldwani",
            "value": "Haldwani"
        },
        {
            "label": "Harbatpur",
            "value": "Harbatpur"
        },
        {
            "label": "Haridwar",
            "value": "Haridwar"
        },
        {
            "label": "Jaspur",
            "value": "Jaspur"
        },
        {
            "label": "Joshimath",
            "value": "Joshimath"
        },
        {
            "label": "Kaladhungi",
            "value": "Kaladhungi"
        },
        {
            "label": "Kalagarh Project Colony",
            "value": "Kalagarh Project Colony"
        },
        {
            "label": "Kashipur",
            "value": "Kashipur"
        },
        {
            "label": "Khatima",
            "value": "Khatima"
        },
        {
            "label": "Kichha",
            "value": "Kichha"
        },
        {
            "label": "Kotdwara",
            "value": "Kotdwara"
        },
        {
            "label": "Laksar",
            "value": "Laksar"
        },
        {
            "label": "Lansdowne",
            "value": "Lansdowne"
        },
        {
            "label": "Lohaghat",
            "value": "Lohaghat"
        },
        {
            "label": "Manglaur",
            "value": "Manglaur"
        },
        {
            "label": "Mussoorie",
            "value": "Mussoorie"
        },
        {
            "label": "Naini Tal",
            "value": "Naini Tal"
        },
        {
            "label": "Narendranagar",
            "value": "Narendranagar"
        },
        {
            "label": "Pauri",
            "value": "Pauri"
        },
        {
            "label": "Pipalkoti",
            "value": "Pipalkoti"
        },
        {
            "label": "Pithoragarh",
            "value": "Pithoragarh"
        },
        {
            "label": "Raipur",
            "value": "Raipur"
        },
        {
            "label": "Raiwala Bara",
            "value": "Raiwala Bara"
        },
        {
            "label": "Ramnagar",
            "value": "Ramnagar"
        },
        {
            "label": "Ranikhet",
            "value": "Ranikhet"
        },
        {
            "label": "Rishikesh",
            "value": "Rishikesh"
        },
        {
            "label": "Roorkee",
            "value": "Roorkee"
        },
        {
            "label": "Rudraprayag",
            "value": "Rudraprayag"
        },
        {
            "label": "Sitarganj",
            "value": "Sitarganj"
        },
        {
            "label": "Srinagar",
            "value": "Srinagar"
        },
        {
            "label": "Sultanpur",
            "value": "Sultanpur"
        },
        {
            "label": "Tanakpur",
            "value": "Tanakpur"
        },
        {
            "label": "Tehri",
            "value": "Tehri"
        },
        {
            "label": "Tehri-Garhwal",
            "value": "Tehri-Garhwal"
        },
        {
            "label": "Udham Singh Nagar",
            "value": "Udham Singh Nagar"
        },
        {
            "label": "Uttarkashi",
            "value": "Uttarkashi"
        },
        {
            "label": "Vikasnagar",
            "value": "Vikasnagar"
        }
    ],
    "West Bengal": [
        {
            "label": "Adra",
            "value": "Adra"
        },
        {
            "label": "Ahmedpur",
            "value": "Ahmedpur"
        },
        {
            "label": "Aistala",
            "value": "Aistala"
        },
        {
            "label": "Aknapur",
            "value": "Aknapur"
        },
        {
            "label": "Alipurduar",
            "value": "Alipurduar"
        },
        {
            "label": "Amlagora",
            "value": "Amlagora"
        },
        {
            "label": "Amta",
            "value": "Amta"
        },
        {
            "label": "Amtala",
            "value": "Amtala"
        },
        {
            "label": "Andal",
            "value": "Andal"
        },
        {
            "label": "Arambagh community development block",
            "value": "Arambagh community development block"
        },
        {
            "label": "Asansol",
            "value": "Asansol"
        },
        {
            "label": "Ashoknagar Kalyangarh",
            "value": "Ashoknagar Kalyangarh"
        },
        {
            "label": "Badkulla",
            "value": "Badkulla"
        },
        {
            "label": "Baduria",
            "value": "Baduria"
        },
        {
            "label": "Bagdogra",
            "value": "Bagdogra"
        },
        {
            "label": "Bagnan",
            "value": "Bagnan"
        },
        {
            "label": "Bagula",
            "value": "Bagula"
        },
        {
            "label": "Bahula",
            "value": "Bahula"
        },
        {
            "label": "Baidyabati",
            "value": "Baidyabati"
        },
        {
            "label": "Bakreswar",
            "value": "Bakreswar"
        },
        {
            "label": "Balarampur",
            "value": "Balarampur"
        },
        {
            "label": "Bali Chak",
            "value": "Bali Chak"
        },
        {
            "label": "Bally",
            "value": "Bally"
        },
        {
            "label": "Balurghat",
            "value": "Balurghat"
        },
        {
            "label": "Bamangola community development block",
            "value": "Bamangola community development block"
        },
        {
            "label": "Baneswar",
            "value": "Baneswar"
        },
        {
            "label": "Bangaon",
            "value": "Bangaon"
        },
        {
            "label": "Bankra",
            "value": "Bankra"
        },
        {
            "label": "Bankura",
            "value": "Bankura"
        },
        {
            "label": "Bansberia",
            "value": "Bansberia"
        },
        {
            "label": "Bansihari community development block",
            "value": "Bansihari community development block"
        },
        {
            "label": "Barabazar",
            "value": "Barabazar"
        },
        {
            "label": "Baranagar",
            "value": "Baranagar"
        },
        {
            "label": "Barasat",
            "value": "Barasat"
        },
        {
            "label": "Bardhaman",
            "value": "Bardhaman"
        },
        {
            "label": "Barjora",
            "value": "Barjora"
        },
        {
            "label": "Barrackpore",
            "value": "Barrackpore"
        },
        {
            "label": "Baruipur",
            "value": "Baruipur"
        },
        {
            "label": "Basanti",
            "value": "Basanti"
        },
        {
            "label": "Basirhat",
            "value": "Basirhat"
        },
        {
            "label": "Bawali",
            "value": "Bawali"
        },
        {
            "label": "Begampur",
            "value": "Begampur"
        },
        {
            "label": "Belda",
            "value": "Belda"
        },
        {
            "label": "Beldanga",
            "value": "Beldanga"
        },
        {
            "label": "Beliatore",
            "value": "Beliatore"
        },
        {
            "label": "Berhampore",
            "value": "Berhampore"
        },
        {
            "label": "Bhadreswar",
            "value": "Bhadreswar"
        },
        {
            "label": "Bhandardaha",
            "value": "Bhandardaha"
        },
        {
            "label": "Bhatpara",
            "value": "Bhatpara"
        },
        {
            "label": "Birbhum district",
            "value": "Birbhum district"
        },
        {
            "label": "Birpara",
            "value": "Birpara"
        },
        {
            "label": "Bishnupur",
            "value": "Bishnupur"
        },
        {
            "label": "Bolpur",
            "value": "Bolpur"
        },
        {
            "label": "Budge Budge",
            "value": "Budge Budge"
        },
        {
            "label": "Canning",
            "value": "Canning"
        },
        {
            "label": "Chakapara",
            "value": "Chakapara"
        },
        {
            "label": "Chakdaha",
            "value": "Chakdaha"
        },
        {
            "label": "Champadanga",
            "value": "Champadanga"
        },
        {
            "label": "Champahati",
            "value": "Champahati"
        },
        {
            "label": "Champdani",
            "value": "Champdani"
        },
        {
            "label": "Chandannagar",
            "value": "Chandannagar"
        },
        {
            "label": "Chandrakona",
            "value": "Chandrakona"
        },
        {
            "label": "Chittaranjan",
            "value": "Chittaranjan"
        },
        {
            "label": "Churulia",
            "value": "Churulia"
        },
        {
            "label": "Contai",
            "value": "Contai"
        },
        {
            "label": "Cooch Behar",
            "value": "Cooch Behar"
        },
        {
            "label": "Cossimbazar",
            "value": "Cossimbazar"
        },
        {
            "label": "Dakshin Dinajpur district",
            "value": "Dakshin Dinajpur district"
        },
        {
            "label": "Dalkola",
            "value": "Dalkola"
        },
        {
            "label": "Dam Dam",
            "value": "Dam Dam"
        },
        {
            "label": "Darjeeling",
            "value": "Darjeeling"
        },
        {
            "label": "Daulatpur",
            "value": "Daulatpur"
        },
        {
            "label": "Debagram",
            "value": "Debagram"
        },
        {
            "label": "Debipur",
            "value": "Debipur"
        },
        {
            "label": "Dhaniakhali community development block",
            "value": "Dhaniakhali community development block"
        },
        {
            "label": "Dhulagari",
            "value": "Dhulagari"
        },
        {
            "label": "Dhulian",
            "value": "Dhulian"
        },
        {
            "label": "Dhupguri",
            "value": "Dhupguri"
        },
        {
            "label": "Diamond Harbour",
            "value": "Diamond Harbour"
        },
        {
            "label": "Digha",
            "value": "Digha"
        },
        {
            "label": "Dinhata",
            "value": "Dinhata"
        },
        {
            "label": "Domjur",
            "value": "Domjur"
        },
        {
            "label": "Dubrajpur",
            "value": "Dubrajpur"
        },
        {
            "label": "Durgapur",
            "value": "Durgapur"
        },
        {
            "label": "Egra",
            "value": "Egra"
        },
        {
            "label": "Falakata",
            "value": "Falakata"
        },
        {
            "label": "Farakka",
            "value": "Farakka"
        },
        {
            "label": "Fort Gloster",
            "value": "Fort Gloster"
        },
        {
            "label": "Gaighata community development block",
            "value": "Gaighata community development block"
        },
        {
            "label": "Gairkata",
            "value": "Gairkata"
        },
        {
            "label": "Gangadharpur",
            "value": "Gangadharpur"
        },
        {
            "label": "Gangarampur",
            "value": "Gangarampur"
        },
        {
            "label": "Garui",
            "value": "Garui"
        },
        {
            "label": "Garulia",
            "value": "Garulia"
        },
        {
            "label": "Ghatal",
            "value": "Ghatal"
        },
        {
            "label": "Giria",
            "value": "Giria"
        },
        {
            "label": "Gobardanga",
            "value": "Gobardanga"
        },
        {
            "label": "Gobindapur",
            "value": "Gobindapur"
        },
        {
            "label": "Gopalpur",
            "value": "Gopalpur"
        },
        {
            "label": "Gopinathpur",
            "value": "Gopinathpur"
        },
        {
            "label": "Gorubathan",
            "value": "Gorubathan"
        },
        {
            "label": "Gosaba",
            "value": "Gosaba"
        },
        {
            "label": "Gosanimari",
            "value": "Gosanimari"
        },
        {
            "label": "Gurdaha",
            "value": "Gurdaha"
        },
        {
            "label": "Guskhara",
            "value": "Guskhara"
        },
        {
            "label": "Habra",
            "value": "Habra"
        },
        {
            "label": "Haldia",
            "value": "Haldia"
        },
        {
            "label": "Haldibari",
            "value": "Haldibari"
        },
        {
            "label": "Halisahar",
            "value": "Halisahar"
        },
        {
            "label": "Harindanga",
            "value": "Harindanga"
        },
        {
            "label": "Haringhata",
            "value": "Haringhata"
        },
        {
            "label": "Haripur",
            "value": "Haripur"
        },
        {
            "label": "Hasimara",
            "value": "Hasimara"
        },
        {
            "label": "Hindusthan Cables Town",
            "value": "Hindusthan Cables Town"
        },
        {
            "label": "Hooghly district",
            "value": "Hooghly district"
        },
        {
            "label": "Howrah",
            "value": "Howrah"
        },
        {
            "label": "Ichapur",
            "value": "Ichapur"
        },
        {
            "label": "Indpur community development block",
            "value": "Indpur community development block"
        },
        {
            "label": "Ingraj Bazar",
            "value": "Ingraj Bazar"
        },
        {
            "label": "Islampur",
            "value": "Islampur"
        },
        {
            "label": "Jafarpur",
            "value": "Jafarpur"
        },
        {
            "label": "Jaigaon",
            "value": "Jaigaon"
        },
        {
            "label": "Jalpaiguri",
            "value": "Jalpaiguri"
        },
        {
            "label": "Jamuria",
            "value": "Jamuria"
        },
        {
            "label": "Jangipur",
            "value": "Jangipur"
        },
        {
            "label": "Jaynagar Majilpur",
            "value": "Jaynagar Majilpur"
        },
        {
            "label": "Jejur",
            "value": "Jejur"
        },
        {
            "label": "Jhalida",
            "value": "Jhalida"
        },
        {
            "label": "Jhargram",
            "value": "Jhargram"
        },
        {
            "label": "Jhilimili",
            "value": "Jhilimili"
        },
        {
            "label": "Kakdwip",
            "value": "Kakdwip"
        },
        {
            "label": "Kalaikunda",
            "value": "Kalaikunda"
        },
        {
            "label": "Kaliaganj",
            "value": "Kaliaganj"
        },
        {
            "label": "Kalimpong",
            "value": "Kalimpong"
        },
        {
            "label": "Kalna",
            "value": "Kalna"
        },
        {
            "label": "Kalyani",
            "value": "Kalyani"
        },
        {
            "label": "Kamarhati",
            "value": "Kamarhati"
        },
        {
            "label": "Kamarpukur",
            "value": "Kamarpukur"
        },
        {
            "label": "Kanchrapara",
            "value": "Kanchrapara"
        },
        {
            "label": "Kandi",
            "value": "Kandi"
        },
        {
            "label": "Karimpur",
            "value": "Karimpur"
        },
        {
            "label": "Katwa",
            "value": "Katwa"
        },
        {
            "label": "Kenda",
            "value": "Kenda"
        },
        {
            "label": "Keshabpur",
            "value": "Keshabpur"
        },
        {
            "label": "Kharagpur",
            "value": "Kharagpur"
        },
        {
            "label": "Kharar",
            "value": "Kharar"
        },
        {
            "label": "Kharba",
            "value": "Kharba"
        },
        {
            "label": "Khardaha",
            "value": "Khardaha"
        },
        {
            "label": "Khatra",
            "value": "Khatra"
        },
        {
            "label": "Kirnahar",
            "value": "Kirnahar"
        },
        {
            "label": "Kolkata",
            "value": "Kolkata"
        },
        {
            "label": "Konnagar",
            "value": "Konnagar"
        },
        {
            "label": "Krishnanagar",
            "value": "Krishnanagar"
        },
        {
            "label": "Krishnapur",
            "value": "Krishnapur"
        },
        {
            "label": "Kshirpai",
            "value": "Kshirpai"
        },
        {
            "label": "Kulpi",
            "value": "Kulpi"
        },
        {
            "label": "Kultali",
            "value": "Kultali"
        },
        {
            "label": "Kulti",
            "value": "Kulti"
        },
        {
            "label": "Kurseong",
            "value": "Kurseong"
        },
        {
            "label": "Lalgarh",
            "value": "Lalgarh"
        },
        {
            "label": "Lalgola",
            "value": "Lalgola"
        },
        {
            "label": "Loyabad",
            "value": "Loyabad"
        },
        {
            "label": "Madanpur",
            "value": "Madanpur"
        },
        {
            "label": "Madhyamgram",
            "value": "Madhyamgram"
        },
        {
            "label": "Mahiari",
            "value": "Mahiari"
        },
        {
            "label": "Mahishadal community development block",
            "value": "Mahishadal community development block"
        },
        {
            "label": "Mainaguri",
            "value": "Mainaguri"
        },
        {
            "label": "Manikpara",
            "value": "Manikpara"
        },
        {
            "label": "Masila",
            "value": "Masila"
        },
        {
            "label": "Mathabhanga",
            "value": "Mathabhanga"
        },
        {
            "label": "Matiali community development block",
            "value": "Matiali community development block"
        },
        {
            "label": "Matigara community development block",
            "value": "Matigara community development block"
        },
        {
            "label": "Medinipur",
            "value": "Medinipur"
        },
        {
            "label": "Mejia community development block",
            "value": "Mejia community development block"
        },
        {
            "label": "Memari",
            "value": "Memari"
        },
        {
            "label": "Mirik",
            "value": "Mirik"
        },
        {
            "label": "Monoharpur",
            "value": "Monoharpur"
        },
        {
            "label": "Muragacha",
            "value": "Muragacha"
        },
        {
            "label": "Muri",
            "value": "Muri"
        },
        {
            "label": "Murshidabad",
            "value": "Murshidabad"
        },
        {
            "label": "Nabadwip",
            "value": "Nabadwip"
        },
        {
            "label": "Nabagram",
            "value": "Nabagram"
        },
        {
            "label": "Nadia district",
            "value": "Nadia district"
        },
        {
            "label": "Nagarukhra",
            "value": "Nagarukhra"
        },
        {
            "label": "Nagrakata",
            "value": "Nagrakata"
        },
        {
            "label": "Naihati",
            "value": "Naihati"
        },
        {
            "label": "Naksalbari",
            "value": "Naksalbari"
        },
        {
            "label": "Nalhati",
            "value": "Nalhati"
        },
        {
            "label": "Nalpur",
            "value": "Nalpur"
        },
        {
            "label": "Namkhana community development block",
            "value": "Namkhana community development block"
        },
        {
            "label": "Nandigram",
            "value": "Nandigram"
        },
        {
            "label": "Nangi",
            "value": "Nangi"
        },
        {
            "label": "Nayagram community development block",
            "value": "Nayagram community development block"
        },
        {
            "label": "North 24 Parganas district",
            "value": "North 24 Parganas district"
        },
        {
            "label": "Odlabari",
            "value": "Odlabari"
        },
        {
            "label": "Paikpara",
            "value": "Paikpara"
        },
        {
            "label": "Panagarh",
            "value": "Panagarh"
        },
        {
            "label": "Panchla",
            "value": "Panchla"
        },
        {
            "label": "Panchmura",
            "value": "Panchmura"
        },
        {
            "label": "Pandua",
            "value": "Pandua"
        },
        {
            "label": "Panihati",
            "value": "Panihati"
        },
        {
            "label": "Panskura",
            "value": "Panskura"
        },
        {
            "label": "Parbatipur",
            "value": "Parbatipur"
        },
        {
            "label": "Paschim Medinipur district",
            "value": "Paschim Medinipur district"
        },
        {
            "label": "Patiram",
            "value": "Patiram"
        },
        {
            "label": "Patrasaer",
            "value": "Patrasaer"
        },
        {
            "label": "Patuli",
            "value": "Patuli"
        },
        {
            "label": "Pujali",
            "value": "Pujali"
        },
        {
            "label": "Puncha community development block",
            "value": "Puncha community development block"
        },
        {
            "label": "Purba Medinipur district",
            "value": "Purba Medinipur district"
        },
        {
            "label": "Purulia",
            "value": "Purulia"
        },
        {
            "label": "Raghudebbati",
            "value": "Raghudebbati"
        },
        {
            "label": "Raghunathpur",
            "value": "Raghunathpur"
        },
        {
            "label": "Raiganj",
            "value": "Raiganj"
        },
        {
            "label": "Rajmahal",
            "value": "Rajmahal"
        },
        {
            "label": "Rajnagar community development block",
            "value": "Rajnagar community development block"
        },
        {
            "label": "Ramchandrapur",
            "value": "Ramchandrapur"
        },
        {
            "label": "Ramjibanpur",
            "value": "Ramjibanpur"
        },
        {
            "label": "Ramnagar",
            "value": "Ramnagar"
        },
        {
            "label": "Rampur Hat",
            "value": "Rampur Hat"
        },
        {
            "label": "Ranaghat",
            "value": "Ranaghat"
        },
        {
            "label": "Raniganj",
            "value": "Raniganj"
        },
        {
            "label": "Raypur",
            "value": "Raypur"
        },
        {
            "label": "Rishra",
            "value": "Rishra"
        },
        {
            "label": "Sahapur",
            "value": "Sahapur"
        },
        {
            "label": "Sainthia",
            "value": "Sainthia"
        },
        {
            "label": "Salanpur community development block",
            "value": "Salanpur community development block"
        },
        {
            "label": "Sankarpur",
            "value": "Sankarpur"
        },
        {
            "label": "Sankrail",
            "value": "Sankrail"
        },
        {
            "label": "Santipur",
            "value": "Santipur"
        },
        {
            "label": "Santoshpur",
            "value": "Santoshpur"
        },
        {
            "label": "Santuri community development block",
            "value": "Santuri community development block"
        },
        {
            "label": "Sarenga",
            "value": "Sarenga"
        },
        {
            "label": "Serampore",
            "value": "Serampore"
        },
        {
            "label": "Serpur",
            "value": "Serpur"
        },
        {
            "label": "Shyamnagar West Bengal",
            "value": "Shyamnagar West Bengal"
        },
        {
            "label": "Siliguri",
            "value": "Siliguri"
        },
        {
            "label": "Singur",
            "value": "Singur"
        },
        {
            "label": "Sodpur",
            "value": "Sodpur"
        },
        {
            "label": "Solap",
            "value": "Solap"
        },
        {
            "label": "Sonada",
            "value": "Sonada"
        },
        {
            "label": "Sonamukhi",
            "value": "Sonamukhi"
        },
        {
            "label": "Sonarpur community development block",
            "value": "Sonarpur community development block"
        },
        {
            "label": "South 24 Parganas district",
            "value": "South 24 Parganas district"
        },
        {
            "label": "Srikhanda",
            "value": "Srikhanda"
        },
        {
            "label": "Srirampur",
            "value": "Srirampur"
        },
        {
            "label": "Suri",
            "value": "Suri"
        },
        {
            "label": "Swarupnagar community development block",
            "value": "Swarupnagar community development block"
        },
        {
            "label": "Takdah",
            "value": "Takdah"
        },
        {
            "label": "Taki",
            "value": "Taki"
        },
        {
            "label": "Tamluk",
            "value": "Tamluk"
        },
        {
            "label": "Tarakeswar",
            "value": "Tarakeswar"
        },
        {
            "label": "Titagarh",
            "value": "Titagarh"
        },
        {
            "label": "Tufanganj",
            "value": "Tufanganj"
        },
        {
            "label": "Tulin",
            "value": "Tulin"
        },
        {
            "label": "Uchalan",
            "value": "Uchalan"
        },
        {
            "label": "Ula",
            "value": "Ula"
        },
        {
            "label": "Uluberia",
            "value": "Uluberia"
        },
        {
            "label": "Uttar Dinajpur district",
            "value": "Uttar Dinajpur district"
        },
        {
            "label": "Uttarpara Kotrung",
            "value": "Uttarpara Kotrung"
        }
    ]
}