import React, { useEffect, useState, useRef, useCallback } from "react";
import Navbar from "../Navbar/Navbar";
import {
  Layout,
  Collapse,
  Empty,
  ConfigProvider,
  Row,
  Col,
  Slider,
  Modal,
  Divider,
} from "antd";
import styles from "../Course/coursedetails.module.css";
import { baseURL } from "../../../../Constant";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import {
  getToken,
  getUserInfo,
} from "../../../../common/helpers/localStorage.helper";
import SmHeader from "../../../SmHeader/SmHeader";
import ApexCharts from "react-apexcharts";
import TextArea from "antd/es/input/TextArea";
import { toastNotify } from "./../../../shared/Toast/Toast";
import { useRecoilValue } from "recoil";
import { AtomUserInfo } from "../../../../store/genral.store";
import { usePdf } from "@mikecousins/react-pdf";
import { GrLinkPrevious, GrLinkNext } from "react-icons/gr";
import dayjs from "dayjs";
import Subordinate from './Subordinate';

const SubCourseDetail = () => {
  // Variables
  const { Content } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const { id } = useParams();
  const token = getToken("token");
  const [courseDetail, setCourseDetail] = useState({});
  const [taskList, setTaskList] = useState([]);
  const [tData, setTData] = useState({});
  const userInfo = useRecoilValue(AtomUserInfo);
  const [docs, setDocs] = useState([]);
  const [docs1, setDocs1] = useState([]);
  const [dataSeries, setDataSeries] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalData1, setModalData1] = useState({});

  const [page, setPage] = useState(1);
  const Presentation = useRef(null);

  const { pdfDocument } = usePdf({
    file: docs,
    page: page,
    canvasRef: Presentation,
  });

  const [pageNo, setPageNo] = useState(1);
  const implementation_plan = useRef(null);

  const { pdfDocument: pdfDocument1 } = usePdf({
    file: docs1,
    page: pageNo,
    canvasRef: implementation_plan,
  });

  //callbacks
  useEffect(() => {
    if (id) {
      fetchAdminData(id);
    }
  }, [id, userInfo.role]);

  const handleChange = (e) => {
    let _value = Number(e.target.value);
    if (_value > pdfDocument?.numPages) {
      toastNotify(
        `Page number should not be greater than ${pdfDocument?.numPages}`,
        "error"
      );
      return;
    } else {
      setPage(_value);
    }
  };

  const handlePdf2Change = (e) => {
    let _value = Number(e.target.value);
    if (_value > pdfDocument1?.numPages) {
      toastNotify(
        `Page number should not be greater than ${pdfDocument1?.numPages}`,
        "error"
      );
      return;
    } else {
      setPageNo(_value);
    }
  };

  // Functions

  const handleValidation = (data) => {
    if (!data) return "Answer Should Not Be Empty";
    if (!data?.answer_by_subordinate) return "Answer Should Not Be Empty";
    if (!data?.level_by_subordinate) return "Level Should Not Be Empty";
    return true;
  };

  const handleUpdateTask = async (answer, taskId) => {
    let isValid = handleValidation(answer);
    if (isValid === true) {
      let _payload = { ...answer };
      delete _payload["isDisabled"];
      delete _payload["isModified"];
      delete _payload["answer"];
      delete _payload["level"];
      delete _payload["answer_by_subordinate"];
      delete _payload["level_by_subordinate"];
      delete _payload["answer_by_admin"];
      delete _payload["level_by_admin"];
      delete _payload["task_deadline"];
      delete _payload["id"];
      _payload["role"] = "subordinate";
      _payload["answer_by_subordinate"] = answer.answer_by_subordinate;
      _payload["level_by_subordinate"] = answer.level_by_subordinate;
      try {
        const response = await axios({
          method: "post",
          data: _payload,
          url: `${baseURL}/update_task_review/${taskId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status === 200) {
          toastNotify("Answer Added Successfully", "sucess");
          // fetchData(id);
          fetchAdminData(id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      toastNotify(isValid, "error");
    }
  };

  const handleDraftUpdateAnswer = async (answer) => {
    let isValid = handleValidation(answer);
    if (isValid === true) {
      let _payload = { ...answer };
      delete _payload["isDisabled"];
      delete _payload["isModified"];
      delete _payload["answer"];
      delete _payload["level"];
      delete _payload["answer_by_inline"];
      delete _payload["level_by_inline"];
      delete _payload["answer_by_admin"];
      delete _payload["level_by_admin"];
      delete _payload["task_deadline"];
      delete _payload["is_completed"];
      delete _payload["is_inline_completed"];
      delete _payload["is_admin_completed"];
      delete _payload["is_subord_completed"];
      delete _payload["id"];
      _payload["role"] = "subordinate";
      _payload["answer_by_subordinate"] = answer.answer_by_subordinate;
      _payload["level_by_subordinate"] = 0;
      try {
        const response = await axios({
          method: "post",
          data: _payload,
          url: `${baseURL}/update_task_review_draft/${answer.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status === 200) {
          toastNotify("Answer Added Successfully", "sucess");
          // fetchData(id);
          fetchAdminData(id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      toastNotify(isValid, "error");
    }
  };

  const makeData = (taskData, heading,emp_name) => {
    let _list = [];
    let _newList = [];
    let index = 0;
    let _nObj = {};
    for (const data in taskData) {
      index++;
      const element = taskData[data];
      let obj = {};
      const taskMonth = dayjs(heading[data]?.day);
      const currentMonth = dayjs();

      if (taskMonth.isBefore(currentMonth, "month")) {
        obj["collapsible"] = "header"; // Before June, collapsible
      } else if (taskMonth.isSame(currentMonth, "month")) {
        obj["collapsible"] = "header"; // June, collapsible
      } else {
        obj["collapsible"] = "disabled"; // After June, not collapsible
      }
      obj["key"] = data;
      obj["label"] = (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            color: "#171151",
          }}
        >
          <p>{heading[data ?? ""]?.month}</p>
          <p className={styles.deadlineDate}>
            {" "}
            Deadline Date : {heading[data ?? ""]?.day}
          </p>
        </div>
      );
      let _element = [...element];
      let _el = [];
      for (const ele of _element) {
        let _ans = [];
        let _obj = { ...ele };
        for (const ans of ele.answer) {
          let _obj = { ...ans };
          _ans.push(_obj);
        }
        _obj["answer"] = _ans;
        _el.push(_obj);
        _nObj[index] = _obj;
      }
      _newList.push(_el);
      obj["children"] = _el?.map((val, idx) => {
        let _obj = {};
        _obj["key"] = idx;
        _obj["label"] = val?.questions;
        _obj["children"] = val?.answer?.map((answer, index) => {
          let _idx = index + 1;
          let _disabled;
          let currentDate = dayjs().format("YYYY-MM-DD");
          // if (!answer?.answer_by_subordinate || answer?.isModified) {
          //   _disabled = false;
          // } else if (answer?.id && answer?.answer) {
          //   if (answer.is_completed) {
          //     _disabled = true;
          //   } else {
          //     _disabled = false;
          //   }
          // } else {
          //   _disabled = answer?.isDisabled === false ? false : true;
          // }

          if(!answer?.answer_by_subordinate  || !answer.is_subord_completed){
            _disabled=false;

          }else{
            _disabled=true
          }

          // if (taskMonth.isBefore(currentDate, "day")) {
          //   _disabled = true;
          // }
          return (
            <>
              <div>
                {Object.keys(answer)?.includes("answer_by_subordinate") && (
                  <div key={index}>
                    <div className={styles.employeeinputs}>
                      <div className={styles.inputWrp}>
                        <label className={styles.questionLabel}>
                          Answer by {userInfo?.user?.first_name}{" "}
                        </label>
                        {renderInput(
                          "answer_by_subordinate",
                          answer.answer_by_subordinate,
                          _disabled,
                          taskData,
                          data,
                          idx,
                          index,
                          heading,emp_name
                        )}
                      </div>
                      <div className={styles.sliderWrp}>
                        <label className={styles.questionLabel}>
                          Assesment by {userInfo?.user?.first_name}
                        </label>

                        <div className={styles.sliderCounter}>
                          <Slider
                            min={1}
                            max={10}
                            disable={_disabled}
                            onChange={(e) =>
                              handleAnswerInput(
                                e,
                                "level_by_subordinate",
                                taskData,
                                data,
                                idx,
                                index,
                                heading,emp_name
                              )
                            }
                            disabled={_disabled}
                            value={answer.level_by_subordinate ? answer.level_by_subordinate : 1}
                            marks={{
                              1: "1",
                              2: "2",
                              3: "3",
                              4: "4",
                              5: "5",
                              6: "6",
                              7: "7",
                              8: "8",
                              9: "9",
                              10: "10",
                            }}
                            step={1}
                          />
                        </div>

                        {!_disabled && (
                          <>
                            <div className={styles.checkbtnWrp}>
                              <button
                                className={styles.checkBtn}
                                onClick={() =>
                                  handleDraftUpdateAnswer(answer, val.id)
                                }
                              >
                                Draft
                              </button>
                              <ConfigProvider
                                theme={{
                                  token: {
                                    colorSplit: "#fff",
                                  },
                                }}
                              >
                                <Divider
                                  type="vertical"
                                  className={styles.divider}
                                />
                              </ConfigProvider>
                              <button
                                className={styles.checkBtn}
                                onClick={() =>
                                  handleUpdateTask(answer, answer.id)
                                }
                              >
                                Submit
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                 <div key={index}>
                  <div className={styles.employeeinputs}>
                    <div className={styles.inputWrp}>
                      <label className={styles.questionLabel}>
                        Answer by Presenter
                      </label>
                      {renderInput(
                        "answer",
                        // answer.answer,
                        answer.level !== 0
                          ? answer.answer
                            : "",
                        true
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        });

        return (
          <div>
            <div key={index}>
              <ConfigProvider
                theme={{
                  components: {
                    Collapse: {
                      headerBg: "white",
                    },
                  },
                }}
              >
                <Collapse
                  className="custom-collapse-inner"
                  accordion={true}
                  defaultActiveKey={["0"]}
                  items={[_obj]}
                />
              </ConfigProvider>
            </div>
          </div>
        );
      });
      _list.push(obj);
    }
    setTaskList(_list);
    return _nObj;
  };

  const fetchAdminData = async (id) => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/show_course_detail_admin/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const courseData = await response?.data?.course_data;
      const _taskData = await response?.data?.task_data;
      const _heading = await response?.data?.heading_peersub;
const emp_name = response?.data?.name
      let newObj = {};
      for (const key in _taskData) {
        let _newList = [];
        for (const data of _taskData[key]) {
          let _newObj = { ...data };
          let _sorted = data?.answer?.sort((a, b) => {
            let isInclude = Object.keys(a)?.includes("answer_by_subordinate");
            return isInclude ? 1 : -1;
          });
          _newObj["answer"] = _sorted;
          _newList.push(_newObj);
        }
        newObj[key] = _newList;
      }
      const res = makeData(newObj, _heading,emp_name);
      setTData(res);
      if (courseData) {
        const pptURl = courseData.presentation
          ? `${baseURL}${courseData.presentation}`
          : null;
        const pptURl2 = courseData.implementation_plan
          ? `${baseURL}${courseData.implementation_plan}`
          : null;

        // Only setDocs if pptURl is not null
        if (pptURl) {
          setDocs(pptURl);
        } else {
          // Handle the case where pptURl is null, if necessary
          console.error("pptURl is null, cannot set docs.");
        }

        // If you also want to handle pptURl2, you can add similar logic
        if (pptURl2) {
          setDocs1(pptURl2)
          // handle pptURl2 as needed
        } else {
          console.error("pptURl2 is null, cannot set docs.");
        }
      } else {
        console.error("courseData is null or undefined.");
        // You might want to handle this case by setting a default state or showing an error message to the user
      }
      setCourseDetail(courseData);
      fetchGraphData(courseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const renderNoData = () => {
    return <Empty description="No Task Available" />;
  };

  const fetchGraphData = async (val) => {
    try {
      let _payload = {};
      _payload["course_name"] = val?.course_name;
      const response = await axios({
        method: "post",
        data: _payload,
        url: `${baseURL}/monthly_data_entered/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = response.data;
      let list = [];
      let monthList = response?.data?.month_list;
      let _chartData = { ...chartData };
      let _options = { ...chartData.options };
      let _xaxis = { ..._options.xaxis };
      _xaxis["categories"] = monthList;
      _options["xaxis"] = _xaxis;
      _chartData["options"] = _options;
      setChartData(_chartData);
      for (const key in data) {
        if (key === "monthly_avg") continue;
        if (key === "month_list") continue;

        let element = data[key];
        for (const ele in element) {
          if (ele === "Self Assessment") continue;
          if (ele === "iCretegy Assessment") continue;
          if (ele === "Peer (CFT) Assessment") continue;
          let obj = {};
          obj["name"] = ele;
          obj["data"] = element[ele];
          obj["type"] = "area";
          list.push(obj);
        }
      }
      setDataSeries(list);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openModal = (data) => {
    setPage(0);
    setOpen(true);
    setModalData(data);
    setTimeout(() => {
      setPage(1);
    }, 2000);
  };

  const closeModal = () => {
    setOpen(false);
    setModalData({});
  };
  const openModal1 = (data) => {
    setPageNo(0);
    setOpen1(true);
    setModalData1(data);
    setTimeout(() => {
      setPageNo(1);
    }, 2000);
  };

  const closeModal1 = () => {
    setOpen1(false);
    setModalData({});
  };

  //others

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        height: 350,
        type: "area",
        redrawOnParentResize: true,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      yaxis: [
        {
          title: {
            text: "Assessment",
          },
          min: 0,
          max: 10,
        },
      ],
      colors: ["#1677ff", "#FF9300", "#49FF00", "#FF0000"],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.3,
          opacityTo: 0.8,
          stops: [0, 300],
        },
      },
    },
  });

  const renderInput = (
    name,
    value,
    disable,
    taskData,
    data,
    idx,
    index,
    heading,emp_name
  ) => {
    return (
      <div className={styles.input_wrapper}>
        <TextArea
          className={styles.input}
          name={name}
          value={value}
          disabled={disable}
          onChange={(e) =>
            handleAnswerInput(e, name, taskData, data, idx, index, heading,emp_name)
          }
        />
      </div>
    );
  };

  const handleAnswerInput = (
    e,
    name,
    taskData,
    month,
    index,
    ansIdx,
    heading,emp_name
  ) => {
    // console.log(e ,'');
    let _value = "";
    if (name === "level_by_subordinate" || name === "level_by_admin") {
      _value = e;
    } else {
      const { value } = e.target;
      _value = value;
    }
    let _taskData = { ...taskData };
    let _month = [..._taskData[month]];
    let _selMonth = { ..._month[index] };
    let _answer = [..._selMonth.answer];
    let _selectedAnsWer = { ..._answer[ansIdx] };
    _selectedAnsWer[name] = _value;
    _selectedAnsWer["isModified"] = true;
    _answer[ansIdx] = _selectedAnsWer;
    _selMonth["answer"] = _answer;
    _month[index] = _selMonth;
    _taskData[month] = _month;
    const res = makeData(_taskData, heading,emp_name);
    setTData(res);
  };

  return (
    <Layout>
      <Navbar collapsed={collapsed} />
      <Layout>
        <Content className={styles.courseDetailPageWrp}>
          <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />

          <div className={styles.courseDetail}>
            <div className={styles.presentation_page_wrapper}>
              <h1>Overall Assessment - {courseDetail.course_name}</h1>
              <div className={styles.chart}>
                {/* <ApexCharts
                  options={chartData.options}
                  series={chartData.series}
                  type="line"
                  height={400}
                  style={{ overflow: "hidden" }}
                /> */}
                <ApexCharts
                  options={chartData.options}
                  // series={chartData.series}
                  series={dataSeries || []}
                  type="area"
                  height={400}
                  style={{ overflow: "hidden" }}
                />
              </div>

              <div className={styles.presentation_ppt_wrapper}>
                <h1>Presentations</h1>
                <div className={styles.presentationWrp}>
                  <div className={styles.pptWrp}>
                    <h2 className="courseName_1">Outlook Presentation</h2>
                    <div className={styles.displayControlls}>
                      <div>
                        <button
                          className={styles.contrBtn}
                          disabled={page === 1}
                          onClick={() => setPage(page - 1)}
                        >
                          <GrLinkPrevious />
                        </button>
                      </div>

                      <div>
                        {page} / {pdfDocument?.numPages}
                      </div>
                      <div>
                        <button
                          className={styles.contrBtn}
                          disabled={page === pdfDocument?.numPages}
                          onClick={() => setPage(page + 1)}
                        >
                          <GrLinkNext />
                        </button>
                      </div>
                    </div>
                    {!pdfDocument && <span>Loading...</span>}
                    <canvas ref={Presentation} className={styles.pdfCanvas} />

                    <button
                      onClick={() => openModal(docs)}
                      style={{
                        fontSize: "1rem",
                        backgroundColor: "#171151",
                        color: "white",
                        borderRadius: "1rem",
                        border: "none",
                        padding: "1rem",
                      }}
                    >
                      Full Screen
                    </button>
                  </div>
                  <div className={styles.pptWrp}>
                    <h2 className="courseName_1">Implementation Plan</h2>
                    <div className={styles.displayControlls}>
                      <div>
                        <button
                          className={styles.contrBtn}
                          disabled={pageNo === 1}
                          onClick={() => setPageNo(pageNo - 1)}
                        >
                          <GrLinkPrevious />
                        </button>
                      </div>

                      <div>
                        {pageNo} / {pdfDocument1?.numPages}
                      </div>
                      <div>
                        <button
                          className={styles.contrBtn}
                          disabled={pageNo === pdfDocument1?.numPages}
                          onClick={() => setPageNo(pageNo + 1)}
                        >
                          <GrLinkNext />
                        </button>
                      </div>
                    </div>
                    {!pdfDocument && <span>Loading...</span>}
                    <canvas
                      ref={implementation_plan}
                      className={styles.pdfCanvas}
                    />
                    <button
                      onClick={() => openModal1(docs1)}
                      style={{
                        fontSize: "1rem",
                        backgroundColor: "#171151",
                        color: "white",
                        borderRadius: "1rem",
                        border: "none",
                        padding: "1rem",
                      }}
                    >
                      Full Screen
                    </button>
                  </div>
                </div>
              </div>

              <div className={styles.taskListWrapper}>
                <h1>Task List</h1>
                <div className={styles.tasks}>
                  <ConfigProvider
                    theme={{
                      components: {
                        Collapse: {
                          headerBg: "#FAFAFA",
                        },
                      },
                    }}
                  >
                    <Collapse
                      accordion
                      className="custom-collapse"
                      defaultActiveKey={["1"]}
                      items={taskList ?? renderNoData}
                    />
                  </ConfigProvider>
                </div>
              </div>
            </div>
          </div>

          <Modal
            centered
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => closeModal(false)}
            width={1000}
          >
            {modalData && (
              <div className={styles.modalDataPdf}>
                <div className={styles.controllsAndInput}>
                  <div className={styles.pdfInput}>
                    <label>Search Page Number</label>
                    <input
                      label="Search Page Number"
                      value={page}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={styles.displayControlls}>
                    <div>
                      <button
                        className={styles.contrBtn}
                        disabled={page === 1}
                        onClick={() => setPage(page - 1)}
                      >
                        <GrLinkPrevious />
                      </button>
                    </div>

                    <div>
                      {page} / {pdfDocument?.numPages}
                    </div>
                    <div>
                      <button
                        className={styles.contrBtn}
                        disabled={page === pdfDocument?.numPages}
                        onClick={() => setPage(page + 1)}
                      >
                        <GrLinkNext />
                      </button>
                    </div>
                  </div>
                </div>

                {!pdfDocument && <span>Loading...</span>}
                <canvas ref={Presentation} className={styles.pdfCanvas} />
              </div>
            )}
          </Modal>
          <Modal
            centered
            open={open1}
            onOk={() => setOpen1(false)}
            onCancel={() => closeModal1(false)}
            width={1000}
          >
            {modalData1 && (
              <>
                <div className={styles.controllsAndInput}>
                  <div className={styles.pdfInput}>
                    <label>Search Page Number</label>
                    <input
                      label="Search Page Number"
                      value={pageNo}
                      onChange={handlePdf2Change}
                    />
                  </div>
                  <div className={styles.displayControlls}>
                    <div>
                      <button
                        className={styles.contrBtn}
                        disabled={pageNo === 1}
                        onClick={() => setPageNo(pageNo - 1)}
                      >
                        <GrLinkPrevious />
                      </button>
                    </div>

                    <div>
                      {pageNo} / {pdfDocument1?.numPages}
                    </div>
                    <div>
                      <button
                        className={styles.contrBtn}
                        disabled={pageNo === pdfDocument1?.numPages}
                        onClick={() => setPageNo(pageNo + 1)}
                      >
                        <GrLinkNext />
                      </button>
                    </div>
                  </div>
                </div>

                {!pdfDocument && <span>Loading...</span>}
                <canvas
                  ref={implementation_plan}
                  className={styles.pdfCanvas}
                />
              </>
            )}
          </Modal>
        </Content>
      </Layout>
    </Layout>
  );
};

export default SubCourseDetail;
