import React, { useEffect, useState } from "react";
import styles from "./login.module.css";
import { Link, Route, useNavigate } from "react-router-dom";
import axios from "axios";
import { LoginUrl } from "../../../Constant";
import TTInput from "../../shared/TTInput/TTInput";
import {
  getToken,
  getUserInfo,
  setToken,
} from "../../../common/helpers/localStorage.helper";
import Footer from "../Footer/Footer";
import { toastNotify } from "../../shared/Toast/Toast";
import { IcamsRoutes } from "../../../common/constants/route.constants";
import header_logo from "../../../assets/icretegy_logo.png";
import { useSetRecoilState } from "recoil";
import { AtomIsLogin } from "../../../store/genral.store";
import icamsImg from "../../../assets/iWalkTheTalk_Logo_final.png";
import Header from "../Header/Header";

const Login = () => {
  //variables
  const navigate = useNavigate();
  const token = getToken("token");
  const user = getUserInfo();
  const [menu, setMenu] = useState(false);
  const setIsLogin = useSetRecoilState(AtomIsLogin);
  const [formdata, setFormdata] = useState({
    username: "",
    password: "",
  });

  //calbacks
  useEffect(() => {
    if (token && user?.id) {
      let dashboard =
        user?.role === "employee"
          ? IcamsRoutes.EmployeeHome
          : user?.role === "ceo"
          ? IcamsRoutes.CeoHome 
          : user?.role === "admin" 
          ? IcamsRoutes.adminHome
          : IcamsRoutes.EmployeeHome;
      navigate(dashboard);
    }
  }, [user?.id]);

  //funtions
  const handleInputChange = (e) => {
    const { data, name } = e;
    let _formData = { ...formdata };
    _formData[name] = data;
    setFormdata(_formData);
  };

  const handleValidation = (data) => {
    if (!data?.username) return "Username Should Not Be Empty";
    if (!data?.password) return "Password Should Not Be Empty";
    return true;
  };

  const handleSubmit = async () => {
    let validationResult = handleValidation(formdata);
    if (validationResult === true) {
      try {
        debugger
        const res = await axios({
          method: "post",
          url:LoginUrl,
          data: formdata,
          headers: {
            "Content-Type": "application/json", // Include the access token in the headers
          },
        });
        if (res.status === 200) {
          const response = res.data;
          let _userInfo = {
            role: response.role,
            id: response.id,
            comp_logo: response.company_logo,
          };
          setToken("userInfo", JSON.stringify(_userInfo));
          setToken("token", response.token);
          let dashboard =
            response?.role === "employee"
              ? IcamsRoutes.EmployeeHome
              : response?.role === "ceo"
              ? IcamsRoutes.CeoHome
              : response?.role === "admin"
              ? IcamsRoutes.adminHome
              : response?.role === "subor"
              ? IcamsRoutes.subordinate
              : response?.role === "peer"
              ? IcamsRoutes.inline
              : IcamsRoutes.subordinate;
          navigate(dashboard);
          setIsLogin(true);
          toastNotify("logined Successfully.", "success");
        }
      } catch (error) {
        toastNotify("Invalid Credentials!", "error");
      }
    } else {
      toastNotify(validationResult, "error");
    }
  };

  const handleKeyUp = (e) => {
    if (e.code === "Enter") handleSubmit();
  };

  // Template
  const renderInput = (label, value, name) => {
    return (
      <TTInput
        type={name === "password" ? "password" : "text"}
        
        onKeyUp={handleKeyUp}
        value={value}
        label={label}
        name={name}
        onChange={handleInputChange}
      />
    );
  };

  return (
    <>
      <div className={styles.main}>
        <div className={styles.header}>
          <Header setHeader={setMenu} />
        </div>
        <div className={styles.loginWrpdiv}>
          <div className={styles.loginWrapper}>
            <div className={styles.image}>
              <img className={styles.leftImage} src={icamsImg} />
            </div>
            <div className={styles.login}>
              <h2>Welcome to iCAMS</h2>
              <p>Sign in to continue to iCAMS</p>
              <div className={styles.logindata}>
                <div className={styles.input}>
                  {renderInput("Username", formdata.username, "username")}
                </div>
                <div className={styles.input}>
                  {renderInput("Password", formdata.password, "password")}
                </div>
              </div>
              <div className={styles.forgot}>
                <Link className={styles.form_forgot_pass} to={"/forgotPass"}>
                  Forget Password?
                </Link>
              </div>
              <button onClick={handleSubmit} className={styles.log_btn}>
                Login
              </button>

              <button className={styles.sign_btn}>
                New User? <Link to="/signup">Sign Up</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
      <div className={`${styles.open} ${menu ? styles.show : ""}`}>
        <Link className={styles.menuBar_link} to="/">
          Home
        </Link>
        <Link className={styles.menuBar_link} to="/signup">
          Sign up
        </Link>
        <Link className={styles.menuBar_link} to="/login">
          Log in
        </Link>
        <Link className={styles.menuBar_link} to="/t_s">
          Terms & Conditions
        </Link>
        <Link className={styles.menuBar_link} to="/privacyAndPolicy">
          Privacy Policy
        </Link>
      </div>
    </>
  );
};

export default Login;
