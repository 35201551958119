import React, { useEffect, useState } from "react";
import styles from "../login/login.module.css";
import { Link, Route, useNavigate } from "react-router-dom";
import axios from "axios";
import { LoginUrl, baseURL } from "../../../Constant";
import TTInput from "../../shared/TTInput/TTInput";
import {
  getToken,
  getUserInfo,
  setToken,
} from "../../../common/helpers/localStorage.helper";
import Footer from "../Footer/Footer";
import { toastNotify } from "../../shared/Toast/Toast";
import { IcamsRoutes } from "../../../common/constants/route.constants";
import header_logo from "../../../assets/icretegy_logo.png";
import { useSetRecoilState } from "recoil";
import { AtomIsLogin } from "../../../store/genral.store";

import icamsImg from "../../../assets/iWalkTheTalk_Logo_final.png";

const ForgotPass = () => {
  const navigate = useNavigate();
  const token = getToken("token");
  const user = getUserInfo();
  const setIsLogin = useSetRecoilState(AtomIsLogin);
  useEffect(() => {
    if (token && user?.id) {
      let dashboard =
        user?.role === "employee"
          ? IcamsRoutes.EmployeeHome
          : user?.role === "ceo"
          ? IcamsRoutes.CeoHome
          : IcamsRoutes.EmployeeHome;
      navigate(dashboard);
    }
  }, [user?.id]);

  const [formdata, setFormdata] = useState({
    email: "",
  });

  const handleInputChange = (e) => {
    const { data, name } = e;
    let _formData = { ...formdata };
    _formData[name] = data;
    setFormdata(_formData);
  };

  const handleValidation = (data) => {
    if (!data?.email) return "Username Should Not Be Empty";
    return true;
  };
  

  const handleSubmit = async () => {
    let validationResult = handleValidation(formdata);
    if (validationResult === true) {
      try {
        const res = await axios({
          method: "post",
          url: `${baseURL}/forgot_password`,
          data: formdata,
          headers: {
            "Content-Type": "application/json", // Include the access token in the headers
          },
        });
        console.log(res.data)
        if (res.data.status === 200) {
          navigate('/login')
          toastNotify(res.data.message,"success")

        }else{
          toastNotify('No user found with this email',"error")
        }
      } catch (error) {
        toastNotify("Invalid Credentials!", "error");
      }
    } else {
      toastNotify(validationResult, "error");
    }
  };

  const handleKeyUp = (e) => {
    if (e.code === "Enter") handleSubmit();
  };
  // Template
  const renderInput = (label, value, name,placeholder) => {
    return (
      <TTInput
        type={name === "password" ? "password" : "text"}
        onKeyUp={handleKeyUp}
        value={value}
        label={label}
        name={name}
        placeholder={placeholder}
        onChange={handleInputChange}
      />
    );
  };

  return (
    <>
      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.head_logo}>
            <Link to="/" className={styles.header_logo}>
              <img src={header_logo} />
            </Link>
          </div>
        </div>
        <div className={styles.loginWrpdiv}>
          <div className={styles.loginWrapper}>
            <div className={styles.image}>
              <img className={styles.leftImage} src={icamsImg} />
            </div>
            <div className={styles.login}>
              <h2>Welcome to iCAMS</h2>
              <p>Forgot Your Password</p>
              <div className={styles.logindata}>
                <div className={styles.input}>
                  {renderInput("Email", formdata.email, "email",'abc@gmail.com')}
                </div>
              </div>
              <button onClick={handleSubmit} className={styles.log_btn}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </>
  );
};

export default ForgotPass;
