import React, { useEffect, useState } from "react";
import { Layout, theme, Tooltip } from "antd";
import styles from "../../../components/AfterLogin/Employee/EmpDetail/empDetail.module.css";
import { FaUserCircle } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../../common/helpers/localStorage.helper";
import Navbar from "../../../components/AfterLogin/Employee/Navbar/Navbar";
import SmHeader from "../../../components/SmHeader/SmHeader";
import { baseURL } from "../../../Constant";

const CeoOnOEmpList = () => {
  // Variables
  const { Content } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const token = getToken("token");
  const [empList, setEmpList] = useState([]);
  const { id } = useParams();

  const DataRender = (employee) => {
    return (
      <div className={styles.EmpList_card}>
        <div className={styles.EmpList_card_img_wrp}>
          {employee.profile_photo ? (
            <img src={`${baseURL}${employee.profile_photo}`} />
          ) : (
            <FaUserCircle />
          )}
        </div>
        <Tooltip
          title={`${employee?.user?.first_name} ${employee?.user?.last_name}`}
        >
          <h2 className={styles.ellips}>
            {employee?.user?.first_name} {employee?.user?.last_name}
          </h2>
        </Tooltip>
        <p className={styles.empDes}>{employee?.designation}</p>
        <p>{employee?.department}</p>

        <button>
          {" "}
          <Link to={`/dashboard/${employee?.user?.id}`} className={styles.Link}>
            View Profile
          </Link>{" "}
        </button>
      </div>
    );
  };
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const fetchData = async (id) => {
    const url = `${baseURL}/show_ceo_dashboard_oneonone/${id}`;
    try {
      const response = await axios({
        method: "get",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      const emplist = data?.employees;
      setEmpList(emplist);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Layout>
      <Navbar collapsed={collapsed} />
      <Layout>
        <Content className={styles.empDetailPage}>
          <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />

          <div className={styles.empDetailwrp}>
            <div className={styles.EmpList}>
              <h1>Workshop Presenters</h1>
              <div className={styles.EmpList_cards}>
                {empList?.map((elist, index) => {
                  return <div key={index}>{DataRender(elist)}</div>;
                })}
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default CeoOnOEmpList;
