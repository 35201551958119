import React, { useState } from 'react'
import AdminChatDetail from './AdminChatDetail'
import { Layout } from "antd";
import Navbar from '../AfterLogin/Employee/Navbar/Navbar';
import SmHeader from '../SmHeader/SmHeader';

const Chat = () => {
  const { Content } = Layout;
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div>
      <Layout>
      <Navbar collapsed={collapsed} />
      <Layout>
        <Content>
          <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />
          <div>

        <AdminChatDetail />
          </div>
        </Content>
        </Layout>
        </Layout>
    </div>
  )
}

export default Chat