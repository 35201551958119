import React, { useState, useEffect } from "react";
import { Layout, Modal, theme, Button, Tooltip } from "antd";
import styles from "../../AdminDashboard/AdminWorkshopPanel/adminWS.module.css";
import { useNavigate, useParams, Link } from "react-router-dom";
import { baseURL } from "../../../../Constant";
import axios from "axios";
import { getToken } from "../../../../common/helpers/localStorage.helper";
import { toastNotify } from "../../../../components/shared/Toast/Toast";
import Navbar from "../../../../components/AfterLogin/Employee/Navbar/Navbar";
import SmHeader from "../../../../components/SmHeader/SmHeader";
import TTSelect from "../../../../components/shared/TTSelect/TTSelect";


const AdminOnOWork = () => {
  // Variables
  const token = getToken("token");
  const navigate = useNavigate();
  const [workShopList, setWorkShopList] = useState([]);
  const [workShopsOpt, setWorkShopsOpt] = useState([]);
  const [enrollWorkShopList, setenrollWorkShopList] = useState({
    workshop_id: "",
    workshop_name: "",
  });

  const { id } = useParams();
  const [isModal, setIsModal] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const { Content } = Layout;
  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  //functions

  const showModal = () => {
    setIsModal(true);
  };
  const handleClose = () => {
    setIsModal(false);
  };

  const handleChange = (e) => {
    console.log(e);
    const { data } = e;
    setenrollWorkShopList({
      ...enrollWorkShopList,
      ["workshop_id"]: data.id,
      ["workshop_name"]: data.value,
    });
  };

  const fetchData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/get_workshop_by_company/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      console.log(data);
      setWorkShopList(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchComapnyData = async () => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/showWorkshops`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const workshopList = await response?.data;
      let workOpt = [];
      for (const workshop of workshopList) {
        let obj = {};
        obj["id"] = workshop?.id;
        obj["label"] = workshop?.workshop_name;
        obj["value"] = workshop?.workshop_name;
        workOpt.push(obj);
      }
      setWorkShopsOpt(workOpt);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleValidation = (data) => {
    if (!data?.workshop_id) return "Answer Should Not Be Empty";
    return true;
  };

  const handleSubmit = async () => {
    let isValid = handleValidation(enrollWorkShopList);
    if (isValid === true) {
      toastNotify("Answer Added Successfully", "sucess");
      let _payload = { ...enrollWorkShopList };
      delete _payload["workshop_name"];
      _payload["company_id"] = id;

      try {
        const response = await axios({
          method: "post",
          data: _payload,
          url: `${baseURL}/enroll_workshop`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data)
        if (response.data.status === true) {
          setIsModal(false);
          toastNotify("Answer Added Successfully", "sucess");
          fetchData(id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      console.log("false");
      toastNotify("Please Select Workshop", "error");
    }
  };

  //callbacks
  useEffect(() => {
    if (id) {
      fetchData();
      fetchComapnyData();
    }
    if (!token) navigate("/login");
  }, [id, token]);

  //others
  const DataRender = (workshoplist) => {
    return (
      <Link to={`/adminOnOEmpList/${workshoplist?.id}`}>
        <div  className={styles.admin_panel_companylist_card} >
        <div className={styles.admin_panel_com_logo}>
          <img   className={styles.admin_workshopImage} src={`${baseURL}${workshoplist?.Workshop?.images}`} />
        </div>
        <h2>{workshoplist?.Workshop?.workshop_name}</h2>
        <Tooltip title={workshoplist?.Workshop?.workshop_description} >
        <p className={(window.innerWidth > 600) && styles.ellips}>{workshoplist?.Workshop?.workshop_description}</p>
        </Tooltip>
        </div>
      </Link>
    );
  };

  return (
    <div className={styles.dashboardWrapper}>
      <Layout>
        <Navbar collapsed={collapsed} />
        <Layout>
          <Content>
            <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />

            <div
              style={{
                padding: 24,
                minHeight: 100,
                background: "#e8e8e8",
                borderRadius: borderRadiusLG,
              }}
              className={styles.workshoplistWrp}
            >
              <div className={styles.admin_panel}>
                <div className={styles.workShopList_header}>
                  <h1>Workshops</h1>
                  <Button onClick={showModal} className={styles.enrollWorkshopBtn}>Enroll WorkShop</Button>
                </div>
                <div className={styles.admin_panel_companylist}>
                  {workShopList?.map((workshop, index) => {
                    return <div key={index} >{DataRender(workshop)}</div>;
                  })}
                </div>
              </div>
            </div>

            <Modal
              title="Enroll Workshop"
              open={isModal}
              onCancel={handleClose}
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
            >
              <div className={styles.modelFormWrp}>
                <TTSelect
                  label="Workshop"
                  name="workshop_name"
                  value={enrollWorkShopList.workshop_name}
                  options={workShopsOpt}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.modelButton}>
                <Button onClick={handleSubmit}>Upload</Button>
              </div>
            </Modal>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default AdminOnOWork;
