import React, { useEffect, useState } from "react";
import styles from "./home.module.css";
import Courses from "./Cources/Courses";
// import Reviews from "./Reviews/Reviews";
import Testimonials from "./Reviews/Testimonials";
import Footer from "./Footer/Footer";
import home_logo from "../../assets/iWalkTheTalk_Logo_final.png";
import Header from "./Header/Header";
import { Button, Card, Carousel, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { GoHome } from "react-icons/go";
import Regulatory from "../../assets/beforelogin_services_images/ro.png";
import leadership from "../../assets/beforelogin_services_images/ra.png";
import Technical from "../../assets/beforelogin_services_images/tw.png";
import pharmaExcellence from "../../assets/beforelogin_services_images/pi.png";
import DMF from "../../assets/beforelogin_services_images/lw.png";
import QualityCompliance from "../../assets/beforelogin_services_images/bs.png";

const Home = () => {
  // Template

  const courses = (img, BGcolor, title, content, fade, link) => {
    return (
      <div className={styles.animation}>
        <div
          className={styles.servicesCard}
          data-aos={fade}
          data-aos-delay="500"
        >
          <div
            className={styles.servicesCard_img}
            style={{ backgroundColor: BGcolor }}
          >
            <img src={img} />
          </div>
          <h3 dangerouslySetInnerHTML={{ __html: title }}></h3>
          <div>
            <p
              className={styles.courseContent}
              dangerouslySetInnerHTML={{ __html: content }}
            ></p>
          </div>
          <div className={styles.serviceReadMore}>
            <a href={link} target="_blank">
              <Button style={{ backgroundColor: BGcolor, color: "white" }}>
                Read More
              </Button>
            </a>
          </div>
        </div>
      </div>
    );
  };

  const [menu, setMenu] = useState(false);

  // const closeMenuBar = () => {
  //   setMenu(false);
  // };

  return (
    <div className="beforeLoginContenet">
      <div className={styles.header}>
        <Header setHeader={setMenu} />
      </div>
      <div className={styles.container}>
        <div
          data-aos="zoom-in"
          data-aos-delay="5000"
          className={styles.section1}
        >
          <img src={home_logo} />
        </div>
        <div className={styles.section3}>
          <h1 className="heading">Services iCretegy Offers..</h1>
          <div className={styles.servicesCardlist}>
            {courses(
              Regulatory,
              "#cd001a",
              "iRegulatoryServices<sup>®</sup>",
              `End to End (E2E) <strong>Regulatory Affairs</strong> and Regulatory Operations (<strong>eCTD</strong>) services across the globe`,
              "fade-right",
              "https://icretegy.com/service/iRegulatoryServices/"
            )}
            {courses(
              leadership,
              "#ef6a00",
              "iLeadershipWorkshop<sup>®</sup>",
              "<strong>Caoch2Coachee</strong>: Sharpen your leadership skills to enhance Strategic Execution to the Next level",
              "fade-up",
              "https://icretegy.com/service/iLeadershipWorkshop/"
            )}
            {courses(
              Technical,
              "#f2cd00",
              "iTechnicalWorkshop<sup>®</sup>",
              "<strong>Train2Trainers</strong>: To enhance best practices in Technical areas using On-Site Workshops & Trainings",
              "fade-left",
              "https://icretegy.com/service/iTechnicalWorkshop/"
            )}
            {courses(
              pharmaExcellence,
              "#79c300",
              "iPharmaExcellence<sup>®</sup>",
              "<strong>Innovation</strong> through Mascot based training, ChatBot, & other Excellence ideas to enhance your productivity & output.",
              "fade-right",
              "https://icretegy.com/service/iPharmaExcellence/"
            )}
            {courses(
              DMF,
              "#1961ae",
              "iDMFReview&Compliance<sup>®</sup>",
              "End to End (E2E) <strong>API DMF services</strong> across the globe including supplier Quality Audits, GMP Inspection",
              "fade-down",
              "https://icretegy.com/service/iTechnicalTools/"
            )}
            {courses(
              QualityCompliance,
              "#61007d",
              "iQualityCompliance<sup>®</sup>",
              "<strong>cGMP Audits, Pre-Approval (PAI)</strong> inspection and Post surveillance Inspection for FDF dosage form.",
              "fade-left",
              "https://icretegy.com/service/iQualityCompliance/"
            )}
          </div>
        </div>
        <div className={styles.section4}>
          <h1 className="heading">
            About iCAMS<sup>®</sup>
          </h1>
          <ul className={styles.section4_Desc}>
            <li className={styles.li} style={{ marginBottom: "1rem" }}>
              {" "}
              iCAMS<sup>®</sup> (iCret Accountability Management Software) is
              rooted within the theme of "iWalkTheTalk"<sup>®</sup>, which
              signifies putting words into action. This software encapsulates
              the essence of integrity; when thoughts, words and action are in
              harmony. Whatever the presenters have discussed during their
              respective workshops, they will practice during their day-day
              lives.{" "}
            </li>
            <li className={styles.li} style={{ marginBottom: "1rem" }}>
              It inspires leaders to not just attend leadership workshops, but
              to actively and consistently engage themselves in their ongoing
              development journey.{" "}
            </li>
            <li className={styles.li} style={{ marginBottom: "1rem" }}>
              This software effectively and directly delivers tailor-made
              leadership lessons and introspective self-evaluation inquiries, so
              individuals can dedicate themselves to a year-long process of
              growth and continuous improvement, as well as inspire others to
              think more and do more.{" "}
            </li>
            <li className={styles.li} style={{ marginBottom: "1rem" }}>
              {" "}
              Through this dynamic software, we are able to track every person’s
              progress and observe how they plan to implement their newly
              acquired and strengthened skills with the help of one-on-one
              coaching programs & more. This includes charts and graphics which
              comprehensively display their present and future objectives. This
              360° feedback will be viewed and monitored by their peers (CFTs),
              subordinates, HR, CEO(s), upper-management & a dedicated iCAMS
              mentor who ensures well regulated progress every week, which is
              crucial for professional success.
            </li>
            <li className={styles.li} style={{ marginBottom: "1rem" }}>
              Together let’s experience humanistic improvement that nurtures
              personal, professional, and spiritual growth; ultimately fostering
              both individuals and the company towards absolute and
              monumental success.
            </li>
          </ul>

          <p data-aos="fade-right" className={styles.CusPara}></p>
        </div>
        <div className={styles.section5}>
          <Courses />
        </div>

        <div className={styles.section6}>
          <Testimonials />
        </div>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
      <div className={`${styles.open} ${menu ? styles.show : ""}`}>
        <Link className={styles.menuBar_link} to="/">
          Home
        </Link>
        <Link className={styles.menuBar_link} to="/signup">
          Sign up
        </Link>
        <Link className={styles.menuBar_link} to="/login">
          Log in
        </Link>
        <Link className={styles.menuBar_link} to="/t_s">
          Terms & Conditions
        </Link>
        <Link className={styles.menuBar_link} to="/privacyAndPolicy">
          Privacy Policy
        </Link>
      </div>
    </div>
  );
};

export default Home;
