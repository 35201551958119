import React, { useState, useEffect } from "react";
import { Layout, Divider, Select, Button, Modal } from "antd";
import Navbar from "../Navbar/Navbar";
import styles from "./emphome.module.css";
import { Link } from "react-router-dom";
import {
  getToken,
  getUserInfo,
} from "../../../../common/helpers/localStorage.helper";
import { baseURL } from "../../../../Constant";
import SmHeader from "../../../SmHeader/SmHeader";
import learntolead_logo from "../../../../../src/assets/Coach2Coacee.png";
import { useRecoilState } from "recoil";
import { AtomUserInfo } from "../../../../store/genral.store";
import ApexCharts from "react-apexcharts";
import axios from "axios";
import OneToOne from "../../../../assets/one_to_one_coaching.png";
import teamWork from "../../../../assets/teamWork.png";
import groupCoaching from "../../../../assets/groupCoaching.png";
import leadership from "../../../../assets/leadership.png";
import Chatbox from "./../../Chatbox/Chatbox";
import { FaRegEdit } from "react-icons/fa";
import { toastNotify } from "../../../shared/Toast/Toast";
import TextArea from "antd/es/input/TextArea";

const EmpHome = () => {
  //variables
  const token = getToken("token");
  const [userInfo, setUserInfo] = useRecoilState(AtomUserInfo);
  const user = getUserInfo();
  const [selectedOption, setSelectedOption] = useState(0);
  const [filterOptions, setFilterOptions] = useState([]);
  const [graphData, setGraphData] = useState({});

  const [selectedFirstOption, setSelectedFirstOption] = useState(""); // Set default value here
  const { Content } = Layout;
  const [briefDescription, setBriefDescription] = useState({
    description: "",
    description_1: "",
    description_2: "",
  });
  const [editBriefDescription, setEditBriefDescription] = useState({
    description: "",
    description_1: "",
    description_2: "",
  });

  const [isOpen, setIsOpen] = useState(false);
  
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "radialBar",
      series: graphData[selectedFirstOption] || [],
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show: true,
            fontSize: "10px",
            fontWeight: 600,
            offsetY: -10,
            width: "4rem",
          },
          total: {
            show: true,
          },
          enabled: true,
        },
        hollow: {
          size: "40",
        },
        max: 10, // Set the maximum value for the radial bar chart
        min: 1, // Set the minimum value for the radial bar chart
      },
    },
    labels: [
      "Self Assessment",
      "Peer (CFT) Assessment",
      "Subordinate Assessment",
      "iCretegy Assessment",
    ],
    responsive: [
      {
        breakpoint: 1440,
        options: {
          chart: {
            height: 300,
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
        },
      },
      {
        breakpoint: 991,
        options: {
          chart: {
            height: 280,
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
        },
      },
      {
        breakpoint: 781,
        options: {
          chart: {
            height: 200,
          },

          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
        },
      },
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 300,
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 250,
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
        },
      },
    ],
  });

  //constant
  const labels = [
    {
      id: 1,
      label: "Self Assessment",
      color: "#249efa",
    },

    {
      id: 2,
      label: "Peer (CFT) Assessment",
      color: "#24e5a4",
      hidden: userInfo.is_oneonone,
    },
    {
      id: 3,
      label: "Subordinate Assessment",
      color: "#fcba39",
      hidden: userInfo.is_oneonone,
    },
    {
      id: 4,
      label: "iCretegy Assessment",
      color: "#fd5f76",
    },
  ];

  //Callbacks
  useEffect(() => {
    if (user.id) {
      fetchHomeGraphData(user.id);
      getUserData(user.id);
    }
  }, [user.id]);

  useEffect(() => {
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      chart: {
        ...prevChartOptions.chart,
        series:
          (selectedFirstOption ? graphData[selectedOption.value] : "") ||
          graphData[selectedOption] ||
          [],
      },
    }));
  }, [graphData, selectedOption]);

  //funcitons

  const fetchHomeGraphData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/last_mr/${user.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const GraphData = await response?.data;
      console.log(GraphData.month,'data');
      setGraphData(graphData);

      let _chatData = {...chartOptions}
      let _plotOpt = {..._chatData.plotOptions}
      let _chatOpt = {..._plotOpt.radialBar}
      let _datalabels = {..._chatOpt.dataLabels}
      let _total = {..._datalabels.total}
      _total.label = GraphData.month;
      _datalabels.total = _total;
      _chatOpt.dataLabels = _datalabels;
      _plotOpt.radialBar  = _chatOpt;
      _chatData.plotOptions= _plotOpt; 
      setChartOptions(_chatData)
      let graphFilterOption = [];
      for (const graph in GraphData.average_by_course) {
        let obj = {};
        obj["label"] = graph;
        obj["value"] = graph;
        graphFilterOption.push(obj);
      }
      setFilterOptions(graphFilterOption);
      setSelectedOption(graphFilterOption[0]);
      setSelectedFirstOption(graphFilterOption[0]);
      setGraphData(GraphData?.average_by_course);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFilterChange = (e) => {
    setSelectedOption(e);
  };

  const getUserData = async (id) => {
    const response = await axios({
      method: "get",
      url: `${baseURL}/show_user_profile/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response?.data;
    setBriefDescription({
      description: data.description,
      description_1: data.description_1,
      description_2: data.description_2,
    });
    setUserInfo(data);
  };

  const handleEditDes = async () => {
    const updatedFormData = new FormData();
    updatedFormData.append("description", editBriefDescription.description);
    updatedFormData.append("description_1", editBriefDescription.description_1);
    updatedFormData.append("description_2", editBriefDescription.description_2);
    const response = await axios({
      method: "post",
      data: updatedFormData,
      url: `${baseURL}/update_user_profile/${user?.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.message === "User updated successfully.") {
      setIsOpen(false);
      setEditBriefDescription({
        description: "",
        description_1: "",
        description_2: "",
      });
      toastNotify("Brief Description Updated Successfully", "success");
      getUserData(userInfo?.user?.id);
    }
  };

  const openModal = () => {
    setIsOpen(true);
    setEditBriefDescription(briefDescription);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleKeyUp = (e) => {
    if (e.code === "Enter") {
      handleEditDes();
    }
  };

  const handleInputChange = (val) => {
    const { name, value } = val.target;
    setEditBriefDescription({ ...editBriefDescription, [name]: value });
  };

  // others
  const DataCardRender = (icons, label, content) => {
    return (
      <div className={styles.ceoHomeCard}>
        <div className={styles.adminhome_card_icons}>
          <img src={icons} />
        </div>
        <div className={styles.adminHome_serCard_content}>
          <h2>{label}</h2>
          <p>{content}</p>
        </div>
      </div>
    );
  };

  const renderInputTemp = (label, value, name) => {
    return (
      <div className={styles.inp}>
        <TextArea
          className={styles.input}
          name={name}
          value={value}
          label={label}
          rows={3}
          onKeyUp={handleKeyUp}
          // onChange={(e) =>
          //   handleAnswerInput(e, name, taskData, data, idx, index)
          // }
          onChange={handleInputChange}
        />
        {/* <TTInput
          onKeyUp={handleKeyUp}
          value={value}
          label={label}
          name={name}
          onChange={handleInputChange}
        /> */}
      </div>
    );
  };

  return (
    <div className={styles.homewrp}>
      <Layout>
        <Navbar />
        <Layout>
          <Content className={styles.HomeContentWrapper}>
            <SmHeader />

            <div className={styles.HomeContent}>
              <div className={styles.home_page_wrapper}>
                <div className={styles.home_page_contant}>
                  <div className={styles.dash_inline_wrapper}>
                    <div className={styles.inline_header_img}>
                      <img
                        src={`${baseURL}${userInfo?.profile_photo}`}
                        alt="image"
                      />
                    </div>
                    <div className={styles.profileCardContent}>
                      <h2>
                        {userInfo?.user?.first_name} {userInfo?.user?.last_name}
                      </h2>
                      <div className={styles.empdesignation}>
                        {userInfo.role === "employee" && (
                          <p>
                            {userInfo?.designation} - {userInfo?.department}
                          </p>
                        )}
                        {userInfo.role === "ceo" && (
                          <p> {userInfo?.designation}</p>
                        )}
                        <p>{userInfo?.company_name}</p>
                      </div>
                    </div>
                  </div>
                  <div className={styles.home_logo}>
                    <div className={styles.home_page_content_img}>
                      <img src={learntolead_logo} />
                    </div>
                  </div>
                </div>
                {userInfo.role === "employee" && (
                  <div className={styles.header_sec3}>
                    <div className={styles.sec3_left}>
                      <div className={styles.chartLabel}>
                        <div className={styles.chartheader}>
                          <label>Last Month Review</label>
                          <Select
                            value={selectedOption}
                            onChange={handleFilterChange}
                            style={{ width: 200 }}
                            options={filterOptions}
                          />
                        </div>
                      </div>
                      <ApexCharts
                        options={chartOptions}
                        series={chartOptions.chart.series}
                        type="radialBar"
                        height={350}
                      />
                      <div className={styles.DisplaychartLabel}>
                        {labels?.map((label) => {
                          if (!label.hidden)
                            return (
                              <div className={styles.labels}>
                                <span
                                  style={{
                                    backgroundColor: label.color,
                                    width: "1rem",
                                    height: "1rem",
                                    marginRight: "0.5rem",
                                  }}
                                ></span>
                                <p
                                  style={{
                                    fontWeight: "800",
                                    textAlign: "left",
                                  }}
                                >
                                  {label.label}
                                </p>
                              </div>
                            );
                        })}
                      </div>
                    </div>

                    <div className={styles.sec3_right}>
                      <div className={styles.flex}>
                        <h2>
                          Brief About {userInfo?.user?.first_name}{" "}
                          {userInfo?.user?.last_name}
                        </h2>
                        <div className={styles.editDesBtn}>
                          <Button onClick={() => openModal()}>
                            <FaRegEdit />
                          </Button>
                        </div>
                      </div>
                      <div className={styles.content}>
                        {briefDescription?.description}
                      </div>
                      {briefDescription?.description && (
                        <Divider
                          style={{
                            borderWidth: "0.8px",
                            borderColor: "#0000004d",
                            margin: "2rem 0px",
                          }}
                        />
                      )}
                      <div className={styles.content}>
                        {briefDescription?.description_1}
                      </div>
                      {briefDescription?.description_1 && (
                        <Divider
                          style={{
                            borderWidth: "0.8px",
                            borderColor: "#0000004d",
                            margin: "2rem 0px",
                          }}
                        />
                      )}
                      <div className={styles.content}>
                        {briefDescription?.description_2}
                      </div>
                      {/* <div
                        dangerouslySetInnerHTML={{
                          __html: userInfo?.description,
                        }}
                      /> */}
                      {/* <h2 style={{ margin: "0" }}>
                        Brief About Ms. Rajni Sakpal :
                      </h2>
                      <p
                        className={styles.content}
                        style={{ marginTop: "3rem" }}
                      >
                        Ms. Rajni's presentation (‘Your Mindset is Your Future’)
                        was very well appreciated in ‘Learn2Lead Leadership
                        Workshop’ conducted by Harman in association of iCretegy
                        at Aurangabad on March 2<sup>nd</sup> & 3<sup>rd</sup>{" "}
                        2024.
                      </p>
                      <Divider
                        style={{
                          borderWidth: ".8px",
                          borderColor: "black",
                          margin: "2rem 0",
                        }}
                      />
                      <p className={styles.content}>
                        <span
                          style={{
                            fontSize: "large",
                            fontWeight: "1000",
                            marginRight: ".5rem",
                          }}
                        >
                          Ms. Rajni
                        </span>
                        has {">"} 25 years of experience in Import-Export area
                        in Pharma Industry & currently she is working with
                        Harman Finochem Ltd. as a{" "}
                        <strong style={{ marginRight: ".3rem" }}>
                          Head of Import-Export
                        </strong>
                        Department.
                      </p>

                      <Divider
                        style={{
                          borderWidth: ".8px",
                          borderColor: "black",
                          margin: "2rem 0",
                        }}
                      />
                      <p className={styles.content}>
                        She handles work from Head Office Mumbai and she
                        directly reports to both the Directors {"("}Mr. Harpreet
                        & Dr. Gurpreet{")"}. Her Cross Functional Teams which
                        she works day-in & day-out are Marketing - API & FDF,
                        Plant Operations, Purchase, Accounts and Finance.
                      </p>

                      <br /> */}
                    </div>

                    <Modal
                      // title="Edit Brief"
                      open={isOpen}
                      onCancel={closeModal}
                      cancelButtonProps={{ style: { display: "none" } }}
                      okButtonProps={{ style: { display: "none" } }}
                      width={800}
                    >
                      <h2>Edit Brief</h2>

                      {/* <Editor
                        apiKey="g6wtedb6qqkdbiu9l6ca2g06mgnl8ynnjy0j66xqldgvpmv6"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue={editorRef.current}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            "ai preview powerpaste casechange footnotes tinycomments searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed advtemplate codesample table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist wordcount tinymcespellchecker mergetags a11ychecker editimage help formatpainter permanentpen pageembed charmap quickbars linkchecker emoticons advtable export mentions typography markdown importword",
                          ],
                          toolbar:
                            "undo redo | importword | aidialog aishortcuts | blocks fontsizeinput | bold italic | align numlist bullist | link image | table media pageembed | lineheight  outdent indent | strikethrough forecolor backcolor formatpainter removeformat | charmap emoticons checklist | code fullscreen preview | save print export | pagebreak anchor codesample footnotes mergetags | addtemplate inserttemplate | addcomment showcomments | ltr rtl casechange | spellcheckdialog a11ycheck",
                          importword_service_url: "add.url.here",
                          templates: [],
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                      /> */}
                      <div className={styles.editorWrapper}>
                        {renderInputTemp(
                          "Description 1",
                          editBriefDescription?.description,
                          "description"
                        )}
                        {renderInputTemp(
                          "Description 2",
                          editBriefDescription?.description_1,
                          "description_1"
                        )}
                        {renderInputTemp(
                          "Description 3",
                          editBriefDescription?.description_2,
                          "description_2"
                        )}
                        {/* <JoditEditor
                        ref={editorRef}
                        value={editorRef.current}
                        style={{ fontFamily:"Helvetica,Arial,sans-serif", fontSize:70  }}
                        tabIndex={1} // tabIndex of textarea
                        onChange={(newContent) => {
                          editorRef.current = newContent
                        }}
                      /> */}
                      </div>
                      <div className={styles.submitBtn}>
                        <Button onClick={handleEditDes}>Submit</Button>
                      </div>
                    </Modal>
                  </div>
                )}

                {userInfo.role === "ceo" && (
                  <div className={styles.ceoCards}>
                    <h1>iLeadershipWorkshop</h1>
                    <div className={styles.ceoCardswrp}>
                      <Link to={`/ceoOnOEmpList/${user.id}`}>
                        {DataCardRender(OneToOne, "One-On-One Coaching")}
                      </Link>
                      {DataCardRender(teamWork, "Team Coaching")}
                      {DataCardRender(groupCoaching, "Group Coaching ")}
                      <Link to="/emp-detail">
                        {DataCardRender(
                          leadership,
                          "On-site Leadership Workshop"
                        )}
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Content>
        </Layout>
        <Chatbox />
      </Layout>
    </div>
  );
};

export default EmpHome;
