import React, { useState, useEffect } from "react";
import {
  Layout,
  theme,
  Button,
  Modal,
  ConfigProvider,
  Popconfirm,
  message,
  Tooltip,
} from "antd";
import Navbar from "../../../../components/AfterLogin/Employee/Navbar/Navbar";
import SmHeader from "../../../../components/SmHeader/SmHeader";
import styles from "./adminWS.module.css";
import {
  getToken,
  getUserInfo,
} from "../../../../common/helpers/localStorage.helper";
import { baseURL } from "../../../../Constant";
import axios from "axios";
import TTInput from "./../../../../components/shared/TTInput/TTInput";
import { Toast } from "../../../../components/shared/Toast/Toast";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const AdminWorkshopPanel = () => {
  // Variables

  const token = getToken("token");
  const [collapsed, setCollapsed] = useState(false);
  const { id } = useParams();
  const [workShopList, setWorkShopList] = useState([]);
  const [workShopDetail, setWorkShopDetail] = useState({
    workshop_name: "",
    workshop_description: "",
    images: "",
  });
  const { Content } = Layout;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [isModal, setIsModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [preview, setPreview] = useState(false);

  //functions
  const showAddModal = () => {
    setIsModal(true);
    setIsEdit(false);
  };
  const handleClose = () => {
    setIsModal(false);
    setWorkShopDetail({});
  };
  const showEditModal = (data) => {
    setIsEdit(true);
    setIsModal(true);
    setWorkShopDetail(data);
    // setIsEditModal(true);
  };

  const fetchData = async () => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/showWorkshops`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      setWorkShopList(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, data } = e;
    setWorkShopDetail((prevState) => ({
      ...prevState,
      [name]: data,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setWorkShopDetail((prevState) => ({
      ...prevState,
      images: file,
    }));
    if (isEdit) {
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("workshop_name", workShopDetail.workshop_name);
    formData.append(
      "workshop_description",
      workShopDetail.workshop_description
    );
    formData.append("images", workShopDetail.images);

    try {
      const response = await axios({
        method: "post",
        data: formData,
        url: `${baseURL}/addWorkshop`,
        headers: {
          "Content-Type": "application/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      let _workshopList = [...workShopList];
      _workshopList.push(data);
      toast.success("Workshop added successfully!");
      setWorkShopList(_workshopList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsModal(false);
    setWorkShopDetail({});
  };

  const handleUpdateData = async () => {
    const formData = new FormData();
    formData.append("workshop_name", workShopDetail.workshop_name);
    formData.append(
      "workshop_description",
      workShopDetail.workshop_description
    );
    formData.append("images", workShopDetail.images);
    try {
      const response = await axios({
        method: "post",
        data: formData,
        url: `${baseURL}/updateWorkshop/${workShopDetail.id}`,
        headers: {
          "Content-Type": "application/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response?.data;
      let _workShopList = [...workShopList];
      let index = workShopList.findIndex((workShop) => workShop.id === data.id);
      _workShopList.splice(index, 1, data);
      setWorkShopList(_workShopList);
      toast.success("Workshop updated Successfully");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setWorkShopDetail({});
    setIsModal(false);
  };

  const confirm = () => {
    message.success("Click on Yes");
  };
  const cancel = (e) => {
    message.error("Click on No");
  };

  const handleDeleteData = async (workshop) => {
    try {
      const response = await axios({
        method: "post",
        url: `${baseURL}/deleteWorkshop/${workshop.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = response?.data;
      const _workShopList = [...workShopList];
      let index = workShopList.findIndex((val) => val.id === workshop.id);
      _workShopList.splice(index, 1);
      setWorkShopList(_workShopList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //callbacks
  useEffect(() => {
    fetchData();
  }, []);

  //others
  const DataRender = (workshop) => {
    return (
      <>
        <div className={styles.admin_panel_com_logo}>
          <img src={`${baseURL}${workshop?.images}`} />
        </div>
        <h2>{workshop?.workshop_name}</h2>
        <Tooltip title={workshop?.workshop_description}>
          <p className={styles.ellips}>{workshop?.workshop_description}</p>
        </Tooltip>
        {/* <p>{workshop?.workshop_description}</p> */}
        <div className={styles.wsCardBtn}>
          <Button onClick={() => showEditModal(workshop)}>Edit</Button>
          <Popconfirm
            title="Delete the task"
            description="Are You Sure You Want to Delete This  Workshop?"
            onConfirm={() => handleDeleteData(workshop)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button>Delete</Button>
          </Popconfirm>
        </div>
      </>
    );
  };

  return (
    <div className={styles.dashboardWrapper}>
      <Layout>
        <Navbar collapsed={collapsed} />
        <Layout>
          <Content>
            <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />

            <div className={styles.adminWSWrp}>
              <div className={styles.admin_panel}>
                <div className={styles.workShopList_header}>
                  <h1>Workshops</h1>
                  <Button onClick={showAddModal} className={styles.enrollWorkshopBtn}>Add WorkShop</Button>
                </div>
                <div className={styles.admin_panel_companylist}>
                  {workShopList?.map((workshop, index) => {
                    return (
                      <div
                        className={styles.admin_panel_companylist_card}
                        key={index}
                      >
                        {DataRender(workshop)}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <ConfigProvider
              theme={{
                components: {
                  Modal: {
                    titleFontSize: 24,
                  },
                },
              }}
            >
              <Modal
                title={isEdit ? "Edit WorkShop" : "Add New WorkShop"}
                open={isModal}
                onCancel={handleClose}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
              >
                <div className={styles.modelFormWrp}>
                  <TTInput
                    type="text"
                    label="WorkShop name:"
                    name="workshop_name"
                    className={styles.workshopName}
                    value={workShopDetail.workshop_name}
                    onChange={handleInputChange}
                    placeholder="Workshop Name"
                  />
                  <TTInput
                    type="text"
                    label="WorkShop Description:"
                    name="workshop_description"
                    className={styles.workshopDescription}
                    value={workShopDetail.workshop_description}
                    onChange={handleInputChange}
                    placeholder="Workshop Description"
                  />
                  <div className={styles.workshopimage}>
                    <label>Upload WorkShop Image:</label>
                    {preview ? (
                      <img className={styles.previewImage} src={preview} />
                    ) : (
                      <img
                        className={styles.previewImage}
                        src={`${baseURL}${workShopDetail.images}`}
                      />
                    )}
                    <input
                      type="file"
                      className={styles.imageInput}
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                <div className={styles.modelButton}>
                  <Button onClick={isEdit ? handleUpdateData : handleSubmit}>
                    {isEdit ? "Update" : "Upload"}
                  </Button>
                </div>
              </Modal>
            </ConfigProvider>
          </Content>
          <Toast />
        </Layout>
      </Layout>
    </div>
  );
};

export default AdminWorkshopPanel;
