import React, { useState, useEffect } from "react";
import {
  Layout,
  theme,
  Dropdown,
  Modal,
  Divider,
  Button,
  DatePicker,
} from "antd";
import Navbar from "../../../../components/AfterLogin/Employee/Navbar/Navbar";
import SmHeader from "../../../../components/SmHeader/SmHeader";
import styles from "./adminUM.module.css";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  getToken,
  getUserInfo,
} from "../../../../common/helpers/localStorage.helper";
import { baseURL } from "../../../../Constant";
import { FaUserCircle } from "react-icons/fa";
import axios from "axios";
import TTSelect from "../../../../components/shared/TTSelect/TTSelect";
import { format } from 'date-fns';
import TTInput from "../../../../components/shared/TTInput/TTInput";
import { toastNotify } from "../../../../components/shared/Toast/Toast";
import { toast } from "react-toastify";


const AdminUserManual = () => {
  // Variables
  const token = getToken("token");
  const navigate = useNavigate();
  const { id } = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const [empList, setEmpList] = useState([]);
  const [enrollDate, setEnrollDate] = useState('');
  const [allCourseList, setAllCourseList] = useState([]);
  const [empEnrollCourse, setEmpEnrollCourse] = useState({
    course: null,
    courseId :null,
    enrolled_user: null,
  });
  const { Content } = Layout;
  const {
    token: {borderRadiusLG },
  } = theme.useToken();

  const [manual,setManual] = useState('')
  //constants




  const handleChange = (e) => {
    const file = e.target.files[0];
    // setManual((prevState) => ({
    //   ...prevState,
    //   presentation: file,
    // }));
    setManual(file)
    // setCourseChange((prevChanges) => ({
    //   ...prevChanges,
    //   presentation: file,
    // }));
  };
  const handleValidation = (data)=>{
    if(!data) return 'file is not uploaded!'
    return true
  }

  const handleSubmitManual = async() =>{
    let validationResult = handleValidation(manual);
    if (validationResult === true) {
      const formData = new FormData();
      formData.append('manual',manual)
      try {
        const response = await axios({
          method: "post",
          data: formData,
          url: `${baseURL}/user_manual`,
          headers: {
            "Content-Type": "application/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response?.data;
        if(data.status === 200){
            toast.success('Manual uploaded successfully!')
        }else{
            toast.error('file not upload Successfully!')
        }
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      toastNotify(validationResult, "error");
    }
  } 
  
 





  return (
    <div className={styles.dashboardWrapper}>
      <Layout>
        <Navbar collapsed={collapsed} />
        <Layout>
          <Content>
            <SmHeader collapsed={collapsed} setCollapsed={setCollapsed} />
            <div
              style={{
                padding: 24,
                minHeight: 100,
                background: "#e8e8e8",
                borderRadius: borderRadiusLG,
              }}
            >
                <h2>
                    User Manual
                </h2>
                <input 
                type="file"
                onChange={handleChange}
                />
                <Button onClick={handleSubmitManual}>Upload</Button>
              </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default AdminUserManual;
