import React, { useEffect, useRef } from "react";
import "./App.css";
import Home from "./components/BeforeLogin/Home";
// import { Route, Routes } from "react-router-dom";
import {
  Routes,
  Route,
  BrowserRouter,
  HashRouter,
  Outlet,
} from "react-router-dom";

import Login from "./components/BeforeLogin/login/Login";
import Signup from "./components/BeforeLogin/Signup/Signup";
import EmpHome from "./components/AfterLogin/Employee/Home/EmpHome";
import Dashbord from "./components/AfterLogin/Employee/Dashboard/Dashbord";
import Course from "./components/AfterLogin/Employee/Course/Course";
import Profile from "./components/AfterLogin/Employee/Profile/Profile";
import CoursesDetails from "./components/AfterLogin/Employee/Course/CoursesDetails";
import { Toast } from "./components/shared/Toast/Toast";
import CeoDashboard from "./Pages/Dashboard/CeoDashboard/CeoDashboard";
import EmpDetail from "./components/AfterLogin/Employee/EmpDetail/EmpDetail";
import AdminHome from "./Pages/Dashboard/AdminDashboard/AdminHome/AdminHome";
import AdminWorkshopPanel from "./Pages/Dashboard/AdminDashboard/AdminWorkshopPanel/AdminWorkshopPanel";
import AdminCoursePanel from "./Pages/Dashboard/AdminDashboard/AdminCoursePanel/AdminCoursePanel";
import AdminCompanyPanel from "./Pages/Dashboard/AdminDashboard/AdminCompanyPanel/AdminCompanyPanel";
import AdminTaskPanel from "./Pages/Dashboard/AdminDashboard/AdminTaskPanel/AdminTaskPanel";
import WorkShopList from "./Pages/Dashboard/AdminDashboard/WorkShopList/WorkShopList";
import AdminEmpList from "./Pages/Dashboard/AdminDashboard/AdminEmpList/AdminEmpList";
import GlobalProvider from "./Providers/Global.provider";
import { RecoilRoot } from "recoil";
import CalendarList from "./components/AfterLogin/Employee/Calendar/CalendarList";
import AOS from "aos";
import "aos/dist/aos.css";
import CeoCalender from "./Pages/Dashboard/CeoDashboard/CeoCalender";
import ScrollToTop from "./Providers/ScrollToTop";
import Inline from "./components/AfterLogin/Employee/Inline/InlineOfEmp";
import InlineCourseDetail from "./components/AfterLogin/Employee/Inline/InlineCourseDetail";
import CeoCourseDetail from "./Pages/Dashboard/CeoDashboard/CeoCourseDetail";
import Subordinate from "./components/AfterLogin/Employee/SubOrdinate/Subordinate";
import SubCourseDetail from "./components/AfterLogin/Employee/SubOrdinate/SubCourseDetail";
import InlineCourse from "./components/AfterLogin/Employee/Inline/InlineCourse";
import SubordinateCourse from "./components/AfterLogin/Employee/SubOrdinate/SubordinateCourse";
import UserManual from "./components/AfterLogin/Employee/userManual/UserManual";
import Chat from "./components/Chat/Chat";
import AdminCourseDetail from "./Pages/Dashboard/AdminDashboard/AdminCourseDetail/AdminCourseDetail";
import ForgotPass from "./components/BeforeLogin/ForgotPass/ForgotPass";
import ResetPass from "./components/BeforeLogin/ResetPass/ResetPass";
import SubCalendar from "./components/AfterLogin/Employee/SubOrdinate/SubCalendar";
import T_S from "./components/BeforeLogin/T&S/T_S";
import AdminUserManual from "./Pages/Dashboard/AdminDashboard/AdminUserManual/AdminUserManual";
import AdminOnOComp from "./Pages/Dashboard/AdminDashboard/AdminOneOnOne/AdminOnOComp";
import AdminOnOWork from "./Pages/Dashboard/AdminDashboard/AdminOneOnOne/AdminOnOWork";
import AdminOnOEmpList from "./Pages/Dashboard/AdminDashboard/AdminOneOnOne/AdminOnOEmpList";
import AdminUserList from "./Pages/Dashboard/AdminDashboard/AdminUserList/AdminUserList";
import CurerentAssignRole from "../src/components/AfterLogin/Employee/AssignedCourse/CurerentAssignRole"
import CeoOnOEmpList from "./Pages/Dashboard/CeoDashboard/CeoOnOEmpList";
import PrivacyandPolicy from './components/BeforeLogin/PrivcyAndPolicy/PrivacyandPolicy';

function App() { 

  //callbacks
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether the animation should only happen once
      easing: "ease-out", // Easing function for the animation
    });
  }, []);

  
  return (
    <RecoilRoot>  
      <Toast />
      <ScrollToTop>
      <GlobalProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPass" element={<ForgotPass/>} />
          <Route path="/resetPass/:id" element={<ResetPass />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/t_s" element={<T_S/>} />
          <Route path="/privacyAndPolicy" element={<PrivacyandPolicy/>} />
          <Route path="/home" element={<EmpHome />} />
          <Route path="/dashboard/:id" element={<Dashbord />} />
          <Route path="/ceoCourses/:id" element={<Course />} />
          <Route path="/courses/:id" element={<Course />} />
          <Route path="/courses-details/:id" element={<CoursesDetails />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/user-manual" element={<UserManual />} />
          <Route path="/chatDetail/:id" element={<Chat />} />
          <Route path="/calendar/:id" element={<CalendarList />} />
          <Route path="/subordinate" element={<Subordinate/>} />
          <Route path="/subor-courses/:id" element={<SubordinateCourse/>} />
          <Route path="/currentAssignRole" element={<CurerentAssignRole/>} />
          <Route path="/subCalendar" element={<SubCalendar/>} />
          <Route path="/inline" element={<Inline />} />
          <Route path="/inlineCourse/:id" element={<InlineCourse />} />
          <Route path="/sub-Detials/:id" element={<SubCourseDetail />} />
          <Route path="/inline-Detials/:id" element={<InlineCourseDetail />} />
          <Route path="/emp-detail" element={<EmpDetail />} />
          <Route path="/admin_home" element={<AdminHome />} />
          <Route path="/WorkShopsList/:id" element={<WorkShopList />} />
          <Route path="/ceo-dashboard/:id" element={<CeoDashboard />} />
          <Route path="/ceoCalender/:id" element={<CeoCalender />} />
          <Route path="/ceoOnOEmpList/:id" element={<CeoOnOEmpList />} />
          <Route path="/ceo-course-detail/:id" element={<CeoCourseDetail />} />
          <Route path="/adminCompany" element={<AdminCompanyPanel />} />
          <Route path="/adminWorkShops" element={<AdminWorkshopPanel />} />
          <Route path="/adminCourses" element={<AdminCoursePanel />} />
          <Route path="/adminTask/:id" element={<AdminTaskPanel />} />
          <Route path="/adminUserManual/:id" element={<AdminUserManual />} />
          <Route path="/adminCalender" element={<CeoCalender />} />
          <Route path="/adminEmpList/:id" element={<AdminEmpList />} />
          <Route path="/adminCourseDetail/:id" element={<AdminCourseDetail />} />
          <Route path="/adminOnOComp" element={<AdminOnOComp />} />
          <Route path="/adminOnOWork/:id" element={<AdminOnOWork />} />
          <Route path="/adminOnOEmpList/:id" element={<AdminOnOEmpList />} />
          <Route path="/adminUserList" element={<AdminUserList />} />
          

        </Routes>
      </GlobalProvider>
      </ScrollToTop>
    </RecoilRoot>
  );
}

export default App;
